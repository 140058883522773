import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @materialui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h4 className={classes2.top}>ERE y ERTE: todo lo que necesitas saber</h4>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              La palabra ERTE está siendo muy utilizada en los actuales tiempos
              en los que vivimos debido a la crisis sanitaria que está haciendo
              que numerosas empresas españolas deben acogerse a este tipo de
              despidos para poder hacer frente a sus situación económica. Si no
              sabes en que consiste o quieres saber la diferencia con un
              ERE.Atento:
            </p>

            <h3 className={classes2.titulo}>¿Qué es un ERE?</h3>

            <p className={classes2.texto}>
              A continuación&nbsp; te voy a enseñar cómo funciona este tipo de
              despidos y cuáles son sus principales características, así como
              los derechos que tienes como trabajador afectado.
            </p>

            <p className={classes2.texto}>
              El expediente de regulación de empleo se trata de una herramienta
              que asumen las empresas en tiempos de crisis, en los que la
              empresa puede reducir el número de horas de tu contrato o bien
              prescindir de tus servicios de forma completa.
            </p>

            <p className={classes2.texto}>
              <strong>¿Cómo funciona un ERE?</strong>
            </p>

            <p className={classes2.texto}>
              Ante todo, debes saber que una empresa no puede llevar a cabo un
              ERE si no está en una mala situación económica. Existen tres tipos
              de ERES que te voy a enseñar a continuación:
            </p>

            <p className={classes2.texto}>
              <strong>ERE de Extinción:</strong> Se trata de un despido
              colectivo. En este tipo de ERES la empresa prescinde de un gran
              número de empleados.
            </p>

            <p className={classes2.texto}>
              <strong>ERE de Suspensión</strong>: En este tipo de ERE la empresa
              suspende la mayoría de los contratos de sus trabajadores.
            </p>

            <p className={classes2.texto}>
              <strong>ERE de Reducción de Jornada Laboral</strong>: En este tipo
              de ERE, la jornada laboral de sus trabajadores se verá reducida
              debido a su situación. La jornada puede verse reducida a tiempo
              parcial, por horas o incluso por días trabajados.
            </p>

            <p className={classes2.texto}>
              En este caso, el que nos interesa y por tanto el ERE más
              dramático, es el <strong>ERE de extinción.</strong>
            </p>

            <p className={classes2.texto}>
              Antiguamente era necesaria una autorización administrativa para
              poder hacer uno de estos despidos, pero tras la actual reforma del
              trabajo, son las empresas las que deciden cuando pueden hacer uno
              de estos procedimiento, algo que ha generado muchas quejas entre
              los trabajadores de todo el país.
            </p>

            <p className={classes2.texto}>
              Eso si, hay una serie de requisitos que las empresas deberán tener
              para poder aplicar un ERE. Las empresas deben alegar causas
              económicas, organizativas o bien técnicas que afecten a la
              producción durante al menos 90 días en empresas con menos de 100
              trabajadores.Existe causa económica cuando haya perdidas o el
              nivel de ingresos este disminuyendo peligrosamente.
            </p>

            <p className={classes2.texto}>
              Procedimiento a seguir en un Expediente de Regulación de Empleo de
              extinción:
            </p>

            <p className={classes2.texto}>
              -Primero se llevara a cabo un periodo donde los trabajadores
              podrán hacer consultas a sus representantes sindicales. En éste
              periodo los representantes deben informar de todos los datos que
              los empresarios les aporten y escuchar las dudas que puedan surgir
              a los trabajadores.
            </p>

            <p className={classes2.texto}>
              -Después de esto, se hace una memoria donde se describen las
              causas del motivo de despido con toda la documentación necesaria.
              La empresa debe justificar debidamente cuáles son las causas por
              las que quiere aplicar este tipo de despido y deberá acogerse al
              ámbito legal para realizar cualquier tipo de procedimiento.
            </p>

            <p className={classes2.texto}>
              -La empresa deberá notificar el despido a sus trabajadores a
              través de los organismos oportunos. La notificación es obligatoria
              ya que de lo contrario en Expediente no tendrá ningún valor real.
            </p>

            <p className={classes2.texto}>
              -Si los empleados impugnan la decisión del despido, podrán alegar
              su defensa a los Tribunales. De esta manera si los trabajadores no
              están de acuerdo con las condiciones que la empresa les ofrecen o
              creen que no existen motivos aparentes para realizar este tipo de
              despidos, podrán elevar la impugnación a las Tribunales que serán
              los encargados de estudiar las circunstancias por las que la
              empresa ha decidido hacer este tipo de despido y ver si cumple con
              los criterios reales.
            </p>

            <p className={classes2.texto}>
              <strong>Derechos en un ERE</strong>
            </p>

            <p className={classes2.texto}>
              En este punto te voy a enseñar cuáles son tus principales derechos
              si has sido víctima de un ERE por tu empresa:
            </p>

            <p className={classes2.texto}>
              <strong>-Derecho a indemnización:</strong> Tienes derecho a
              recibir una prestación de 20 días por año trabajado. Si el despido
              ha sido de manera improcedente los días se elevaran a 33.
            </p>

            <p className={classes2.texto}>
              <strong>
                -Derecho a solicitar una prestación por desempleo:
              </strong>{" "}
              el trabajador tendrá derecho a solicitar esta prestación cuando
              cumpla los requisitos de cotización establecidos. Por lo que si
              quieres acceder a este tipo de prestación deberás tener cotizados
              al menos 180 días en los últimos seis años trabajados. El importe
              de tu prestación dependerá de la base por la que la empresa
              estuviera cotizando por ti, así como el salario que recibieras y
              el tipo de jornada laboral con la que estabas trabajando.
            </p>

            <p className={classes2.texto}>
              <strong>-Derecho a recolocación:</strong> La empresa tiene que
              garantizarte que intentara buscarte otro trabajo similar en
              distintas empresas y con las mismas condiciones que tenías
              antiguamente.
            </p>

            <p className={classes2.texto}>
              <strong>-Derecho a impugnar:</strong> Los trabajadores afectados
              tienen derecho a elevar el despido a impugnación ante los
              tribunales.&nbsp;
            </p>

            <h3 className={classes2.titulo}>¿Qué es un ERTE?</h3>

            <p className={classes2.texto}>
              El&nbsp;Expediente de Regulación Temporal de Empleo se trata de
              una medida que adoptan las empresas en tiempos de crisis para
              reducir o suspender los contratos de sus trabajadores.
            </p>

            <p className={classes2.texto}>
              Este procedimiento es algo temporal, por lo que la empresa está
              obligada a reinstaurar tu puesto y las antiguas condiciones
              laborales cuando el periodo de crisis se acabe.&nbsp;
            </p>

            <p className={classes2.texto}>
              A continuación voy a enseñarte cuales son los tipos de ERTES que
              existen y cuáles son tus principales derechos si tu ha empresa ha
              tenido que realizar uno en sus trabajadores&nbsp;
            </p>

            <p className={classes2.texto}>
              ¿Cuáles son los tipos de ERTE que existen?
            </p>

            <p className={classes2.texto}>
              <strong>Reducción de la actividad laboral</strong>
            </p>

            <p className={classes2.texto}>
              En este tipo de procedimiento la empresa notifica a sus empleados
              que va a reducir su jornada laboral.Existe una reducción mínima
              del 10% y una máxima del 70% para poder aplicarse este tipo de
              ERTE.
            </p>

            <p className={classes2.texto}>
              <strong>Suspensión de la actividad&nbsp;</strong>
            </p>

            <p className={classes2.texto}>
              La modalidad más acusada y temida. En este caso se prescinde del
              trabajo de sus empleados durante un periodo de tiempo concreto.
            </p>

            <p className={classes2.texto}>
              Su aplicación siempre debe ser motivada por una fuerza mayor como
              el cierre aéreo, las restricciones de movilidad entre países o
              crisis sanitarias como la actual provocada por el Covid-19.
            </p>

            <p className={classes2.texto}>
              La aplicación de un ERTE deberá establecerse únicamente durante el
              tiempo que dure la crisis,por lo que no existen plazos ni mínimos
              ni máximos para aplicarla.
            </p>

            <p className={classes2.texto}>
              Un empleado que ha sufrido un ERTE pasa a quedar en situación de
              desempleo por lo que su prestación será el 70% de la base
              reguladora sobre la que este cotizando durante los seis primeros
              meses, pasando a un 50% a partir del mes séptimo. El abono de la
              prestación es a cuenta del Estado y el empleado no consumirá el
              paro que le pueda quedar al no ser un despido como tal.
            </p>

            <p className={classes2.texto}>
              Este tipo de ERTES puede afectar a un número ilimitado de
              trabajadores y tampoco existe ningún mínimo.
            </p>

            <p className={classes2.texto}>
              <strong>
                ¿Cuáles son sus beneficios para empleados y empresarios?
              </strong>
            </p>

            <p className={classes2.texto}>
              Los beneficios del ERTE consisten en poder flexibilizar la
              situación laboral en periodos de crisis y es útil para pasar una
              situación temporal, volviendo a tener los mismos derechos tras su
              reincorporación. De esta manera la empresa también reduce sus
              costes laborales para poder recuperarse de la crisis y se libera
              de tener que pagar a sus trabajadores hasta solucionar el tema por
              el cual ha tenido que realizar el ERTE.
            </p>

            <p className={classes2.texto}>
              Los empleados cuentan con la ventaja de que tras el periodo de
              crisis, sus puestos volverá a ser iguales y mientras esta dura,
              pueden seguir percibiendo un sueldo similar al que tenían, al
              menos los primeros meses. También garantiza que los empleados no
              puedan ser despedidos durante los siguientes seis meses tras
              volver al trabajo, lo cual genera cierta seguridad a la hora de
              volver de nuevo a la vida laboral.
            </p>

            <p className={classes2.texto}>
              <strong>Diferencias entre un ERTE y un ERE</strong>
            </p>

            <p className={classes2.texto}>
              Te voy a explicar cuáles son las principales diferencias entre un
              ERE y un ERTE para que puedas saber diferenciarlas de manera
              sencilla y entiendas de esta manera mejor en que consiste cada
              tipo de despido
            </p>

            <p className={classes2.texto}>
              <strong>-Siglas:</strong> La principal dolencia en sus siglas se
              encuentra en el añadido Temporal, Un ERE quiere decir Expediente
              Regulador de Empleo y un ERTE quiere decir Expediente Regulador
              Temporal de Empleo.
            </p>

            <p className={classes2.texto}>
              <strong>-Artículo del estatuto de trabajadores:</strong>
            </p>

            <p className={classes2.texto}>
              &nbsp; El artículo que recoge el ERE es el artículo 51, puedes
              encontrar sus características que:{" "}
              <a
                className={classes2.link}
                href="https://www.canaltrabajo.com/articulo-51-despido-colectivo"
              >
                https://www.canaltrabajo.com/articulo-51-despido-colectivo
              </a>
            </p>

            <p className={classes2.texto}>
              &nbsp; El artículo que regula un ERTE es el artículo 47:{" "}
              <a
                className={classes2.link}
                href="http://www2.fsc.ccoo.es/comunes/recursos/99927/doc209929_ET,_articulo_47.pdf"
              >
                http://www2.fsc.ccoo.es/comunes/recursos/99927/doc209929_ET,_articulo_47.pdf
              </a>
            </p>

            <p className={classes2.texto}>
              <strong>Situación de la empresa:</strong>
            </p>

            <p className={classes2.texto}>
              &nbsp; En un Expediente de Regulación de&nbsp; empleo la empresa
              suele cesar por completo su actividad debido a factores que le han
              hecho llegar a una crisis en la que no pueden asumir los gastos.
            </p>

            <p className={classes2.texto}>
              &nbsp; En un Expediente Temporal o ERTE, el despido es temporal,
              hasta que la situación económica que les ha llevado a la crisis
              mejore y puedan volver a reincorporar a sus trabajadores.
            </p>

            <p className={classes2.texto}>
              <strong>Despido</strong>:
            </p>

            <p className={classes2.texto}>
              &nbsp;En un ERE el despido es total, sin embargo en un ERTE tan
              solo es una suspensión temporal del contrato de trabajo, cuando
              las canciones mejores los trabajadores volverán a la empresa.
            </p>

            <p className={classes2.texto}>
              <strong>Duración del despido:</strong>
            </p>

            <p className={classes2.texto}>
              En un ERE la duración del despido suele ser definitiva por lo que
              no hay manera de volver a trabajar en esa empresa, sin embargo en
              un ERTE el despido es de carácter temporal.
            </p>

            <p className={classes2.texto}>
              <strong>Derecho a prestación contributiva:</strong>
            </p>

            <p className={classes2.texto}>
              En un ERE existe el derecho a recibir una prestación contributiva
              por desempleo según el tipo de base salarial y el tiempo que hayas
              estado cotizando para la empresa. En un ERTE no existe este tipo
              de derecho, aunque si bien es cierto que en grandes crisis como la
              que nos encontramos el gobierno está haciendo una excepción debido
              al alto número de empresas que han tenido que hacer ERTES a sus
              empleados por la actual crisis sanitaria del mundo.
            </p>

            <p className={classes2.texto}>
              Te he enseñado en que consiste un ERE y un ERTE, como puedes
              diferenciar y cuáles son tus derechos si tu empresa te ha aplicado
              uno. Ahora ya conoces un poco más en qué consisten estos
              Expedientes de Empleo que aplica el Estado y que puedes hacer ante
              ellos.
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
