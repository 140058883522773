import { makeStyles } from '@material-ui/core';
import React from 'react';
import fakeAdStyle from './FakeAdStyle';

const useStyles = makeStyles(fakeAdStyle);

export default function FakeAd({ withMargin = true }) {
  const classes = useStyles();

  return (
    <div
      className={[
        classes.adWrap,
        withMargin ? classes.withMargin : undefined,
      ].join(' ')}
    >
      Anuncio
    </div>
  );
}
