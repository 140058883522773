import React, { useEffect, useState, useCallback } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Galera from "./Galera";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useParams } from "react-router";
import {
  headerColor,
  blackColor,
  whiteColor,
  grayColor,
  fundoCursos
} from "../componentesEmpleo/material-kit-pro-react";
import { DonutSmall } from "@material-ui/icons";

import ModalForm from "./ModalForm";

import AdsGoogle from "../../../AdsGoogle";

import Footer from "./Footer";
import LongMenu from "./LongMenu";

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: grayColor[0],
    padding: theme.spacing(8, 0, 6)
  },
  textHeroContent: {
    color: whiteColor,
    fontSize: "105%",
    fontWeight: "bold"
  },
  textHeroContentDestaque: {
    color: headerColor
  },
  heroTittle: {
    color: whiteColor
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: "auto",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1,
    backgroundColor: grayColor[0],
    color: whiteColor
  },
  footer: {
    backgroundColor: fundoCursos,
    padding: theme.spacing(6),
    color: theme.palette.primary.blanco
  },
  fundoContenedorGrids: {
    backgroundColor: fundoCursos
  },

  ColorMenu: {
    backgroundColor: headerColor,
    color: whiteColor
  },

  ColorAviso: {
    backgroundColor: theme.palette.primary.aviso,
    padding: "1px",
    fontSize: "120%"
  },
  Ads: {
    color: blackColor,
    backgroundColor: fundoCursos,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textTransform: "none"
  },
  menuText: {
    marginTop: "10px",
    fontSize: "18px",
    textTransform: "none"
  },
  noEmail: {
    color: grayColor[3],
    borderColor: grayColor[3],
    marginRight: "10px",

    "&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: grayColor[5]
    },
    inscritos: {
      fontSize: "20px"
    }
  },
  inscribirme: {
    marginTop: "10px",

    color: headerColor,
    borderColor: headerColor,
    "&:hover,&:focus": {
      color: whiteColor,
      borderColor: whiteColor
    }
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh"
  }
}));
//inem,universidadmadrid,jardineria,albanil,carpintero,electricista,electronica,programacion
export default function Cursos() {
  const classes = useStyles();
  let cardsCounter = 0;

  let { category } = useParams();
  const [cursos, setCursos] = useState([]);
  const [header, setHeader] = useState("");

  const carregarCursos = useCallback(async () => {
    const url = `https://cursos.apihmservicios.com/courses/${category}`;

    const solve = await axios.get(url);
    shuffle(solve.data);
    setCursos(solve.data);
    switch (category) {
      case "universidadmadrid":
        setHeader("Universidades de Madrid");

        break;
      case "jardineria":
        setHeader("Jardinería");

        break;

      case "inem":
        setHeader("Inem");

        break;
      case "albanil":
        setHeader("Albañil");

        break;
      case "carpintero":
        setHeader("Carpintería");

        break;
      case "electricista":
        setHeader("Electricista");

        break;
      case "electronica":
        setHeader("Electrónica");

        break;

      default:
        setHeader("Cursos");

        break;
    }
  }, [setCursos, category]);

  const renderCard = curso => {
    return (
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.card}>
          <CardMedia
            className={classes.cardMedia}
            image={curso.image_url}
            title={curso.id}
          />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2">
              {`${curso.name}`}
            </Typography>
            <Typography>{`${curso.description.slice(0, 100) +
              "..."}`}</Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Button
                className={classes.inscribirme}
                variant="outlined"
                href={`${curso.url}`}
              >
                Inscribirme
              </Button>

              <Galera />
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const renderAdsCard = () => {
    return (
      <Grid item xs={12} sm={6} md={4}>
        <Card className={`${classes.card} ${classes.Ads}`}>
          <Typography align="center" className={classes.Ads}>
            <AdsGoogle block={2} />
          </Typography>
        </Card>
      </Grid>
    );
  };

  const shuffle = array => {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  useEffect(() => {
    carregarCursos();
  }, [carregarCursos]);

  if (cursos.length === 0) {
    return (
      <div className={classes.loading}>
        <DonutSmall style={{ marginRight: "5px" }} />
        Cargando...
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>HM Servicios - {header}</title>
      </Helmet>
      <AppBar position="relative" className={classes.ColorMenu}>
        <Toolbar>
          <LongMenu />
          <p className={classes.menuText}>{header}</p>
        </Toolbar>
      </AppBar>

      <main className={classes.fundoContenedorGrids}>
        <div>
          {/*HEADER DOWN*/}

          <Container maxWidth="md">
            <br></br>
            <Typography align="left" variant="h6" className={classes.Ads}>
              Hola, estos son los cursos que he encontrado para ti, son todos
              gratuitos y online, espero que te ayuden...
            </Typography>

            <Typography align="center" className={classes.Ads}>
              <br></br>

              <AdsGoogle />
            </Typography>
          </Container>
        </div>
        {/*----------------------- CARDS, fazer um shuffle + random nos cursos pra nao perder sempre ----------------------*/}

        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4} className={classes.cardGrid}>
            {cursos.map(curso => {
              let random = Math.round(Math.random() + 3);
              if (cardsCounter < random) {
                cardsCounter++;
                return renderCard(curso);
              } else {
                cardsCounter = 0;
                return renderAdsCard();
              }
            })}
          </Grid>
        </Container>
      </main>

      {/*FORMULARIO LEADS*/}

      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography
              className={classes.heroTittle}
              component="h3"
              variant="h4"
              align="center"
              color="text"
              gutterBottom
            >
              Recibe notificaciones
            </Typography>
            <Typography
              className={classes.textHeroContent}
              variant="p"
              align="center"
              color="textSecondary"
              paragraph
            >
              <b className={classes.textHeroContentDestaque}>
                Cursos gratuitos{" "}
              </b>{" "}
              subvencionados por el gobierno y{" "}
              <b className={classes.textHeroContentDestaque}>
                ofertas de empleo a diario
              </b>{" "}
              en cadenas como Mercadona, Carrefour y mucho más.
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <ModalForm />
                </Grid>
                <Grid item>
                  <Button
                    className={classes.noEmail}
                    variant="outlined"
                    href="https://hmnoticias.com/inem/"
                  >
                    No tengo e-mail
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}
