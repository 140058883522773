import React, { useState } from "react";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import a from "../Jobs/images/a.jpeg";
import b from "../Jobs/images/b.jpg";
import c from "../Jobs/images/c.jpg";
import d from "../Jobs/images/d.jpg";
import e from "../Jobs/images/e.jpg";
import f from "../Jobs/images/f.jpg";
import g from "../Jobs/images/g.jpeg";
import h from "../Jobs/images/h.jpg";
import i from "../Jobs/images/i.jpeg";
import j from "../Jobs/images/j.jpeg";
import k from "../Jobs/images/k.jpg";
import l from "../Jobs/images/l.png";
import m from "../Jobs/images/m.jpg";
import n from "../Jobs/images/n.jpeg";
import o from "../Jobs/images/o.jpg";
import p from "../Jobs/images/p.jpg";
import q from "../Jobs/images/q.jpg";
import r from "../Jobs/images/r.jpg";
import t from "../Jobs/images/t.jpg";
import w from "../Jobs/images/w.jpg";
import y from "../Jobs/images/y.jpg";
import z from "../Jobs/images/z.jpg";
import aa from "../Jobs/images/aa.jpg";
import bb from "../Jobs/images/bb.jpg";
import cc from "../Jobs/images/cc.jpg";
import dd from "../Jobs/images/dd.jpg";
import ee from "../Jobs/images/ee.jpg";
import ff from "../Jobs/images/ff.jpg";
import gg from "../Jobs/images/gg.jpg";
import hh from "../Jobs/images/hh.jpg";
import ii from "../Jobs/images/ii.jpg";
import jj from "../Jobs/images/jj.jpg";
import kk from "../Jobs/images/kk.jpg";
import ll from "../Jobs/images/ll.jpg";
import mm from "../Jobs/images/mm.jpg";
import nn from "../Jobs/images/nn.jpg";
import oo from "../Jobs/images/oo.jpg";
import pp from "../Jobs/images/pp.jpg";
import qq from "../Jobs/images/qq.jpg";
import rr from "../Jobs/images/rr.jpg";
import tt from "../Jobs/images/tt.jpg";
import ww from "../Jobs/images/ww.jpg";
import yy from "../Jobs/images/yy.jpg";
import zz from "../Jobs/images/zz.jpg";

const StyledBadge = withStyles(theme => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""'
    }
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0
    }
  }
}))(Badge);

const SmallAvatar = withStyles(() => ({
  root: {
    width: 30,
    height: 30
  }
}))(Avatar);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1)
    }
  }
}));

export default function BadgeAvatars() {
  const classes = useStyles();

  const ind = {
    0: a,
    1: b,
    2: c,
    3: d,
    4: e,
    5: f,
    6: g,
    7: h,
    8: i,
    9: j,
    10: k,
    11: l,
    12: m,
    13: n,
    14: o,
    15: p,
    16: q,
    17: r,
    18: t,
    19: w,
    20: y,
    21: z,
    22: "",
    23: "",
    24: "",
    25: "",
    26: "",
    27: "",
    28: "",
    29: "",
    30: "",
    31: "",
    32: "",
    33: "",
    34: ""
  };
  const ind2 = {
    0: aa,
    1: bb,
    2: cc,
    3: dd,
    4: ee,
    5: ff,
    6: gg,
    7: hh,
    8: ii,
    9: jj,
    10: kk,
    11: ll,
    12: mm,
    13: nn,
    14: oo,
    15: pp,
    16: qq,
    17: rr,
    18: tt,
    19: ww,
    20: yy,
    21: zz
  };

  const [urlImg] = useState(Math.round(Math.random() * 34));
  const [urlImg2] = useState(Math.round(Math.random() * 20 + 1));
  const [urlImg3] = useState(Math.round(Math.random() * 21));
  const [urlImg4] = useState(Math.round(Math.random() * 21));

  return (
    <div className={classes.root}>
      <StyledBadge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        variant="dot"
      >
        <AvatarGroup max={4}>
          <SmallAvatar alt="Reme" src={ind[urlImg]} />
          <SmallAvatar alt="Travis Howard" src={ind2[urlImg2]} />
          <SmallAvatar alt="Pepe" src={ind[urlImg3]} />

          <SmallAvatar
            alt="Lola"
            className={classes.SmallAv}
            src={ind2[urlImg4]}
          />
        </AvatarGroup>
      </StyledBadge>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
      ></Badge>
    </div>
  );
}
