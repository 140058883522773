import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import {
  blackColor,
  headerColor,
} from "../../../../assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h4 className={classes2.top}>
        50 Cursos gratuitos de Harvard University
      </h4>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              Harvard ha liberado docenas de cursos totalmente gratuitos para
              todas las personas que quieran acceder a ellos.
            </p>

            <p className={classes2.texto}>
              La mayoría de estos cursos son de temas sociales aunque también
              puedes encontrar algunos de informática y biología.
            </p>

            <p className={classes2.texto}>
              Debes saber que todos sus cursos están en Ingles y que si quieres
              un certificado de haberlo realizado te supondrá un precio de 50 a
              150 dólares.
            </p>

            <p className={classes2.texto}>
              {" "}
              Aun así merece la pena si quieres plasmar en tu curriculum un
              curso de esta prestigiosa universidad.
            </p>

            <h3 className={classes2.titulo}>
              Qué cursos gratuitos puedo hacer en la Universidad de Harvard
            </h3>

            <p className={classes2.texto}>
              <strong>Introducción al desarrollo de juegos</strong>
            </p>

            <p className={classes2.texto}>
              Con este curso vas a aprender los principios básicos para poder
              desarrollar juegos interactivos. Partiendo desde cero te enseñarán
              cómo puedes programar y crear personajes utilizando unas funciones
              sencillas y fáciles de aprender.
            </p>

            <p className={classes2.texto}>
              <strong>Estadística R</strong>
            </p>

            <p className={classes2.texto}>
              Con este curso podrás aprender en qué consiste la estadística R y
              cómo puedes implementarla en tu trabajo si trabajas con este tipo
              de muestreos.
            </p>

            <p className={classes2.texto}>
              <strong>Ciencia y Cocina</strong>
            </p>

            <p className={classes2.texto}>
              En este curso podrás aprender los principios físicos y la
              ingeniería que rigen las recetas más cotizadas. Muy útil si eres
              cocinero profesional y quieres conocer más sobre el mundo de la
              cocina.
            </p>

            <p className={classes2.texto}>
              {" "}
              <strong> Principios básicos de Bioquímica</strong>
            </p>

            <p className={classes2.texto}>
              En este curso aprenderás a adentrarte en las moléculas del ser
              humano desde las formas de vida más sencillas a las más complejas.
              Si te gusta la bioquímica y quieres saber más sobre ella, este es
              tu curso.
            </p>

            <p className={classes2.texto}>
              {" "}
              <strong> Religión, Conflicto y Paz</strong>
            </p>

            <p className={classes2.texto}>
              {" "}
              En este curso te ofrecen información acerca de los grandes
              conflictos de la humanidad en base a la religión y cómo pudieron
              solventarlos. Es un curso que puede aportarte grandes
              conocimientos sobre la historia de la humanidad.{" "}
            </p>

            <p className={classes2.texto}>
              <strong> Filosofía Moral y Política</strong>
            </p>

            <p className={classes2.texto}>
              {" "}
              Este curso te enseña las bases sobre la filosofía moral y política
              así como debatir sobre ella. Un curso muy útil si eres profesor de
              filosofía y quieres enseñar a tus alumnos cuales son los
              principios fundamentales de la filosofía o si tienes curiosidad
              por aprender la forma usada para debatir problemas filosóficos.{" "}
            </p>

            <p className={classes2.texto}>
              <strong> Protección infantil: derechos del niño</strong>
            </p>

            <p className={classes2.texto}>
              Este curso te enseña a conocer las leyes sobre violencia,
              explotación y abandono, encuadrado en un marco de derechos
              humanos. Es un curso útil si trabajas con niños y quieres conocer
              sus derechos.
            </p>

            <p className={classes2.texto}>
              {" "}
              <strong> El antiguo héroe griego</strong>
            </p>

            <p className={classes2.texto}>
              Este curso nos muestra los héroes Griegos a través de la novela la
              Iliada y la Odisea entre otros. Es un curso útil si eres un
              apasionado de la antigua Grecia o si eres profesor y quieres
              enseñar a tus alumnos detalles relevantes sobre la historia.{" "}
            </p>

            <p className={classes2.texto}>
              <strong>Introducción a la Informática</strong>
            </p>

            <p className={classes2.texto}>
              En este curso aprenderás los conceptos básicos de la informática y
              las ideas principales a la hora de programar.{" "}
            </p>

            <p className={classes2.texto}>
              <strong> Cambio climático</strong>
            </p>

            <p className={classes2.texto}>
              Este curso te ofrece la posibilidad de descubrir cuáles son los
              efectos del cambio climático para nuestra salud y cómo podemos
              disminuir su impacto.
            </p>

            <p className={classes2.texto}>
              <strong>Tierra y Vida Extraterrestre</strong>
            </p>

            <p className={classes2.texto}>
              Aprende sobre la existencia de vida extraterrestre y las
              investigaciones asociadas a esta. Es un curso útil si te gustan
              los temas OVNIS y quieres aprender lo que actualmente se conoce
              sobre ellos.
            </p>

            <p className={classes2.texto}>
              {" "}
              <strong> Genómica Funcional</strong>
            </p>

            <p className={classes2.texto}>
              En este curso aprenderás a analizar datos experimentales y el uso
              de software utilizado para ello como el software R y
              Bioconductoes.Un curso diseñado para aprender a aplicar técnicas
              de investigación si eres biólogo o médico y quieres aprender sobre
              el análisis de protocolos experimentales.{" "}
            </p>

            <p className={classes2.texto}>
              <strong> Análisis de datos de alta dimensión</strong>
            </p>

            <p className={classes2.texto}>
              {" "}
              Con este curso aprenderás técnicas para analizar datos de alta
              dimensión.{" "}
            </p>

            <p className={classes2.texto}>
              <strong> Opio en America. La crisis.</strong>
            </p>

            <p className={classes2.texto}>
              En este curso podrás aprender en que consiste la epidemia de opio
              en Estados Unidos incluyendo conocimiento sobre cómo puede
              superarse su adicción.
            </p>

            <p className={classes2.texto}>
              {" "}
              <strong> Arquitectura básica.</strong>
            </p>

            <p className={classes2.texto}>
              Principios básicos de la arquitectura partiendo de los edificios
              más importantes que ha habido en la historia de la humanidad.{" "}
            </p>

            <p className={classes2.texto}>
              <strong> Modelos lineales y álgebra matricial</strong>
            </p>

            <p className={classes2.texto}>
              En este curso adquieras conocimientos sobre la programación R para
              poder aplicarlos al análisis de modelos lineales. Computación de
              alto rendimiento para la Genómica reproducible En este curso te
              ensañara como aplicar estructuras de ensayo y anotación del genoma
              a análisis de datos. Muy útil si trabajas en el sector de la
              investigación. Inferencia estadística y modelado para experimentos
              de alto rendimiento:
            </p>

            <p className={classes2.texto}>
              En este curso puedes aprender cuales son las técnicas usadas para
              llevar a cabo análisis de estadística aplicada a alto rendimiento.
              Resulta un curso interesante para investigadores que estudien
              temas afines y quieran aprender cómo mejorar sus resultados en una
              investigación.{" "}
            </p>

            <p className={classes2.texto}>
              <strong>Cultura China</strong>
            </p>

            <p className={classes2.texto}>
              Gracias a este curso podrás descubrir en que se basa la cultura
              China investigando desde sus orígenes hasta la época actual.{" "}
            </p>

            <p className={classes2.texto}>
              <strong> Diagramas causales</strong>
            </p>

            <p className={classes2.texto}>
              Puedes conocer las reglas que te permitan usar imágenes eficaces
              para poder apoyar el diseño de un estudio científico.{" "}
            </p>

            <p className={classes2.texto}>
              <strong>Respuesta humanitaria ante conflictos</strong>
            </p>

            <p className={classes2.texto}>
              Con este curso podrás aprender que principios se rigen a la hora
              de abordar emergencias sanitarias y sociales.{" "}
            </p>

            <p className={classes2.texto}>
              <strong>Economías Emergentes</strong>
            </p>

            <p className={classes2.texto}>
              Con este curso podrás aprender las claves para emprender e innovar
              ante los problemas actuales a los que nos emfrentamos.
            </p>

            <p className={classes2.texto}>
              <strong>Cristianismo a través de las escrituras</strong>
            </p>

            <p className={classes2.texto}>
              Si quieres conocer la historia del cristianismo desde sus
              orígenes, este curso puede resultarte de gran interés. Te
              explicara en qué consistía el cristianismo y como eran las
              características de esta religión desde sus albores mas tempranos.
            </p>

            <p className={classes2.texto}>
              <strong>Mitocondrias</strong>
            </p>

            <p className={classes2.texto}>
              Este curso está dirigido a biólogos y todas aquellas personas
              interesadas en aprender cómo funcionan las células mitocondriales.
            </p>

            <p className={classes2.texto}>
              <strong>Anatomía: el esqueleto</strong>
            </p>

            <p className={classes2.texto}>
              Si quieres conocer la anatomía básica del esqueleto humano y
              cuales es la función de cada hueso este curso está hecho para ti.
            </p>

            <p className={classes2.texto}>
              <strong>Respiración Artificial</strong>
            </p>

            <p className={classes2.texto}>
              Un curso muy interesante para aprender en que consiste la
              respiración artificial, cuales son los parámetros que debe tener
              una persona para respirar correctamente y cómo funcionan este tipo
              de aparatos.
            </p>

            <p className={classes2.texto}>
              Resulta muy útil, más aun en los tiempos que vivimos debido a la
              crisis sanitaria del Covid19, un virus que provoca problemas
              respiratorios y con el que los pacientes de mayor gravedad pueden
              necesitar aparatos de respiración artificial.
            </p>

            <p className={classes2.texto}>
              <strong>Cálculo aplicado</strong>
            </p>

            <p className={classes2.texto}>
              Con el curso de calcula aplicado vas a poder aprender cómo aplicar
              el cálculo a tu vida diaria y como poder realizar investigaciones
              basadas en análisis de última generación. Un curso muy útil si
              quieres saber cómo diseñar correctamente una investigación.
            </p>

            <p className={classes2.texto}>
              <strong>Poesía americana</strong>
            </p>

            <p className={classes2.texto}>
              En este curso te enseñaran las principales corrientes de poesía
              América, cuáles son sus características y las obras más
              importantes que existen en la historia de América.
            </p>

            <p className={classes2.texto}>
              <strong>Innovaciones médicas</strong>
            </p>

            <p className={classes2.texto}>
              Un curso muy interesante si quieres conocer los últimos avances de
              la tecnología media. En este curso te enseñaran cuales son los
              últimos aparatos e investigaciones que se están aplicando para
              mejorar la vida de las personas.
            </p>

            <p className={classes2.texto}>
              <strong>El cristianismo temprano</strong>
            </p>

            <p className={classes2.texto}>
              Si quieres aprender en qué consistía el cristianismo desde sus
              albores, éste curso puede resultarte de interés. En él, te
              explicara como surgió en cristianismo en el mundo antigua y como
              se ha ido desarrollando hasta llegar a la época actual.
            </p>

            <p className={classes2.texto}>
              <strong>Política de Estados Unidos</strong>
            </p>

            <p className={classes2.texto}>
              La política en Estados Unidos ha tomado un interés creciente
              últimamente. En este curso podrás aprender cuales son las claves
              de la política actual en el país y como ha ido avanzando desde sus
              inicios. Un curso interesante si eres experto en política o
              historiador y quieres saber más sobre la política usada en este
              país.
            </p>

            <p className={classes2.texto}>
              <strong>Programación R</strong>
            </p>

            <p className={classes2.texto}>
              En este curso se introduce a conceptos básicos de programación en
              estadística R para hacer análisis de carácter científico.{" "}
            </p>

            <p className={classes2.texto}>
              <strong>Contratos</strong>
            </p>

            <p className={classes2.texto}>
              Un curso destinado a conocer como se elabora un contrato, cómo
              puede hacerse ejecutable y que podemos hacer si una parte rompe el
              contrato.
            </p>

            <p className={classes2.texto}>
              {" "}
              <strong> Sistemas de adivinación</strong>
            </p>

            <p className={classes2.texto}>
              Se trata de un curso destinado a adquirir conocimientos sobre los
              sistemas de adivinación antiguos hasta la época actual.
            </p>

            <p className={classes2.texto}>
              <strong>Pirámides de Giza</strong>
            </p>

            <p className={classes2.texto}>
              En esta ocasión, la universidad de Harvard ofrece un curso para
              aprender la historia de las pirámides de Giza, como se
              construyeron y cual es aportación en la historia a nuestra época
              actual.
            </p>

            <p className={classes2.texto}>
              <strong>Hinduismo a través de las escrituras</strong>
            </p>

            <p className={classes2.texto}>
              Si quieres conocer la historia del hinduismo, Harvard te ofrece un
              curso para poder indagar en sus características a través de los
              escritos que han llegado hasta nuestros días.{" "}
            </p>

            <p className={classes2.texto}>
              <strong> Aprendizaje. Los líderes mundiales</strong>
            </p>

            <p className={classes2.texto}>
              Se trata de un curso para aprender las teorías del aprendizaje y
              el liderazgo. Un curso muy útil para directivos que quiere saber
              cómo pueden llegar a ser grandes líderes en su empresa y como
              deben tratar a su empleados, convirtiéndose en el mejor de los
              jefes posibles.{" "}
            </p>

            <p className={classes2.texto}>
              <strong> Ópera del siglo XIX</strong>
            </p>

            <p className={classes2.texto}>
              En este curso podrás aprender el impacto de tres obras claves de
              la década del año 1800:Les Huguenots, Das Rheingold y Otello.
              Enlace
            </p>

            <p className={classes2.texto}>
              <strong>Salud y Sociedad</strong>
            </p>

            <p className={classes2.texto}>
              Esta vez, Harvard te ofrece un curso donde se pone en común
              nuestra actual sociedad con los problemas de salud que actualmente
              tenemos. Te va a explicar cuáles son los problemas sanitarios más
              frecuentes y cómo podemos ayudar a la sociedad a&nbsp; asumirlos.
            </p>

            <p className={classes2.texto}>
              <strong> Python para la investigación</strong>
            </p>

            <p className={classes2.texto}>
              Se trata de un curso para aprender a programar con el programa
              Python destinado a estudios de carácter investigativo.
            </p>

            <p className={classes2.texto}>
              <strong>La antigua Grecia</strong>
            </p>

            <p className={classes2.texto}>
              Este curso te va a enseñar las características de la antigua
              Grecia, cómo era su sociedad y como ha llegado a ser una de las
              grandes civilizaciones del mundo.
            </p>

            <p className={classes2.texto}>
              <strong>El gobierno en Estados Unidos</strong>
            </p>

            <p className={classes2.texto}>
              Con este curso podrás aprender la historia de Estados Unidos desde
              su inicio en el terreno político. De esta manera entenderás mejor
              la situación actual del país.
            </p>

            <p className={classes2.texto}>
              <strong>Justicia</strong>
            </p>

            <p className={classes2.texto}>
              Si siempre has queridos saber las bases en las que se sustenta la
              justicia y las leyes actuales del mundo. Éste es tu
              curso.Aprenderas los diferentes sistemas legales que existen y
              cuáles son sus principales características.
            </p>

            <p className={classes2.texto}>
              <strong>La ciencia del tiempo: lluvia</strong>
            </p>

            <p className={classes2.texto}>
              Con este curso aprenderás a conocer que mecanismos físicos y
              químicos suceden cuando llueve, hay tormenta o huracanes. Un curso
              muy útil para aprender las bases de la climatología y entender su
              proceso.
            </p>

            <p className={classes2.texto}>
              <strong>China y el comunismo</strong>
            </p>

            <p className={classes2.texto}>
              El curso de Harvard sobre China y el comunismo te va a enseñar en
              que consiste y las características principales de este tipo de
              sociedad.
            </p>

            <p className={classes2.texto}>
              <strong>La epidemia de cólera de 1854</strong>
            </p>

            <p className={classes2.texto}>
              Este curso te enseñara en que consistió la epidemia que asoló
              Londres y la importancia  que ha tenido en los estudios
              epidiomologicos actuales.{" "}
            </p>

            <p className={classes2.texto}>
              <strong> Principios de Informática</strong>
            </p>

            <p className={classes2.texto}>
              Se trata de un curso de introducción para las empresas destinado a
              conocer los conocimientos mínimos de informática y programación.
            </p>

            <p className={classes2.texto}>
              <strong> Introducción a la probabilidad:</strong>
            </p>

            <p className={classes2.texto}>
              En este curso podras aprender cuales son las bases de la
              probabilidad para poder aplicarla a futuras investigaciones o
              estudios centrados en el área.
            </p>

            <p className={classes2.texto}>
              <strong>PredicictonX</strong>
            </p>

            <p className={classes2.texto}>
              Este curso te va enseñar en que consiste el programa de análisis
              de datos para hacer investigaciones de diversa índole. Un curso
              útil si quieres aprender a analizar datos de interés y aplicarlos
              a tu trabajo de investigación.
            </p>

            <p className={classes2.texto}>
              <strong>Métodos cuantitativos en Biología</strong>
            </p>

            <p className={classes2.texto}>
              Si eres biólogo o te apasiona el tema, con este curso podrás
              aprender a realizar métodos cuantitativos para utilizar en
              investigaciones partiendo desde cero.
            </p>

            <p className={classes2.texto}>
              <strong>Neurociencia</strong>
            </p>

            <p className={classes2.texto}>
              Si quieres conocer las bases de la neurociencia puedes hacer este
              curso de la universidad de Harvad, que te enseñara los principios
              básicos de la ciencia cerebral.
            </p>

            <p className={classes2.texto}>
              <strong> Ópera del siglo XVIII: Handel y Mozart</strong>
            </p>

            <p className={classes2.texto}>
              Harvard te propone este curso para descubrir la ópera barroca a
              través de Giulio Cesare de Handel y Don Giovanni de Mozart.
            </p>

            <p className={classes2.texto}>
              Te he enseñado cuales son los 50 cursos que la universidad de
              Harvard esta ofreciendo gratuitiamente.Si quieres obtener un
              certificado deberás pagar entre 50 y 150 dolares.Pero si solo
              quieres aprender, éstos cursos son para ti.
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
