import React from "react";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { grayColor } from "assets/jss/material-kit-pro-react";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://hmnoticias.com/cursos/">
        Hugo Rocha
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  footText: {
    textTransform: "none",
    "&:hover": {
      color: grayColor[0],
    },
  },
}));

const footers = [
  {
    title: "Menu",
    description: {
      title: [
        { title: "Inicio", url: "https://hmservicios.com/" },
        { title: "Contacto", url: "https://hmservicios.com/contactar" },
        { title: "Sobre Nosotros", url: "https://hmservicios.com/aviso-legal" },
      ],
    },
  },
  {
    title: "Empleo",
    description: {
      title: [
        {
          title: "Ofertas de Empleo",
          url: "https://hmservicios.com/vacantes",
        },
        {
          title: "Trabaja en Supermercados",
          url:
            "https://hmnoticias.com/espana/7-supermercados-con-ofertas-de-empleo/",
        },
      ],
    },
  },
  {
    title: "Derechos",
    description: {
      title: [
        {
          title: "Políticas de Privacidad",
          url: "https://hmservicios.com/politicas-de-privacidad",
        },
      ],
    },
  },
  {
    title: "Cursos Online",
    description: {
      title: [
        {
          title: "Cursos Gratis INEM",
          url: "https://hmnoticias.com/cursos/",
        },
        {
          title: "Cursos Inglés Gratuito",
          url: "https://hmnoticias.com/cursos/",
        },
      ],
    },
  },
];

export default function Pricing() {
  const classes = useStyles();

  return (
    <React.Fragment>
      {/* Footer */}
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Grid container spacing={4} justify="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography
                variant="h6"
                color="textPrimary"
                gutterBottom
                className={classes.footText}
              >
                {footer.title}
              </Typography>
              <ul>
                {footer.description.title.map((item) => (
                  <li key={item.title}>
                    <Link
                      href={item.url}
                      variant="subtitle1"
                      color="textSecondary"
                      className={classes.footText}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}
