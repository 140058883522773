import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AdsGoogle from "../../../../AdsGoogle";
import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px"
  },
  contenedor: {
    fontSize: "17px"
  },
  titulo: {
    textAlign: "center"
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px"
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline"
    }
  }
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h4 className={classes2.top}>Ayudas que el Gobierno da a la población</h4>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              En esta ocasión te voy a contar cuales son las ayudas que el
              Gobierno ofrece a los ciudadanos a nivel económico y cuáles son
              los requisitos que debes tener si quieres acceder a una de ellas.
            </p>
            <p>{<AdsGoogle />}</p>
            <h3 className={classes2.titulo}>Ayudas para el desempleado</h3>

            <p className={classes2.texto}>
              <strong>Prestación por Desempleo:</strong>
            </p>

            <p className={classes2.texto}>
              Esta es la ayuda más común. Se trata de una prestación que se
              recibe cuando una persona se ha quedado en desempleo. Este tipo de
              prestación requiere una serie de requisitos que son los
              siguientes:
            </p>

            <p className={classes2.texto}>
              -Haber cotizado 360 días los últimos 6 años.
            </p>

            <p className={classes2.texto}>
              -Estar dado de alta como demandante de empleo.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>-Ser mayor de edad.</p>

            <p className={classes2.texto}>-Búsqueda de empleo activa.</p>

            <p className={classes2.texto}>
              -No estar en edad de jubilarse ni ser trabajador por cuenta
              propia.
            </p>

            <p className={classes2.texto}>
              El salario percibido variara según el tiempo que tengas cotizado y
              la base reguladora por la que la empresa cotizara por ti durante
              el periodo trabajado, así como el tipo de jornada laboral que
              tenías.
            </p>

            <p className={classes2.texto}>
              <strong>Subsidio por cotización insuficiente:</strong>
            </p>

            <p className={classes2.texto}>
              Este tipo de ayuda se da a personas que no han llegado a cotizar
              los suficientes días para cobrar la prestación por desempleo. La
              cantidad será de 430€ mensuales (en caso de que hubieras trabajo
              en jornada completa) y el tiempo dependerá de si tienes o no
              cargas familiares, pudiendo ir de los 6 a los 21 meses.
            </p>

            <p className={classes2.texto}>
              Para acceder a este subsidio deberás cumplir los siguientes
              requisitos:
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              -No haber cotizado más de 360 los últimos 6 años.
            </p>

            <p className={classes2.texto}>
              -Haber cotizado al menos 90 días si no tienes hijos a cargo.
            </p>

            <p className={classes2.texto}>-Carecer de rentas mayores al SMI.</p>

            <p className={classes2.texto}>
              -Estar en situación legal de desempleo.
            </p>

            <p className={classes2.texto}>
              <strong>Renta Activa de Inserción:</strong>
            </p>

            <p className={classes2.texto}>
              Esta renta supone una ayuda económica de 431€ que es concedida a
              desempleados de larga duración que tienen dificultades para
              obtener un empleo. Los requisitos son los siguientes:
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Debes cumplir obligatoriamente alguno de estos 4 requisitos:
            </p>

            <p className={classes2.texto}>
              -Desempleados de larga duración de más de 45 años.
            </p>

            <p className={classes2.texto}>
              -Emigrantes retornados de más de 45 años.
            </p>

            <p className={classes2.texto}>-Víctima de violencia de género.</p>

            <p className={classes2.texto}>
              -Persona con discapacidad de más del 33%.
            </p>

            <p className={classes2.texto}>
              Además deberás cumplir estos requisitos:
            </p>

            <p className={classes2.texto}>-Ser menor de 65 años.</p>

            <p className={classes2.texto}>
              -No tener ingresos mayores a los 712 € mensuales.
            </p>

            <p className={classes2.texto}>
              -Que la unidad familiar no supere el tope de rentas es decir más
              de 712€ por persona.
            </p>

            <p className={classes2.texto}>
              -No haber sido beneficiario de rentas activa de inserción más de
              tres veces.
            </p>

            <p className={classes2.texto}>
              -Acreditar una búsqueda de empleo activa.
            </p>

            <p className={classes2.texto}>
              <strong>Subsidio para presos excarcelados:</strong>
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Si has sido liberado de prisión, debes saber que cuentas con la
              oportunidad de obtener un subsidio de 431€ mensuales si cumples
              los siguientes requisitos:
            </p>

            <p className={classes2.texto}>-Estar en desempleo.</p>

            <p className={classes2.texto}>-Ser demándate de empleo.</p>

            <p className={classes2.texto}>
              -No tener rentas propias que superen el SMI.
            </p>

            <p className={classes2.texto}>
              -No haber rechazado ofertas de trabajo con anterioridad.
            </p>

            <p className={classes2.texto}>
              -Haber estado preso más de 180 días.
            </p>

            <p className={classes2.texto}>
              -No tener derecho a una prestación contributiva por desempleo.
            </p>

            <p className={classes2.texto}>
              <strong>Subsidio para emigrantes con retorno:</strong>
            </p>

            <p className={classes2.texto}>
              Si has tenido que salir del país para trabajar, debes saber que
              puedes obtener una ayuda económica de 431€ si cumples una serie de
              requisitos:
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              -Ser trabajador Español de países fuera de la Unión
              Europea,Australia o Suiza.
            </p>

            <p className={classes2.texto}>
              -Haber trabajado como mínimo 12 meses en los últimos 6 años
              anteriores en el extranjero.
            </p>

            <p className={classes2.texto}>
              -o tener derecho a cobrar el paro dentro del territorio Español.
            </p>

            <p className={classes2.texto}>
              Una vez regreses a España ,deberás estar dado de alta como
              demandante de empleo un mes para poder solicitar este subsidio.
            </p>

            <p className={classes2.texto}>
              <strong>-Renta agraria</strong>
            </p>

            <p className={classes2.texto}>
              Los trabajadores del campo de la región de Andalucía pueden
              beneficiarse de una ayuda económica que va de los 437€ a los 575€,
              siempre y cuando se cumplan una serie de requisitos:
            </p>

            <p className={classes2.texto}>-Estar empadronado en Andalucía.</p>

            <p className={classes2.texto}>
              -Ser trabajador eventual del sector agrario.
            </p>

            <p className={classes2.texto}>-Estar insistió en el SEASS.</p>

            <p className={classes2.texto}>
              -Haber cotizado un mínimo de 20 días en los últimos 180 días.
            </p>

            <p className={classes2.texto}>
              -No tener rentas que superen el SMI(Salario mínimo
              interprofesional).
            </p>

            <p className={classes2.texto}>
              -Inscribirse como demandante de empleo.
            </p>

            <h3 className={classes2.titulo}>Ayudas para Autónomos</h3>

            <p className={classes2.texto}>
              <strong>Ayuda por cese de actividad:</strong>
            </p>

            <p className={classes2.texto}>
              Si has tenido que cesar tu negocio puedes acceder a una ayuda si
              cumples los siguientes requisitos:
            </p>

            <p className={classes2.texto}>-Ser autónomo.</p>

            <p className={classes2.texto}>
              -Estar afiliado a la seguridad social.
            </p>

            <p className={classes2.texto}>
              -Estar al corriente de las cuotas con Hacienda.
            </p>

            <p className={classes2.texto}>
              -Tener un periodo de cotización de mínimo 12 meses.
            </p>

            <p className={classes2.texto}>
              -No haber alcanzado la edad de jubilación.
            </p>

            <p className={classes2.texto}>-Tener un compromiso de actividad.</p>

            <p className={classes2.texto}>
              Ayudas para personas que quieren emprender:
            </p>

            <p className={classes2.texto}>
              <strong>Tarifa plana de 50€:</strong>
            </p>

            <p className={classes2.texto}>
              El gobierno puso en marcha una serie de medidas para que los
              jóvenes pudieran hacerse autónomos sin tener que pagar las altas
              tasas que se piden, al menos, hasta saber si su negocio realmente
              funciona. Para ello tendrás que cumplir unos requisitos como son:
            </p>
            <p>{<AdsGoogle block={2} />}</p>

            <p className={classes2.texto}>
              -Darse de alta como autónomo por primera vez.
            </p>

            <p className={classes2.texto}>
              -No haber estado dado de alta los dos años anteriores.
            </p>

            <p className={classes2.texto}>
              Los primeros doce meses se pagaran 50€.Los seis meses siguientes
              abra una reducción del 50% y los ultimo 6 meses una reducción del
              30%.Exsite una reducción adicional del 30% durante otros doce
              meses si tienes menos de 30 años o si eres mujer menor de 35 años.
            </p>

            <p className={classes2.texto}>En total se ahorran unos 3.400€</p>

            <p className={classes2.texto}>
              <strong>Pago del paro completo: </strong>
            </p>

            <p className={classes2.texto}>
              Si cumples con los requisitos para pedir la prestación
              contributiva por desempleo puedes pedir el pago completo de tu
              prestación en estas condiciones:
            </p>
            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://jooble.org"
                target="_blank"
                rel="noreferrer noopener"
                aria-label=" (se abre en una nueva pestaña)"
              >
                Quizás te interese: Empresas con Ofertas de Empleo
              </a>
            </p>

            <p className={classes2.texto}>Incorporación a una corporativa</p>

            <p className={classes2.texto}>
              -Inicio de una actividad como autónomo.
            </p>

            <p className={classes2.texto}>
              -Incorporación a una entidad mercantil.
            </p>

            <h3 className={classes2.titulo}>Ayudas a la familia</h3>

            <p className={classes2.texto}>
              <strong>Ayudas para familias numerosas:</strong>
            </p>

            <p className={classes2.texto}>
              Si tienes a tu cargo más de tres hijos puedes beneficiarte de las
              ayudas para familias numerosas. Estas son tus ventajas:
            </p>

            <p className={classes2.texto}>
              -Bonificación del 45% en las cuotas dela seguridad social si
              contratas un cuidador o un empleado del hogar.
            </p>

            <p className={classes2.texto}>
              -Ampliación de periodo considerado como mínimo para cotizar a la
              seguridad social.
            </p>

            <p className={classes2.texto}>
              -Ampliación del permiso de maternidad y paternidad.
            </p>

            <p className={classes2.texto}>
              -Descuentos de hasta el 50% en transporte por carretera.
            </p>

            <p className={classes2.texto}>
              -Descuento de hasta un 10% en billetes de avión a nivel nacional.
            </p>

            <p className={classes2.texto}>-Escisión en tasas públicas.</p>

            <p className={classes2.texto}>
              -Bonificaciones en impuestos locales como el IBI.
            </p>

            <p className={classes2.texto}>
              -Incentivos a la hora de realizar compras en grandes
              supermercados.
            </p>

            <p className={classes2.texto}>Ayudas para madres:</p>

            <p className={classes2.texto}>
              Si eres madre puedes acceder a una ayuda según la situación en la
              que te encuentres.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              <strong>Ayuda para madres solteras por el Covid-19:</strong>
            </p>

            <p className={classes2.texto}>
              -Moratoria del pago de la hipoteca.
            </p>

            <p className={classes2.texto}>-Teletrabajo.</p>

            <p className={classes2.texto}>
              -Adaptación a una reducción de jornada laboral.
            </p>

            <p className={classes2.texto}>
              <strong>Deducción por maternidad:</strong>
            </p>

            <p className={classes2.texto}>
              El único requisito es ser madre trabajadora. Se percibirá una
              cuantía de 1200€.
            </p>

            <p className={classes2.texto}>
              <strong>Ayuda por hijo a cargo:</strong>
            </p>

            <p className={classes2.texto}>Los requisitos son los siguientes:</p>

            <p className={classes2.texto}>
              -Ser madre de un hijo menor de 18 años o tener hijos con una
              discapacidad del 65%.
            </p>

            <p className={classes2.texto}>
              -Si los hijos tienen una discapacidad se recibirán 1.000€ anuales.
            </p>

            <p className={classes2.texto}>
              -Si tienes hijos sin discapacidad percibirás 28€ mensuales siempre
              que en tu familia no se superen los 12.424€ anuales.
            </p>

            <p className={classes2.texto}>
              -Si hijos a cargo mayores de 18 años con discapacidad puedes
              percibir hasta 593,40 € mensuales dependiendo del grado de
              discapacidad.
            </p>

            <p className={classes2.texto}>
              <strong>Madres solteras con gemelos o trillizos:</strong>
            </p>

            <p className={classes2.texto}>
              Si has sido madre soltera de gemelos o trillizos puedes percibir
              hasta 7,400€ anuales.
            </p>

            <p className={classes2.texto}>
              <strong>Ayudas por impago de prestaciones alimentarias:</strong>
            </p>

            <p className={classes2.texto}>
              Puedes pedir esta ayuda si cumples estos requisitos:
            </p>

            <p className={classes2.texto}>
              -No superar la cuantía máxima según cada comunidad autónoma.
            </p>

            <p className={classes2.texto}>
              -Debes haber reclamado el impago de que no recibes el dinero para
              alimentar a tus hijos a cargo.
            </p>

            <h3 className={classes2.titulo}>Ayudas para jóvenes</h3>

            <p className={classes2.texto}>
              <strong>Contrato en prácticas:</strong>
            </p>

            <p className={classes2.texto}>
              Si quieres acceder a un empleo puedes hacerlo a través de un
              contrato en prácticas. Te voy a contar cuáles son sus puntos de
              interés:
            </p>

            <p className={classes2.texto}>
              -Estarás dado de alta en la seguridad social, por lo que cotizaras
              por ello.
            </p>

            <p className={classes2.texto}>
              -El primer año el salario será como mínimo el 70% del salario
              normal.
            </p>

            <p className={classes2.texto}>
              -El tiempo de un contrato en prácticas no puede excederse más de 2
              años.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              -Deberás aportar una titulación que te implique en el puesto de
              trabajo y no podrá pasar más de cinco años tras su obtención para
              poder acceder a un contrato en prácticas.
            </p>

            <p className={classes2.texto}>
              <strong>Garantía juvenil:</strong>Esta ayuda supone ofertas de
              formación así como prácticas para menores de 30 años que no
              cuenten con formación específica que les permita obtener un
              trabajo.
            </p>

            <p className={classes2.texto}>
              -Deberás ser mayor de 16 años y no tener más de 30.
            </p>

            <p className={classes2.texto}>
              -Tener nacionalidad española o en caso de ser inmigrante, una
              autorización para residir en España.
            </p>

            <p className={classes2.texto}>
              -Comprometerse a buscar empleo de forma activa.
            </p>

            <p className={classes2.texto}>-No tener formación.</p>

            <p className={classes2.texto}>
              -Estar empadronado en alguno municipio de España.
            </p>

            <p className={classes2.texto}>
              <strong>FP Dual:</strong>
            </p>

            <p className={classes2.texto}>
              Este modelo de formación cuenta con la ventaja de formarse a nivel
              académico a la vez que se realizan prácticas en empresas
              vinculadas.
            </p>

            <p className={classes2.texto}>
              Para acceder a este tipo de formación deberás acudir a un centro
              de formación estatal en tu comunidad autónoma e infórmate de los
              requisitos y ventajas que ofrecen.
            </p>

            <p className={classes2.texto}>
              Ayudas para mujeres víctimas de violencia de género.
            </p>

            <p className={classes2.texto}>
              Si has sido una mujer víctima de violencia de género puedes
              acceder a la Renta Activa de inserción antes comentada o a la
              ayuda de pago único de 2,581€.TE cuento los requisitos:
            </p>

            <p className={classes2.texto}>
              -Acreditar tu situación como víctima de violencai de género.
            </p>

            <p className={classes2.texto}>
              -No tener otro tipo de rentas superiores a 675€ mensuales.
            </p>

            <p className={classes2.texto}>
              -No haber disfrutado antes de esta ayuda.
            </p>

            <p className={classes2.texto}>
              -Contar con un informe de los servicios Sociales que avalen tu
              situación actual.
            </p>

            <h3 className={classes2.titulo}>Ayudas por el Covid-19</h3>

            <p className={classes2.texto}>
              La actual crisis sanitaria ha hecho que el gobierno conceda una
              cuantía de renta mínima que entrara en vigor en el próximo mes de
              junio.
            </p>

            <p className={classes2.texto}>
              <strong>Ingreso mínimo vital:</strong>
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Los requisitos para acceder a esta ayuda serán los siguientes:
            </p>

            <p className={classes2.texto}>
              -Podrá obtenerlo cualquier persona cuyos ingresos mensuales no
              superen los 200€.
            </p>

            <p className={classes2.texto}>
              -Podrá obtenerlos por unidad familiar siempre y cuando se perciban
              un máximo de 450€ por persona en la unidad familiar.
            </p>

            <p className={classes2.texto}>
              -La cuantía básica serán 424€ mensuales que se verá aumentada
              según el número de hijos que se tenga a cargo.
            </p>

            <p className={classes2.texto}>
              Te he explicado cuales son las ayudas que actualmente el Gobierno
              está ofreciendo para ayudar a las personas que se encuentren en
              momentos difíciles económicamente.
            </p>

            <p className={classes2.texto}>
              Si quieres saber más sobre estas ayudas y como puedes obtenerlas
              consulta en la delegación del Gobierno de Tu comunidad autónoma o
              entra en la página web del gobierno:{" "}
              <a
                className={classes2.link}
                href="http://tramites.administracion.gob.es/comunidad/tramites/tramites"
                target="_blank"
                rel="noreferrer noopener"
                aria-label=" (se abre en una nueva pestaña)"
              >
                http://tramites.administracion.gob.es/
              </a>
               <p>{<AdsGoogle block={2} />}</p>
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
