import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AdsGoogle from "../../../../AdsGoogle";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px"
  },
  contenedor: {
    fontSize: "17px"
  },
  titulo: {
    textAlign: "center"
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px"
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline"
    }
  }
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />

      <h4 className={classes2.top}>
        30 Consejos para hacer un curriculum impecable
      </h4>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              Hoy en día armar un buen curriculum vitae es algo clave para
              acceder a los mejores puestos de trabajo.
            </p>
            <p>{<AdsGoogle />}</p>
            <p className={classes2.texto}>
              Los encargados de recursos humanos deben ver  muchos curriculums
              para hacer una buena criba y poder elegir el mejor empleado, por
              ello debemos, además de facilitarles la tarea de algún modo,
              hacernos destacar por encima de otros candidatos aportando
              información de valor y utilizando un curriculum claro, ordenado y
              a la vez original.
            </p>

            <p className={classes2.texto}>
              En este artículo te voy a contar cuales son los 30 consejos más
              importantes para hacer destacar tu curriculum vitae entre los
              demás candidatos y&nbsp; cómo puedes darle un diseño original que
              capte la atención de los entrevistadores.
            </p>

            <h3 className={classes2.titulo}>
              Cómo aumentar mis posibilidades de obtener un puesto de trabajo
            </h3>

            <p className={classes2.texto}>
              Te voy a enseñar treinta consejos para poder mejorar tu currcilum
              vitae y ser el mejor candidato al puesto ofertado.
            </p>

            <p className={classes2.texto}>Una sola página:</p>

            <p className={classes2.texto}>
              Los entrevistadores deben valorar cientos de curriculums para
              buscar un candidato perfecto, por ello, ahorrarles trabajo
              editando un curriculum en una sola página en lugar de hacer que el
              entrevistador deba ojear varias hojas, hará que se centre en los
              puntos clave de tu curriculum y tendrás mayores oportunidades a la
              hora de ser elegido.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}> Sé sincero:</p>

            <p className={classes2.texto}>
              Es importante que no mientas excesivamente a la hora de
              confeccionar tu curriculum. No puedes poner que tienes un dominio
              casi bilingüe de un idioma, si tienes un nivel de usuario pues
              tarde o temprano la verdad saldrá a la luz y puede costarte más
              caro de lo que crees.
            </p>

            <p className={classes2.texto}>
               Especifica las funciones desempeñadas:
            </p>

            <p className={classes2.texto}>
              Resulta de mucho interés que indiques las funciones que
              desempeñabas en los puestos de trabajo. Esto, le hará ver al
              encargado de recursos humanos cuáles son tus habilidades y te
              posicionará en un mejor lugar.
            </p>

            <p className={classes2.texto}> Usa la tecnología:</p>

            <p className={classes2.texto}>
              Implantar tu email o un Código QR que le lleve al entrevistador a
              un curriculum más extenso puede ser útil si está interesado en tu
              formación y experiencia y quiere saber más sobre ti. Además
              mostrará que estas al tanto de las nuevas tecnologías.
            </p>

            <p className={classes2.texto}> Información de contacto:</p>

            <p className={classes2.texto}>
              Es importante incluir todos los medios de contacto con los que
              cuentes para que pueda saber más acerca de ti, puedes poner tu
              cuenta de Lindekin, Skype y otras similares. Eso sí, ten cuidado
              con los nombres de usuarios que usas, pueden decir demasiado sobre
              ti.
            </p>

            <p className={classes2.texto}> Cuidado con las redes sociales:</p>

            <p className={classes2.texto}>
              Si usas redes sociales es fácil que accedan a ellas poniendo tu
              nombre en un buscador, por ello elige otro nombre o asegúrate de
              que estas redes no se muestren fotos o comentarios que puedan
              perjudicarte.
            </p>

            <p className={classes2.texto}> Utiliza el formato PDF:</p>

            <p className={classes2.texto}>
              El formato PDF es el formato mejor valorado por las empresas la
              hora de reconocer curriculums. Envía los curriculums en este
              formato a no ser que en la empresa te digan lo contrario.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}> Utiliza plantillas gratuitas:</p>

            <p className={classes2.texto}>
              En internet puedes encontrar miles de plantillas gratuitas en las
              que solo deberás rellenar y adjuntar una fotografía. Con esto,
              puedes conseguir un curriculum original que destaque entre otros.
            </p>

            <p className={classes2.texto}>
              Te dejo algunas páginas donde puedes descargar plantillas
              gratuitas:
            </p>

            <p className={classes2.texto}>
              <a className={classes2.link} href="https://www.micvideal.es">
                https://www.micvideal.es
              </a>
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://orientacion-laboral.infojobs.net/plantillas-de-curriculum-gratuitas"
              >
                https://orientacion laboral.infojobs.net/plantillas de
                curriculum gratuitas
              </a>
            </p>

            <p className={classes2.texto}>&nbsp;Usa una buena fotografía</p>

            <p className={classes2.texto}>
              La fotografía que adjuntes a tu curriulum vitae debe ser reciente,
              con un fondo blando y en el que se muestre claramente tu rostro.
              Evita detalles como pendientes llamativos u otros detalles que
              llamen demasiado la atención.
            </p>

            <p className={classes2.texto}>&nbsp;Cuida la ortografía</p>

            <p className={classes2.texto}>
              Asegúrate de que tu curriculum no tiene ninguna falta de
              ortografía y que la sintaxis usada es la correcta. Para ello
              puedes usar correctores de internet o del mismo programa editor
              con el que hagas tu curriculum. Sin embargo asegúrate de leerlo
              todo después, ya que a veces no configura bien la sintaxis de las
              palabras
            </p>

            <p className={classes2.texto}> Incluye una carta de presentación</p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Es importante armar una buena carta de presentación donde te
              presentes y expliques porqué quieres formar parte de la empresa en
              cuestión. Esto hará que el entrevistador perciba un interés por tu
              parte mayor que si solo mandas un curriculum en solitario.
            </p>

            <p className={classes2.texto}>
               Se coherente con las fechas que indicas
            </p>

            <p className={classes2.texto}>
              Revisa todas las fechas desde el inicio hasta el final y comprueba
              que estas se ajustan a la realidad. Esto es importante ya que un
              gran espacio en blanco puede hacer que el entrevistador imagine
              cosas que quizás no son ciertas y esto puede afectarte
              negativamente. Por ello es mejor dejar claras todas las fechas y
              vigilar que haya coherencia entre ellas.
            </p>

            <p className={classes2.texto}>
               Envía tu curriculum a la dirección correcta
            </p>

            <p className={classes2.texto}>
              Si la empresa tiene un formulario en su página web especial para
              inscribirte en ofertas de trabajo o mandar tu curriculum, úsalo.
              Si por el contrario no tiene esta opción asegúrate de que la
              dirección de email donde mandes tu curriculum sea la correcta.
            </p>

            <p className={classes2.texto}>
              &nbsp;Adapta tu curriculum a la empresa
            </p>

            <p className={classes2.texto}>
              Si quieres trabajar en una empresa de informática, poco les va a
              importar que hayas trabajado en tu juventud de repartidor. Por
              ello, enfócate en experiencias y habilidades que puedan
              desempeñarse en el trabajo que estás buscando
            </p>

            <p className={classes2.texto}> Estructúralo correctamente</p>

            <p className={classes2.texto}>
              Si quieres que tu curriculum este bien diseñado debes saber
              estructurarlo de forma correcta. Para ello divide tu curriculum en
              cuatro sectores: uno para tu formación, otro para tu experiencia,
              el tercero será para tus datos de contacto y el cuarto y último,
              para otros datos de interés como tus aptitudes personales o tus
              licencias de conducir así como idiomas y programas informáticos
              que manejes.
            </p>

            <p className={classes2.texto}> Usa la negrita</p>

            <p className={classes2.texto}>
              Puedes usar las negritas para destacar puntos que creas que el
              entrevistador debe saber con prioridad. Muchas veces ciertos datos
              pueden pasar inadvertidos, sobre todo si el entrevistador tiene
              cientos de curriculums que revisar.
            </p>

            <p className={classes2.texto}> Enfatiza tus aptitudes</p>

            <p className={classes2.texto}>
              Puedes incluir una sección donde indiques adjetivos que te definan
              de forma clara y sencilla, por ejemplo: Dinámico, responsable o
              proactivo. De esta manera el entrevistador tendrá una imagen de ti
              más específica.
            </p>

            <p className={classes2.texto}> Fuente legible</p>

            <p className={classes2.texto}>
              Utiliza una buen legible que no sea molesta a la vista y pueda
              leerse cómodamente. La fuente más utilizada a la hora de hacer
              documentos importantes suele ser Arial tamaño 12. Otra fuente muy
              utilizada en los curriculums en la Times New Roman, aunque en
              menor medida.
            </p>

            <p className={classes2.texto}>
              En cualquier caso nunca utilices fuentes que, aunque son bonitas y
              originales pueden entorpecer la lectura de tu curriculum vitae. Y
              recuerda, que el entrevistador debe leer cientos de estos, por lo
              que no queremos que lo descarte solo por tener una letra incómoda
              de leer.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}> Utiliza un título correcto</p>

            <p className={classes2.texto}>
              Tenemos la manía de poner como título nuestro nombre y apellidos.
              Es más acertado poner una descripción dirigida al puesto que
              queramos conseguir, por ejemplo: ``Enfermero especialista en
              Cardiología´´.Y seguidamente nuestro nombre y apellidos.
            </p>

            <p className={classes2.texto}> Idioma</p>

            <p className={classes2.texto}>
              Si vas a trabajar en una gran multinacional, enviar tú curriculum
              en ingles puede ser de gran ayuda a la hora de ser elegido. Eso
              sí, revísalo adecuadamente y mira que tanto la lingüística como la
              sintaxis sea la correcta. Es útil si tienes la ocasión, de que tu
              curriclulm sea&nbsp; revisado por una persona bilingüe.
            </p>

            <p className={classes2.texto}> No uses abreviaturas</p>

            <p className={classes2.texto}>
              Debes omitir el uso de abreviaturas en un curriculum vitae. Quizás
              tu conozcas su significado pero el encargado de recursos&nbsp;
              humanos no tiene por qué saberlo.
            </p>

            <p className={classes2.texto}> Nivel de idiomas</p>

            <p className={classes2.texto}>
              Es importante que incluyas tu nivel de idiomas para que puedan ser
              valorados de forma correcta. Si tienes algún certificado o diploma
              que acredite un nivel de idiomas, es buen momento para plasmarlo
              en tu curriculum.
            </p>

            <p className={classes2.texto}> Evita información que no sea útil</p>

            <p className={classes2.texto}>
              Debes evitar información que no aporte nada en tu curriculum, eso
              hará que tu curriclum sea demasiado extenso y el entrevistador no
              se centre en lo realmente importante. Omite detalles que no digan
              nada sobre ti pues incluso pueden perjudicarte.
            </p>

            <p className={classes2.texto}> Fechas vacías</p>

            <p className={classes2.texto}>
              Si tu curriculum tiene un parón de varios años, no lo dejes en
              blanco. Puedes poner que has estado formándote o te has dedicado a
              asuntos personales. Otra opción es explicarlo si te conciertan
              para una entrevista de trabajo con tus propias palabras.
            </p>

            <p className={classes2.texto}> No incluyes tus hobbies</p>

            <p className={classes2.texto}>
              A los entrevistadores no les interesa si te gusta tocar la
              guitarra o si eres un apasionado de los coches. Omite estos
              detalles y céntrate en lo realmente&nbsp; importante.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}> No lo cuentes todo</p>

            <p className={classes2.texto}>
              Debes hacer un curriculum de manera que el entrevistador se quede
              con las ganas de saber más sobre ti, para ello no debes contar
              todo por completo, deja ciertos detalles menos importantes pero
              que puedes comentar si te llaman para realizar la entrevista.
            </p>

            <p className={classes2.texto}> Usa espacio</p>

            <p className={classes2.texto}>
              No hagas que tu curriculum se vea demasiado concentrado, esto
              puede resultar molesto para la vista. Es útil utilizar dobles
              espacios para ello.
            </p>

            <p className={classes2.texto}> Siempre a ordenador</p>

            <p className={classes2.texto}>
              Nunca escribas tu curriculum a mano. Si no tienes ordenador o
              impresora, pídele a alguien te deje confeccionar tu curriculum en
              el suyo, pero bajo ningún concepto des un curriculum vitae escrito
              a mano.
            </p>

            <p className={classes2.texto}> Ten tu curriculum actualizado</p>

            <p className={classes2.texto}>
              Aunque estés trabajando y creas que no lo vas a necesitar, es
              importante tener tu curriculum actualizado con tu formación y
              experiencia más reciente, de esta manera lo tendrás listo para
              cuando te sea necesario.
            </p>

            <p className={classes2.texto}> Guárdalo en tu ordenador</p>

            <p className={classes2.texto}>
              Guarda el documento en tu ordenador para que puedas acceder a él
              en cualquier momento. Aunque la mayoría de empresas no suele decir
              en que formato quieren recibir un curriculum, es útil que guardes
              el documento en formato Word y PDF.
            </p>

            <p className={classes2.texto}> Informática</p>

            <p className={classes2.texto}>
              Debes indicar los programas informáticos que manejas y cuál es tu
              nivel de experiencia en ellos. Estos les hará hacerse una idea de
              cuáles son tus habilidades y aptitudes.
            </p>

            <p className={classes2.texto}>
              Te he enseñado cuales son los mejores consejos para formar un
              curriculum vitae perfecto y poder obtener la entrevista de trabajo
              que te llevara al trabajo que andas buscando. Pon estas ideas en
              práctica y lograras trabajar donde siempre has soñado.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
