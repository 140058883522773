import React, { useState, useCallback, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import sanitizeHtml from "sanitize-html";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";
import axios from "axios";

import Search from "@material-ui/icons/Search";
import CardsWithJobs from "./CardsWithJobs";

import {
  headerColor,
  whiteColor,
  grayColor,
  hexToRgb,
  dangerColor
} from "../../assets/jss/material-kit-pro-react";

const useStylesInput = makeStyles(theme => ({
  root: {
    "& $notchedOutline": {
      borderWidth: 1
    },
    "&:hover $notchedOutline": {
      borderWidth: 1
    },
    "&$focused $notchedOutline": {
      borderWidth: 1,
      borderColor: "black"
    }
  },
  focused: {},
  notchedOutline: {}
}));

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: grayColor[0],
    padding: theme.spacing(8, 0, 6)
  },
  textHeroContent: {
    color: whiteColor,
    fontSize: "105%",
    fontWeight: "bold"
  },
  textHeroContentDestaque: {
    color: headerColor
  },
  heroTittle: {
    color: whiteColor
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: "450px",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1,
    backgroundColor: grayColor[3],
    color: whiteColor
  },
  footer: {
    backgroundColor: grayColor,
    padding: theme.spacing(6),
    color: theme.palette.primary.blanco
  },
  fundoContenedorGrids: {
    backgroundColor: grayColor
  },

  ColorMenu: {
    backgroundColor: headerColor,
    color: whiteColor
  },

  ColorAviso: {
    backgroundColor: theme.palette.primary.aviso,
    padding: "1px",
    fontSize: "120%"
  },
  Ads: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textTransform: "none",
    textAlign: "center"
  },
  menuText: {
    marginTop: "10px",
    fontSize: "18px",
    textTransform: "none"
  },
  noEmail: {
    color: grayColor[3],
    borderColor: grayColor[3],
    marginRight: "10px",

    "&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: grayColor[5]
    },
    inscritos: {
      fontSize: "20px"
    }
  },
  inscribirme: {
    marginTop: "10px",

    color: headerColor,
    borderColor: headerColor,
    "&:hover,&:focus": {
      color: whiteColor,
      borderColor: whiteColor
    }
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh"
  },

  containerLoad: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  containerInput: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-70px"
  },
  botonBuscar: {
    width: "115px",
    backgroundColor: dangerColor[0],
    borderColor: dangerColor[0],

    color: whiteColor,
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.2)",

    "&:hover,&:focus": {
      color: dangerColor[0],
      backgroundColor: dangerColor[4],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.2)"
    }
  },
  notFound: {
    padding: "20px"
  },
  listAlert: {
    padding: "20px",
    width: "290px"
  }
}));
export default function JobsContainer() {
  const classes = useStyles();
  const classesInput = useStylesInput();
  const [jobs, setJobs] = useState([]);
  const [input, setInput] = useState("");
  const [empty, setEmpty] = useState(false);
  const [find, setFind] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const cleanString = input => {
    //Lower case and remove prepositions
    const lowerCaseSanitizedInput = sanitizeHtml(input.toLowerCase())
      .trim()
      .replace(",", " ")
      .replace(/de /g, " ")
      .replace(/ de /g, " ")
      .replace(/ en /g, " ")
      .replace(/ y /g, " ")
      .replace(/ para /g, " ")
      .replace(/ un /g, " ")
      .replace(/ tras /g, " ")
      .replace(/ una /g, " ")
      .replace(/ uno /g, " ")
      .replace(/ que /g, " ")
      .replace(/ solo /g, " ")
      .replace(/ la /g, " ")
      .replace(/ lo /g, " ")
      .replace(/ e /g, " ")
      .trim()
      .replace(/ /g, "-")
      .replace(/--/g, "-");

    return lowerCaseSanitizedInput;
  };

  const handleSearchJobs = async () => {
    setLoading(true);
    if (input !== "") {
      await axios.post("https://api.hmservicios.com/api/search/save", {
        name: input
      });

      const cleanedInput = cleanString(input);
      const url = `https://api.hmservicios.com/api/search/${cleanedInput}`;

      const solve = await axios.get(url);

      if (solve.data.length === 0) {
        setNotFound(true);
      }
      setJobs(solve.data);
    } else {
      setJobs([]);
      setEmpty(true);
    }
    setFind(true);
    setLoading(false);
  };

  const handleSearch = value => {
    setLoading(false);
    setInput(value);
    setEmpty(false);
    setNotFound(false);
    setFind(false);
  };

  const handleInitialJobs = useCallback(async () => {
    setLoading(true);
    const url = `https://api.hmservicios.com/api/search/camarero`;
    const solve = await axios.get(url);
    setJobs(solve.data);
    setLoading(false);
  }, [setJobs]);

  useEffect(() => {
    handleInitialJobs();
  }, [handleInitialJobs]);

  return (
    <>
      <Helmet>
        <title> HM Servicios - Vacantes </title>
      </Helmet>

      <main className={classes.fundoContenedorGrids}>
        <div>
          <Container maxWidth="md">
            <div className={classes.containerInput}>
              <OutlinedInput
                classes={classesInput}
                id="outlined-adornment-password"
                placeholder="ocupación o ciudad..."
                value={input}
                onChange={e => handleSearch(e.target.value)}
                notched={true}
                endAdornment={
                  <InputAdornment position="end">
                    <Button
                      variant="outlined"
                      className={classes.botonBuscar}
                      onClick={handleSearchJobs}
                      disabled={loading}
                    >
                      <Search style={{ marginRight: "5px" }} />
                      Buscar
                    </Button>
                  </InputAdornment>
                }
                style={{
                  width: "100%",
                  marginTop: "50px"
                }}
              />
            </div>
            {empty ? (
              <Alert
                variant="filled"
                severity="warning"
                style={{ marginTop: "30px" }}
              >
                ¡No pueden haber campos vacíos!
              </Alert>
            ) : null}
            {notFound ? (
              <div className={classes.notFound}>
                <h4 style={{ textAlign: "center" }}>
                  No hemos encontrado vacantes...
                </h4>
                <li>Intenta una nueva búsqueda.</li>
                <li>Mira si has escrito bien las palabras.</li>
                <li>Intenta buscar con una única palabra.</li>
              </div>
            ) : null}
          </Container>
        </div>
        {/*----------------------- CARDS ----------------------*/}

        {loading ? (
          <div
            style={{
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center"
            }}
          >
            <CircularProgress color="inherit" size="30px" />
          </div>
        ) : (
          <Container className={classes.cardGrid} maxWidth="md">
            {jobs.length > 0 && (
              <CardsWithJobs jobs={jobs} search={input} find={find} />
            )}
          </Container>
        )}
      </main>
    </>
  );
}
