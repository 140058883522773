/* eslint-disable */
import React, { useContext } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import axios from "axios";
import sanitizeHtml from "sanitize-html";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Button from "components/CustomButtons/Button.js";
import {
  headerColor,
  whiteColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import Context from "../../../../Context/Context";

const useStyles = makeStyles(styles);

const styleButton = {
  backgroundColor: "success",
  color: whiteColor,
  boxShadow:
    "0 14px 26px -12px rgba(" +
    hexToRgb(whiteColor) +
    ", 0.42), 0 4px 23px 0px rgba(" +
    hexToRgb(whiteColor) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(whiteColor) +
    ", 0.2)",

  "&:hover,&:focus": {
    color: headerColor[0],
    backgroundColor: whiteColor,
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(headerColor[0]) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(headerColor[0]) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(headerColor[0]) +
      ", 0.2)",
  },
};
export default function HeaderLinks({
  value,
  setValue,
  category,
  title,
  setTitle,
  image,
  setImage,
}) {
  const classes = useStyles();

  const MeuContext = useContext(Context);
  const { setError, setPublicado } = MeuContext;

  const handleSubmit = async () => {
    if (
      title !== "" &&
      image !== "" &&
      value !== "" &&
      value !== "<p><br></p>"
    ) {
      const url =
        "https://api.hmservicios.com/api/publicar";

      await axios.post(url, {
        id: sanitizeHtml(title),
        title: sanitizeHtml(title),
        url_image: sanitizeHtml(image),
        content: sanitizeHtml(value),
        category: category,
      });
      setValue("");

      setError(false);
      setPublicado(true);
    } else {
      setError(true);
      setPublicado(false);
    }

    setTimeout(() => {
      setError(false);
      setPublicado(false);
    }, 3000);
  };

  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <Button
          type="submit"
          style={styleButton}
          color="success"
          size="lg"
          onClick={handleSubmit}
          //comprobar que no está vacío
        >
          Publicar
        </Button>
      </ListItem>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary",
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};
