import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",

    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h2 className={classes2.top}>7 Lugares con plantillas de curriculum</h2>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              Basta de estar escribiendo currículos, ve a estos sitios y haz tus
              currículos de forma rápida y automática.
            </p>

            <p className={classes2.texto}>
              Si vas a comenzar la aventura de buscar un trabajo, lo primero que
              debes hacer es armarte de paciencia, ser dinámico y buscar una
              buena plantilla de curriculum que defina cuáles son tus virtudes y
              atraiga a las empresas de manera correcta.
            </p>

            <p className={classes2.texto}>
              A continuación te vamos a mostrar la importancia de redactar
              correctamente un curriculum y dónde puedes obtener la mejor
              plantilla de un curriculum para poder obtener el trabajo que andas
              buscando.
            </p>

            <h3 className={classes2.titulo}>
              La Importancia de Redactar un Curriculum Correctamente
            </h3>

            <p className={classes2.texto}>
              A la hora de buscar un empleo debemos tener claro que la redacción
              de un buen curriculum es la mejor carta de presentación que
              podemos dar para que las empresas nos elijan como el mejor
              candidato.
            </p>

            <p className={classes2.texto}>
              Debemos tener claro que el curriculum con el que nos presentemos
              debe ser claro y fácil de leer.{" "}
            </p>

            <p className={classes2.texto}>
              Los profesionales de recursos humanos no dedican más de un minuto
              a leer cada curriculum, por lo que debemos redactarlo de forma
              fácil y visual.
            </p>

            <p className={classes2.texto}>
              {" "}
              Lo ideal es que el curriculum ocupe una hoja, o&nbsp; como máximo
              dos.
            </p>

            <p className={classes2.texto}>
              Debemos también, adecuar los puestos de trabajo reflejados así
              como la formación académica al puesto por el que quedamos optar.{" "}
            </p>

            <p className={classes2.texto}>
              Por ejemplo, poco le importará al encargado de recursos humanos
              que busca un ingeniero electrónico, que hayamos hecho un curso de
              cocina asiática hace diez años. Seamos más listos que ellos.
            </p>

            <h3 className={classes2.titulo}>Cuidado con las Invenciones</h3>

            <p className={classes2.texto}>
              {" "}
              No podemos poner en el curriculum que contamos, por ejemplo con un
              nivel alto de un idioma en concreto cuando nuestros conocimientos
              son básicos, ni tampoco poner que tenemos experiencia en un sector
              que tan apenas conocemos.{" "}
            </p>

            <p className={classes2.texto}>
              Y es que, al final todas las mentiras caben saliendo a la luz, por
              lo tanto no debemos caer en exageraciones ya que nos pueden costar
              el puesto deseado y que seamos descartados al momento.
            </p>

            <p className={classes2.texto}>
              Por todo ello, contar con un curriculum bien redactado es una de
              las claves para lograr superar la entrevista de trabajo y recibir
              esa llamada tan esperada.
            </p>

            <h3 className={classes2.titulo}>
              Descargar Plantillas de un Curriculum
            </h3>

            <p className={classes2.texto}>
              En internet existen multitud de páginas que ofrecen plantillas de
              curriculum muy bien estructuradas y con un diseño llamativo que
              tan solo tienes que rellenar con tu formación, experiencia y
              habilidades.
            </p>

            <h3 className={classes2.titulo}>Primer Empleo</h3>

            <p className={classes2.texto}>
              En la web primerempleo, podrás encontrar una variedad de
              plantillas&nbsp; de curriculums totalmente gratuitos que incluyen:
              Curriculum básico, combinado, funcional, Europeo y Europass.{" "}
            </p>

            <p className={classes2.texto}>
              Es una web muy famosa y conocida en el sector, sobre todo enfocada
              a estudiantes que acaban de acabar sus respectivas carreras y
              buscan una primera oportunidad laboral.
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://www.primerempleo.com"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="VER PÁGINA (se abre en una nueva pestaña)"
              >
                VER PÁGINA
              </a>
            </p>

            <h3 className={classes2.titulo}>Modelos Currículos</h3>

            <p className={classes2.texto}>
              En la web modelocurriculum, puedes encontrar plantillas de
              curriculums gratuitos con un diseño muy dinámico, atractivo y
              versátil.{" "}
            </p>

            <p className={classes2.texto}>
              Entre sus modelos podemos destacar plantilla de curriculum
              combinado, funcional, cronológico, americano, diseñado en
              photoshop etc...
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://www.modelocurriculum.net"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="VER PÁGINA (se abre en una nueva pestaña)"
              >
                VER PÁGINA
              </a>
            </p>

            <h3 className={classes2.titulo}>Plantillas-Curriculum</h3>

            <p className={classes2.texto}>
              En esta web puedes encontrar una gran variedad de plantillas de
              curriculums y cartas de presentación que tienes a disposición para
              descargar de forma totalmente gratuita.{" "}
            </p>

            <p className={classes2.texto}>
              En esta web te explican la mejor manera de rellenar la plantilla
              de curriculum y además, te dan consejos sobre cómo hacer una buena
              carta de presentación que muestre una introducción de tus
              conocimientos y tu interés por el puesto que solicitas.
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://plantillas-curriculum.com/listo-para-mas/"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="VER PÁGINA (se abre en una nueva pestaña)"
              >
                VER PÁGINA
              </a>
            </p>

            <h3 className={classes2.titulo}>Zety</h3>

            <p className={classes2.texto}>
              Esta página española te ayuda con un blog especializado en
              búsqueda de empleo&nbsp; y te ofrece plantillas de curriculums
              como ejemplos que podrás utilizar para confeccionar el tuyo propio
              de manera sencilla y profesional.
            </p>

            <p className={classes2.texto}>
              Una web muy útil que nos aconseja sobre puntos clave, como las
              entrevistas, la presentación de un curriculum, el estado actual
              del mercado laboral en nuestro país etc…
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://zety.es"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="VER PÁGINA (se abre en una nueva pestaña)"
              >
                VER PÁGINA
              </a>
            </p>

            <h3 className={classes2.titulo}>InfoJobs</h3>

            <p className={classes2.texto}>
              Una de las webs más famosas a la hora de encontrar trabajo a nivel
              estatal y mundial.
            </p>

            <p className={classes2.texto}>
              {" "}
              En esta webs confluyen multitud de ofertas de trabajo que podemos
              encontrar usando el buscador, tanto por país, como por provincia o
              tipo de puesto buscado.{" "}
            </p>

            <p className={classes2.texto}>
              Esta página nos ofrece una sección de descarga gratuita donde
              podemos encontrar plantillas de curriculums diseñadas por los
              mejores especialistas del sector.
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://www.infojobs.net"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="VER PÁGINA (se abre en una nueva pestaña)"
              >
                VER PÁGINA
              </a>
            </p>

            <h3 className={classes2.titulo}>Marketing and Web</h3>

            <p className={classes2.texto}>
              Esta web&nbsp; nos ofrece la oportunidad de adquirir, previo pago,
              una cantidad ilimitada de plantillas de curriculums y cartas de
              presentación.{" "}
            </p>

            <p className={classes2.texto}>
              Puedes encontrar multitud de plantillas de curriculums por incluso
              tan solo 2€, por lo que resulta bastante económico si tenemos en
              cuenta que nos puede ayudar a conseguir el puesto que andamos
              buscando.
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://www.marketingandweb.es"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="VER PÁGINA (se abre en una nueva pestaña)"
              >
                VER PÁGINA
              </a>
            </p>

            <h3 className={classes2.titulo}>Freepik</h3>

            <p className={classes2.texto}>
              En la famosa web de&nbsp;FREEPIK, donde podemos buscar miles de
              imágenes gratuitas también&nbsp; puedes encontrar numerosas
              plantillas de curriculums totalmente modificables y con un diseño
              que sin duda, no dejará indiferente a ningún entrevistador.{" "}
            </p>

            <p className={classes2.texto}>
              Desde la plantilla de curriculum más sencilla, pasando por la más
              funcional a la más original.
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://www.freepik.es"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="VER PÁGINA (se abre en una nueva pestaña)"
              >
                VER PÁGINA
              </a>
            </p>

            <p className={classes2.texto}>
              Os hemos enseñado la importancia de redactar correctamente un
              curriculum y mostrado cuáles son las mejores páginas web donde
              descargar plantillas de curriculums .&nbsp;{" "}
            </p>

            <p className={classes2.texto}>
              Ahora , solo quede elegir la tuya y rellenarla con todo tu
              potencial.
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
