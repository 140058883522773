import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h2 className={classes2.top}>
        Agencias de colocación que te consiguen empleo
      </h2>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              Asesoramiento laboral y garantía de empleo, esa es la labor de una
              agencia de colocación.
            </p>

            <p className={classes2.texto}>
              Hace ya muchas décadas las agencias de colocación surgieron en
              nuestro país para ayudar a los ciudadanos en la búsqueda de empleo
              y asesoramiento a nivel laboral.
            </p>

            <p className={classes2.texto}>
              <strong>
                A continuación vamos a explicarte en que consiste una agencia de
                colocación, cómo trabaja y dónde puedes encontrar una en tu
                provincia.
              </strong>
            </p>

            <h3 className={classes2.titulo}>
              ¿Qué es una agencia de colocación?
            </h3>

            <p className={classes2.texto}>
              Una agencia de colocación es una entidad pública o privada que
              realiza actividades orientadas a ayudar a los ciudadanos en la
              búsqueda de empleo.
            </p>

            <p className={classes2.texto}>
              Se trata de un instrumento que los distintos órganos del Estado
              pueden utilizar para que las personas que buscan empleo lo
              encuentren en el menor tiempo posible.
            </p>

            <p className={classes2.texto}>
              La actuación de toda Agencia de Colocación está dirigida por el
              SEPE aunque no todas ellas colaboraran activamente con este.
            </p>

            <p className={classes2.texto}>
              <strong>
                Las agencias de colocación que colaboren con el SEPE, prestarán
                sus servicios de manera totalmente gratuita a los trabajadores y
                las distintas empresas debido a que su remuneración será pagada
                por los Servicios de Empleo Público, es decir, el Estado.
              </strong>
            </p>

            <h3 className={classes2.titulo}>
              ¿Qué agencias de colocación existen en tu provincia?
            </h3>

            <p className={classes2.texto}>
              A continuación te vamos a mostrar un listado con la principal
              agencia de colocación que existe según la provincia donde te
              ubiques.
            </p>

            <h3 className={classes2.titulo}>A Coruña</h3>

            <p className={classes2.texto}>
              ADER RECURSOS HUMANOS EMPRESA DE TRABAJO TEMPORAL SA
            </p>

            <p className={classes2.texto}>Teléfono: 981 122 784</p>

            <h3 className={classes2.titulo}>Álava</h3>

            <p className={classes2.texto}>
              AGENCIA&nbsp;DE&nbsp;COLOCACIÓN&nbsp;Y EMPRESA DE RECOLOCACIÓN
            </p>

            <p className={classes2.texto}>Teléfono: 945 260 533</p>

            <h3 className={classes2.titulo}>Albacete</h3>

            <p className={classes2.texto}>
              <strong>&nbsp;</strong>AGENCIA DE COLOCACIÓN - AYUNTAMIENTO DE
              ALBACETE
            </p>

            <p className={classes2.texto}>Teléfono:&nbsp; 967 218 821</p>

            <h3 className={classes2.titulo}>Alicante</h3>

            <p className={classes2.texto}>
              INTEREMPLEO ALICANTE - ETT Y AGENCIA DE COLOCACIÓN
            </p>

            <p className={classes2.texto}>Teléfono: 965 25 58 13</p>

            <h3 className={classes2.titulo}>Almería</h3>

            <p className={classes2.texto}>
              INNOVACION HUMANA CONSULTORES Y ASOCIADOS, S.L.
            </p>

            <p className={classes2.texto}>Teléfono: 950 265 512</p>

            <h3 className={classes2.titulo}>Asturias</h3>

            <p className={classes2.texto}>
              AGENCIA DE COLOCACIÓN - COCEMFE ASTURIAS &nbsp;
            </p>

            <p className={classes2.texto}>&nbsp;&nbsp; Teléfono: 985 396 855</p>

            <h3 className={classes2.titulo}>Ávila</h3>

            <p className={classes2.texto}>
              AVILA AGENCIA&nbsp;DE&nbsp;COLOCACIÓN
            </p>

            <p className={classes2.texto}>Teléfono: 920 350 000</p>

            <h3 className={classes2.titulo}>Badajoz</h3>

            <p className={classes2.texto}>
              AGENCIA&nbsp;DE&nbsp;COLOCACIÓN&nbsp;Y EMPRESA DE RECOLOCACIÓN
            </p>

            <p className={classes2.texto}>Teléfono: 924 286 161</p>

            <h3 className={classes2.titulo}>Barcelona</h3>

            <p className={classes2.texto}>PLATÓN SELECCIÓN DE PERSONAL</p>

            <p className={classes2.texto}>Teléfono: 693 25 12 46</p>

            <h3 className={classes2.titulo}>Burgos</h3>

            <p className={classes2.texto}>
              ECYL. OFICINA DE EMPLEO DE BURGOS INFANTAS
            </p>

            <p className={classes2.texto}>Teléfono: 947 75 05 98</p>

            <h3 className={classes2.titulo}>Cáceres</h3>

            <p className={classes2.texto}>INSTITUTO DE DESARROLLO SAN MIGUEL</p>

            <p className={classes2.texto}>Teléfono: 927 23 30 34</p>

            <h3 className={classes2.titulo}>Cádiz</h3>

            <p className={classes2.texto}>NUFAN S.L.</p>

            <p className={classes2.texto}>Teléfono: 956 26 14 28</p>

            <h3 className={classes2.titulo}>Cantabria</h3>

            <p className={classes2.texto}>
              AGENCIA DE DESARROLLO LOCAL SANTA CRUZ DE BEZANA
            </p>

            <p className={classes2.texto}>Teléfono: 942 58 58 25</p>

            <h3 className={classes2.titulo}>Castellón</h3>

            <p className={classes2.texto}>
              NEXIAN EMPRESA DE TRABAJO TEMPORAL – CASTELLÓN
            </p>

            <p className={classes2.texto}>Teléfono: 902 933 670</p>

            <h3 className={classes2.titulo}>Ciudad Real</h3>

            <p className={classes2.texto}>GRUPO CASTELLANOS&nbsp;</p>

            <p className={classes2.texto}>Teléfono: 926 25 69 43</p>

            <h3 className={classes2.titulo}>Córdoba</h3>

            <p className={classes2.texto}>EMPRESA DE TRABAJO TEMPORAL</p>

            <p className={classes2.texto}>Teléfono: 625 214 996</p>

            <h3 className={classes2.titulo}>Cuenca</h3>

            <p className={classes2.texto}>OFICINA EMPLEO SEPE CUENCA</p>

            <p className={classes2.texto}>Teléfono: 969 75 05 98</p>

            <h3 className={classes2.titulo}>Girona</h3>

            <p className={classes2.texto}>FUNDACIÓ DRISSA</p>

            <p className={classes2.texto}>Teléfono: 972 249 344</p>

            <h3 className={classes2.titulo}>Granada</h3>

            <p className={classes2.texto}>
              CENTRO INTEGRAL DE EMPLEO DE CÁRITAS DIOCESANA
            </p>

            <p className={classes2.texto}>Teléfono: 958 283 800</p>

            <h3 className={classes2.titulo}>Guadalajara</h3>

            <p className={classes2.texto}>FASTER GUADALAJARA</p>

            <p className={classes2.texto}>Teléfono: 949 254 654</p>

            <h3 className={classes2.titulo}>Guipúzcoa</h3>

            <p className={classes2.texto}>LANAK AGENCIA DE EMPLEO</p>

            <p className={classes2.texto}>Teléfono: 943 04 20 69</p>

            <h3 className={classes2.titulo}>Huelva</h3>

            <p className={classes2.texto}>CRIT INTERIM ETT – HUELVA</p>

            <p className={classes2.texto}>Teléfono: 959 246 444</p>

            <h3 className={classes2.titulo}>Huesca</h3>

            <p className={classes2.texto}>AGENCIA DE COLOCACION MANPOWER</p>

            <p className={classes2.texto}>Teléfono: 974 241 998</p>

            <h3 className={classes2.titulo}>Jaén</h3>

            <p className={classes2.texto}>ADECCO EMPRESA DE TRABAJO TEMPORAL</p>

            <p className={classes2.texto}>Teléfono: 953 294 800</p>

            <h3 className={classes2.titulo}>La Rioja</h3>

            <p className={classes2.texto}>INSERTA EMPLEO LOGROÑO</p>

            <p className={classes2.texto}>Teléfono: 941 236 490</p>

            <h3 className={classes2.titulo}>Las Palmas</h3>

            <p className={classes2.texto}>
              ACHE CANARIAS CENTRO ESPECIAL EMPLEO
            </p>

            <p className={classes2.texto}>Teléfono: 928 271 104</p>

            <h3 className={classes2.titulo}>León</h3>

            <p className={classes2.texto}>AGENCIA DE COLOCACION AEMTA</p>

            <p className={classes2.texto}>Teléfono: 987 224 633</p>

            <h3 className={classes2.titulo}>Lérida</h3>

            <p className={classes2.texto}>OFICINA DE TREBALL DE LLEIDA</p>

            <p className={classes2.texto}>Teléfono: 973 230 550</p>

            <h3 className={classes2.titulo}>Lugo</h3>

            <p className={classes2.texto}>OFICINA DE EMPREGO DE LUGO NORTE</p>

            <p className={classes2.texto}>Teléfono: 982 828 641</p>

            <h3 className={classes2.titulo}>Madrid</h3>

            <p className={classes2.texto}>
              EMPLEO Y EMPRESA. AGENCIA PRIVADA DE COLOCACIÓN
            </p>

            <p className={classes2.texto}>Teléfono: 951 01 13 50</p>

            <h3 className={classes2.titulo}>Málaga</h3>

            <p className={classes2.texto}>
              AGENCIA DE COLOCACION OCUPA2 - EMPLEO INTELIGENTE
            </p>

            <p className={classes2.texto}>Teléfono: 951 55 75 57</p>

            <h3 className={classes2.titulo}>Murcia</h3>

            <p className={classes2.texto}>INTEREMPLEO MURCIA</p>

            <p className={classes2.texto}>Teléfono: 968 27 07 15</p>

            <h3 className={classes2.titulo}>Navarra</h3>

            <p className={classes2.texto}>AGENCIA DE COLOCACION GRUPOEMPLEO</p>

            <p className={classes2.texto}>Teléfono: 948 21 26 74</p>

            <h3 className={classes2.titulo}>Ourense</h3>

            <p className={classes2.texto}>
              OFICINA DO SERVIZO PÚBLICO DE EMPREGO DE GALICIA, OURENSE-CENTRO
            </p>

            <p className={classes2.texto}>Teléfono: 901 11 99 99</p>

            <h3 className={classes2.titulo}>Palencia</h3>

            <p className={classes2.texto}>
              GRUPO NORTE SOLUCIONES DE RR.HH. PALENCIA
            </p>

            <p className={classes2.texto}>Teléfono: 979 71 21 35</p>

            <h3 className={classes2.titulo}>Pontevedra</h3>

            <p className={classes2.texto}>
              EMPRESA DE TRABAJO TEMPORAL MANPOWER
            </p>

            <p className={classes2.texto}>Teléfono: 986 86 24 12</p>

            <h3 className={classes2.titulo}>Salamanca</h3>

            <p className={classes2.texto}>
              CÁRITAS CENTRO DE FORMACIÓN Y EMPLEO PADRE BASABE
            </p>

            <p className={classes2.texto}>Teléfono: 923 21 07 26</p>

            <h3 className={classes2.titulo}>Segovia</h3>

            <p className={classes2.texto}>EMPRESA DE TRABAJO JOBANDTALENT</p>

            <p className={classes2.texto}>Teléfono: 915 78 20 99</p>

            <h3 className={classes2.titulo}>Sevilla</h3>

            <p className={classes2.texto}>
              EMPRESA TRABAJO TEMPORAL TEMPORING SEVILLA
            </p>

            <p className={classes2.texto}>Teléfono: 954 57 71 85</p>

            <h3 className={classes2.titulo}>Soria</h3>

            <p className={classes2.texto}>FLEXIPLAN, AGENCIA DE EMPLEO</p>

            <p className={classes2.texto}>Teléfono: 975 23 97 73</p>

            <h3 className={classes2.titulo}>Tarragona</h3>

            <p className={classes2.texto}>OFICINA DE EMPLEO DEL TREBALL</p>

            <p className={classes2.texto}>Teléfono: 977 24 47 75</p>

            <h3 className={classes2.titulo}>Santa Cruz de Tenerife</h3>

            <p className={classes2.texto}>FUNCATRA DE SANTA CRUZ DE TENERIFE</p>

            <p className={classes2.texto}>Teléfono: 922 59 83 01</p>

            <h3 className={classes2.titulo}>Teruel</h3>

            <p className={classes2.texto}>EMPLEARAGON AGENCIA DE COLOCACION</p>

            <p className={classes2.texto}>Teléfono: 608 07 16 69</p>

            <h3 className={classes2.titulo}>Toledo</h3>

            <p className={classes2.texto}>VIRTUTEC CENTRO ESPECIAL DE EMPLEO</p>

            <p className={classes2.texto}>Teléfono: 925 28 31 14</p>

            <h3 className={classes2.titulo}>Valencia</h3>

            <p className={classes2.texto}>
              AGENCIA DE COLOCACIÓN APUNT VALENCIA
            </p>

            <p className={classes2.texto}>Teléfono: 622 71 74 67</p>

            <h3 className={classes2.titulo}>Valladolid</h3>

            <p className={classes2.texto}>
              OFICINA DE EMPLEO DE VALLADOLID ZORRILLA
            </p>

            <p className={classes2.texto}>Teléfono: 983 99 00 00</p>

            <h3 className={classes2.titulo}>Vizcaya</h3>

            <p className={classes2.texto}>
              HAYS - CONSULTORÍA DE SELECCIÓN DE PERSONAL BILBAO
            </p>

            <p className={classes2.texto}>Teléfono: 944 23 87 17</p>

            <h3 className={classes2.titulo}>Zamora</h3>

            <p className={classes2.texto}>GRUPO EULEN DE TRABAJO TEMPORAL</p>

            <p className={classes2.texto}>Teléfono: 980 50 90 61</p>

            <h3 className={classes2.titulo}>Zaragoza</h3>

            <p className={classes2.texto}>FUNDACIÓN FEDERICO OZANAM</p>

            <p className={classes2.texto}>Teléfono: 976 443 366</p>

            <p className={classes2.texto}>
              Te hemos enseñado en qué consiste una agencia de colocación de
              empleo y cuáles son las principales según tu provincia así como
              sus contactos para ponerte en comunicación con ellos.&nbsp;
            </p>

            <p className={classes2.texto}>
              Ya estás listo para coger tu teléfono y caminar hacia un nuevo
              futuro laboral.
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
