import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  title: {
    marginBottom: 16,
    fontWeight: "bold"
  },
  buttonYes: {
    backgroundColor: "#28a745",
    color: "#FFF",
    marginTop: 5,
    "&:hover,&:focus": {
      color: "#FFF",
      backgroundColor: "rgba(40,167,69,.75)"
    }
  },
  buttonNo: {
    borderColor: "#FFF",
    color: "#FFF",
    marginLeft: 10,
    marginTop: 5,
    "&:hover,&:focus": {
      color: "#FFF",
      backgroundColor: "rgba(255,255,255,0.2)"
    }
  }
});

export default function RemoteJobsWelcome() {
  const classes = useStyles();
  return (
    <Box
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      bgcolor="#141414"
      color="#FFF"
    >
      <Container>
        <Typography variant="h5" component="h1" className={classes.title}>
          Antes de acceder a las vacantes debes de confirmar tu edad
        </Typography>
        <Typography variant="h6" component="p">
          ¿Tienes más de 18 años?
        </Typography>
        <Button
          variant="contained"
          href="/vacantes-remotas"
          className={classes.buttonYes}
          size="large"
        >
          Sí
        </Button>
        <Button
          variant="outlined"
          href="/vacantes-remotas"
          className={classes.buttonNo}
          size="large"
        >
          No
        </Button>
      </Container>
    </Box>
  );
}
