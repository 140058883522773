import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";
import AdsGoogle from "../../../../AdsGoogle";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px"
  },
  contenedor: {
    fontSize: "17px"
  },
  titulo: {
    textAlign: "center"
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px"
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline"
    }
  }
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />

      <h4 className={classes2.top}>51 Consejos para ahorrar dinero</h4>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              En esta ocasión voy a enseñarte 51 consejos para ahorrar dinero de
              forma fácil y que llega a fin de mes no te suponga una batalla
            </p>
            <p>{<AdsGoogle />}</p>
            <h3 className={classes2.titulo}>¿Cómo ahorrar dinero?</h3>

            <p className={classes2.texto}>
               No uses muchos productos para limpiar:
            </p>

            <p className={classes2.texto}>
              {" "}
              Actualmente existen cientos de productos de limpieza para todo
              tipo de superficies y problemas. Evita comprar un producto para
              cada cosa y usa limpiadores multiusos o mejor aún, puedes limpiar
              con vinagre. Esto te hará ahorrar dinero y espacio.
            </p>

            <p className={classes2.texto}> Pagar impuestos en su tiempo:</p>

            <p className={classes2.texto}>
              {" "}
              No olvides pagar los impuestos en el plazo estipulado. Si te
              retrasas te multaran con un recargo y un impuesto de poco dinero
              puede salirte mucho más caro. Para ello organiza en un calendario
              las fechas estimadas de cuando sueles recibir la carta del pago
              por tu impuesto y tu estate atento.
            </p>

            <p className={classes2.texto}> Infórmate sobre finanzas:</p>

            <p className={classes2.texto}>
              {" "}
              En internet existen multitud de páginas webs que enseñar a los
              consumidores de a pie a organizar su dinero y sacarle rendimiento.
              Puedes ver videos en internet o descargar Ebooks.Recuerda: La
              información es poder.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}> Di adiós a los vicios:</p>

            <p className={classes2.texto}>
              {" "}
              Si quieres ahorrar la mejor manera es empezar por poner fin a
              vicios que además de ser dañinos para tu salud te cuestan una
              fortuna al mes. Si bebes o fumas demasiado quizás sea el momento
              ideal para dejarlo atrás.
            </p>

            <p className={classes2.texto}> Ahorra ingresos extra:</p>

            <p className={classes2.texto}>
              {" "}
              Si consigues dinero extra por trabajos puntuales o regalos de
              familiares o amigos, evita gastarlo. Ahorra ese dinero para
              comprar algo que realmente necesites en un futuro o ten un fondo
              de ahorros que te permita salir a pie si ocurre algún imprevisto.
            </p>

            <p className={classes2.texto}> Vigila las compras:</p>

            <p className={classes2.texto}>
              {" "}
              Si eres un comprador compulsivo debes intentar calmarte y comprar
              solo lo que necesites.
            </p>

            <p className={classes2.texto}> Ordena tus facturas:</p>

            <p className={classes2.texto}>
              {" "}
              Es útil ordenar tus facturas de meses u años anteriores para que
              puedas comprobar el gasto que normalmente haces y tomar las
              medidas oportunas.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}> No te compares:</p>

            <p className={classes2.texto}>
              {" "}
              Si tu amigo se acaba de comprar un coche carísimo y a ti tan
              apenas te llega para pagar la gasolina, no desesperes. Cada uno
              tenemos nuestras circunstancias en la vida y el tiempo puede
              cambiar de un momento a otro.
            </p>

            <p className={classes2.texto}> Apaga la tele si no la ves:</p>

            <p className={classes2.texto}>
              {" "}
              Si tienes la costumbre de tener la televisión encendida aunque
              estés haciendo otras tareas intenta apagarla cada vez que no la
              veas. Aún no consume demasiado, estar mucho tiempo encendida puede
              suponernos un gasto extra no deseado.
            </p>

            <p className={classes2.texto}> No pongas mucho el horno:</p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              {" "}
              El horno es uno de los electrodomésticos de los hogares que más
              energía consume. Por tanto si quieres ahorrar intenta utilizarlo
              lo menos posible o utilizar el microondas en mayor medida.
            </p>

            <p className={classes2.texto}> Organiza quedadas en casa:</p>

            <p className={classes2.texto}>
              {" "}
              Si te gusta tener una vida social activa pero cada vez que sales
              te gastas media nomina, puedes organizar quedadas con los amigos
              en casa donde cada uno aporte algo. De esta manera ahorraras y lo
              pasaras igual o mejor de bien.
            </p>

            <p className={classes2.texto}> Cuida tu salud:</p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Es importante que cuides tu salud para que los gastos que se
              puedan generar de por ejemplo, un accidente doméstico sean los
              mínimos posibles.
            </p>

            <p className={classes2.texto}>&nbsp;Mantén orden en tu casa:</p>

            <p className={classes2.texto}>
               ¿Lo necesitas? Antes de comprar un objeto pregúntate si realmente
              lo necesitas o solo supone un capricho pasajero que después de un
              tiempo va a quedar en el fondo de un armario o en lo alto de una
              estantería.
            </p>

            <p className={classes2.texto}>
              Compra productos de calidad: Si tienes que comprar por ejemplo,
              calzado para tus hijos, intenta que este sea de calidad. Durará
              mucho más tiempo y saldrás ahorrando.
            </p>

            <p className={classes2.texto}> Compra de segunda mano:</p>

            <p className={classes2.texto}>
              {" "}
              En internet puedes encontrar el articulo exacto que estás buscando
              prácticamente nuevo y por la mitad de lo que vale en la tienda. Es
              una forma de ahorrar de la que cada vez somos más conscientes.
            </p>

            <p className={classes2.texto}> Vende lo que no uses:</p>

            <p className={classes2.texto}>
              Si por el contrario eres tú quien tiene cosas que no usas, puedes
              sacar un dinero extra poniendo el anuncio de su venta en internet.
            </p>

            <p className={classes2.texto}> Juega con tus hijos:</p>

            <p className={classes2.texto}>
              Pero en lugar de jugar a la consola, hazlo con una pelota o sal a
              correr con ellos. De esta manera le enseñaras a valorar que no
              todo se puede comprar con dinero.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}> Haz la comida en casa:</p>

            <p className={classes2.texto}>
              Además de convertirte en un gran cocinero, hacer la comida en casa
              te ayudara a ahorrar mucho dinero
            </p>

            <p className={classes2.texto}> Apaga luces:</p>

            <p className={classes2.texto}>
              {" "}
              Vigila que las luces que no estés usando estén apagadas. Tenemos
              la costumbre de encender luces de una habitación, irnos y dejarla
              encendida. Asegúrate de que esto no pase o instala un detector de
              presencia.
            </p>

            <p className={classes2.texto}> Usa ventilador:</p>

            <p className={classes2.texto}>
              {" "}
              El aire acondicionado resulta muy atractivo para las olas de
              calor, pero también gasta muchísima energía. Si quieres
              refrescarte pero que esto no te cueste demasiado, puedes usar un
              ventilador. Tú bolsillo lo notará.
            </p>

            <p className={classes2.texto}> Organízate con tu familia:</p>

            <p className={classes2.texto}>
              Una vez a la semana, organiza los gastos que podáis tener e
              intenta ganar un compromiso por parte de tu familia de que van a
              cumplirlo.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}> Aléjate de consumistas: </p>

            <p className={classes2.texto}>
              Evita estar con gente que sea muy consumista pues esto puede hacer
              que te arrastre a su espiral d consumo.
            </p>

            <p className={classes2.texto}> Haz presupuestos mensuales:</p>

            <p className={classes2.texto}>
              {" "}
              Es útil idear un presupuesto mensual donde fijas los pagos máximos
              que quieres hacer en un determinado sector y cuantos estas
              dispuesto a gastar.
            </p>

            <p className={classes2.texto}> Compra marcas blancas:</p>

            <p className={classes2.texto}>
              Las grandes cadenas de supermercados están vendiendo productos de
              calidad con marcas blancas. Esto puede hacerte ahorrar mucho
              dinero sabiendo que estas marcas blancas son a menudo producidas
              por grandes marcas conocidas.
            </p>

            <p className={classes2.texto}> Busca chollos:</p>

            <p className={classes2.texto}>
              Busca ofertas de supermercados que pueden hacerte ahorrar mucho
              dinero. En los grandes almacenes existen muchas ofertas de
              productos rebajados que pueden ayudarte a la hora de no gastar más
              de lo necesario.
            </p>

            <p className={classes2.texto}> Viaja a lugares baratos:</p>

            <p className={classes2.texto}>
              Opta por visitar lugares poco frecuentados en los que el turismo
              sea más barato. De esta manera no tienes que prescindir de viajar
              y puedes seguir ahorrando.
            </p>

            <p className={classes2.texto}>Descarga ebooks: </p>

            <p className={classes2.texto}>
              Si te gusta leer, una alternativa a los libros que normalmente son
              excesivamente caros es descargar ebooks por internet. Existen
              muchas aginas donde puedes hacer de manera gratuita.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}> Compra al por mayor:</p>

            <p className={classes2.texto}>
              Si tiene la oportunidad puedes acceder a lugares de venta al por
              mayor donde debes comprar una cantidad elevada del mismo producto
              pero sale mucho más barato por unidad. Es una ventaja si por
              ejemplo, sois familia numerosa en tu hogar.
            </p>

            <p className={classes2.texto}> Congela tus sobras:</p>

            <p className={classes2.texto}>
              No tiras las sobras de tu comida. Congela lo que ya no vayas a
              comer para otras veces, de esta manera estarás ahorrando y
              evitando tener que gastar más luz y recursos para volver a
              cocinar.
            </p>

            <p className={classes2.texto}> Usa cupones de descuento:</p>

            <p className={classes2.texto}>
              En las ventas por internet a menudo puedes encontrar una casilla
              para insertar un código de descuento. Antes de comprar investiga
              si hay alguno para la página web. Puedes ahorrar dinero.
            </p>

            <p className={classes2.texto}> Si puedes, hazlo tú: </p>

            <p className={classes2.texto}>
              Si tienes habilidades puedes optar por hacer tú mismo ciertas
              acciones como cortarte el pelo, teñiste o hacerte la manicura. De
              esta manera estarás ahorrando una cantidad importante de dinero al
              mes.
            </p>

            <p className={classes2.texto}> Compra más verdura:</p>

            <p className={classes2.texto}>
              {" "}
              La verdura además de ser más sana es mucho más barata que comprar
              carne o pesacado.Por ello puedes optar adquirir más alimentos
              vegetarianos si quieres ahorrar dinero.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}> Evita comida rápida:</p>

            <p className={classes2.texto}>
              La comida rápida además de no ser buena para la salud, supone un
              gasto tonto que te hará perder dinero. Cocina en casa y veras como
              tus ahorros aumentan.
            </p>

            <p className={classes2.texto}> Cuidado con las rebajas:</p>

            <p className={classes2.texto}>
              Las rebajas pueden suponen muy provocativas, pero antes de ir a
              comprar en estos periodos, asegúrate de que necesitas.
            </p>

            <p className={classes2.texto}> Compra por internet:</p>

            <p className={classes2.texto}>
              {" "}
              En internet existen multitud de ofertas que pueden salirte mucho
              más baratas que si compras de manera física.
            </p>

            <p className={classes2.texto}> Compara precios:</p>

            <p className={classes2.texto}>
              Antes de comprar un artículo, compara precios entre distintas
              tiendas. Los precios pueden variar mucho de un lugar a otro.
            </p>

            <p className={classes2.texto}> Compra alimentos de temporada: </p>

            <p className={classes2.texto}>
              Es útil comprar alimentos que sean temporada y cuyos precios son
              más bajos. Además los productos serán de mejor calidad y te
              aseguraras de que llevan menos sustancias nocivas.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}> Alquila lo que no uses:</p>

            <p className={classes2.texto}>
              Si tienes un parking que no estas usando o una a habitación libre
              puedes optar por alquilarla para sacar un dinero extra y ahorrar
              con ello.
            </p>

            <p className={classes2.texto}> No acumules:</p>

            <p className={classes2.texto}>
              Evita acumular objetos que no vas a usar. Si vives en un ambiente
              con muchos objetos, además de provocarte estrés hará que quieras
              adquirir más, por lo que gastaras más dinero.
            </p>

            <p className={classes2.texto}>
               No uses demasiado las redes sociales:
            </p>

            <p className={classes2.texto}>
              Evita usar demasiado las redes sociales, en ellas aparecen vidas
              de personas que gastan mucho dinero u promocionan artículos que
              seguramente no necesitas.
            </p>

            <p className={classes2.texto}> Haz una lista de la compra:</p>

            <p className={classes2.texto}>
              Antes de ir a hacer la compra, abre tu nevera y haz una lista con
              todo lo que necesitas realmente. DE esta manera evitaras caer en
              el descontrol e comprar cosas que no necesitas e ir al grano.
            </p>

            <p className={classes2.texto}> Paga en efectivo:</p>

            <p className={classes2.texto}>
              Es muy cómodo pagar con tarjeta y a la vez el cerebro piensa que
              no viendo el dinero, no es tanto el gasto. Por ello es mejor que
              dejes la tarjeta del banco en tu casa y pagues en efectivo, De
              esta manera no harás gastos extras y saldrás ahorrando.
            </p>

            <p className={classes2.texto}> Educa a tus hijos:</p>

            <p className={classes2.texto}>
              Educa a tus hijos a la hora de hacerles valorar el dinero, esto te
              evitara que cada vez que vayas a una gran superficie empiecen a
              llevar tu carro de la compra con caprichos.
            </p>

            <p className={classes2.texto}> Compara tarifas de móvil:</p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Actualmente las compañías telefónicas ofrecen unas ofertas muy
              económicas de internet y de móviles. Si tienes una vieja tarifa
              quizás estés pagando mucho más de lo que deberías.
            </p>

            <p className={classes2.texto}> Cambia tus viejas bombillas:</p>

            <p className={classes2.texto}>
              Cambia tus bombillas por bombillas de bajo consumo y ahorrar una
              cantidad importante de dinero al año.
            </p>

            <p className={classes2.texto}> Planifica viajes:</p>

            <p className={classes2.texto}>
              Antes de viajar investiga sobre lugares que ofrecen servicios
              gratuitos en el destino o donde puedes comer de forma barata.
            </p>

            <p className={classes2.texto}> Lavadoras con programas cortos:</p>

            <p className={classes2.texto}>
              Usa lavadoras con programa cortos para ahorrar mensualmente, de
              esta manera reducirás el coste energético y con ello la factura de
              la luz.
            </p>

            <p className={classes2.texto}> Anticipa tus compras:</p>

            <p className={classes2.texto}>
              Anticípate a las compras de navidad o fechas señaladas. Si lo
              haces una vez ya entrada la temporada las tiendas suelen subir los
              precios, por lo que es mejor comprar los regalos unos meses antes
              de las fiestas señaladas.
            </p>

            <p className={classes2.texto}> Usa burletes:</p>

            <p className={classes2.texto}>
              Es bueno usar burletes en las puertas para así no perder calor y
              ahorrar en el consumo de calefacción.
            </p>

            <p className={classes2.texto}>
              Te he enseñado 51 formas de ahorrar sencillas y al alcance de
              cualquiera. Si quieres ahorrar pon en marcha estos consejos, tú
              bolsillo te lo agradecerá.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
