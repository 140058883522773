import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import AdsGoogle from "../../../../AdsGoogle";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px"
  },
  contenedor: {
    fontSize: "17px"
  },
  titulo: {
    textAlign: "center"
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px"
  }
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <h4 className={classes2.top}>Cómo trabajar como freelancer</h4>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              En esta ocasión te voy a mostrar cuales son las 21 páginas más
              buscadas para poder trabajar desde casa como freelancer.
            </p>
            <p>{<AdsGoogle />}</p>

            <p className={classes2.texto}>
              En la actualidad el trabajo de freelancer es muy demandado gracias
              a los avances de la tecnología que nos permite&nbsp; trabajar con
              nuestro ordenador y una conexión a internet estable.
            </p>

            <p className={classes2.texto}>
              Diseño gráfico, proyectos universitarios, redacción de
              artículos…son muchos los trabajos freelancer a los que puedes
              acceder y te voy contar como.
            </p>

            <h3 className={classes2.titulo}>Workana</h3>

            <p className={classes2.texto}>
              Elegida como la mejor web para los frelancers. Esta página de
              origen latinoamericano cuenta ya con miles de freelancers y
              clientes satisfechos.
            </p>

            <p className={classes2.texto}>
              Deberás registrarse en su página web y esperar a que tu perfil sea
              validado. Por otra parte una vez tu cuenta sea validada deberás
              completar tu perfil y añadir una fotografía.
            </p>
            <p>{<AdsGoogle block={2} />}</p>

            <p className={classes2.texto}>
              Para buscar trabajos debes elegir la categoría donde quieres
              trabajar y se te mostrará un listado con todas las ofertas
              disponibles. Cuando elijas una, recuerda escribir correctamente tu
              carta de presentación y porqué estás interesado en la oferta de
              trabajo.
            </p>

            <p className={classes2.texto}>
              Después, deberás ofrecer un presupuesto por el que estás dispuesto
              a hacer el trabajo. Si eres nuevo y no tienes calificación, te
              recomiendo empezar con presupuestos bajos para así subir de nivel
              y obtener más contrataciones. No debes olvidar que Workana se
              queda con el 20% de comisión sobre tu oferta, algo a tener en
              cuenta.
            </p>

            <p className={classes2.texto}>
              Una vez entregues el trabajo podrás elegir el modo en el que
              retirar el dinero,(sin un mínimo para ello) a través de PayPal o
              por transferencia bancaria.
            </p>

            <h3 className={classes2.titulo}>TextBroker</h3>

            <p className={classes2.texto}>
              Textbrokes es una web en la que se subasta contenido en formato
              digital. Esta página hace de intermediario entre las empresas y
              los redactores de manera que los pone en comunicación para hacer
              encargos y recibir propuestas.
            </p>

            <p className={classes2.texto}>
              Para acceder a esta web deberás pasar primero por una prueba de
              redacción, una vez validada se te asignará una categoría según la
              calidad del trabajo. De esta manera con un nivel más alto podrás
              obtener mejores trabajos y por tanto ganar más dinero.
            </p>

            <p className={classes2.texto}>
              Para que te hagas una idea si has obtenido dos estrellas de un
              total de 5 posibles, te pagaran 7 dólares por cada 1000 palabras.
              Lo difícil es acceder a niveles superiores ya que la mayoría de
              usuarios se concretan en los niveles dos y tres y por lo tanto es
              difícil obtener trabajos de forma recurrente.
            </p>
            <p>{<AdsGoogle block={2} />}</p>

            <p className={classes2.texto}>
              Debes tener en cuenta que la plataforma se lleva 30% de comisión
              de tu trabajo, algo que puede echarte para atrás si eres nuevo en
              este mundo.
            </p>

            <h3 className={classes2.titulo}>Freelancer</h3>

            <p className={classes2.texto}>
              Esta página web es otra de las páginas para freelancers más
              famosas de internet. Similar a Workana, una vez creado tu perfil
              de usuario y rellenado correctamente, deberás buscar la categoría
              donde quieres trabajar para buscar ofertas del sector.
            </p>

            <p className={classes2.texto}>
              ¿En qué consiste?:Similar a otras webs del mismo tipo ,un usuario
              publica un proyecto de determinadas características, después, los
              frelancers interesados se inscriben en la oferta y hacen al
              cliente un presupuesto básico. Una vez seas elegido el cliente
              transfiere los fondos a la plataforma que los guardará hasta que
              hayas terminado tu trabajo.&nbsp;
            </p>

            <p className={classes2.texto}>
              Debes saber que esta web se llevara un 10% de comisión antes de
              hacer tu propuesta de trabajo.
            </p>

            <h3 className={classes2.titulo}>Classonline</h3>

            <p className={classes2.texto}>
              Esta página web esta dedica a la venta de cursos ofrecidos por
              usuarios. Si siempre has querido ser profesor en esta página web
              puedes crear cursos fácilmente y ofrecerlos al público por importe
              que fijes. También puedes programar cursos y hacerlos en directo.
              Debes saber que esta web se lleva el 20% del dinero que ganes y
              los cursos puedes oscilar de entre los 20 a los 150 €.
            </p>

            <h3 className={classes2.titulo}>Malt</h3>

            <p className={classes2.texto}>
              Empresa española dedicada a la búsqueda de freelances en todo el
              territorio. Esta página tiene otras series de características. En
              esta ocasión completaras tu perfil de freelancer con todos tus
              datos profesionales, tu experiencia en el sector y cuál es tu
              presupuesto por horas.
            </p>

            <p className={classes2.texto}>
              Serán los clientes los que hagan una búsqueda de freelancers y los
              que elijan el cliente que más encaje con lo que buscan a través de
              la visualización de perfiles.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              No pierdes nada por crearte un perfil, es algo rápido que puede
              ponerte en contacto con empresas importantes del sector donde
              trabajes.
            </p>

            <h3 className={classes2.titulo}>Fiverr</h3>

            <p className={classes2.texto}>
              En esta página web, deberás crear un perfil atractivo mostrando
              tus habilidades, por ejemplo, si eres diseñador gráfico puedes
              subir tus archivos de más éxito y una carta de presentación. De
              este modo crearás un anuncio en el que tendrás que describir un
              servicio y fijar un precio para este. Debes recordar que de tu
              presupuesto, Fivver se llevara un 20% de comisión.
            </p>

            <p className={classes2.texto}>
              Una vez hecho esto, los clientes interesados se pondrán en
              contacto contigo aceptando tu propuesta. Obtendrás una valoración
              tras el trabajo que puede repercutir de forma decisiva a la hora
              de obtener más contrataciones.
            </p>

            <h3 className={classes2.titulo}>Lancetalent</h3>

            <p className={classes2.texto}>
              Se trata de una web similar a la mencionada Workana, sin embargo
              aquí no tendrás que esperar para que tu cuenta sea validada. Debes
              hacerte un perfil atractivo y original donde muestres tus
              destrezas en el sector que elijas.
            </p>

            <p className={classes2.texto}>
              Después realiza una búsqueda de ofertas según el sector y haz una
              propuesta al cliente donde expliques cuáles son tus conocimientos
              en la materia y fijes el presupuesto por el que estarías dispuesto
              a hacer el trabajo. En este caso, la plataforma se lleva un 15% de
              comisión sobre el precio que ofertes.
            </p>

            <p className={classes2.texto}>
              Recuerda que es importante obtener una buena valoración ya que de
              esta manera obtendrás más oportunidades de trabajar y por tanto,
              ganar dinero.
            </p>

            <h3 className={classes2.titulo}>Nubelo</h3>

            <p className={classes2.texto}>
              Se trata de una web española que pone en contacto clientes con
              empresas o particulares que busquen un frelancer. Una vez los
              clientes publiquen su oferta la plataforma te enviara un email si
              cumples con los requisitos que el cliente busca, deberás hacer una
              puja coherente y adjuntar una carta de presentación.
            </p>

            <p className={classes2.texto}>
              En esta ocasión la plataforma se lleva un 10% de comisión y es
              posible obtener una cuenta Premium en la que pagando 29€ el
              porcentaje de la comisión se reduce a la mitad. Esto es útil si ya
              has obtenido muchas valoraciones positivas en tu trabajo y haces
              muchos proyectos en esta plataforma.
            </p>

            <h3 className={classes2.titulo}>Guru</h3>

            <p className={classes2.texto}>
              De habla inglesa, esta página conecta empresas con clientes. Una
              vez registrado deberás buscar la oferta que más te interese según
              el sector donde trabajes, después deberás ofrecer un precio por
              tus servicios sabiendo que la comisión de esta web va desde los 5
              al 9% dependiendo del trabajo. También ofrece la posibilidad de
              contratar planes para que esta comisión se vea reducida.
            </p>
            <p>{<AdsGoogle block={2} />}</p>

            <p className={classes2.texto}>
              Debes saber que la mayoría de las ofertas son en ingles por lo que
              es un dato a tener en cuenta si quieres presupuestar por ellas-.
            </p>

            <h3 className={classes2.titulo}>Upwork</h3>

            <p className={classes2.texto}>
              Quizás la página webs de freelancers mas importantes a nivel
              mundial. Esta página web no se encuentra disponible en español,
              por lo que deberás dominar el inglés si quieres pujar en ella.
            </p>

            <p className={classes2.texto}>
              Una vez registrado el funcionamiento es similar al de las demás
              plataformas. Deberás registrarte adjuntando una foto de perfil y
              describiendo tus habilidades para después, buscar la oferta que
              más te interese. En este caso esta web se lleva una comisión que
              va desde el 5 al 20% según el trabajo realizado.
            </p>

            <p className={classes2.texto}>
              Su ventaja es que ofrece millones de ofertas por lo que el número
              de asignaciones puede ser mayor.
            </p>

            <h3 className={classes2.titulo}>Genniuz</h3>

            <p className={classes2.texto}>
              Una página web similar a Fivver y de origen español. Tiene la
              ventaja de que no necesita validación de la cuenta por lo que
              podrás empezar a trabajar rápidamente. En este tipo de paginas,
              eres tu quien ofrece un servicio y un precio por el trabajo
              realizado, de esta forma, serán los clientes los que se pongan en
              contacto contigo para encargarte un trabajo como FreeLancer.
            </p>

            <p className={classes2.texto}>
              Es una buena alternativa si eres nuevo en este mundo y quieres
              empezar desde cero.
            </p>

            <h3 className={classes2.titulo}>Peopleperhour</h3>

            <p className={classes2.texto}>
              Su página totalmente en ingles funciona de forma bidireccional.
              Los clientes pueden publicar una oferta de trabajo y esperar a
              recibir presupuestos o bien buscar directamente un profesional que
              encaje con los criterios de búsqueda. Por ello, la importancia de
              que crees un buen perfil para poder ser seleccionado. Las
              comisiones pueden ir desde un 5 a un 15%.
            </p>

            <h3 className={classes2.titulo}>Eadtools</h3>

            <p className={classes2.texto}>
              Plataforma de venta de cursos latinoamericana, esta plataforma te
              ofrece la posibilidad de crear cursos online sobre una categoría
              que domines y venderlos de forma sencilla. Su venta es que, en
              este caso no cobra comisión alguna.
            </p>
            <p>{<AdsGoogle block={2} />}</p>

            <h3 className={classes2.titulo}>Flexjobs</h3>

            <p className={classes2.texto}>
              Web en ingles muy interesante para FreeLancer ya que no cobra
              comisión a los usuarios. Esta web pone en contacto clientes con
              freelancers.La empresa será en este caso la que pague la comisión,
              para ello este portal se asegura de que los trabajos estés
              correctamente realizados uno por uno, asegurando de este modo la
              calidad que se pide.
            </p>

            <h3 className={classes2.titulo}>CrowdSPRING</h3>

            <p className={classes2.texto}>
              Otra web en ingles que no cobra comisión a los freelancers.Esta
              página web la empresa pone su oferta de empleo y fija un
              precio,después,el portal manda un email a todos los usuarios que
              encajen con lo que la empresa busca. Una vez hecho esto, deberás
              ponerte en contacto con la empresa y explicar cómo trabajarías
              intentando ser el mejor postulador.
            </p>

            <h3 className={classes2.titulo}>Envato</h3>

            <p className={classes2.texto}>
              Esta web esta especializada en temas de diseño gráfico. Una página
              que ofrece salarios muy elevados pero también altas comisiones de
              un 30% y unos requisitos de acceso bastante severos. Deberás
              rellenar un formulario donde se muestre que eres apto para formar
              parte de su red de freelancers.
            </p>

            <h3 className={classes2.titulo}>Kodatic</h3>

            <p className={classes2.texto}>
              Plataforma en español, para acceder a esta web también deberás
              rellenar un formulario que será revisado por su equipo de
              técnicos. Una vez dentro el modo de trabajo es similar al de otros
              portales, deberás buscar la oferta que encaje con tus habilidades
              y prespuestarla. Su novedad es que ofrece una sección de trabajo
              compartido si lo que quieres es trabajar con otro usuario.
            </p>

            <h3 className={classes2.titulo}>Udemy</h3>

            <p className={classes2.texto}>
              Página web en ingles especializada en la venta de cursos online.
              En este caso deberás registrarte y crear un curso sobre un
              determinado tema, fijas un precio y la página lo pondrá en su base
              para que así los usuarios puedan acceder a él. Esta web se lleva
              un 15% de comisión sobre el precio que indiques.
            </p>

            <h3 className={classes2.titulo}>Teachable</h3>
            <p className={classes2.texto}>
              Página web reconocida a nivel mundial por su oferta de cursos
              académicos. Deberás regístrate y crear un curso de calidad por el
              que fijaras un precio. Su gran ventaja es que no cobra comisiones
              por lo que el 100% del dinero&nbsp; que ganes será tuyo.
            </p>

            <h3 className={classes2.titulo}>Gurú</h3>

            <p className={classes2.texto}>
              Otra conocida plataforma que trabaja de forma similar a las ya
              mencionadas, es importante que armes un buen perfil pues en esta
              página web las valoraciones que obtengas son realmente
              importantes. Debes saber que antes de presupuestar por un trabajo,
              esta web se lleva un20% de comisión, algo a tener&nbsp; en cuenta
              antes de presentar tu candidatura.
            </p>

            <h3 className={classes2.titulo}>Domestika</h3>

            <p className={classes2.texto}>
              En esta página web puedes registrarte como usuario y vender tus
              cursos de manera virtual.
            </p>

            <p className={classes2.texto}>
              Puedes regístrate y elaborar cursos de todo tipo de temática para
              poder compartir y vender de forma totalmente personalizada. En
              este caso la página cobra un 40% de comisión, quizás demasiado.
            </p>

            <p className={classes2.texto}>
              Te he enseñado 21 páginas web donde puedes trabajar como
              freelancer y empezar a ganar dinero con tus habilidades desde
              casa. Compara y elige la que más te convenga.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
