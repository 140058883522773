import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h4 className={classes2.top}>30 Empleo que desaparecerán en 10 años</h4>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              En esta ocasión te voy a enseñar cuales son los 30 trabajos que
              van a desaparecer en los próximos diez años y que puedes hacer si
              trabajas en alguno de ellos.
            </p>

            <h3 className={classes2.titulo}>
              ¿Qué trabajos pueden desaparecer próximamente?
            </h3>

            <p className={classes2.texto}>
              <strong>Taxistas</strong>
            </p>

            <p className={classes2.texto}>
              Uno de los principales empleos que va a desaparecer es l trabajo
              de taxistas. Debido a nuevas empresas que ofrecen precios más
              económicos como la famosa Uber o los programas que se dedican
              contactar personas para hacer viajes colaborativos. Si este es tu
              caso, es buen momento para reinventarse y apostar por las nuevas
              tecnologías
            </p>

            <p className={classes2.texto}>
              <strong>Documentalistas en papel</strong>
            </p>

            <p className={classes2.texto}>
              La digitalización de los documentos y la aparición de volúmenes
              masivos de datos inutilizarán la tarea de estos expertos en
              documentación informativa o biblioteconomía, que ordenan grandes
              cantidades de documentos en papel. En contrapartida, se precisarán
              numerosos científicos de datos y responsables de Big
              Data para analizar y jerarquizar la información digital. 
            </p>

            <p className={classes2.texto}>
              Administrativos y personal bancario: La tendencia hacia la banca
              móvil se agudizará con el paso del tiempo, potenciada por la
              aparición de aplicaciones bancarias, chatbots y asistentes
              telefónicos que provocarán despidos masivos de empleados de
              atención al público y responsables de papeleo administrativo y
              burocracia en las sucursales.
            </p>

            <p className={classes2.texto}>
              Personal de atención al cliente: Los call centers sustituirán a su
              personal humana por sistemas de machine learning cada vez más
              naturales, buscadores por voz y chatbots. En el futuro, cabe
              esperar que los programas informatizados sean capaces de captar
              ironías, enfados o la satisfacción de los usuarios.
            </p>

            <p className={classes2.texto}>
              <strong>Wending Planner</strong>
            </p>

            <p className={classes2.texto}>
              Actualmente el negocio de las bodas está cayendo en picado. Cada
              vez son menos las parejas que deciden pasar por el altar y menos
              aún, contratar una persona que les organice la boda, por ello este
              es uno de los trabajos que va a tender a desaparecer en el futuro.
            </p>

            <p className={classes2.texto}>
              <strong>Operarios del sector papel</strong>
            </p>

            <p className={classes2.texto}>
              La aparición de documentos y libros en formato digital cada vez
              más extendida va a hacer que el sector de la imprenta del papel
              termine por desaparecer progresivamente. El trasvase progresivo
              aunque no total del formato papel al digital provocará, aunque no
              se pueda hablar de extinción completa, el cierre de un elevado
              porcentaje de empresas de impresión en los próximos años.
            </p>

            <p className={classes2.texto}>
              <strong>Personal de cine</strong>
            </p>

            <p className={classes2.texto}>
              Este trabajo también es uno de los condenados a desaparecer.
              Actualmente podemos obtener entradas de cine por internet, a
              través de aplicaciones móviles o en cajeros especiales para ellos.
              Por ello si trabajas como personal de cine quizás debas ir
              pensando en buscar un mejor puesto de trabajo.&nbsp;
            </p>

            <p className={classes2.texto}>
              <strong>Carteros</strong>
            </p>

            <p className={classes2.texto}>
              Aunque esto pueda parecer lejano, se estima que dentro de 10 años
              sean los dones los encargados de repartir paquetería. El comercio
              por internet está tomando fuerza, más aun en los tiempos actuales
              donde resulta más difícil desplazarse a las tiendas para adquirir
              productos. Por ello, una buena alternativa de trabajo será crear
              tiendas online y dedicarse a la venta por internet.&nbsp;
            </p>

            <p className={classes2.texto}>
              <strong>Costureros</strong>
            </p>

            <p className={classes2.texto}>
              Queda lejos el tiempo en el que confeccionábamos trajes a medida o
              llevábamos al costurero prendas que necesitaban un arreglo.
              Actualmente la caída de precios en el sector textil hace que no
              resulte muy rentable arreglar prendas. Ello junto a la
              automatización de la confección de prendas hace que este sea uno
              de los empleos que posiblemente caiga en el futuro. Sin embargo
              las nuevas tendencias en moda van a necesitan un mayor número de
              personas que se encarguen del diseño de prendas o el estilismo.
            </p>

            <p className={classes2.texto}>
              <strong>Community Manager</strong>
            </p>

            <p className={classes2.texto}>
              Aunque ahora mismo parezca una locura que este puesto de trabajo
              vaya a desaparecer, ciertos estudios de importantes Universidades
              afirman que pasados unos años este tipo de trabajos se convertirán
              en habilidades intrínsecas de las propias empresas, por lo que no
              necesitaran personal externo.
            </p>

            <p className={classes2.texto}>
              <strong>Agente de viajes</strong>
            </p>

            <p className={classes2.texto}>
              Antiguamente era normal ir a una agencia de viajes y consultar
              múltiples catálogos de viajes para encontrar el viaje soñado. Hoy
              en día todo lo que buscamos, incluidas las mejores ofertas lo
              podemos encontrar por internet, por lo que este, es uno de los
              trabajos que puede desaparecer en los próximos 10 años. Si este es
              tu caso quizás sea buen momento para abrir tu propia agencia de
              viajes por internet como ya mucha gente está haciendo.
            </p>

            <p className={classes2.texto}>
              <strong>Cajeros de banco</strong>
            </p>

            <p className={classes2.texto}>
              Cada día, el dinero en efectivo es menos usado. Debido a la nueva
              tecnología como la usada por Paypal,Aplle Pay o incluso las
              criptomonedas, los cajeros de banco van a tener que reinventarse
              si quieren seguir trabajando
            </p>

            <p className={classes2.texto}>
              <strong>Operadores peajes</strong>
            </p>

            <p className={classes2.texto}>
              En la mayoría de países de la Unión Europea ya podemos ver como en
              muchas cabinas de peaje ya no existen personas para cobrar.
              Existen programas y aplicaciones que son capaces de levantar las
              barreras de forma automática y cobrar el importe sin la necesidad
              de usar personal.
            </p>

            <p className={classes2.texto}>
              <strong>Intermediarios</strong>
            </p>

            <p className={classes2.texto}>
              El trabajo de Broker de seguros o agentes inmobiliarios así como
              otro tipo de asesores está en riesgo. Muchos de estos servicios
              han sido ya sustituidos por portales de internet. El uso de las
              tecnologías permite a los usuarios conocer todos los datos
              necesarios para poder informarse y asesorarse sin tener que acudir
              a un tercero. Si trabajas en este sector es adecuado que empieces
              a investigar otros medios con los que ganarte la vida como puede
              ser especializarte en marketing digital, algo que supone el futuro
              en el empleo.
            </p>

            <p className={classes2.texto}>
              <strong>Personal de la televisión por cable</strong>
            </p>

            <p className={classes2.texto}>
              Actualmente portales como HBO o Netflix están sustituyendo las
              antiguas empresas de televisión por cable, por lo que no es raro
              de pensar que dentro de poco terminen por desaparecer. Actualmente
              tener una conexión a internet es más que suficiente para poder ver
              todo tipo de series y programas de televisión sin tener que
              instalar aparatos de ningún tipo. Si te dedicas a este sector
              quizás debas reinventarte con trabajos enmarcados en las nuevas
              tecnologías como servicios de ventas por internet o reparación de
              sistemas de redes.
            </p>

            <p className={classes2.texto}>
              <strong>Quiosqueros</strong>
            </p>

            <p className={classes2.texto}>
              Las nuevas tecnologías han hecho que cada vez sea menos la gente
              que adquiere periódicos y revistas en formato papel. Actualmente
              podemos acceder a este tipo de información usando un dispositivo
              con acceso a internet por lo que el trabajo de quiosquero
              está&nbsp; condenado a desaparecer en los próximos años. Si es tu
              caso, puedes intentar buscar trabajo en el sector publicitario o
              en la venta de documentos online.
            </p>

            <p className={classes2.texto}>
              <strong>Liquidadores de impuestos</strong>
            </p>

            <p className={classes2.texto}>
              Actualmente, gracias a la tecnología vigente, existen algoritmos
              que son capaces de liquidar impuestos sin ningún tipo de error.
              Por ello este trabajo va a tender a desaparecer en los próximos
              años. Una alternativa: estudiar economía.
            </p>

            <p className={classes2.texto}>
              <strong>Relojero</strong>
            </p>

            <p className={classes2.texto}>
              Actualmente son pocas las personas que siguen dedicándose a ello.
              Por lo que se estima que dentro de diez años habrán acabado por
              desaparecer. Hoy en día la mayoría de relojes no cuentan con el
              complicado funcionamiento de los antiguos relojes, que han sido
              sustituidos por relojes inteligentes. Si este es tu caso una buena
              alternativa puede ser fórmate como técnico electrónico, para así
              poder reparar los relojes electrónicos, tan demandados en la
              actualidad.
            </p>

            <p className={classes2.texto}>
              <strong>Conductores de trenes, metros o tranvías</strong>
            </p>

            <p className={classes2.texto}>
              El uso de las nuevas tecnologías va a hacer que los trabajos de
              conductor pierdan fuerza. Actualmente ya podemos ver como muchos
              de estos medios de transporte pueden manejarse desde una
              centralita por lo que no es necesario ningún conductor. Si es tu
              caso, puedes formarte como técnico electrónico para reparar los
              fallos que puedan surgir de su uso.
            </p>

            <p className={classes2.texto}>
              <strong>Telefonista</strong>
            </p>

            <p className={classes2.texto}>
              Un trabajo muy común hoy en dial trabajo de telefonista esta
              previsto como uno de los empleos que no sobrevivirán mucho tiempo.
              El uso de robots y programas inteligentes hará que las personas
              acaben siendo sustituidas por la teclogia.Sin embargo se va a
              necesitar personas que dirijan las funciones de las llamadas y
              profesionales de marketing para crear campañas que atraigan más
              clientes.
            </p>

            <p className={classes2.texto}>
              <strong>Operario de metal</strong>
            </p>

            <p className={classes2.texto}>
              Los operarios de metal y plástico en cargados de fundir y acomodar
              el material para los diferentes objetos van a ver peligrar su
              trabajo en los próximos años. El uso de herramientas automatizadas
              hace que cada vez sea menos necesaria la mano de obra usada en
              este sector, por lo que este tipo d empleo tendera a desaparecer.
            </p>

            <p className={classes2.texto}>
              <strong>Construcciones de casa prefabricadas</strong>
            </p>

            <p className={classes2.texto}>
              Hasta no hace mucho, las casas prefabricadas necesitaban mucho
              personal que se encargara de su instalación. Hoy en día con los
              recursos de internet y los que ofrecen las tiendas dedicadas a su
              venta, no son necesarias personas que se encarguen de esta labor.
              Eso, con el hecho de que la antigua moda de tener una casa
              prefabrica está en desuso hace que este sector este abocado a
              desaparecer.
            </p>

            <p className={classes2.texto}>
              <strong>Operadores de máquinas de coser</strong>
            </p>

            <p className={classes2.texto}>
              Antiguamente las máquinas de coser eran un recurso muy utilizado
              por personas dedicadas al sector. Actualmente las fábricas usan
              maquinaria industrial especializada que permite prescindir de
              personas que realicen esta labor. Puede ser útil dedicarse a
              reparar averías de grandes maquinas si quieres seguir trabajando
              en ello.
            </p>

            <p className={classes2.texto}>
              <strong>Reparador zapatos</strong>
            </p>

            <p className={classes2.texto}>
              Algo similar al sector textil ocurre con la profesión de zapatero.
              Debido a la bajada de precios que ofrecen las tiendas no resulta
              muy rentable arreglar zapatos actualmente, por lo que es otro de
              los trabajos condenado a la desaparición. Si aun asirte sigue
              apasionando este mundo, puede ser útil formarte en una escuela de
              diseño.
            </p>

            <p className={classes2.texto}>
              <strong>Técnicos ferroviarios</strong>
            </p>

            <p className={classes2.texto}>
              Siempre ha habido personal encargado de que las vías por donde
              circulan los trenes se encuentren en buen estado para un correcto
              uso, sin embargo el uso de tecnologías como drenes o sensores hace
              que cada vez sean menos las ofertas en este tipo de trabajos. Si
              te apasiona este mundo, deberás modernizarte con cursos de
              ingeniería civil o reparación de sistemas electrónicos.
            </p>

            <p className={classes2.texto}>
              <strong>Inspector Fiscal</strong>
            </p>

            <p className={classes2.texto}>
              Una vez más, el uso de las tecnologías está haciendo que todos los
              tramites se hagan a través de los medios computacionales, por lo
              que la figura del inspector como la conocemos, puede desaparecer
              proximante.En su lugar, se usaran personas capaces de organizar la
              documentación que les llegue a través de la red y analizarla para
              ver que cumplen la legalidad.
            </p>

            <p className={classes2.texto}>
              <strong>Reparador de Electrodomésticos</strong>
            </p>

            <p className={classes2.texto}>
              Debido a la conocida obsolescencia programada la vida útil de
              nuestros electrodomésticos tiene un periodo definido, por lo que
              cada vez son menos las personas que llevan sus electrodomésticos a
              reparar. Eso, unido a la bajada de precios de los diferentes
              artefactos que usamos en la vida diaria, hace que el trabajo de
              técnico de reparación de electrodomésticos desparezca en los
              próximos años.
            </p>

            <p className={classes2.texto}>
              <strong>Leñador</strong>
            </p>

            <p className={classes2.texto}>
              Antiguamente los leñadores eran necesarios para poder obtener una
              fuente de energía para calentarse y cocinar. Hoy en día la
              maquinaria industrial aporta las ventajas de poder recolectar
              grandes cantidades de leña sin tener que usar recursos humanos.
            </p>

            <p className={classes2.texto}>
              Te he enseñado cuales son los 30 trabajos que van a desaparecer en
              los próximos 10 años. Si trabajas en uno de ellos quizás debas
              replantearte tu futuro laboral y buscar nuevas opciones.
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
