import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  youtubeColor,
  headerColor,
  whiteColor,
  grayColor,
} from "../componentesEmpleo/material-kit-pro-react.js";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  notificadoButton: {
    color: headerColor,
    borderColor: headerColor,
    "&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: grayColor[5],
      borderColor: whiteColor,
    },
  },
  notify: {
    color: theme.palette.primary.blanco,
    backgroundColor: headerColor,
  },
  errorText: {
    color: youtubeColor,
  },
}));

export default function ModalForm() {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    if (emailRegex.test(email)) {
      setError(false);
      console.log("Email correto");
    } else {
      e.preventDefault();
      setError(true);
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        className={classes.notificadoButton}
      >
        Ser notificado
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent align="center"></DialogContent>

        <DialogTitle align="center" id="alert-dialog-title">
          {"Deja tu e-mail abajo"}{" "}
        </DialogTitle>
        <br></br>

        <DialogContent>
          <Grid item>
            {
              <form
                action="https://paginas.rocks/Pages/Index/379928"
                method="post"
                onSubmit={handleSubmit}
              >
                <input id="id" name="id" type="hidden" value="379928" />
                <input id="pid" name="pid" type="hidden" value="15436799" />
                <input
                  id="list_id"
                  name="list_id"
                  type="hidden"
                  value="379928"
                />
                <input
                  id="provider"
                  name="provider"
                  type="hidden"
                  value="leadlovers"
                />
                <input type="hidden" id="source" name="source" value="" />
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control form-ll"
                  id="email"
                  name="email"
                  placeholder="E-mail"
                />
                {error ? (
                  <p className={classes.errorText}>E-mail no válido.</p>
                ) : null}

                <br></br>

                <br></br>
                <Button
                  className={classes.notify}
                  type="submit"
                  variant="contained"
                >
                  Recibir Notificaciones
                </Button>
              </form>
            }
          </Grid>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="light">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
