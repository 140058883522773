import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Share from "@material-ui/icons/Share";
import ChatBubble from "@material-ui/icons/ChatBubble";
import Schedule from "@material-ui/icons/Schedule";
import TrendingUp from "@material-ui/icons/TrendingUp";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import cardBlog1 from "assets/img/cursillo.jpg";
import cardBlog2 from "assets/img/examples/paro.jpg";

import blog8 from "assets/img/trampilla.jpg";
import higor from "assets/img/faces/higor.jpg";
import isabel from "assets/img/faces/isabel.jpg";
import hombre from "assets/img/faces/hombre.jpg";

import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";

const useStyles = makeStyles(styles);

export default function SectionCards() {
  const classes = useStyles();

  return (
    <div>
      <div className="cd-section" id="cards">
        <div className={classes.sectionGray}>
          <div>
            {/* BLOG CARDS START */}
            <div>
              <div className={classes.container}>
                <div className={classes.title}></div>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6} lg={4}>
                    <Card blog>
                      <CardHeader image>
                        <a href="https://hmnoticias.com/cursos/">
                          <img src={cardBlog1} alt="cursos" />
                          <div className={classes.cardTitleAbsolute}>
                            Cursos Gratuitos del INEM
                          </div>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${cardBlog1})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                      <CardBody>
                        <Info>
                          <h6 className={classes.cardCategory}>IMPORTANTE</h6>
                        </Info>
                        <div className={classes.cardDescription}>
                          El Gobierno en conjunto con Amazon, Huawei y Google,
                          disponibilizan varios cursos gratuitos (online) con
                          empleo asegurado.
                        </div>
                      </CardBody>
                    </Card>
                    <Card color="info">
                      <CardBody color>
                        <h5 className={classes.cardCategorySocialWhite}>
                          <i className="fab fa-twitter" />
                          Twitter
                        </h5>
                        <h4 className={classes.cardTitleWhite}>
                          {'"'}No dejes de creer en ti, todo es posible.{'"'}
                        </h4>
                      </CardBody>
                      <CardFooter>
                        <div className={classes.authorWhite}>
                          <img
                            src={higor}
                            alt="HigorRocha"
                            className={classes.imgRaised + " " + classes.avatar}
                          />
                          <span>Higor Rocha</span>
                        </div>
                        <div
                          className={classes.statsWhite + " " + classes.mlAuto}
                        >
                          <Favorite />
                          12.7K ·
                          <Share />
                          139
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={4}>
                    <Card>
                      <CardBody>
                        <Danger>
                          <h6 className={classes.cardCategory}>
                            <TrendingUp /> TRENDING
                          </h6>
                        </Danger>
                        <h4 className={classes.cardTitle}>
                          <a href="/trabajar-como-freelancer">
                            10 Páginas con ofertas de empleo para trabajar en
                            casa...
                          </a>
                        </h4>
                      </CardBody>
                      <CardFooter>
                        <div className={classes.author}>
                          <img
                            src={isabel}
                            alt="IsabelRocha"
                            className={classes.imgRaised + " " + classes.avatar}
                          />
                          <span>Isabel Rocha</span>
                        </div>
                        <div className={classes.stats + " " + classes.mlAuto}>
                          <Favorite />
                          347 ·
                          <ChatBubble />
                          45
                        </div>
                      </CardFooter>
                    </Card>
                    <Card blog>
                      <CardHeader image>
                        <a href="/trucos-para-ahorrar-dinero">
                          <img src={cardBlog2} alt="paro" />
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${cardBlog2})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                      <CardBody>
                        <Success>
                          <h6 className={classes.cardCategory}>Favorito</h6>
                        </Success>
                        <h4 className={classes.cardTitle}>
                          <a href="/trucos-para-ahorrar-dinero">
                            Cómo ahorrar dinero en 2021
                          </a>
                        </h4>
                        <p className={classes.cardDescription}>
                          Aprende a ahorrar tu dinero de una forma fácil y
                          sencilla. No tendrás qeu dejar el café de todos los
                          días, solo tendrás que mirar aquellos gastos realmente
                          importante.
                        </p>
                      </CardBody>
                      <CardFooter>
                        <div className={classes.author}>
                          <img
                            src={hombre}
                            alt="dinero"
                            className={classes.imgRaised + " " + classes.avatar}
                          />
                          <span>Miguel Silva</span>
                        </div>
                        <div className={classes.stats + " " + classes.mlAuto}>
                          <Schedule />5 min
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={4}>
                    <Card blog>
                      <CardHeader image>
                        <a href="/mas-ofertas-empleo-en-el-mundo">
                          <img src={blog8} alt="ayuda" />
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${blog8})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                      <CardBody>
                        <Danger>
                          <h6 className={classes.cardCategory}>
                            <TrendingUp /> TRENDING
                          </h6>
                        </Danger>
                        <h4 className={classes.cardTitle}>
                          <a href="/mas-ofertas-empleo-en-el-mundo">
                            Estos son los trabajos con más ofertas de empleo en
                            el mundo...{" "}
                          </a>
                        </h4>
                      </CardBody>
                      <CardFooter>
                        <div className={classes.author}>
                          <img
                            src={hombre}
                            alt="miguel"
                            className={classes.imgRaised + " " + classes.avatar}
                          />
                          <span>Miguel Silva</span>
                        </div>
                        <div className={classes.stats + " " + classes.mlAuto}>
                          <Schedule />7 min
                        </div>
                      </CardFooter>
                    </Card>
                    <Card color="success">
                      <CardBody color>
                        <h5 className={classes.cardCategorySocialWhite}>
                          <NotificationImportantIcon /> Destacado
                        </h5>
                        <h4 className={classes.cardTitleWhite}>
                          <a href="/calcular-subsidio-desempleo">
                            Cómo calcular el paro
                          </a>
                        </h4>
                        <p className={classes.cardDescriptionWhite}>
                          Descubre la cantidad de paro que te corresponde y cómo
                          pedirlo sin salir de la comodidad de tu casa de manera
                          rápida y sencilla.
                        </p>
                        <div
                          className={
                            classes.stats + " " + classes.justifyContentCenter
                          }
                        >
                          <a href="/calcular-subsidio-desempleo">
                            <Button color="white" round>
                              Leer Ahora
                            </Button>
                          </a>
                        </div>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            {/* BLOG CARDS END */}
          </div>
        </div>
      </div>
    </div>
  );
}
