import React, { useState, useEffect, useRef, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import { Helmet } from "react-helmet";
import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

import blog5 from "assets/img/teste2.jpg";
import card1 from "assets/img/10paginas.jpg";
import card2 from "assets/img/trampa.jpg";

import card4 from "assets/img/paro.jpg";
import card5 from "assets/img/coca.jpg";
import card6 from "assets/img/agenteventas.jpg";

import card8 from "assets/img/linkedin.jpg";
import subastaEscrapalia from "assets/img/subasta.jpg";

import subastaIndustrial from "assets/img/subastapc.jpg";

import card12 from "assets/img/basura.jpg";

import card14 from "assets/img/sae.jpg";
import card15 from "assets/img/errores.jpg";
import card16 from "assets/img/registro.jpg";

import card19 from "assets/img/ayuda.jpg";
import card20 from "assets/img/coche.jpg";

import card22 from "assets/img/gasolina.jpg";

import card29 from "assets/img/ere.jpg";

import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(blogsStyle);
const useStyles2 = makeStyles({
  ajuste: {
    marginTop: "-50px",
  },
  leer: {
    color: blackColor,
    marginLeft: "5px",
    fontWeight: "bold",
    textDecoration: "underline",
    "&:hover": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});

export default function SectionBlogs({ ...rest }) {
  const classes = useStyles();
  const classes2 = useStyles2();
  const [show, setShow] = useState(false);
  const elementRef = useRef();
  useEffect(() => {
    const onChange = (entries, observer) => {
      const el = entries[0];
      //console.log(el);
      if (el.isIntersecting) {
        setShow(true);

        observer.disconnect();
      }
    };
    const observer = new IntersectionObserver(onChange, {
      rootMargin: "20px",
    });
    observer.observe(elementRef.current);
    return () => observer.disconnect();
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>HM Servicios - Blog</title>
      </Helmet>
      <div className="cd-section" {...rest}>
        {/* Blogs 3 START */}
        <div className={classes.blog}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={10}
                md={10}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <CardHeader image plain>
                        <a href="/trabajar-como-freelancer">
                          <img src={card1} alt="empleo" />
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${card1})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${card1})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <Info>
                        <h6 className={classes.cardCategory}>EMPLEO</h6>
                      </Info>
                      <h3 className={classes.cardTitle}>
                        <a href="/trabajar-como-freelancer">
                          Cómo trabajar como freelancer
                        </a>
                      </h3>
                      <p className={classes.description}>
                        Descubre cuáles son las empresas que buscan empleados
                        para trabajar desde casa. Aumenta tu prestigio y ten
                        acceso a una red de clientes que te garantizarán un buen
                        sueldo.
                        <a
                          href="/trabajar-como-freelancer"
                          className={classes2.leer}
                        >
                          Leer ahora
                        </a>
                      </p>
                      <p className={classes.author}>
                        por <b>Hugo Rocha</b>
                      </p>
                    </GridItem>
                  </GridContainer>
                </Card>
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                      <CardHeader image plain>
                        <a href="/contratado-en-la-primera-entrevista">
                          <img src={card2} alt="trampa" />
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${card2})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${card2})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <Danger>
                        <h6 className={classes.cardCategory}>
                          <TrendingUp />
                          Destacado
                        </h6>
                      </Danger>
                      <h3 className={classes.cardTitle}>
                        <a href="/contratado-en-la-primera-entrevista">
                          Cómo ser contratado en la primera entrevista{" "}
                        </a>
                      </h3>
                      <p className={classes.description}>
                        Pasar la entrevista es uno de los procesos más duros,
                        por eso aquí tienes las 11 preguntas que te harán en
                        cualquier entrevista y cómo responderlas.
                        <a
                          href="/contratado-en-la-primera-entrevista"
                          className={classes2.leer}
                        >
                          {" "}
                          Leer ahora{" "}
                        </a>
                      </p>
                      <p className={classes.author}>
                        por <b>Hugo Rocha</b>
                      </p>
                    </GridItem>
                  </GridContainer>
                </Card>
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <CardHeader image plain>
                        <a href="/calcular-subsidio-desempleo">
                          <img src={card4} alt="paro" />
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${card4})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${card4})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <Info>
                        <h6 className={classes.cardCategory}>Paro</h6>
                      </Info>
                      <h3 className={classes.cardTitle}>
                        <a href="/calcular-subsidio-desempleo">
                          Aprende a calcular tu subsidio por desempleo
                        </a>
                      </h3>
                      <p className={classes.description}>
                        Aprende a calcular la retribución por desempleo que te
                        corresponde e ir a sellar el paro mes a mes sin salir de
                        tu casa. Todo de una forma sencilla y rápida para que
                        puedas conseguirlo en el momento que más necesites.
                        <a
                          href="/calcular-subsidio-desempleo"
                          className={classes2.leer}
                        >
                          {" "}
                          Leer ahora{" "}
                        </a>
                      </p>
                      <p className={classes.author}>
                        por <b>Hugo Rocha</b>
                      </p>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>

      {/**LazyLoad*/}
      <div ref={elementRef}>
        {show ? (
          <div className={(classes.blog, classes2.ajuste)}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={10}
                  md={10}
                  className={classes.mlAuto + " " + classes.mrAuto}
                >
                  <Card plain blog className={classes.card}>
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                        <CardHeader image plain>
                          <a href="/curriculum-impecable">
                            <img src={card12} alt="basura" />
                          </a>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${card12})`,
                              opacity: "1",
                            }}
                          />
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${blog5})`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                      </GridItem>
                      <GridItem xs={12} sm={8} md={8}>
                        <Success>
                          <h6 className={classes.cardCategory}>Curriculum</h6>
                        </Success>
                        <h3 className={classes.cardTitle}>
                          <a href="/curriculum-impecable">
                            30 Consejos para hacer un curriculum impecable
                          </a>
                        </h3>
                        <p className={classes.description}>
                          Existe un robot que hace la evaluación automática de
                          tu curriculum, si no sabes cómo pasar por ese robot,
                          tu curriculum acabará literalmente en la basura.
                          Descubre cómo evitar eso.
                          <a
                            href="/curriculum-impecable"
                            className={classes2.leer}
                          >
                            {" "}
                            Leer ahora{" "}
                          </a>
                        </p>
                        <p className={classes.author}>
                          por <b>Hugo Rocha</b>
                        </p>
                      </GridItem>
                    </GridContainer>
                  </Card>
                  <Card plain blog className={classes.card}>
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                        <CardHeader image plain>
                          <a href="/mas-ofertas-empleo-en-el-mundo">
                            <img src={card5} alt="coca-cola" />
                          </a>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${card5})`,
                              opacity: "1",
                            }}
                          />
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${card5})`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                      </GridItem>
                      <GridItem xs={12} sm={8} md={8}>
                        <Danger>
                          <h6 className={classes.cardCategory}>Empleo</h6>
                        </Danger>
                        <h3 className={classes.cardTitle}>
                          <a href="/mas-ofertas-empleo-en-el-mundo">
                            Profesiones con más ofertas de empleo
                          </a>
                        </h3>
                        <p className={classes.description}>
                          Descubre cuáles son las profesiones con más ofertas de
                          empleo en el mundo. Sabiendo cuáles son las ofertas
                          con más demanda podrás buscar ofertas de empleo en
                          lugar correcto.{" "}
                          <a
                            href="/mas-ofertas-empleo-en-el-mundo"
                            className={classes2.leer}
                          >
                            {" "}
                            Leer ahora{" "}
                          </a>
                        </p>
                        <p className={classes.author}>
                          por <b>Hugo Rocha</b>
                        </p>
                      </GridItem>
                    </GridContainer>
                  </Card>
                  <Card plain blog className={classes.card}>
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                        <CardHeader image plain>
                          <a href="/empleo-mas-demandado">
                            <img src={card6} alt="agente" />
                          </a>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${card6})`,
                              opacity: "1",
                            }}
                          />
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${card6})`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                      </GridItem>
                      <GridItem xs={12} sm={8} md={8}>
                        <Success>
                          <h6 className={classes.cardCategory}>Ventas</h6>
                        </Success>
                        <h3 className={classes.cardTitle}>
                          <a href="/empleo-mas-demandado">
                            El empleo más demandado del mundo
                          </a>
                        </h3>
                        <p className={classes.description}>
                          El COVID-19 ha despertado en las empresas la necesidad
                          de tener empleados vendiendo de forma telemática. El
                          mercado laboral se ha inundado de dichas ofertas y
                          aquí te ofrezco las mejores empresas para trabajar.
                          <a
                            href="/empleo-mas-demandado"
                            className={classes2.leer}
                          >
                            {" "}
                            Leer ahora{" "}
                          </a>
                        </p>
                        <p className={classes.author}>
                          por <b>Hugo Rocha</b>
                        </p>
                      </GridItem>
                    </GridContainer>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>

            {/* Blogs 3 END */}

            {/* Blogs 3 START */}
            <div className={(classes.blog, classes2.ajuste)}>
              <div className={classes.container}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={10}
                    md={10}
                    className={classes.mlAuto + " " + classes.mrAuto}
                  >
                    <Card plain blog className={classes.card}>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={4}>
                          <CardHeader image plain>
                            <a href="/trucos-para-ahorrar-dinero">
                              <img src={card20} alt="traductor" />
                            </a>
                            <div
                              className={classes.coloredShadow}
                              style={{
                                backgroundImage: `url(${card20})`,
                                opacity: "1",
                              }}
                            />
                            <div
                              className={classes.coloredShadow}
                              style={{
                                backgroundImage: `url(${card20})`,
                                opacity: "1",
                              }}
                            />
                          </CardHeader>
                        </GridItem>
                        <GridItem xs={12} sm={8} md={8}>
                          <Info>
                            <h6 className={classes.cardCategory}>
                              Ganar dinero
                            </h6>
                          </Info>
                          <h3 className={classes.cardTitle}>
                            <a href="/trucos-para-ahorrar-dinero">
                              51 Consejos para ahorrar dinero
                            </a>
                          </h3>
                          <p className={classes.description}>
                            Existen varias trampas en las que estás cayendo que
                            te hacen gastar mucho más dinero de lo que deberías.
                            Aquí te explico cuáles son y cómo puedes evitarlas y
                            así ahorrar dinero.
                            <a
                              href="/trucos-para-ahorrar-dinero"
                              className={classes2.leer}
                            >
                              {" "}
                              Leer ahora{" "}
                            </a>
                          </p>
                          <p className={classes.author}>
                            por <b>Hugo Rocha</b>
                          </p>
                        </GridItem>
                      </GridContainer>
                      {/* Monetizze ADS*/}
                    </Card>
                    <Card plain blog className={classes.card}>
                      <GridContainer>
                        <GridItem xs={12} sm={4} md={4}>
                          <CardHeader image plain>
                            <a href="/cursos-google">
                              <img src={card8} alt="linkedin" />
                            </a>
                            <div
                              className={classes.coloredShadow}
                              style={{
                                backgroundImage: `url(${card8})`,
                                opacity: "1",
                              }}
                            />
                            <div
                              className={classes.coloredShadow}
                              style={{
                                backgroundImage: `url(${card8})`,
                                opacity: "1",
                              }}
                            />
                          </CardHeader>
                        </GridItem>
                        <GridItem xs={12} sm={8} md={8}>
                          <Danger>
                            <h6 className={classes.cardCategory}>
                              <TrendingUp />
                              Empleo
                            </h6>
                          </Danger>
                          <h3 className={classes.cardTitle}>
                            <a href="/cursos-google">
                              Cursos de Google gratuitos{" "}
                            </a>
                          </h3>
                          <p className={classes.description}>
                            Gracias a la gigante Google puedes formarte con
                            cursos de gran calibre sin tener que gastar
                            absolutamnete nada. Todo el material está disponible
                            online y para todos lo público.
                            <a href="/cursos-google" className={classes2.leer}>
                              {"   "}
                              Leer ahora{" "}
                            </a>
                          </p>
                          <p className={classes.author}>
                            por <b>Hugo Rocha</b>
                          </p>
                        </GridItem>
                      </GridContainer>
                      {/* Monetizze ADS*/}
                    </Card>
                    <Card plain blog className={classes.card}>
                      <GridContainer>
                        <GridItem xs={12} sm={4} md={4}>
                          <CardHeader image plain>
                            <a href="/ere-erte">
                              <img src={card29} alt="ere" />
                            </a>
                            <div
                              className={classes.coloredShadow}
                              style={{
                                backgroundImage: `url(${card29})`,
                                opacity: "1",
                              }}
                            />
                            <div
                              className={classes.coloredShadow}
                              style={{
                                backgroundImage: `url(${card29})`,
                                opacity: "1",
                              }}
                            />
                          </CardHeader>
                        </GridItem>
                        <GridItem xs={12} sm={8} md={8}>
                          <Danger>
                            <h6 className={classes.cardCategory}>ERE y ERTE</h6>
                          </Danger>
                          <h3 className={classes.cardTitle}>
                            <a href="/ere-erte">
                              ERE y ERTE, diferencias y derechos
                            </a>
                          </h3>
                          <p className={classes.description}>
                            ERE (Expediente de regulación de empleo) o un ERTE
                            (expediente de regulación temporal de empleo). Debes
                            saber cuáles son tus derechos y que diferencias hay
                            entre cada uno.
                            <a href="/ere-erte" className={classes2.leer}>
                              {" "}
                              Leer ahora{" "}
                            </a>
                          </p>
                          <p className={classes.author}>
                            por <b>Hugo Rocha</b>
                          </p>
                        </GridItem>
                      </GridContainer>
                      {/* Monetizze ADS*/}
                    </Card>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            {/* Blogs 3 END */}
            {/* Blogs 3 START */}
            <div className={(classes.blog, classes2.ajuste)}>
              <div className={classes.container}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={10}
                    md={10}
                    className={classes.mlAuto + " " + classes.mrAuto}
                  >
                    <Card plain blog className={classes.card}>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={4}>
                          <CardHeader image plain>
                            <a href="/cual-es-mas-barato">
                              <img src={card22} alt="sepe" />
                            </a>
                            <div
                              className={classes.coloredShadow}
                              style={{
                                backgroundImage: `url(${card22})`,
                                opacity: "1",
                              }}
                            />
                            <div
                              className={classes.coloredShadow}
                              style={{
                                backgroundImage: `url(${card22})`,
                                opacity: "1",
                              }}
                            />
                          </CardHeader>
                        </GridItem>
                        <GridItem xs={12} sm={8} md={8}>
                          <Info>
                            <h6 className={classes.cardCategory}>Ahorro</h6>
                          </Info>
                          <h3 className={classes.cardTitle}>
                            <a href="/cual-es-mas-barato">Diésel VS Gasolina</a>
                          </h3>
                          <p className={classes.description}>
                            Hemos calculado cuánto cuesta mantener un coche de
                            gasolina y uno diésel. ¿Quieres saber cuál ha sido
                            el resultado? Te vas a sorprender.
                            <a
                              href="/cual-es-mas-barato"
                              className={classes2.leer}
                            >
                              {" "}
                              Leer ahora{" "}
                            </a>
                          </p>
                          <p className={classes.author}>
                            por <b>Hugo Rocha</b>
                          </p>
                        </GridItem>
                      </GridContainer>
                      {/* Monetizze ADS*/}
                    </Card>
                    <Card plain blog className={classes.card}>
                      <GridContainer>
                        <GridItem xs={12} sm={4} md={4}>
                          <CardHeader image plain>
                            <a href="/cursos-harvard">
                              <img src={card14} alt="repartidores" />
                            </a>
                            <div
                              className={classes.coloredShadow}
                              style={{
                                backgroundImage: `url(${card14})`,
                                opacity: "1",
                              }}
                            />
                            <div
                              className={classes.coloredShadow}
                              style={{
                                backgroundImage: `url(${card14})`,
                                opacity: "1",
                              }}
                            />
                          </CardHeader>
                        </GridItem>
                        <GridItem xs={12} sm={8} md={8}>
                          <Danger>
                            <h6 className={classes.cardCategory}>
                              <TrendingUp />
                              Destacado
                            </h6>
                          </Danger>
                          <h3 className={classes.cardTitle}>
                            <a href="/cursos-harvard">
                              50 Cursos gratuitos de Harvard University
                            </a>
                          </h3>
                          <p className={classes.description}>
                            Una de las universidades más solicitadas del mundo
                            te da la oportunidad de formarte de forma gratuita
                            sin salir de la comodidad de tu casa de forma
                            totalmente online. Cursos de alto calibre
                            <a href="/cursos-harvard" className={classes2.leer}>
                              {" "}
                              Leer ahora{" "}
                            </a>
                          </p>
                          <p className={classes.author}>
                            por <b>Hugo Rocha</b>
                          </p>
                        </GridItem>
                      </GridContainer>
                      {/* Monetizze ADS*/}
                    </Card>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            {/* Blogs 3 END */}
            {/* Blogs 3 START */}
            <div className={classes.container}>
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={10}
                  md={10}
                  className={classes.mlAuto + " " + classes.mrAuto}
                >
                  <Card plain blog className={classes.card}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={4}>
                        <CardHeader image plain>
                          <a href="/ayudas-poblacion">
                            <img src={card19} alt="ayuda" />
                          </a>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${card19})`,
                              opacity: "1",
                            }}
                          />
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${card19})`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                      </GridItem>
                      <GridItem xs={12} sm={8} md={8}>
                        <Info>
                          <h6 className={classes.cardCategory}>Ayudas</h6>
                        </Info>
                        <h3 className={classes.cardTitle}>
                          <a href="/ayudas-poblacion">
                            Ayudas que el Gobierno da a la población
                          </a>
                        </h3>
                        <p className={classes.description}>
                          En este post aprenderás todo sobre las ayudas que el
                          Gobierno tiene para la población y cómo acceder a
                          ellas de forma fácil y rápida. Un paso a paso que hará
                          llegar a tu casa tu subsidio.
                          <a href="/ayudas-poblacion" className={classes2.leer}>
                            {" "}
                            Leer ahora{" "}
                          </a>
                        </p>
                        <p className={classes.author}>
                          por <b>Hugo Rocha</b>
                        </p>
                      </GridItem>
                    </GridContainer>
                  </Card>
                  <Card plain blog className={classes.card}>
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                        <CardHeader image plain>
                          <a href="/desapareceran-en-30-anos">
                            <img src={card15} alt="errores" />
                          </a>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${card15})`,
                              opacity: "1",
                            }}
                          />
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${card15})`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                      </GridItem>
                      <GridItem xs={12} sm={8} md={8}>
                        <Danger>
                          <h6 className={classes.cardCategory}>Cuidado</h6>
                        </Danger>
                        <h3 className={classes.cardTitle}>
                          <a href="/desapareceran-en-30-anos">
                            30 Empleo que desaparecerán en 10 años
                          </a>
                        </h3>
                        <p className={classes.description}>
                          Con el avance de la tecnología muchos empleos van a
                          desaparecer y puede que te estés preparando para una
                          profesión que posiblemente no exista en un futuro.
                          <a
                            href="/desapareceran-en-30-anos"
                            className={classes2.leer}
                          >
                            {" "}
                            Leer ahora{" "}
                          </a>
                        </p>
                        <p className={classes.author}>
                          por <b>Hugo Rocha</b>
                        </p>
                      </GridItem>
                    </GridContainer>
                  </Card>
                  <Card plain blog className={classes.card}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={4}>
                        <CardHeader image plain>
                          <a href="/empleo-disponible-europa">
                            <img src={card16} alt="empleo-demandar" />
                          </a>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${card16})`,
                              opacity: "1",
                            }}
                          />
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${card16})`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                      </GridItem>
                      <GridItem xs={12} sm={8} md={8}>
                        <Info>
                          <h6 className={classes.cardCategory}>Empleo</h6>
                        </Info>
                        <h3 className={classes.cardTitle}>
                          <a href="/empleo-disponible-europa">
                            Cómo ver las ofertas de empleo disponibles en toda
                            Europa
                          </a>
                        </h3>
                        <p className={classes.description}>
                          ¿Por qué buscar empleo solo en España? No tienes
                          fronteras para trabajar y aquí vas a aprender a
                          encontrar todas las ofertas de empleo disponibles
                          fuera de España.
                          <a
                            href="/empleo-disponible-europa"
                            className={classes2.leer}
                          >
                            {" "}
                            Leer ahora{" "}
                          </a>
                        </p>
                        <p className={classes.author}>
                          por <b>Hugo Rocha</b>
                        </p>
                      </GridItem>
                    </GridContainer>
                    <br />
                  </Card>

                  <Card plain blog className={classes.card}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={4}>
                        <CardHeader image plain>
                          <a href="/subasta-coches-escrapalia">
                            <img
                              src={subastaEscrapalia}
                              alt="subasta en escrapalia"
                            />
                          </a>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${subastaEscrapalia})`,
                              opacity: "1",
                            }}
                          />
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${subastaEscrapalia})`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                      </GridItem>
                      <GridItem xs={12} sm={8} md={8}>
                        <Info>
                          <h6 className={classes.cardCategory}>Coches</h6>
                        </Info>
                        <h3 className={classes.cardTitle}>
                          <a href="/subasta-coches-escrapalia">
                            Cómo conseguir coches subastados en Escrapalia
                          </a>
                        </h3>
                        <p className={classes.description}>
                          Descubre los mejores coches subastados por menos de
                          1.000€ en una web única, sencilla y segura. Puja por
                          los mejores coches sabiendo su estado de conservación
                          y su valoración hecha por profesionales.
                          <a
                            href="/subasta-coches-escrapalia"
                            className={classes2.leer}
                          >
                            {" "}
                            Leer ahora{" "}
                          </a>
                        </p>
                        <p className={classes.author}>
                          por <b>Hugo Rocha</b>
                        </p>
                      </GridItem>
                    </GridContainer>
                    <br />
                  </Card>

                  <Card plain blog className={classes.card}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={4}>
                        <CardHeader image plain>
                          <a href="/subasta-material-coches-industrial">
                            <img
                              src={subastaIndustrial}
                              alt="Subastas de coches y material industrial"
                            />
                          </a>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${subastaIndustrial})`,
                              opacity: "1",
                            }}
                          />
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${subastaIndustrial})`,
                              opacity: "1",
                            }}
                          />
                        </CardHeader>
                      </GridItem>
                      <GridItem xs={12} sm={8} md={8}>
                        <Info>
                          <h6 className={classes.cardCategory}>Coches</h6>
                        </Info>
                        <h3 className={classes.cardTitle}>
                          <a href="/subasta-material-coches-industrial">
                            Cómo participar en subastas de material y vehículos
                            industriales
                          </a>
                        </h3>
                        <p className={classes.description}>
                          Grandes maquinarias y vehículos industriales se
                          subastan a diraio. Empresas cierran y el material y
                          los vehículos tienen que tener un destino, todos
                          acaban en las subastas.
                          <a
                            href="/subasta-material-coches-industrial"
                            className={classes2.leer}
                          >
                            {" "}
                            Leer ahora{" "}
                          </a>
                        </p>
                        <p className={classes.author}>
                          por <b>Hugo Rocha</b>
                        </p>
                      </GridItem>
                    </GridContainer>
                    <br />
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
}
