import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AdsGoogle from "../../../../AdsGoogle";
import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px"
  },
  contenedor: {
    fontSize: "17px"
  },
  titulo: {
    textAlign: "center"
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px"
  },
  link: {
    color: headerColor[0],

    "&:hover,&:focus": {
      color: headerColor[0],
      textDecoration: "underline"
    }
  }
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h4 className={classes2.top}>
        Aprende a calcular tu subsidio por desempleo{" "}
      </h4>

      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              Actualmente los varias las prestaciones que el Servicio de Empleo
              Público Estatal está ofreciendo a los ciudadanos para que puedan
              obtener una fuente de ingresos con la que poder vivir.
            </p>
            <p>{<AdsGoogle />}</p>
            <p className={classes2.texto}>
              En esta ocasión te voy a enseñar la prestación más frecuente: la
              prestación contributiva por desempleo.
            </p>

            <p className={classes2.texto}>
              Se trata de una prestación que se concede a las personas que se
              han quedado sin trabajo siempre y cuando tengan una serie de
              requisitos que te contaré a continuación.
            </p>

            <p className={classes2.texto}>
              Por ello, te voy a contar como puedes calcular la prestación por
              desempleo que te corresponde según tu base salarial y el tiempo
              que hayas estado trabajando.
            </p>

            <p className={classes2.texto}>
              {" "}
              También te contaré cómo puedes acceder a las ofertas de empleo que
              publica el SEPE en su página web y cómo puedes acceder a ellas.
            </p>

            <p className={classes2.texto}>
              Por último te voy a enseñar cómo puedes acceder a la formación
              gratuita que ofrece el SEPE y con la que puedes obtener los
              conocimientos necesarios para obtener un nuevo trabajo o
              reinventarte laboralmente hablando.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <h3 className={classes2.titulo}>¿Cómo calculo mi paro?</h3>

            <p className={classes2.texto}>
              A continuación te voy a explicar cómo puedes calcular la
              prestación por desempleo que te corresponde según tus
              características personales de dos maneras de diferentes: de forma
              manual y a través de una calculadora online.
            </p>

            <p className={classes2.texto}>
              Pero primero, veremos los requisitos fundamentales para poder
              cobrar la prestación por desempleo:&nbsp;
            </p>

            <p className={classes2.texto}>
              <strong>Requisitos para cobrar el paro</strong>
            </p>

            <p className={classes2.texto}>
              Antes de ello te voy a explicar cuáles son los requisitos que
              debes cumplir si quieres acceder a una de estas prestaciones por
              desempleo:
            </p>

            <li className={classes2.texto}>
              Debes estar afiliado a la Seguridad social actualmente.
            </li>

            <li className={classes2.texto}>
              {" "}
              Encontrarte en situación de desempleo, comprometiéndote a que
              actualmente estas buscando empleo de forma activa.
            </li>

            <li className={classes2.texto}>No tener la edad de jubilarte.</li>

            <li className={classes2.texto}>
              No estar cobrando ninguna otra prestación de la seguridad social
              que sea incompatible como por ejemplo una invalidez.
            </li>
            <p>{<AdsGoogle block={2} />}</p>
            <li className={classes2.texto}>
              No puedes estar trabajando por cuenta ajena.
            </li>

            <li className={classes2.texto}>
              Haber cotizado al menos 360 días en los 6 años anteriores a la
              fecha indicada.
            </li>

            <p className={classes2.texto}>
              Si cumples con estos requisitos puedes acceder a pedir una
              prestación por desempleo, hay que tener en cuenta ciertos factores
              personales como cuál ha sido tu salario o el tiempo que hayas
              estado trabajando.
            </p>

            <p className={classes2.texto}>
              <strong>El paro se calcula en base a dos ideas:</strong>
            </p>

            <li className={classes2.texto}>La actual base reguladora.</li>

            <li className={classes2.texto}>
              Cuál es la cantidad de dinero que la empresa ha estado cotizando
              por ti en los últimos 180 días.
            </li>

            <p className={classes2.texto}>
              Para calcular que dinero te pertenece debes dividir entre 180 el
              dinero que la empresa haya estado cotizando por ti en los últimos
              6 meses y multiplicarlo por 30(mensual)
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Si no conoces la base sobre la que has estado cotizando puedes
              buscar en tu última nómina el apartado donde pone: Base de
              contingencias comunes.
            </p>

            <p className={classes2.texto}>
              A partir de estos datos, cobrarás el 70% del resultado obtenido en
              los primeros 6 meses y 50% durante el resto de mensualidades.
            </p>

            <p className={classes2.texto}>
              Recuerda que para conocer la base de cotización tendrás que buscar
              en tu última nómina un concepto llamado&nbsp;Base Contingencias
              Comunes. Será en ese apartado en el que aparezca la cantidad
              cotizada.
            </p>

            <p className={classes2.texto}>
              También debes contar con otros datos como las cantidades máximas y
              mínimas que te pueden pagar.
            </p>

            <li className={classes2.texto}>
              Si tienes hijos, recibirás un máximo de 1242 €
            </li>

            <li className={classes2.texto}>
              Si no tienes cargas familiares recibirás un máximo de 1087€
            </li>

            <li className={classes2.texto}>
              Si tienes más de dos hijos recibirás un máximo de 1397€
            </li>

            <p className={classes2.texto}>
              En el caso de los mínimo si tienes hijos la cantidad será de 664€
              como mínimo y en caso no tener ningún hijo la cantidad mínima será
              497€.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <h3 className={classes2.titulo}>
              ¿Cómo usar una calculadora online para calcular tu paro?
            </h3>

            <p className={classes2.texto}>
              Si no quieres romperte la cabeza con cálculos que pueden ser
              erróneos, el SEPE te ofrece la posibilidad de calcular tu
              prestación por desempleo desde su página web.
            </p>

            <p className={classes2.texto}>Te voy a enseñar cómo hacerlo.</p>

            <p className={classes2.texto}>
              Debes acceder a la página web que te enseño a continuación:{" "}
              <a
                className={classes2.link}
                href="https://sede.sepe.gob.es/dgsimulador/introSimulador.do"
              >
                https://sede.sepe.gob.es/dgsimulador/introSimulador.do
              </a>
            </p>

            <p className={classes2.texto}>
              Te mostrara dos opciones:{" "}
              <a
                className={classes2.link}
                href="https://sede.sepe.gob.es/dgsimulador/introSimSimuladorPregunta1.do"
              >
                Ha finalizado su contrato y desea saber qué prestación o
                subsidio le corresponde
              </a>
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://sede.sepe.gob.es/dgsimulador/introSimSimuladorSubsidioPregunta1Inicial.do"
              >
                Ha agotado la prestación contributiva por desempleo y desea
                saber si tiene derecho a subsidio.
              </a>
            </p>

            <p className={classes2.texto}>
              En este caso, clicaremos en la primera opción.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Indica la fecha en la que acabo tu contrato laboral.
            </p>

            <p className={classes2.texto}>
              Te preguntara si has recibido alguna prestación en los últimos 6
              años anteriores a la fecha.
            </p>

            <p className={classes2.texto}>
              Debes indicar a continuación cuanto tiempo has estado trabajando.
            </p>

            <p className={classes2.texto}>
              A continuación te preguntara si todo ese tiempo antes mencionado
              ha sido en la misma empresa o por el contrario ha sido en varios
              tipos de empresas.
            </p>

            <p className={classes2.texto}>
              A continuación te preguntara si el salario percibido ha sido el
              mismo durante todo este tiempo, de esta manera sabrá como calcular
              correctamente tu prestación por desempleo.
            </p>

            <p className={classes2.texto}>
              Después deberás indicar el importe de tu última nómina.
            </p>

            <p className={classes2.texto}>
              Te pedirá que indiques que tipo de jornada laboral tenías en tu
              ultimo empleo. Este puede ser jornada completa o jornada parcial.
            </p>

            <p className={classes2.texto}>
              Si eliges una jornada parcial, el siguiente paso deberás indicar
              que porcentaje de tiempo estabas trabajando, por ejemplo si era
              una media jornada deberás indicar un 50%.
            </p>

            <p className={classes2.texto}>
              A continuación deberás indicar en el caso que sea preciso, el
              número de hijos que tienes actualmente.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Finalmente te mostrara el resultado de tu cálculo de prestación
              por desempleo. Debes recordar que es algo orientativo por lo que
              puede variar ligeramente,
            </p>

            <h3 className={classes2.titulo}>
              ¿Cómo busco ofertas de empleo en el SEPE?
            </h3>

            <p className={classes2.texto}>
              El SEPE ofrece una página web dedicada a la búsqueda de empleo en
              portales oficiales tanto para trabajos públicos como para empresas
              privadas. Te voy a explicar paso a paso como puedes acceder a
              ellas.
            </p>

            <p className={classes2.texto}>
              Primero debes entrar en el siguiente enlace:{" "}
              <a
                className={classes2.link}
                href="https://www.sepe.es/HomeSepe/Personas/encontrar-trabajo/ofertas-empleo.html"
              >
                https://www.sepe.es/HomeSepe/Personas/encontrar-trabajo/ofertas-empleo.html
              </a>
            </p>

            <p className={classes2.texto}>
              Te dirigirá a la página de búsqueda de empleo del Gobierno con el
              nombre Emplearte.
            </p>

            <p className={classes2.texto}>
              Verás que aparece una casilla en blanco donde puedes escribir el
              tipo de trabajo que estás buscando tanto si es de empleo público
              como privado y clicar en el botón de buscar.
            </p>

            <p className={classes2.texto}>
              Te parecerá un listado con todas las ofertas de empleo actualmente
              activas.
            </p>

            <p className={classes2.texto}>
              En la columna de la izquierda puedes aplicar los filtros y buscar
              ofertas de empleo que más encajen con tus necesidades.
            </p>

            <p className={classes2.texto}>
              Los filtros de búsqueda incluyen: Provincia, fecha, portal de
              origen, estduios, jornada laboral, experiencia y sector
              profesional.
            </p>

            <p className={classes2.texto}>
              La lista se reducirá según los criterios asignados.
            </p>

            <p className={classes2.texto}>
              Cuando te interese una oferta de empleo, debes clicar sobre ella
              para ver más información.
            </p>

            <p className={classes2.texto}>
              Si estás interesado en inscribirte en la oferta de empleo, deberás
              acceder al portal de empleo en cuestión y regístrate en este
              siguiendo los pasos que te indiquen.
            </p>

            <h3 className={classes2.titulo}>
              Cómo puedes acceder a los cursos gratuitos del INEM
            </h3>

            <p className={classes2.texto}>
              El INEM ofrece una gran variedad de cursos formativos que te van a
              ayudar a conseguir empleo de forma más sencillas.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Algunos de ellos como son los certificados de profesionalidad
              ofrecen además practicas externas en empresas en las que muchos
              usuarios acaban trabajando, por lo que es muy interesante formarte
              en ellas.
            </p>

            <p className={classes2.texto}>
              Para acceder al listado de cursos que ofrece el INEM, debes
              acceder a la siguiente página web:{" "}
              <a
                className={classes2.link}
                href="https://www.sepe.es/HomeSepe/Personas/formacion/ofertas-formativas/ofertas-formacion-comunidades-autonomas.html"
              >
                https://www.sepe.es/HomeSepe/Personas/formacion/ofertas-formativas/ofertas-formacion-comunidades-autonomas.html
              </a>
            </p>

            <p className={classes2.texto}>
              Una vez allí, debes acceder a la comunidad autónoma donde quieras
              realizar cursos de formación.
            </p>

            <p className={classes2.texto}>
              La página te enviara directamente al portal formativo de tu
              comunidad autónoma. Entonces deberás  buscar el curso de formación
              que más te interese. Puedes hacer una búsqueda según sectores de
              formación y elegir la modalidad en la que quieres recibir el
              curso: teleformacion o formación presencial.
            </p>

            <p className={classes2.texto}>
              {" "}
              También puedes marcar si el curso es específico de un programa de
              formación para el empleo y ofrece prácticas en empresas.
            </p>

            <p className={classes2.texto}>
              Una vez elegido el curso formativo, según cada comunidad, te
              pedirá que rellenes un formulario aportando los siguientes datos:
            </p>

            <p className={classes2.texto}>Nombre y apellidos.</p>

            <p className={classes2.texto}>Documento Nacional de Identidad</p>

            <p className={classes2.texto}>Fecha de nacimiento</p>

            <p className={classes2.texto}>
              Fecha de alta en el INEM y oficina donde te encuentras inscrito
            </p>

            <p className={classes2.texto}>
              Grado de estudios que tienes actualmente completado
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>Dirección completa</p>

            <p className={classes2.texto}>
              Email para poder ponerse en contacto contigo en caso de ser
              seleccionado
            </p>

            <p className={classes2.texto}>Teléfono de contacto</p>

            <p className={classes2.texto}>
              <strong>Otros datos de interés:</strong> En este cuadro debes
              decir por qué quieres hacer este curso de formación y cómo puede
              ayudare a conseguir empleo.
            </p>

            <p className={classes2.texto}>
              Hay ciertos cursos que solo se ofrecen a trabajadores ocupados por
              lo que deberás leer con atención la descripción del curso así como
              sus requisitos.
            </p>

            <p className={classes2.texto}>
              Normalmente se pondrán en contra cto contigo para hacerte una
              prueba de selección en el caso de que el curso este recibiendo
              demasiadas solicitudes.
            </p>

            <p className={classes2.texto}>
              La prueba de selección puede consistir en pasar un pequeño examen
              de conocimientos en la empresa donde se vaya a realizar el curso o
              rellenar un cuestionario a través de internet.
            </p>

            <p className={classes2.texto}>
              Si resultas elegido, te enviaran un email o te llamarán por
              teléfono para pedirte la documentación que debes enviarles que
              suele ser:
            </p>

            <p className={classes2.texto}>
              Fotocopia de tu Documento Nacional de Identidad
            </p>

            <p className={classes2.texto}>Fotocopia títulos exigidos</p>

            <p className={classes2.texto}>Curriculum Vitae.</p>

            <p className={classes2.texto}>
              Tarjeta de demandante de empleo actualizada.
            </p>

            <p className={classes2.texto}>
              La puedes descargar en internet o llevar la que te dio el técnico
              de empleo cuando fuiste a darte de alta.
            </p>

            <p className={classes2.texto}>
              En caso de que seas un trabajador activo deberás llevar la
              fotocopia de tu última nómina.
            </p>

            <p className={classes2.texto}>
              Si finalmente hace uno de estos cursos debes saber que tu
              situación búsqueda de empleo quedara en suspensión y que sino
              indicas lo contrario no recibirás alertas de empleo.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Si quieres seguir recibiéndolas debes ponerte en contacto con un
              técnico de empleo en tu oficina más próxima y hacérselo indicar.
            </p>

            <p className={classes2.texto}>
              Una vez finalices tú curso de formación recuerda cambiar esta
              situación de nuevo para que puedan seguir enviándote
              notificaciones de empleo.
            </p>

            <p className={classes2.texto}>
              Es importante si quieres recibir ofertas de empleo para acceder a
              los puestos de trabajo que los técnicos de empleo eligen
              individualmente para cada desempleado.
            </p>

            <p className={classes2.texto}>
              Te he explicado cómo puedes calcular tu prestación por desempleo y
              cómo puedes acceder a los cursos formativos que ofrece el INEM así
              como la manera de buscar empleo a través del SEPE.
            </p>

            <p className={classes2.texto}>
              Ya no tienes excusa para encontrar trabajo y fórmate para ser el
              mejor profesional.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
