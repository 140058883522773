import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h2 className={classes2.top}>Cómo conseguir empleo de media jornada</h2>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              Hemos observado que abundan los empleos de media jornada.
            </p>

            <p className={classes2.texto}>
              Indeed es uno de los canales en Internet en los que actualmente
              existen más ofertas de empleo.
            </p>

            <p className={classes2.texto}>
              Se podría decir que es una&nbsp; especie de Google buscador de
              empleo, por eso es tan importante saber buscar bien y aunque
              resulte sencillo, te enseñaremos trucos que pueden facilitarlo aún
              más.
            </p>

            <p className={classes2.texto}>
              <strong>
                A continuación te vamos a mostrar como buscar empleo de media
                jornada en Indeed de manera fácil y sin tener que comerte la
                cabeza.
              </strong>
            </p>

            <h3 className={classes2.titulo}>Cómo ver las ofertas de empleo</h3>

            <p className={classes2.texto}>
              Indeed rastrea entre múltiples páginas dedicadas a la publicación
              de ofertas de empleo y analiza las diferentes fuentes agrupándolas
              según algunos criterios.{" "}
            </p>

            <p className={classes2.texto}>
              Te vamos a mostrar cómo buscar en esta ocasión, ofertas de empleo
              a&nbsp; media jornada.
            </p>

            <p className={classes2.texto}>
              Se trata de un meta buscador muy sencillo de utilizar por lo que
              no tendrás problemas para buscar ofertas de empleo.
            </p>

            <h4 className={classes2.titulo}>
              Qué búsquedas puedo realizar en Indeed
            </h4>

            <p className={classes2.texto}>
              <strong>Por tipo de trabajo buscado</strong>: Puedes buscar
              ofertas de empleo buscando los puestos que más se ajusten a tus
              necesidades.
            </p>

            <p className={classes2.texto}>
              Si por ejemplo nos interesa un trabajo&nbsp; de ingeniero
              electrónico debemos poner esas mismas palabras y clicar: "Buscar
              empleos"
            </p>

            <p className={classes2.texto}>
              <strong>Por palabra clave</strong>: Puedes hacer una búsqueda
              escribiendo las palabras claves que crees que mejor se adaptan a
              lo que buscas.
            </p>

            <p className={classes2.texto}>
              Por ejemplo: Desarrollo personal, donde la búsqueda te dará
              resultados como profesor de yoga, psicólogo, terapeuta etc.
            </p>

            <p className={classes2.texto}>
              <strong>Por zona de interés</strong>: Indeed te permite hacer
              búsquedas de ofertas de empleo personalizadas por ciudades, países
              o poniendo tu código postal.
            </p>

            <p className={classes2.texto}>
              <strong>Por tipo de empresa:</strong> También es posible buscar
              ofertas de empleo escribiendo el nombre de la empresa donde nos
              interese trabajar.
            </p>

            <p className={classes2.texto}>
              De esta forma, saldrán todas las ofertas de empleo que existen
              bajo el nombre de dicha empresa.
            </p>

            <p className={classes2.texto}>
              A continuación te mostraremos como puedes buscar ofertas de empleo
              de media jornada a modo de ejemplo:
            </p>

            <h3 className={classes2.titulo}>Ofertas de empleo</h3>

            <p className={classes2.texto}>
              Debes entrar en la página web principal de Indeed en la siguiente
              dirección:&nbsp;{" "}
              <a
                className={classes2.link}
                rel="noreferrer noopener"
                aria-label=" (se abre en una nueva pestaña)"
                href="http://www.indeed.es"
                target="_blank"
              >
                INDEED
              </a>
            </p>

            <p className={classes2.texto}>
              En la casilla: Qué, debes poner el tipo de oferta de empleo que te
              gustaría conseguir, por ejemplo "dependienta".
            </p>

            <p className={classes2.texto}>
              El mismo buscador te sugerirá palabras acordes para completar la
              búsqueda y hacerla más precisa.
            </p>

            <p className={classes2.texto}>
              Justo en la casilla de al lado&nbsp;nos aparece: " Donde "
            </p>

            <p className={classes2.texto}>
              Allí puedes poner la ciudad donde quieres encontrar ese empleo,
              también es posible como hemos explicado anteriormente escribir el
              país o el código postal que nos pertenezca..
            </p>

            <p className={classes2.texto}>
              Seguidamente pulsaremos: Buscar empleo, y nos aparecerá un listado
              con todas las ofertas de empleo relacionadas con nuestra búsqueda
              en una o varias páginas.
            </p>

            <h3 className={classes2.titulo}>Empleo de media jornada</h3>

            <p className={classes2.texto}>
              En la columna de la izquierda podemos personalizar la búsqueda por
              diferentes sectores como: Distancia, tipo de empleo, ubicación
              etc...
            </p>

            <p className={classes2.texto}>
              <strong>
                Es precisamente en la característica: Tipo de empleo, donde
                podemos especificar: media jornada, clicando justo encima.
              </strong>
            </p>

            <p className={classes2.texto}>
              Veremos como la búsqueda se recude sustancialmente al haberse
              aplicado este filtro.
            </p>

            <p className={classes2.texto}>
              Si clicamos sobre cualquier oferta de empleo que nos resulte de
              interés veremos cómo se abre un diálogo al lado derecho de la
              página que nos amplía la información sobre la oferta de empleo.
            </p>

            <p className={classes2.texto}>
              Después debemos pulsar: solicitar más información y directamente
              la página nos redigirá al portal donde este alojada la oferta de
              empleo en cuestión.
            </p>

            <p className={classes2.texto}>
              Recuerda, Indeed no es una página de ofertas de empleo como tal,
              es una página que busca ofertas de empleo de entre todas las
              páginas webs dedicadas a ello de internet.<strong>&nbsp;</strong>
            </p>

            <p className={classes2.texto}>
              También puedes crear alertas de empleo.
            </p>

            <p className={classes2.texto}>
              Puedes utilizar la herramienta de alertas de empleo de Indeed para
              que seas avisado en cuento el buscador encuentre ofertas que
              pueden resultarte de tu interés y así llegar primero que nadie a
              la oferta de empleo.
            </p>

            <p className={classes2.texto}>
              El sistema enviará un email a tu correo electrónico cada vez que
              el meta buscador encuentre una nueva oferta de empleo.
            </p>

            <p className={classes2.texto}>
              <strong>
                Te vamos a explicar que puedes hacer para activar estas alertas
              </strong>
              :
            </p>

            <p className={classes2.texto}>
              Primero debes hacer una búsqueda de una de las maneras que te
              hemos explicado.
            </p>

            <p className={classes2.texto}>
              Una vez realizado esto, verás en la esquina superior de la parte
              derecha una casilla donde te pide un email si quieres recibir
              ofertas similares.
            </p>

            <p className={classes2.texto}>
              Cuando escribas tu correo electrónico, debes clicar el botón:
              Activa, que aparece a la derecha.
            </p>

            <p className={classes2.texto}>
              Seguidamente, tras dar estos pasos, recibirás un email a tu correo
              electrónico para que confirmes tu alerta de empleo.&nbsp;
            </p>

            <p className={classes2.texto}>
              Debes activar tu alerta haciendo clic en el email que acabas de
              recibir.
            </p>

            <p className={classes2.texto}>
              Y con estos simples pasos, cada vez que se publiquen ofertas de
              empleo relacionadas con tu búsqueda se te enviará un email al
              instante.
            </p>

            <p className={classes2.texto}>
              Te hemos enseñado en que consiste la plataforma de búsquedas de
              empleo Indeed, de que maneras puedes realizar tus búsquedas y cómo
              puedes activar una alerta de empleo.
            </p>

            <p className={classes2.texto}>
              Ahora ya conoces todo lo necesario para encaminar tu vida hacia un
              nuevo proyecto, que te lleve hasta donde tú quieras llegar.
            </p>

            <p className={classes2.texto}>Suerte.</p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
