import React from "react";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import a from "../imagenes/a.jpeg";
import b from "../imagenes/b.jpg";
import c from "../imagenes/c.jpg";
import d from "../imagenes/d.jpg";
import e from "../imagenes/e.jpg";
import f from "../imagenes/f.jpg";
import g from "../imagenes/g.jpeg";
import h from "../imagenes/h.jpg";
import i from "../imagenes/i.jpeg";
import j from "../imagenes/j.jpeg";
import k from "../imagenes/k.jpg";
import l from "../imagenes/l.png";
import m from "../imagenes/m.jpg";
import n from "../imagenes/n.jpeg";
import o from "../imagenes/o.jpg";
import p from "../imagenes/p.jpg";
import q from "../imagenes/q.jpg";
import r from "../imagenes/r.jpg";
import t from "../imagenes/t.jpg";
import w from "../imagenes/w.jpg";
import y from "../imagenes/y.jpg";
import z from "../imagenes/z.jpg";

import aa from "../imagenes/aa.jpg";
import bb from "../imagenes/bb.jpg";
import cc from "../imagenes/cc.jpg";
import dd from "../imagenes/dd.jpg";
import ee from "../imagenes/ee.jpg";
import ff from "../imagenes/ff.jpg";
import gg from "../imagenes/gg.jpg";
import hh from "../imagenes/hh.jpg";
import ii from "../imagenes/ii.jpg";
import jj from "../imagenes/jj.jpg";
import kk from "../imagenes/kk.jpg";
import ll from "../imagenes/ll.jpg";
import mm from "../imagenes/mm.jpg";
import nn from "../imagenes/nn.jpg";
import oo from "../imagenes/oo.jpg";
import pp from "../imagenes/pp.jpg";
import qq from "../imagenes/qq.jpg";
import rr from "../imagenes/rr.jpg";
import tt from "../imagenes/tt.jpg";
import ww from "../imagenes/ww.jpg";
import yy from "../imagenes/yy.jpg";
import zz from "../imagenes/zz.jpg";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 30,
    height: 30,
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "10px",
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function BadgeAvatars() {
  const classes = useStyles();

  const ind = {
    0: a,
    1: b,
    2: c,
    3: d,
    4: e,
    5: f,
    6: g,
    7: h,
    8: i,
    9: j,
    10: k,
    11: l,
    12: m,
    13: n,
    14: o,
    15: p,
    16: q,
    17: r,
    18: t,
    19: w,
    20: y,
    21: z,
    22: "",
    23: "",
    24: "",
    25: "",
    26: "",
    27: "",
    28: "",
    29: "",
    30: "",
    31: "",
    32: "",
    33: "",
    34: "",
  };
  const ind2 = {
    0: aa,
    1: bb,
    2: cc,
    3: dd,
    4: ee,
    5: ff,
    6: gg,
    7: hh,
    8: ii,
    9: jj,
    10: kk,
    11: ll,
    12: mm,
    13: nn,
    14: oo,
    15: pp,
    16: qq,
    17: rr,
    18: tt,
    19: ww,
    20: yy,
    21: zz,
  };

  const urlImg = Math.round(Math.random() * 34);
  const urlImg2 = Math.round(Math.random() * 20 + 1);
  const urlImg3 = Math.round(Math.random() * 21);
  const urlImg4 = Math.round(Math.random() * 21);

  return (
    <div className={classes.root}>
      <StyledBadge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        variant="dot"
      >
        <AvatarGroup max={4}>
          <SmallAvatar alt="Reme" src={ind[urlImg]} />
          <SmallAvatar alt="Travis Howard" src={ind2[urlImg2]} />
          <SmallAvatar alt="Pepe" src={ind[urlImg3]} />

          <SmallAvatar
            alt="Lola"
            className={classes.SmallAv}
            src={ind2[urlImg4]}
          />
        </AvatarGroup>
      </StyledBadge>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      ></Badge>
    </div>
  );
}
