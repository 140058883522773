import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h2 className={classes2.top}>
        Consejos a tener en cuenta en una entrevista virtual
      </h2>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              Muchos que no siguen estos consejos acaban por no conseguir el
              empleo deseado.
            </p>

            <p className={classes2.texto}>
              Actualmente, gracias a las nuevas tecnologías de las que casi todo
              el mundo disponemos, es habitual que el entrevistador acuerde una
              entrevista de trabajo de manera online.
            </p>

            <p className={classes2.texto}>
              Más aun, en los tiempos que estamos viviendo, debido a la crisis
              sanitaria mundial que obliga a los ciudadanos a mantenerse en sus
              hogares.
            </p>

            <p className={classes2.texto}>
              <strong>
                Por ello, te vamos a enseñar unos consejos clave a la hora de
                conseguir la oferta de empleo que quieres de forma fácil y
                sencilla.
              </strong>
            </p>

            <h3 className={classes2.titulo}>Cómo conseguir el contrato</h3>

            <p className={classes2.texto}>
              Los profesionales de recursos humanos han tenido que adaptarse al
              nuevo panorama mundial y han buscado una forma segura de
              entrevistar a los futuros trabajadores a través del entorno de
              internet.
            </p>

            <p className={classes2.texto}>
              Skype y Zoom se posicionan como las mejores plataformas para hacer
              estas entrevistas de proceso de selección para las diferentes
              ofertas de empleo que van apareciendo.
            </p>

            <p className={classes2.texto}>
              Sin embargo, esto puede ser visto por muchos trabajadores como un
              obstáculo, ya que no disponen de los medios o de los conocimientos
              oportunos.
            </p>

            <p className={classes2.texto}>
              Esto frena su candidatura para obtener la oferta de empleo que
              desean.
            </p>

            <h3 className={classes2.titulo}>
              10 Consejos que garantizan la contratación
            </h3>

            <p className={classes2.texto}>
              Por ello, te vamos a contar diez recomendaciones para enfrentarte
              a una entrevista de empleo de manera virtual y conseguir la oferta
              de empleo que quieres lograr.
            </p>

            <h3 className={classes2.titulo}>Conocer las plataformas</h3>

            <p className={classes2.texto}>
              Es aconsejable descargar el programa con el que te van a
              entrevistar para la oferta de empleo con anterioridad a la
              entrevista acordada.
            </p>

            <p className={classes2.texto}>
              De esta manera podrás familiarizarte con el ambiente que te vas a
              encontrar.{" "}
            </p>

            <p className={classes2.texto}>
              <strong>
                Las más usadas en estos momentos son Zoom, Google Meet, Skype y
                WhatsApp.
              </strong>
            </p>

            <p className={classes2.texto}>
              Hemos descartado a dos futuros empleados por no poder conectar con
              él en el momento de la entrevista.
            </p>

            <h3 className={classes2.titulo}>Prepara la entrevista de empleo</h3>

            <p className={classes2.texto}>
              Debes preparar la entrevista de empleo para conseguir la oferta de
              empleo como si fuese una entrevista normal de forma fisica.
            </p>

            <p className={classes2.texto}>
              Repasa los puntos importantes de tu perfil.
            </p>

            <p className={classes2.texto}>
              Investiga sobre la empresa y su funcionamiento y conoce bien que
              requisitos piden para la oferta de empleo para estar preparado
              ante cualquier cuestión.
            </p>

            <h3 className={classes2.titulo}>Vigila como hablas</h3>

            <p className={classes2.texto}>
              Se trata de una entrevista normal, por lo que debes vestirte
              correctamente y usar un lenguaje apropiado.{" "}
            </p>

            <p className={classes2.texto}>
              Debes ser respetuoso y amable si quieres conseguir la oferta de
              empleo.{" "}
            </p>

            <h3 className={classes2.titulo}>
              Vigila el lugar donde harás la entrevista
            </h3>

            <p className={classes2.texto}>
              Busca un lugar con buena iluminación, aléjate de ruidos molestos y
              sobre todo vigila que el fondo este correctamente ordenado ya que
              resultará clave a la hora de la entrevista.
            </p>

            <p className={classes2.texto}>
              Si quieres conseguir la oferta de empleo no puedes tener la
              habitación hecha un desastre pues el entrevistador advertirá de
              ese estado y lo reflejará en tu personalidad.
            </p>

            <p className={classes2.texto}>
              Es conveniente el uso de auriculares con micrófono para mitigar
              ruidos no deseados.
            </p>

            <h3 className={classes2.titulo}>Vigila tu aspecto</h3>

            <p className={classes2.texto}>
              Debes estar correctamente vestido y aseado para transmitir una
              imagen clara y apropiada al encargado de recursos humanos aunque
              no te vea de forma física.
            </p>

            <p className={classes2.texto}>
              {" "}
              <strong>
                Si quieres conseguir la oferta de empleo, esto es clave.
              </strong>
            </p>

            <h3 className={classes2.titulo}>Cuida tu leguaje no verbal</h3>

            <p className={classes2.texto}>
              Debes vigilar la postura en la que te encuentras cuando haces la
              entrevista de trabajo.
            </p>

            <p className={classes2.texto}>
              Siéntante en una silla cómoda y busca que la cámara se encuentre
              estática y firme.
            </p>

            <p className={classes2.texto}>
              Si quieres conseguir la oferta de empleo tu lenguaje no verbal es
              muy importante.
            </p>

            <h3 className={classes2.titulo}>
              Mira a los ojos del entrevistador
            </h3>

            <p className={classes2.texto}>
              Es importante mantener la mirada en el momento que te habla y no
              despistarte con objetos o ruidos de tu entorno.
            </p>

            <p className={classes2.texto}>
              <strong>
                Debes mirar a la cámara, no a la pantalla, pues es la cámara la
                que capta la imagen.
              </strong>
            </p>

            <h3 className={classes2.titulo}>Revisa tus dispositivos</h3>

            <p className={classes2.texto}>
              Debes verificar antes de la entrevista de trabajo que todos los
              dispositivos que vas a utilizar funcionan correctamente, así como
              tu conexión a internet.
            </p>

            <p className={classes2.texto}>
              De nada servirá que prepares la entrevista para la oferta de
              empleo si luego tu micrófono no se escucha correctamente o no
              tienes conexión.
            </p>

            <p className={classes2.texto}>
              Si en la revisión compruebas que algo no va bien, puedes avisar al
              entrevistador para acordar una nueva fecha e intentar
              solucionarlo.
            </p>

            <p className={classes2.texto}>
              Si quieres conseguir la oferta de empleo es importante que todo
              funcione a la perfección.
            </p>

            <h3 className={classes2.titulo}>Respuestas claras</h3>

            <p className={classes2.texto}>
              Debes ir al grano, no explayarte en detalles que no interesan.
            </p>

            <p className={classes2.texto}>
              Escucha con interés y piensa correctamente lo que vas a responder
              sin grandes pausas.
            </p>

            <p className={classes2.texto}>
              Para la oferta de empleo que quieres conseguir necesitas tener
              habilidades que debes demostrar con tus palabras.
            </p>

            <h3 className={classes2.titulo}>Practica</h3>

            <p className={classes2.texto}>
              {" "}
              Puedes ensayar con amigos por conferencia como puede ser la
              entrevista para la oferta de empleo.
            </p>

            <p className={classes2.texto}>
              Es importante estar bien preparado para todo lo que pueda surgir.
            </p>

            <h3 className={classes2.titulo}>Un truco básico</h3>

            <p className={classes2.texto}>
              Puedes poner objetos o pósters en los que se vea algo relacionado
              con la oferta de empleo para que el entrevistador vea que conoces
              la materia y te apasiona.
            </p>

            <p className={classes2.texto}>
              Te he enseñado cuáles son las claves de una entrevista de empleo
              en un entorno virtual.
            </p>

            <p className={classes2.texto}>
              Practica lo aprendido y sobretodo estate seguro de ti mismo.
            </p>

            <p className={classes2.texto}>
              <strong>
                Puedes conseguir la oferta de empleo de tu vida, también de
                forma online.
              </strong>
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
