import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";
import AdsGoogle from "../../../../AdsGoogle";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px"
  },
  contenedor: {
    fontSize: "17px"
  },
  titulo: {
    textAlign: "center"
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px"
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover": {
      color: headerColor[0],
      textDecoration: "underline"
    }
  }
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />

      <h4 className={classes2.top}>Profesiones con más ofertas de empleo </h4>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              Muchas veces puedes pensar que si volvieras atrás, estudiarías
              otra cosa o quizás te formarías de alguna otra manera al ver el
              mercado actual de trabajo.
            </p>
            <p>{<AdsGoogle />}</p>
            <p className={classes2.texto}>Nunca es tarde.</p>

            <p className={classes2.texto}>
              Si quieres saber cuáles son las 30 profesiones que ofrecen más
              ofertas de empleo en el mundo, toma nota.
            </p>

            <h3 className={classes2.titulo}>
              Cuáles son los trabajos con más ofertas de empleo en todo el mundo
            </h3>

            <p className={classes2.texto}>&nbsp;Cocina:</p>

            <p className={classes2.texto}>
              El sector de la concia está en auge, cada vez son más las personas
              que quieren disfrutar de los sabores de una buena elaboración y de
              ciertos productos gourmet que no todo el mundo sabe cocinar, por
              ello se están ofreciendo muchas ofertas de trabajo en este sector.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Si quieres ser cocinero puedes estudiar un módulo formativo de
              cocina y restauración o si cuentas con bastante dinero, acceder a
              una de las academias que existen en España para formar grandes
              cocineros, como el Basculy Center.
            </p>

            <p className={classes2.texto}>
              Los sueldos desentenderá de tu experiencia y categoría
              profesional, puede ir desde un salario base a miles de euros si
              eres Chef en un gran restaurante.
            </p>

            <p className={classes2.texto}>&nbsp;Comercial:</p>

            <p className={classes2.texto}>
              Actualmente son miles las ofertas de empleo que se ofrece en el
              sector de los agentes de ventas, si cuentas con facultades para
              saber vender puede ser una buena fuente de ingresos.
            </p>

            <p className={classes2.texto}>
              {" "}
              No se necesita ninguna formación para acceder a estos puestos de
              trabajo y su ventaja, es que según tu número de ventas puedes
              obtener grandes beneficios gracias a las comisiones.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Su sueldo, de esta manera parte de un salario base que puede
              aumentar según el número de ventas.
            </p>

            <p className={classes2.texto}>&nbsp;Marketing digital:</p>

            <p className={classes2.texto}>
              Actualmente las ventas por internet representar un gran porcentaje
              de las ventas totales, por eso se necesitan personas
              especializadas en hacer campañas por internet para captar
              clientes.
            </p>

            <p className={classes2.texto}>
              Son las personas especializadas en marketing digital. Para ello,
              se debe estudiar la carrera superior o el grado superior en
              Marketing y luego especializarse en marketing digital.
            </p>

            <p className={classes2.texto}>
              Su sueldo suele oscilar entre los 23.000 y los 26.000€ mensuales.
            </p>

            <p className={classes2.texto}>&nbsp;Promotores:</p>

            <p className={classes2.texto}>
              Hoy en día se están ofreciendo muchos puestos de trabajo para
              trabajar como promotores de grandes marcas que ayuden a dar salida
              y publicidad a sus productos o servicios.
            </p>

            <p className={classes2.texto}>
              Suelen ser contratos de corta duración y en la mayoría de puestos
              no se necesitas una formación específica.
            </p>

            <p className={classes2.texto}>
              Los sueldos suelen ser bajos, pero ofrece la ventaja de obtener un
              dinero extra con los que ayudar en los gastos que puedas tener.
            </p>

            <p className={classes2.texto}>&nbsp;Camareros:</p>

            <p className={classes2.texto}>
              Se necesitan cientos de camareros en todo el mundo debido a la
              gran demanda que existe en el mercado.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Dependiendo del sitio donde quieras trabajar pueden pedir
              formación específica, sin embargo en este  tipo de trabajos suele
              valorarse más la experiencia que tengas.
            </p>

            <p className={classes2.texto}>
              Los sueldos suelen partir del salario base y en lugares como
              Estados Unidos se puede conseguir dinero extra con las propinas
              que suelen ser obligatorias en la mayoría de los lugares.
            </p>

            <p className={classes2.texto}>&nbsp;Enfermeros:</p>

            <p className={classes2.texto}>
              Se necesitan enfermeros especializados en todo el mundo, más aun
              con la crisis actual que estamos viviendo.
            </p>

            <p className={classes2.texto}>
              Para ello deberás estudiar la carrera de enfermería y al ser
              posible especializarte en algún sector demandado. Los sueldos
              suelen oscilar entre los 1.500€ y los 3.000€ según el país donde
              quieras trabajar.
            </p>

            <p className={classes2.texto}>&nbsp;Auxiliar geriatría:</p>

            <p className={classes2.texto}>
              El creciente envejecimiento del mundo está haciendo que cada sean
              más las personas que necesiten servicios de geriatria.
            </p>

            <p className={classes2.texto}>
              Por ello las ofertas de trabajo en el sector se están
              multiplicando de forma exponencial. Para ello deberás estudiar el
              grado medio de auxiliar de enfermería o el de atención
              sociosanitaria.
            </p>

            <p className={classes2.texto}>
              El sueldo  puede ir del salario base a los 1.200€ en España,
              llegando a los 2.000€ en el resto de Europa.
            </p>

            <p className={classes2.texto}>&nbsp;Médicos:</p>

            <p className={classes2.texto}>
              La carrera de medicina siempre ha sido muy demandada en todo el
              mundo, no es una carrera fácil porque no existen muchas personas
              que la estudien.
            </p>

            <p className={classes2.texto}>
              Los sueldos de esta carrera suelen ir de los 1.500 hasta los
              4.000€ según tu especialización y años de experiencia.
            </p>

            <p className={classes2.texto}>
              En el resto del mundo los sueldos suelen ser aún mayores llegando
              a ganar hasta 7.000 € mensuales.
            </p>

            <p className={classes2.texto}>&nbsp;Programadores:</p>

            <p className={classes2.texto}>
              Hoy en día se necesitan muchos programadores para programar
              servidores de internet, páginas webs u otros muchos servicios.
            </p>

            <p className={classes2.texto}>
              Para ello debes estudiar programación o hacer la carrera de
              informática y especializarte en ello. Los sueldos suelen ser muy
              atractivos pudiendo ganar hasta 4.000€ mensuales.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>&nbsp;Ingenieros:</p>

            <p className={classes2.texto}>
              Existe gran demanda de ingenieros en el mundo entero. Puedes
              especializarte en ingeniería electrónica, eléctrica,
              telecomunicaciones etc…Los sueldos en España pueden ir de los
              1.550 hasta los 3.000€.En el resto del mundo estos sueldos pueden
              alcanzar hasta los 6.000€ mensuales.
            </p>

            <p className={classes2.texto}>&nbsp;Repartidores:</p>

            <p className={classes2.texto}>
              Actualmente se necesitan muchos repartidores para cubrir la gran
              demanda de repartos que vienen de las compras por internet.
            </p>

            <p className={classes2.texto}>
              No es necesario ningún estudio pero si se necesita, obviamente, la
              licencia de conducir.
            </p>

            <p className={classes2.texto}>
              Los sueldos pueden partir del salario base o, si eres autónomo
              cobrar según la cantidad de envíos que realices.
            </p>

            <p className={classes2.texto}>&nbsp;Operarios:</p>

            <p className={classes2.texto}>
              El creciente número de fábricas del mundo entero está haciendo que
              se necesiten operarios para trabajar en sus instalciones
            </p>

            <p className={classes2.texto}>
              No es necesaria ninguna formación previa el salario puede ir del
              salario base a los 2.000€ según extras como trabajar en festivos o
              por nocturnidad.
            </p>

            <p className={classes2.texto}>&nbsp;Big data:</p>

            <p className={classes2.texto}>
              Muchas empresas requieren especialistas en Big data para dar
              salida a sus proyectos. Si quieres trabajar en ello deberás
              formarte como informático sabiendo que los sueldos pueden llegar a
              alcanzar hasta los 50.000 brutos anuales.
            </p>

            <p className={classes2.texto}>&nbsp;Robótica:</p>

            <p className={classes2.texto}>
              Las nuevas tecnologías están entrando en nuestra vida de forma
              incesante. La robótica es un sector muy demandado que ofrece
              grandes oportunidades de empleo.
            </p>

            <p className={classes2.texto}>
              Si quieres trabajar de ello deberás estudiar la carrera de
              ingeniería y especializarte en el sector. Según donde trabajes
              puedes ganas hasta 3.000€ mensuales.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>&nbsp;Genetistas:</p>

            <p className={classes2.texto}>
              Actualmente&nbsp; son necesarias muchas personas especializadas en
              genética para realizar investigaciones que ayuden a solventar
              problemas como los que estamos viviendo actualmente. Por ello es
              una carrera de futuro que ofrece generosos salarios según tu
              experiencia.
            </p>

            <p className={classes2.texto}>&nbsp;Físicos:</p>

            <p className={classes2.texto}>
              La física y todo lo que le rodea está siendo muy valorada
              últimamente. Las investigaciones que realizan los físicos de todo
              el mundo ayudan a la humanidad a seguir mejorando por ello, son
              muchos los países que ofertan trabajos como investigadores en
              física.Los sueldos pueden variar según el tipo de empresa y el
              país donde quieras trabajar.
            </p>

            <p className={classes2.texto}>&nbsp;Psicólogos:</p>

            <p className={classes2.texto}>
              El estrés en el que vive la sociedad está pasando factura a muchas
              personas, por ello son necesarios psicólogos especializados en
              todo el mundo para ayudar a las personas en los diferentes
              problemas de índole mental que puedan tener. Para ello deberás
              estudiar la carrera de psicología en una universidad.
            </p>

            <p className={classes2.texto}>
              Los sueldos pueden partir del salario base y llegar a los 3.000€
              si trabajos por cuenta ajena.
            </p>

            <p className={classes2.texto}>&nbsp;Odontólogos:&nbsp;</p>

            <p className={classes2.texto}>
              Hoy en día, la salud bucodental es muy importante para las
              personas que quieren no solo mejorar su aspecto físico, sino
              también cuidar su dentadura de forma óptima. Por ello se necesitan
              odontólogos en todo el mundo debido a la creciente demanda de
              ofertas de empleo.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Si quieres trabajar de ello, deberás estudiar la carrera de
              odontología en la universidad sabiendo que el sueldo puede ir de
              los 1.100€ a los 4.000€según el país donde trabajes.
            </p>

            <p className={classes2.texto}>&nbsp;Fisioterapeutas:</p>

            <p className={classes2.texto}>
              El estrés diario al que estamos sometidos hacer que a nuestro
              cuerpo le pase factura, por ello se necesita fisioterapeutas que
              ayuden a las personas a recuperarse físicamente. Para ello deberás
              estudiar la carrera de fisioterapeuta en la universidad.
            </p>

            <p className={classes2.texto}>
              Los salarios pueden ir de los 1.200€ a los 3.500 según el país.
            </p>

            <p className={classes2.texto}>&nbsp;Asesores:</p>

            <p className={classes2.texto}>
              Las empresas necesitan personas especializadas que les ayuden a
              conocer como es el mercado actual para así enfocarte en
              determinados temas, por ello se requieren asesores que les ayuden
              a dar respuesta a sus preguntas. La formación dependerá del tipo
              de sector donde quieras trabajar y el sueldo suele partir de los
              1.200€.
            </p>

            <p className={classes2.texto}>&nbsp;Economistas:</p>

            <p className={classes2.texto}>
              Las empresas necesitan personas que puedan gestionar sus ingresos
              para sacar el mayor rendimiento posible. Por ello, son necesarios
              economistas que les aconsejen y gestiones sus cuentas. Para ello
              deberás estudiar la carrera de Economía sabiendo que los sueldos
              pueden partir desde los 1.300€.
            </p>

            <p className={classes2.texto}>&nbsp;Especialista en SEO:</p>

            <p className={classes2.texto}>
              El mercado en internet hace que sean muchas las empresas que
              quieran posicionarse en los principales buscadores para así
              obtener mayores beneficios. Por ello se necesitan expertos en
              posicionamiento en todo el mundo. Deberás aprender correctamente
              en qué consiste y formarte con alguno de los cursos que se ofrecen
              en el sector o estudiar marketing en la universidad. Los sueldos
              varían mucho, pues la mayoría de personas que se dedican a ello,
              son autónomos.
            </p>

            <p className={classes2.texto}>&nbsp;Comunity manager:</p>

            <p className={classes2.texto}>
              Las empresas son conscientes de que estar presentes en las redes
              sociales es necesario en los tiempos que vivimos, por ello, se
              están demandado comunnity managers que lleven sus redes sociales
              de forma periódica. No necesitas ningún estudio conciso, pero si
              saber defenderte y posicionar la empresa. El sueldo varía según
              los servicios y los clientes para los que trabajes.
            </p>

            <p className={classes2.texto}>&nbsp;Especialista en Drones:</p>

            <p className={classes2.texto}>
              Los drones han venido para quedarse. Si quieres trabajar como
              especialista en drones deberás tener un perfil técnico y aportar
              formación adecuada para el puesto a desempeñar. Esto varía según
              la empresa donde quieras acceder.
            </p>

            <p className={classes2.texto}>&nbsp;Técnicos de mantenimiento:</p>

            <p className={classes2.texto}>
              Son necesarios técnicos de mantenimiento por todo el mundo que se
              encarguen de reparar las instalaciones para poder llevar a cabo
              los servicios que presten las diferentes empresas y
              organizaciones. El sueldo depende en gran medida del país donde
              vayas a trabajar y la empresa donde se oferte el puesto.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>&nbsp;Mozos de almacén:</p>

            <p className={classes2.texto}>
              Si quieres trabajar de mozo de almacén debes saber que no
              necesitaras formación previa y que los sueldos pueden oscilar
              partiendo del salario base de cada país hasta los 2.000€
            </p>

            <p className={classes2.texto}>&nbsp;Camioneros:</p>

            <p className={classes2.texto}>
              La creciente demanda de productos por parte de los consumidores
              hace que, las mercancías se desplacen cada vez más lejos y con más
              periocidad. Por ello se necesita personas con la licencia de
              camión para poder repartir los productos a las diferentes partes
              del mundo. El sueldo puede ir de los 2.000 a los 4.000 € según el
              país donde trabajes.
            </p>

            <p className={classes2.texto}>&nbsp;Jefes de obras públicas:</p>

            <p className={classes2.texto}>
              Por todo el país, son necesarias personas encargadas en dirigir
              las diferentes obras públicas que se están construyendo. Para
              ello, deberás estudiar la ingeniería de obras públicas sabiendo
              que el salario puede ir desde los 1.200€ a los 3.500€ según la
              empresa y el país para donde trabajes.
            </p>

            <p className={classes2.texto}>&nbsp;Agricultores:</p>

            <p className={classes2.texto}>
              Actualmente se están ofreciendo muchas ofertas de trabajo en el
              mundo de la agricultura. Éste tipo de trabajos tan valorados en la
              antigüedad esta quedado en un segundo plano y cuesta encontrar
              gente que quiera trabajar en ello. No necesitas experiencia precia
              y el sueldo puede partir del salario base hasta alcanzar los 1.200
              1.300€.
            </p>

            <p className={classes2.texto}>&nbsp;Diseñadores gráficos:</p>

            <p className={classes2.texto}>
              El mundo del diseño está en auge. Actualmente se necesitan
              diseñadores gráficos en todo el mundo para potenciar la
              visibilidad de las empresas en la red. Si quieres ser diseñador
              grafico puedes estudiar un módulo profesional o la carrera de
              artes graficas, aunque también puedes aprender de forma
              autodidacta.
            </p>

            <p className={classes2.texto}>
              Los sueldos suelen variar según tu nivel de experiencia y las
              habilidades con las que cuentes.
            </p>

            <p className={classes2.texto}>&nbsp;Celadores:</p>

            <p className={classes2.texto}>
              Actualmente hospitales públicos y privados de todo el planeta
              necesitan personas especializadas para movilizar pacientes. Para
              ello deberás apuntarte a las diferentes bolsas de trabajo que
              existen y completar tu formación con cursos especializados. Los
              salarios parte del salario base a los 1.400 € según el sector.
            </p>

            <p className={classes2.texto}>
              Te he enseñado cuales son los treinta trabajos con más ofertas de
              empleo en todo el mundo. Ahora ya sabes dónde está el trabajo y
              cómo puedes acceder a él.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
