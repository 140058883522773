import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import JobsContainer from "./JobsContainer";
import listaCursosPageStyle from "assets/jss/material-kit-pro-react/views/listaCursosPageStyle.js";

const useStyles = makeStyles({
  ...listaCursosPageStyle,
  main: {
    ...listaCursosPageStyle.main,
    marginBottom: 50
  }
});

export default function JobsTalentCanada() {
  const classes = useStyles();
  return (
    <div>
      <Header color="header" brand={"Job Offers"} />
      <div className={classes.main}>
        <div className={classes.container}>
          <JobsContainer />
        </div>
      </div>
    </div>
  );
}
