import React, { useCallback, useContext, useEffect, Fragment } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LazyLoad from "react-lazyload";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Oferta from "./Oferta";

import sectionCommentsStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionCommentsStyle.js";

import Context from "../../../Context/Context";
import axios from "axios";

import AdsGoogle from "../../../AdsGoogle";
import { useParams } from "react-router";

const useStyles = makeStyles(sectionCommentsStyle);

/*const useStyles2 = makeStyles(() => ({
  teste: {
    padding: "80px 0px",
    marginBottom: "-100px",
    marginLeft: "6%",
  },
  boton: {
    marginTop: "12px",
  },
  select: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "-20px",
  },
  ads: {
    marginTop: "20px",
    marginBottom: "20px",
  },
}));*/

export default function SectionComments() {
  //Random number

  const classes = useStyles();

  const MeuContext = useContext(Context);
  const {
    ofertas,
    setOfertas,
    cargando,
    setCargando,
    setHeader,
    header
  } = MeuContext;
  let { company } = useParams(); //comapny é a empresa que vamos usar
  var j = 0;
  switch (company) {
    case "mercadona":
      setHeader("Mercadona");

      break;
    case "alcampo":
      setHeader("Alcampo");

      break;
    case "corteingles":
      setHeader("El Corte Inglés");

      break;
    case "amazon":
      setHeader("Amazon");

      break;
    case "elpozo":
      setHeader("El Pozo");

      break;
    case "decathlon":
      setHeader("Decathlon");

      break;
    case "bricomart":
      setHeader("Bricomart");

      break;
    case "carrefour":
      setHeader("Carrefour");

      break;
    case "dia":
      setHeader("Dia");

      break;
    case "ikea":
      setHeader("Ikea");

      break;
    case "prosegur":
      setHeader("Prosegur");

      break;
    case "telefonica":
      setHeader("Telefónica");

      break;

    default:
      setHeader("Empresas");

      break;
  }

  const ofertasSet = useCallback(async () => {
    const url = `https://api.hmservicios.com/api/search/${company}`;
    const solve = await axios.get(url);
    setOfertas(solve.data);
    setCargando(false);
  }, [company, setCargando, setOfertas]);

  useEffect(() => {
    ofertasSet();
  }, [ofertasSet]);

  if (ofertas.length === 0) {
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={8}>
            <h4 className={classes.title}>
              {cargando
                ? "Cargando..."
                : "¡Lo siento no hemos encontrado vacantes!"}
            </h4>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  return (
    <Fragment>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={8}>
            <div>
              <h4 className={classes.title}>
                {"Aquí están las ofertas de empleo más recientes lanzadas por " +
                  header +
                  "..."}
              </h4>
              <AdsGoogle />
              {ofertas.map(oferta => {
                if (j < 4) {
                  j++;
                  return (
                    <Oferta
                      oferta={oferta}
                      key={oferta._id}
                      fotoEmpresa={company}
                    />
                  );
                } else {
                  j = 0;

                  return (
                    <LazyLoad offset={-40}>
                      <AdsGoogle block={2} />
                    </LazyLoad>
                  );
                }
              })}
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Fragment>
  );
}
