import React, { useEffect } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import JobsContainer from "./JobsContainer";
import listaCursosPageStyle from "assets/jss/material-kit-pro-react/views/listaCursosPageStyle.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

const useStyles = makeStyles(listaCursosPageStyle);

export default function RemoteJobs() {
  const classes = useStyles();

  /* <!-- Meta Pixel Code PF10 Lauth --> */
  useEffect(() => {
    /* eslint-disable */
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '1181544499457746');
    fbq('track', 'PageView');
    /* eslint-enable */
  }, []);
  /* <!-- End Meta Pixel Code --> */
  return (
    <div>
      {/* <!-- Meta Pixel Code PF10 Lauth --> */}
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=1181544499457746&ev=PageView&noscript=1"
        />
      </noscript>
      {/* <!-- End Meta Pixel Code --> */}
      <Header
        color="header"
        brand={"Vacantes"}
        links={<HeaderLinks dropdownHoverColor="header" />}
      />

      <div className={classes.main}>
        <div className={classes.container}>
          <JobsContainer />
        </div>
      </div>

      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a href="/politicas-de-privacidad" className={classes.block}>
                    Políticas de Privacidad
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/aviso-legal" className={classes.block}>
                    Aviso Legal
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/" className={classes.block}>
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/derechos" className={classes.block}>
                    Derechos
                  </a>
                </ListItem>
              </List>
            </div>
          </div>
        }
      />
    </div>
  );
}
