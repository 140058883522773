import React, { useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import Select from "@material-ui/core/Select";

import { whiteColor } from "assets/jss/material-kit-pro-react";
import Context from "../../Context/Context";

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(1),
    color: whiteColor,
    fontWeight: "bold",
    padding: "3px",
  },

  teste: {
    color: whiteColor,
    marginTop: "-3px",
    marginRight: "3px",
  },
}));

const EstiloInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    marginBottom: "3%",
    border: "1px solid #ced4da",
    fontSize: 18,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#fff",
      boxShadow: "0 0 0 0.2rem rgba(255,255,255,.25)",
    },
  },
}))(InputBase);

export default function NativeSelects() {
  const classes = useStyles();

  const MeuContext = useContext(Context);
  const { provincia, setProvincia } = MeuContext;

  return (
    <div className={classes.container}>
      <Select
        className={classes.selectEmpty}
        value={provincia}
        native
        onChange={(e) => {
          setProvincia(e.target.value);
        }}
        input={<EstiloInput />}
        inputProps={{
          classes: {
            icon: classes.teste,
          },
        }}
      >
        <option value="">Seleccione una provincia</option>
        <option value="alava">Álava</option>
        <option value="albacete">Albacete</option>
        <option value="alicante">Alicante</option>
        <option value="almeria">Almería</option>
        <option value="asturias">Asturias</option>
        <option value="avila">Ávila</option>
        <option value="badajoz">Badajoz</option>
        <option value="baleares"> Baleares</option>
        <option value="barcelona">Barcelona</option>
        <option value="burgos">Burgos</option>
        <option value="caceres">Cáceres</option>
        <option value="cadiz">Cádiz</option>
        <option value="cantabria">Cantabria</option>
        <option value="castellon">Castellón</option>
        <option value="coruna">La Coruña</option>
        <option value="ceuta">Ceuta</option>
        <option value="ciudadreal">Ciudad Real</option>
        <option value="cordoba">Córdoba</option>
        <option value="cuenca">Cuenca</option>
        <option value="girona">Girona</option>
        <option value="granada">Granada</option>
        <option value="guadalajara">Guadalajara</option>
        <option value="guipuzcoa">Guipúzcoa</option>
        <option value="huelva">Huelva</option>
        <option value="huesca">Huesca</option>
        <option value="jaen">Jaén</option>
        <option value="laspalmas">Las Palmas</option>
        <option value="leon">León</option>
        <option value="larioja">La Rioja</option>
        <option value="lleida">Lleida</option>
        <option value="lugo">Lugo</option>
        <option value="madrid">Madrid</option>
        <option value="malaga">Malaga</option>
        <option value="melilla">Melilla</option>
        <option value="murcia">Murcia</option>
        <option value="navarra">Navarra</option>
        <option value="ourense">Ourense</option>
        <option value="palencia">Palencia</option>
        <option value="pontevedra">Pontevedra</option>
        <option value="salamanca">Salamanca</option>
        <option value="sctenerife">SC Tenerife</option>
        <option value="segovia">Segovia</option>
        <option value="sevilla">Sevilla</option>
        <option value="soria">Soria</option>
        <option value="tarragona">Tarragona</option>
        <option value="teruel">Teruel</option>
        <option value="toledo">Toledo</option>
        <option value="valencia">Valencia</option>
        <option value="valladolid">Valladolid</option>
        <option value="vizcaya">Vizcaya</option>
        <option value="zamora">Zamora</option>
        <option value="zaragoza">Zaragoza</option>
      </Select>
    </div>
  );
}
