import { grayColor, headerColor, title } from 'assets/jss/material-kit-pro-react';

const indentClasses = {};
for (let i = 1; i <= 8; i += 1) {
  indentClasses[`& .ql-indent-${i}`] = {
    paddingLeft: `${i * 3}em`,
  };
}

const articleContentStyle = {
  wrap: {
    '& p': {
      fontSize: '1.188rem',
      lineHeight: '1.5em',
      color: grayColor[15],
      marginBottom: '30px',
    },
    '& h1, & h2, & h3, & h4': {
      ...title,
    },
    '& ul, & ol': {
      marginBottom: 0,
    },
    '& li': {
      marginBottom: '20px',
      fontSize: '1.188rem',
      color: grayColor[15],
    },
    '& a': {
      color: headerColor,
    },
    ...indentClasses,
    '& blockquote': {
      fontSize: '1.188rem',
      lineHeight: '1.5em',
      color: grayColor[15],
      borderLeft: '4px solid #ccc',
      margin: '0 40px 30px',
      paddingLeft: 16,
    },
  },
};

export default articleContentStyle;
