import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h2 className={classes2.top}>
        Ofertas de empleo en el Servicio Andaluz de Empleo
      </h2>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              <strong>
                El SAE lanza ofertas de trabajo repartidas por toda Andalucía a
                diario, si buscas alguna vacante de empleo sigue leyendo…
              </strong>
            </p>

            <p className={classes2.texto}>
              En el SAE podrás encontrar muchas ofertas de empleo, pues esta web
              se ha introducido con muchas novedades en la gestión y difusión de
              ofertas de trabajo.&nbsp;
            </p>

            <p className={classes2.texto}>
              Podrás postularte para cualquier oferta de empleo&nbsp;
              <strong>
                desde la comodidad de tu casa y conseguirás entrevistas en menos
                de 48h.
              </strong>
            </p>

            <h3 className={classes2.titulo}>
              ¿Cómo acceder a las ofertas de empleos?
            </h3>

            <p className={classes2.texto}>
              Lo primero que debes hacer para inscribirte a las ofertas de
              empleo, será buscar en el apartado “Ofertas de empleo”, la opción
              de “Busca y consulta anuncios de empleo”.
            </p>

            <p className={classes2.texto}>
              Allí se mostrarán toda la base de datos donde se publican los
              anuncios de empleo difundidos por las empresas u oficinas de
              empleo.&nbsp;
            </p>

            <p className={classes2.texto}>
              Serás redireccionado a una pantalla donde verás las siguientes
              opciones:
            </p>

            <li className={classes2.texto}>
              Seleccionar la categoría y subcategoría que aparece en el menú
              desplegable.
            </li>
            <li className={classes2.texto}>
              Filtrar la oferta de empleo por medio de palabras clave.
            </li>
            <li className={classes2.texto}>
              Si necesitas más criterios puedes usar la “Búsqueda avanzada”, en
              este caso para optar por más criterios de búsqueda, o en caso de
              que no desees buscar en base a ningún criterio, puedes dar clic al
              botón de “buscar”.
            </li>

            <p className={classes2.texto}>
              Luego de hacer tu búsqueda, aparecerán los listados de ofertas de
              empleo disponibles ordenados con fecha de publicación.&nbsp;
            </p>

            <p className={classes2.texto}>
              <strong>
                Las ofertas de empleo están disponibles durante 5 días desde el
                momento de su publicación, salvo algunas que tienen un plazo
                distinto reguladas por otro tipo de normas.
              </strong>
            </p>

            <p className={classes2.texto}>
              Para entrar a la oferta de empleo que necesites entra haciendo
              clic en el titulo de este.&nbsp;
            </p>

            <p className={classes2.texto}>
              Una vez dentro podrás consultar los datos del anuncio para conocer
              todos los detalles y sus datos.&nbsp;
            </p>

            <p className={classes2.texto}>
              <strong>
                <a
                  className={classes2.link}
                  href="https://ws054.juntadeandalucia.es/eureka2/eureka-demandantes/busquedaOfertas.do"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  VER OFERTAS DE EMPLEO EN EL SAE
                </a>
              </strong>
            </p>

            <p className={classes2.texto}>
              Las ofertas de empleo que vengan de la oficina del Servicio
              Andaluz de Empleo aparecerán el siguiente aviso:&nbsp;
            </p>

            <p className={classes2.texto}>
              “Para participar en este proceso de selección es imprescindible la
              inscripción como demandante en los Servicios Públicos de Empleo y
              que los datos registrados en su demanda cumplan los requisitos de
              la oferta”.
            </p>

            <p className={classes2.texto}>
              En la parte inferior de la oferta de empleo aparecerán dos
              opciones:
            </p>

            <p className={classes2.texto}>
              Si seleccionas la opción de “detalles anuncio” puedes descargar el
              documento de la oferta de empleo en difusión que recogen los datos
              resumidos del puesto de trabajo (requisitos, salario, ubicación,
              jornada…).
            </p>

            <p className={classes2.texto}>
              Por el contrario, si seleccionas “Inscribirse”, te dirigirá a la
              sección de registro en el Área Personal del Demandante en el caso
              de que no tengas una cuenta creada en la plataforma.
            </p>

            <p className={classes2.texto}>
              Para ingresar o registrarte solo debes contar con usuario y
              contraseña válidos, certificado digital o DNI electrónico.&nbsp;
            </p>

            <p className={classes2.texto}>
              Si tu demanda está inscrita en los Servicios Públicos de Empleo de
              una comunidad autónoma distinta de Andalucía, deberás acceder con
              tu certificado digital para poder inscribirte en la oferta de
              empleo.
            </p>

            <p className={classes2.texto}>
              Después de que realices tu oferta entrarás como candidato al
              proceso de preselección en el que se ordenan a los postulantes
              dependiendo de la disponibilidad, la fecha de solicitud y la fecha
              de inscripción en el SAE.
            </p>

            <p className={classes2.texto}>
              Por medio de la oferta de empleo se enviará a la entidad
              empleadora todos tus datos y de los otros candidatos que soliciten
              el puesto y que cumplan con los requisitos que filtra la página.
            </p>

            <p className={classes2.texto}>
              <strong>
                <a
                  className={classes2.link}
                  rel="noreferrer noopener"
                  href="https://ws054.juntadeandalucia.es/eureka2/eureka-demandantes/busquedaOfertas.do"
                  target="_blank"
                >
                  VER OFERTAS DE EMPLEO EN EL SAE
                </a>
              </strong>
            </p>

            <p className={classes2.texto}>
              Por lo que tu inscripción a la oferta laboral no es un seguro que
              vaya a llegar a las entidades empleadoras, para ello debes cumplir
              con las características del puesto.&nbsp;
            </p>

            <p className={classes2.texto}>
              En caso de que no sea posible tu inscripción a la oferta de
              empleo, el sistema te avisará con un aviso de error, explicando
              los motivos por los cuales no fue posible enviar tu solicitud.
            </p>

            <p className={classes2.texto}>
              Además de postularte en las ofertas de empleo, hay otras gestiones
              que puedes hacer si estás inscrito en el SAE.
            </p>

            <p className={classes2.texto}>
              Por ejemplo, puedes comparar los requisitos de las ofertas de
              empleo con la información que registraste, de ese modo&nbsp;
              <strong>
                podrás actualizar tu currículo para que se adecue mejor.&nbsp;
              </strong>
            </p>

            <p className={classes2.texto}>
              Si tu demanda está en situación de baja, puedes volver a darla de
              alta desde la opción “Reinscripción de la demanda” (en los casos
              sean aprobados) a través del Área de Gestión.
            </p>

            <p className={classes2.texto}>
              Si solamente necesitas renovarla, puedes realizar el trámite por
              medio de la página en la opción de “Renueva tu demanda”.
            </p>

            <p className={classes2.texto}>
              Puedes editar y actualizar en cualquier momento tus datos
              personales como el domicilio de residencia, teléfonos, correo
              electrónico, entre otros.
            </p>

            <p className={classes2.texto}>
              Datos de tu currículo como idiomas con acreditación,
              conocimientos, cursos y permiso de conducir.
            </p>

            <p className={classes2.texto}>
              También son modificables los datos de empleo, aquellos que se
              refieren a la disponibilidad para el empleo, la jornada, salario,
              relación contractual, ámbito de búsqueda, y demás.&nbsp;
            </p>

            <p className={classes2.texto}>Mucha suerte.</p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
