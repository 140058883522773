import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h4 className={classes2.top}>Diésel VS Gasolina</h4>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              En el artículo de hoy te voy a enseñar las principales ventajas y
              desventajas de los vehículos según su tipo de combustible: diésel
              o gasolina.
            </p>

            <p className={classes2.texto}>
              Unos datos muy interesantes si estás pensando en cambiar de coche
              o adquirir un nuevo vehículo y lo que quieres es saber qué tipo de
              combustible te ofrece mejores rendimientos según tus necesidades
              personales.
            </p>

            <h3 className={classes2.titulo}>
              Ventajas e inconvenientes de los combustibles diésel y gasolina
            </h3>

            <p className={classes2.texto}>
              Te voy a contar las principales ventajas y desventajas entre estos
              dos tipos de combustibles. Atento.
            </p>

            <p className={classes2.texto}>
              <strong>Ventajas de los coches con motor a gasolina</strong>
            </p>

            <p className={classes2.texto}>
              Potencia del vehículo al subir de revoluciones.
            </p>

            <p className={classes2.texto}>
              Un vehículo a gasolina ofrece una mayor potencia en su motor, por
              lo que nunca podrás poner los coches a tantas revoluciones en un
              diésel.
            </p>

            <p className={classes2.texto}>Motores más silenciosos.</p>

            <p className={classes2.texto}>
              Los coches a gasolina ofrecen motores más silenciosos, algo muy
              agradecido en las grandes ciudades.
            </p>

            <p className={classes2.texto}>Mejor conducción:</p>

            <p className={classes2.texto}>
              Siempre se ha dicho que a las personas que les gusta conducir, lo
              harán mucho mejor en un coche con combustible a gasolina. Y es
              que, la sensación de conducción resulta más fácil y placentera con
              este tipo de motores.
            </p>

            <p className={classes2.texto}>Motor más simple:.</p>

            <p className={classes2.texto}>
              Su moto es más sencilla que el que usa un coche diésel, por lo que
              el número e averías también ser menor.
            </p>

            <p className={classes2.texto}>Coches deportivos:</p>

            <p className={classes2.texto}>
              Si lo siempre has querido es tener un coche deportivo, debes saber
              que este tipo de motores está mucho mejor preparado para ello.
            </p>

            <p className={classes2.texto}>Pocos viajes:</p>

            <p className={classes2.texto}>
              Si lo que quieres hacer son viajes largos y más aún, por ciudad,
              es necesario que sepas que este tipo de vehículos no está
              recomendado para ello.
            </p>

            <p className={classes2.texto}>
              Desventajas de los coches con motor a gasolina
            </p>

            <p className={classes2.texto}>Consumo más alto que el diésel:</p>

            <p className={classes2.texto}>
              Su mayor desventaja y la que más echa para atrás a los usuarios es
              que el precio del combustible a gasolina es más caro que el de un
              coche diésel.
            </p>

            <p className={classes2.texto}>Viajes:</p>

            <p className={classes2.texto}>
              Si eres de esas personas a las que les encanta irse cada fin de
              semana a visitar algún rincón de España o aprovecha cualquier
              momento para salir a pasear con su coche, debes saber que este
              tipo de vehículos está menos preparado para hacer viajes largos
              que un coche con motor diésel.
            </p>

            <p className={classes2.texto}>Kilómetros anuales:</p>

            <p className={classes2.texto}>
              Si vas a hacer muchos kilómetros anuales no es un coche que te
              acabe saliendo rentable.
            </p>

            <p className={classes2.texto}>Filtro de partículas:</p>

            <p className={classes2.texto}>
              Aunque actualmente este tipo de vehículos ya lleva filtros de
              partículas, los vehículos más antiguos pueden tener problemas,
              sobre todo a la hora de pasar ITV por las actuales normativas
              medioambientales que se están implantando en nuestro país,
            </p>

            <p className={classes2.texto}>Conducir por la ciudad:</p>

            <p className={classes2.texto}>
              Si tus viajes se van a limitar a conducir por grandes ciudades,
              debes saber que este tipo de motores esta menos preparado para
              ello, por lo que es un factor que puede echarte para atrás a la
              hora de elegir un vehículo a gasolina.
            </p>

            <p className={classes2.texto}>Cargas y Remolques:</p>

            <p className={classes2.texto}>
              A diferencia de los motores diésel, este tipo de vehículo no se
              recomienda si quieres llevar grandes cargas de material o poner un
              remolque, suele generar más fallos que un vehículo con motor a
              diésel.
            </p>

            <p className={classes2.texto}>
              Otra de las desventajas que tiene, es que el tipo de combustible
              que utiliza a diferencia el diésel, puede arder con facilidad y
              por ello, resulta más peligroso.
            </p>

            <p className={classes2.texto}>
              <strong>Ventajas de los coches con motor a diésel</strong>
            </p>

            <p className={classes2.texto}>Menos gasto:</p>

            <p className={classes2.texto}>
              Su principal ventaja es que actualmente el precio del diésel es
              más barato que el precio de un coche a gasolina.Este es uno de los
              principales factores que hace que los coches diésel sean más
              demandados.
            </p>

            <p className={classes2.texto}>Aceleración:</p>

            <p className={classes2.texto}>
              Este tipo de vehículos tiene la ventaja de que su aceleración
              puede hacerse de una forma más rápida, algo a tener en cuenta si
              te gusta mucho pisar esta herramienta, siempre eso sí, con cuidado
              de no pasarse los límites establecidos.
            </p>

            <p className={classes2.texto}>Menos emisiones de Co2:</p>

            <p className={classes2.texto}>
              Los motores diésel tienen menos emisiones de Co2, por lo que
              resulta una ventaja si quieres cuidar el medio ambiente.
            </p>

            <p className={classes2.texto}>Viajes largos:</p>

            <p className={classes2.texto}>
              Si te gusta viajar o tienes que desplazarte lejos para ir a tu
              trabajo su motor está mejor preparado para ello.
            </p>

            <p className={classes2.texto}>Motor:</p>

            <p className={classes2.texto}>
              Este tipo de motores tiene mejores características si te gustan
              los coches grandes.
            </p>

            <p className={classes2.texto}>Remolques:</p>

            <p className={classes2.texto}>
              Otra de sus principales ventajas es que este tipo de vehículos
              tiene una mejor preparación para aguantar la carga o el peso de un
              remolque.
            </p>

            <p className={classes2.texto}>
              Desventajas de los coches con motor a diésel
            </p>

            <p className={classes2.texto}>Olor y suciedad:</p>

            <p className={classes2.texto}>
              El olor del combustible diésel es mucho menos agradable que la
              gasolina. De esta manera también puede resultar mucho más
              engorroso si se acaba derramando. pues es más sucio que el
              combustible de gasolina.
            </p>

            <p className={classes2.texto}>Menos potencia:</p>

            <p className={classes2.texto}>
              A diferencia de los combustibles de gasolina, con los coches
              diésel vas a tener menos revoluciones en tu coche, por lo que su
              potencia será menor que la de un coche a gasolina.
            </p>

            <p className={classes2.texto}>Emisión dañina:</p>

            <p className={classes2.texto}>
              Estos coches son altamente contaminantes, aunque los nuevos
              modelos intentan ser menos nocivos para el medio ambiente. Los
              coches a diésel suponen una auténtica pesadilla para el medio
              ambiente, por ello poco a poco se están sustituyendo por coches
              con cero emisiones.
            </p>

            <p className={classes2.texto}>Más problemas:</p>

            <p className={classes2.texto}>
              Este tipo de coches tienen más fallos en su motor. Algo que debes
              tener en cuenta si quieres un vehículo que te dé pocos problemas.
            </p>

            <p className={classes2.texto}>Sonidos:</p>

            <p className={classes2.texto}>
              Normalmente este tipo de coches son mucho más ruidosos, sobre todo
              en motores de tres y cuatro cilindros, donde su volumen se acentúa
              aún más.
            </p>

            <p className={classes2.texto}>Peor sensación:</p>

            <p className={classes2.texto}>
              A la hora de la condición, la sensación será más desagradable o
              menos placentera que si usas otro tipo de coche con diferente
              combustible.
            </p>

            <h3 className={classes2.titulo}>
              ¿Cómo comparar gastos entre un coche diésel o gasolina?
            </h3>

            <p className={classes2.texto}>
              Te voy  a enseñar una página web donde puedes calcular fácilmente
              la diferencia en el gasto entre un coche  diésel o uno a gasolina,
              tan solo deberás seguir los pasos que te muestro a continuación,
              eligiendo los dos tipos de coches que quieres comparar.
            </p>

            <p className={classes2.texto}>
              {" "}
              Para ello deberás saber de antemano el precio medio actual de los
              combustibles  diésel y gasolina
            </p>

            <p className={classes2.texto}>
              Debes navegar a la siguiente direccion:
              <a
                className={classes2.link}
                href="https://www.dieselogasolina.com/comparador-diesel-o-gasolina.html"
              >
                https://www. diéselogasolina.com/comparador diésel o
                gasolina.html
              </a>
            </p>

            <p className={classes2.texto}>
              Introduce el precio actual del diésel y la gasolina, si no lo
              conoces puedes consultarlo a través del siguiente enlace:{" "}
              <a
                className={classes2.link}
                href="https://www.dieselogasolina.com"
              >
                https://www. diéselogasolina.com
              </a>
            </p>

            <p className={classes2.texto}>
              Después, introduce el modelo, la marca y el año de los vehículos
              que quieres comparar para saber la diferencia del gasto medio
              entre ambos.
            </p>

            <p className={classes2.texto}>
              Ahora, debes indicar la cantidad de kilómetro que sueles hacer al
              año y por donde sueles conducir.
            </p>

            <p className={classes2.texto}>
              Dale al botón donde aparece: calcular.
            </p>

            <p className={classes2.texto}>
              A continuación te saldrán los gastos estipulados en gasolina y
              diésel de los coches indicados para que veas que tipo de coche te
              sale más rentable. Una página web muy interesante si dudas entre
              dos tipos de coches y no sabes cual elegir por miedo a que gasten
              demasiado.
            </p>

            <h3 className={classes2.titulo}>
              Alternativas al  diésel y la gasolina
            </h3>

            <p className={classes2.texto}>
              Si ninguno de estos dos tipos de combustible te convence, puedes
              investigar sobre las posibles alternativas que existen como son:
            </p>

            <p className={classes2.texto}>Vehículos eléctricos:</p>

            <p className={classes2.texto}>
              Este tipo de vehículos muy extendidos actualmente, utilizan uno o
              más motores usando la energía eléctrica para funcionar. Muy
              interesante si te preocupa el medio ambiente y quieres dejar de
              contaminar.
            </p>

            <p className={classes2.texto}>Híbridos:</p>

            <p className={classes2.texto}>
              Similar al anterior, con la diferencia que uno de sus dos motores
              funciona con diésel o gasolina. Este tipo de coches cuenta con la
              ventaja de que su gasto por ciudad es menor al de un coche con
              motor diésel, sin embargo a la hora de salir por carretera su
              consumo se incrementa. Un dato interesante si lo que quieres es un
              vehículo únicamente para moverte por la ciudad para ir a trabajar
              o hacer diferentes recados.
            </p>

            <p className={classes2.texto}>Autogas:</p>

            <p className={classes2.texto}>
              El gas licuado de petróleo se trata de una mezcla de propano y
              butano comprimido. Algunos vehículos pueden incluso combinar la
              gasolina con este tipo de gas. Este tipo de combustible sin
              ninguna duda, es el combustible más barato con diferencia, su
              precio está fijado por la Unión Europea y no varía como puede
              hacer el precio del diésel o gasolina. Además es un combustible
              que no emite emisiones dañinas al medioambiente por lo que es otro
              dato que puede hacerte decantarte por este tipo de combustible
            </p>

            <p className={classes2.texto}>Gas natural:</p>

            <p className={classes2.texto}>
              Este tipo de depósitos disponen de dos tipos de combustibles: uno
              de gasolina y otro de gas natural. Con este tipo de vehículos
              evitaras dañar el medio ambiente además de otras ventajas, como su
              precio o su facilidad a la hora de repostar. A diferencia de los
              coches eléctricos no te llevara más de tres minutos cargar tu
              deposito al completo.
            </p>

            <p className={classes2.texto}>
              Si ninguna duda los coches eléctricos son los menos contaminantes
              por lo que resultan actualmente subvencionados por el gobierno si
              quieres adquirir uno.{" "}
            </p>

            <p className={classes2.texto}>
              La mayor desventaja es que este tipo de vehículos necesita ser
              cargado continuamente, por lo que no podrás hacer viajes
              demasiados largos sin parar a repostar, algo que puede echarte
              para atrás.
            </p>

            <p className={classes2.texto}>
              Otra de sus desventajas es que los puntos de recarga son difíciles
              de encontrar y deberás esperar bastante tiempo para poder recargar
              tu vehículo por completo.{" "}
            </p>

            <p className={classes2.texto}>
              Eso sí, tú bolsillo lo notara muchísimo, sobre todo si vienes de
              un coche con combustible a gasolina a donde la diferencia entre
              precios es abismal.
            </p>

            <p className={classes2.texto}>
              Los otros tipos de vehículos pueden resultarte interesante si
              quieres combinar la potencia de la gasolina con otra fuente menos
              contaminante como la eléctrica o el gas y también, más barata.
            </p>

            <p className={classes2.texto}>
              Te he enseñado cuáles son las características básicas de estos dos
              tipos de combustibles.
            </p>

            <p className={classes2.texto}>
              En total la diferencia media sale por 15€ más caro con un coche 
              diésel, algo que no supone un excesivo gasto siempre y cuando no
              viajes demasido.
            </p>

            <p className={classes2.texto}>
              Compara que tipo de combustible es mejor para ti y empieza a
              buscar el coche que te ofrezca mayores ventajas según tus
              necesidades actuales.
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
