import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import AdsGoogle from "../../../../AdsGoogle";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px"
  },
  contenedor: {
    fontSize: "17px"
  },
  titulo: {
    textAlign: "center"
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px"
  }
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <h4 className={classes2.top}>
        Cómo ser contratado en la primera entrevista
      </h4>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              ¿Te has preguntado alguna vez cuáles son las preguntas de
              entrevistas de trabajo más comunes y como debes contestar
              correctamente?
            </p>
            <p>{<AdsGoogle />}</p>
            <p className={classes2.texto}>
              Hoy te voy a contar cuales son y cómo puedes responder para que la
              entrevista de trabajo que realices sea óptima.
            </p>

            <p className={classes2.texto}>
              Si quieres destacar en una entrevista de trabajo debes recordar
              que la seguridad en ti mismo es clave para tener una entrevista de
              éxito.
            </p>

            <p className={classes2.texto}>
              No tengas miedo, el entrevistador es un ser humano que en mayor o
              menor medida comprende tu situación y solo quiere asegurarse de
              contratar al empleado más adecuado para el puesto en cuestión.{" "}
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Sé optimista, sonríe y muestra seguridad a la hora de responder,
              esto te garantizara un puesto asegurado.
            </p>

            <h3 className={classes2.titulo}>
              50 Preguntas de entrevistas más preguntadas
            </h3>

            <p className={classes2.texto}>
              <strong>¿Cuál es tu mayor virtud?</strong>
            </p>

            <p className={classes2.texto}>
              En este tipo de pregunta debes contestar con adjetivos que te
              definan de manera positiva, recalca que sabes trabajar en equipo
              si es un puesto en el que debas trabajar con gente o que sabes
              organizarte si debes trabajar solo.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              <strong>¿Cuáles es tu mayor debilidad?</strong>
            </p>

            <p className={classes2.texto}>
              Debes optar por adjetivos neutros, por ejemplo puedes contestar:
              ``Creo que soy demasiado perfeccionista a la hora de desempeñar mi
              trabajo´´.
            </p>

            <p className={classes2.texto}>
              <strong>¿Por qué quieres trabajar para nosotros?</strong>
            </p>

            <p className={classes2.texto}>
              Debes demostrar que conoces la historia y los ideales de la
              empresa en este tipo de preguntas, una buena respuesta podría ser:
              ‘Creo que esta empresa ofrece unas condiciones de trabajo optimas
              y cuida de sus empleados´´
            </p>

            <p className={classes2.texto}>
              <strong>¿Dónde te ves dentro de 5 años?</strong>
            </p>

            <p className={classes2.texto}>
              Obviamente debes indicar que te ves ejerciendo tu profesión en
              esta empresa y creciendo tanto a nivel profesional como personal.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              <strong>¿Dónde trabajabas antes?</strong>
            </p>

            <p className={classes2.texto}>
              Es importante que recalques trabajos que tengan que ver con el
              puesto ofertado, si no es el caso, debes indicar que siempre has
              querido trabajar en una empresa de este sector.
            </p>

            <p className={classes2.texto}>
              <strong>¿Qué puedes aportar a nuestra empresa?</strong>
            </p>

            <p className={classes2.texto}>
              Debes indicar adjetivos que te definan positivamente, una buena
              respuesta seria la siguiente: ‘Puedo ofrecer responsabilidad y
              dinamismo a la hora de trabajar, se trabajar bajo presión y creo
              estar capacitado para ejercer las funciones que piden´´
            </p>

            <p className={classes2.texto}>
              <strong>¿Qué puedes dar por encima de otros candidatos?</strong>
            </p>

            <p className={classes2.texto}>
              Debes indicar facetas de tu personalidad o habilidades que no
              tenga otro empleado, si por ejemplo manejas un programa de
              ordenador poco conocido, hazlo saber. Esto te sumara puntos.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              <strong>¿Cuál es tu situación ahora mismo?</strong>
            </p>

            <p className={classes2.texto}>
              Si estas desempleado debes indicar que buscas activamente empleo,
              si por el contrario estas trabajado en otro sitio, indica que
              quieres mejorar profesionalmente y crees que esta empresa puede
              hacerte avanzar.
            </p>

            <p className={classes2.texto}>
              <strong>¿Qué crees que podrías mejorar?</strong>
            </p>

            <p className={classes2.texto}>
              En esta ocasión un tipo de respuesta acertada podría ser una que
              te valorase de forma positiva. ‘quizás tengo que frenarme un poco,
              porque soy muy entusiasta´´.
            </p>

            <p className={classes2.texto}>
              <strong>
                ¿Qué consideras necesario para tener buen ambiente laboral?
              </strong>
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Una buena respuesta podría ser: ``Tener buena relación con mis
              jefes y mis compañeros´´
            </p>

            <p className={classes2.texto}>
              <strong>¿Tienes disponibilidad para mudarte de ciudad?</strong>
            </p>

            <p className={classes2.texto}>
              No debes mentir en este tipo de preguntas, si no puedes
              desplazarte hazlo saber, es importante a la hora de establecer
              unas condiciones que te resulten favorables.
            </p>

            <p className={classes2.texto}>
              <strong>¿Te gusta cumplir las normas?</strong>
            </p>

            <p className={classes2.texto}>
              En estos casos puedes responder: ‘Creo que las normas están hechas
              para que todo funcione bien, por lo que es necesario cumplirlas´´
            </p>

            <p className={classes2.texto}>
              <strong>¿Puedes viajar por trabajo?</strong>
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Solo afírmalo si realmente estas dispuesto a viajar y puedes
              permitirlo, si es así pregunta por las condiciones.
            </p>

            <p className={classes2.texto}>
              <strong>¿Qué te hace feliz?</strong>
            </p>

            <p className={classes2.texto}>
              Debes contestar que lo que más te hace feliz es superarte a ti
              mismo y crecer a nivel personal y profesional.
            </p>

            <p className={classes2.texto}>
              <strong>¿Cuál es tu mayor miedo?</strong>
            </p>

            <p className={classes2.texto}>
              Puedes comentar miedos centrados en el terreno laboral como el de
              no realizarte profesionalmente o no poder acceder al trabajo que
              mereces.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              <strong>¿Cuál es tu mayor logro?</strong>
            </p>

            <p className={classes2.texto}>
              Céntrate en logros profesionales, como algún ascenso que hayas
              tenido en el pasado o una carta de recomendación por alguna de tus
              anteriores empresas.
            </p>

            <p className={classes2.texto}>
              <strong>¿Cuál fue tu peor error en tu anterior empleo?</strong>
            </p>

            <p className={classes2.texto}>
              No es cuestión de echar la culpa a la empresa, es algo que nunca
              debes hacer, una buena respuesta seria ‘quizás exigirme demasiado
              para una empresa que no estaba dispuesta a ayudarme para crecer
              profesionalmente como yo quería.
            </p>

            <p className={classes2.texto}>
              <strong>¿Cuál es tu trabajo ideal?</strong>
            </p>

            <p className={classes2.texto}>
              Debes indicar un puesto de trabajo similar al ofertado y mostrar
              interés contante por aprender y seguir formándote.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              <strong>¿Cómo accediste a esta oferta de empleo?</strong>
            </p>

            <p className={classes2.texto}>
              Indica la manera por la que te enteraste de la oferta de empleo,
              si lo hiciste a través de alguien, di su nombre, esto puede darte
              cierta ventaja a la hora de conseguir el puesto de trabajo.
            </p>

            <p className={classes2.texto}>
              <strong>¿Que salario te gustaría tener?</strong>
            </p>

            <p className={classes2.texto}>
              Debes estudiar el salario que ofertan en función de ofertas
              similares y ajustarte a este. Ni te quedes corto&nbsp; ni esperes
              sueldos millonarios. Se realista.
            </p>

            <p className={classes2.texto}>
              <strong>¿Cómo definirías tu curriculum?</strong>
            </p>

            <p className={classes2.texto}>
              Debes estudiar con anterioridad tu curriulum y señalar cuales son
              los puestos de trabajo que más te han aportado a nivel profesional
              así como tu formación academica. Es cuestión de saber venderse.
            </p>

            <p className={classes2.texto}>
              <strong>¿Por qué debo contratarte en este trabajo?</strong>
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Véndete, demuestra porque eres el mejor candidato al puesto,
              muestra dinamismo y confianza, es la clave.
            </p>

            <p className={classes2.texto}>
              <strong>¿Podrías trabajar en festivos?</strong>
            </p>

            <p className={classes2.texto}>
              Si la respuesta es afirmativa, entérate de las condiciones, si
              ofrecen dinero extra o das de vacaciones, esto hará que muestres
              interés y será&nbsp; bien valorado.
            </p>

            <p className={classes2.texto}>
              <strong>¿Cómo manejarías una queja de un cliente?</strong>
            </p>

            <p className={classes2.texto}>
              Una posible respuesta seria: ‘Ante todo mostraría empatía y buen
              trato, que el cliente se muestre satisfecho y le dé una solución a
              su problema.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              <strong>¿Estarías dispuesto a ejercer otras funciones?</strong>
            </p>

            <p className={classes2.texto}>
              En este caso puedes responder: ‘Estoy cómodo realizando cualquier
              función centrada en el sector, por lo que podría barajarlo´´
            </p>

            <p className={classes2.texto}>
              <strong>¿Te gustaría trabajar desde casa?</strong>
            </p>

            <p className={classes2.texto}>
              Algunos trabajadores deben llevarse trabajo a casa, si estás
              dispuesto a esto, hazlo saber. Si no puedes decir: ‘No es lo que
              más me motiva pero podría valorarlo´´
            </p>

            <p className={classes2.texto}>
              <strong>¿Te gustaría seguir formándote?</strong>
            </p>

            <p className={classes2.texto}>
              Algunas empresas ofrecen formación para mejorar las habilidades de
              sus empleados, si te hacen esta reunta puedes responder: `` Me
              encanta aprender, por lo que recibir más formación sería bueno
              para poder seguir desarrollándome´´
            </p>

            <p className={classes2.texto}>
              <strong>¿Por qué buscas trabajo?</strong>
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Lejos de decir para poder vivir, puedes dar respuestas como:
              ‘Quiero mejorar laboralmente y desarrollar una carrera de futuro´´
            </p>

            <p className={classes2.texto}>
              <strong>¿Cuál es tu mayor motivación en la vida?</strong>
            </p>

            <p className={classes2.texto}>
              Céntrate en el terreno laboral para este tipo de preguntas en
              entrevistas de trabajo, una respuesta seria: ‘Mi mayor motivación
              es llegar a ser el profesional que siempre he querido´´
            </p>

            <p className={classes2.texto}>
              <strong>¿Qué disponibilidad tienes para trabajar?</strong>
            </p>

            <p className={classes2.texto}>
              Se sincero, si solo cuentas con tiempo por las mañanas o por las
              tardes, házselo saber, en caso de que te concedan el trabajo sería
              una faena tener que rechazarlo por no haber sido sincero.
            </p>

            <p className={classes2.texto}>
              <strong>¿Cuál es tu mayor figura de referencia?</strong>
            </p>

            <p className={classes2.texto}>
              Es este caso, es importante que muestres grandes líderes del
              sector donde trabajas, por ejemplo si eres informático puedes
              citar a Bill Gates o similares.
            </p>

            <p className={classes2.texto}>
              <strong>
                ¿Has tenido problemas con compañeros de otros trabajos?
              </strong>
            </p>

            <p className={classes2.texto}>
              No es cuestión de echarles la culpa, si los has tenido una buen
              respuesta seria: ‘Tuve algún percance con un compañero pero
              gracias a la capacidad de dialogo solo quedo en un malentendido´´.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              <strong>¿Sabes trabajar bajo presión?</strong>
            </p>

            <p className={classes2.texto}>
              Obviamente debes dar una respuesta afirmativa, debes incidir en
              que para ti la presión no supone un obstáculo, sino un reto para
              superarte.
            </p>

            <p className={classes2.texto}>
              <strong>¿En qué año se fundó esta empresa?</strong>
            </p>

            <p className={classes2.texto}>
              Una pregunta trampa para saber si realmente conoces la empresa,
              por ello es útil estudiar el origen y la historia de la empresa
              donde quieras trabajar.
            </p>

            <p className={classes2.texto}>
              <strong>¿Cómo sería para ti un buen jefe?</strong>
            </p>

            <p className={classes2.texto}>
              Debes indicar rasgos positivos que todo líder debería tener como
              empatía, responsabilidad y búsqueda activa de soluciones.
            </p>

            <p className={classes2.texto}>
              <strong>¿Te consideras un buen líder?</strong>
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Algunos trabajos requieren que seas un líder para el resto, si te
              hacen este tipo de preguntas puedes contestar``Me gusta dirigir y
              enseñar a los demás, además poseo facultades para ello´´
            </p>

            <p className={classes2.texto}>
              <strong>¿Qué haces en tu tiempo libre?</strong>
            </p>

            <p className={classes2.texto}>
              Está muy bien que te guste correr en pistas de coches, pero quizás
              no encaje aquí. Puedes decir actividades clásicas como leer,
              escuchar buena música o hacer recetas de comida. Esto te aportara
              valores.
            </p>

            <p className={classes2.texto}>
              <strong>
                Si tuvieras que despedir a alguien, ¿Cómo lo harías?
              </strong>
            </p>

            <p className={classes2.texto}>
              Quizás nunca se dé el caso, lo que quieren saber es como te vas a
              llevar con tus compañeros y la clase de persona que eres, una
              respuesta correcta seria: ‘Seria empático, le explicaría el
              problema que tenemos y le haría ver que ha sido la única opción
              que se ha tenido´´.
            </p>

            <p className={classes2.texto}>
              <strong>¿Qué es lo que te gusta más de este sector?</strong>
            </p>

            <p className={classes2.texto}>
              Debes demostrar entusiasmo con el sector. Una respuesta podría
              ser: `Disfruto ejerciendo esta profesión, ya que me llena a la vez
              que me ayuda a superarme´
            </p>

            <p className={classes2.texto}>
              <strong>¿Tiene cargas familiares?</strong>
            </p>

            <p className={classes2.texto}>
              Esta pregunta hoy en día es ilegal, y no tienes por qué
              contestarla. Si te hacen una pregunta así, esto va a decir mucho
              de la empresa. En cualquier caso si quieres contestar puedes
              decir: ‘Si tengo pero no resulta ningún inconfidente para
              desempeñar bien mis funciones´´
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              <strong>¿Tienes alguna pregunta?</strong>
            </p>

            <p className={classes2.texto}>
              Una pregunta trampa que demostrara si tienes iniciativa y estas
              realmente interesado en el puesto, Puedes plantear preguntas que
              no hayan surgido.
            </p>

            <p className={classes2.texto}>
              <strong>Descríbete en una palabra:</strong>
            </p>

            <p className={classes2.texto}>
              Usa adjetivos claros que te definan con una sola palabra, podrían
              ser: Responsable, dinámico, entusiasta, dinamista, creativo,
              proactivo etc…
            </p>

            <p className={classes2.texto}>
              <strong> ¿Qué es lo que puede molestarte en un compañero?</strong>
            </p>

            <p className={classes2.texto}>
              Puedes responder:`Que intente generar mal ambiente de trabajo o
              impide que los demás avancen en su trabajo´.
            </p>

            <p className={classes2.texto}>
              <strong>¿Qué piensan de las grandes empresas?</strong>
            </p>

            <p className={classes2.texto}>
              En este caso quieren saber cómo será tu relación con tus jefes,
              puedes contestar:``Creo que han trabajado mucho para llegar a su
              posición, por lo que las admiro´´
            </p>

            <p className={classes2.texto}>
              <strong>¿Qué tipo de formación tienes?</strong>
            </p>

            <p className={classes2.texto}>
              Debes decir formación centrada en el puesto de trabajo al que
              quieres optar destacando cursos del sector profesional.
            </p>

            <p className={classes2.texto}>
              <strong>¿Cuál es tu grupo de música preferido?</strong>
            </p>

            <p className={classes2.texto}>
              Otra pregunta trampa, quieren saber cómo es tu nivel de reacción,
              por ello prepara grupos de música coherentes sin llegar a los
              extremos.
            </p>

            <p className={classes2.texto}>
              <strong>¿Crees que eres seguro de ti mismo?</strong>
            </p>

            <p className={classes2.texto}>
              Una respuesta acertada a este tipo de preguntas podría ser: ``Me
              considero una persona segura de sí misma que sabe lo que quiere y
              locha por conseguirlo´´.
            </p>

            <p className={classes2.texto}>
              <strong>
                Si tu jefe te dice que tu trabajo no está siendo el esperado,
                ¿Cómo actuarías?
              </strong>
            </p>

            <p className={classes2.texto}>
              Debes indicar que en este caso, debes aportar valor a tu trabajo
              mostrando todas las cosas positivas que has hecho por la empresa y
              que quieres seguir aprendiendo.
            </p>

            <p className={classes2.texto}>
              <strong>¿Qué diría tu trabajo sobre ti?</strong>
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              <strong>
                ¿Cuáles son las debilidades / fortalezas de tu jefe?
              </strong>
            </p>

            <p className={classes2.texto}>
              <strong>
                Si llamara a tu jefe en este momento y le preguntara en qué área
                podrías mejorar, ¿qué crees que me diría?
              </strong>
            </p>

            <p className={classes2.texto}>
              <strong>¿Te gusta competir?</strong>
            </p>

            <p className={classes2.texto}>
              Una `pregunta clave en una entrevista que mostrara si estás
              preparado para optar a ascensos y eres capaz de gestionarlo, una
              respuesta podría ser:
            </p>

            <p className={classes2.texto}>
              `Me gusta competir sanamente, esto me hace confiar más en mí
              mismo.´
            </p>

            <p className={classes2.texto}>
              <strong>¿Cuál ha sido tu mayor fracaso?</strong>
            </p>

            <p className={classes2.texto}>
              Intenta buscar errores cometidos en los que hayas encontrado una
              solución y hayas sido capaz de enriquecerte de tu fracaso.
            </p>

            <p className={classes2.texto}>
              Te he mostrado cuáles son las preguntas más frecuentes que pueden
              hacerte en una entrevista de trabajo y como contestarlas.
            </p>

            <p className={classes2.texto}>
              {" "}
              Ahora, estás preparado para hacer la entrevista de tu vida.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
