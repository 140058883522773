import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @materialui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h2 className={classes2.top}>Descarga tu vida laboral por internet</h2>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              Actualmente, muchas empresas piden el informe de vida laboral para
              poder optar a un puesto de trabajo.{" "}
            </p>
            <p className={classes2.texto}>
              {" "}
              Antes, era necesario ir a las oficinas de empleo para poder
              obtener este informe de vida laboral pero ahora, gracias a las
              nuevas tecnologías puedes consultarlo por internet y descargarlo
              gratuitamente y sin complicaciones para llevarlo a la empresa done
              quieres ser contratado o guardarlo como un documento en&nbsp;
              ordenador.
            </p>

            <p className={classes2.texto}>
              Según el tipo de acceso que tengas para acceder a la plataforma el
              informe podrá ser descargado o enviado a la dirección de contacto
              que indiques.
            </p>

            <h3 className={classes2.titulo}>
              ¿Cómo puedo acceder al informe de vida laboral?
            </h3>

            <p className={classes2.texto}>
              La vida laboral&nbsp;puede solicitarla cualquier persona siempre
              que&nbsp;haya estado dado de alta en España&nbsp;y disponga de un
              número de afiliación a la Seguridad Social.
            </p>

            <p className={classes2.texto}>
              Cualquier persona que haya estado&nbsp;dada de alta en
              España&nbsp;puede solicitar un informe de vida laboral.
            </p>

            <p className={classes2.texto}>
              Si quieres acceder al informe de vida laboral deberás seguir los
              siguientes pasos que te contamos a continuación:
            </p>

            <p className={classes2.texto}>
              Primero debes acceder a la página web:{" "}
              <a
                className={classes2.link}
                href="https://sede.segsocial.gob.es/Sede_1/index.htm"
              >
                https://sede.segsocial.gob.es/Sede_1/index.htm
              </a>
            </p>

            <p className={classes2.texto}>
              Te aparecerá una pantalla donde debes clicar en el apartado que
              nos dice: Ciudadanos.
            </p>

            <p className={classes2.texto}>
              Después, para conseguir el certificado de vida laboral deberás
              buscar en el listado la sección de :{" "}
              <a
                className={classes2.link}
                href="https://sede.segsocial.gob.es/wps/portal/sede/sede/Ciudadanos/informes%2520y%2520certificados/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zijQ3NTAwtDQx93A08XQ0cQ329Qy2NA43djcz0w_EpMLA00I8iRr8BDuBIUL8XIQVAHxgVTr7putHFSSWZOhm5qXl60eAyKLc1GKFSoXk1KKSzLTM5MSU_GKgc6LwGQj2EFgBHhcHpxbrFSGRlT5pAUHpCsqAgCdzsc5/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
              >
                Comunicación de Vida Laboral y Bases de Cotización a los
                trabajadores
              </a>
            </p>

            <p className={classes2.texto}>
              Al clicar en el símbolo de +, verás que se muestran varias
              opciones como son: certificado electrónico, clave, usuario y
              contraseña, sin clave y sin certificado.
            </p>

            <p className={classes2.texto}>
              Debes elegir la modalidad que tengas para acceder al informe de
              vida laboral.
            </p>

            <p className={classes2.texto}>
              Veamos las diferentes modalidades para acceder a tu informe:
            </p>

            <h3 className={classes2.titulo}>
              Vida laboral con certificado digital
            </h3>

            <p className={classes2.texto}>
              Desde esta opción podrás consultar el informe de vida laboral de
              manera inmediata y descargarlo en formato PDF e imprimir el
              documento que obtengas.
            </p>

            <p className={classes2.texto}>
              Para pedir el informe de vida laboral con certificado digital
              debes hacer lo mediante la Sede Electrónica de la Seguridad
              Social.
            </p>

            <p className={classes2.texto}>
              Una vez accedas en el recuadro donde aparece: Acceso al Servicio,
              debes clicar en el icono que dice: Certificado Digital.
            </p>

            <p className={classes2.texto}>
              Después de esto, podrás descargar el certificado en tu ordenador
              en formato PDF y estará listo para imprimirlo si así lo deseas.
            </p>

            <h3 className={classes2.titulo}>
              Vida laboral sin certificado digital
            </h3>

            <p className={classes2.texto}>
              Puedes pedir el informe de vida laboral si no cuentas con
              certificado digital. Deberemos para ello indicar los datos del
              trabajador adecuadamente y sin fallos, ya que sino no podrá
              tramitarse la solicitud.
            </p>

            <p className={classes2.texto}>
              En este caso no podrás descargar el documento directamente, pero
              podrás solicitarlo rellenado el documento para que te lo envíen al
              domicilio que escojas.
            </p>

            <p className={classes2.texto}>
              Te contamos los pasos que has de realizar:
            </p>

            <p className={classes2.texto}>
              Rellena los datos del formulario como nombre y apellidos, DNI,
              provincia, número de seguridad social y domicilio completo.
              Asegúrate que todos tus datos son correctos para que te pueda
              llegar correctamente el informe de vida laboral a tu casa.
            </p>

            <p className={classes2.texto}>
              Una vez hecho esto, pulsa : Aceptar.
            </p>

            <p className={classes2.texto}>
              Se creará un resguardo en el que se te asignará un número de
              referencia. Con este número podrás ver cómo está el estado de tu
              solicitud pasadas 24 horas tras la misma.
            </p>

            <p className={classes2.texto}>
              Recibirás un email al correo electrónico que hayas indicado.
            </p>

            <h3 className={classes2.titulo}>
              Vida laboral con Clave Permanente
            </h3>

            <p className={classes2.texto}>
              La clave permanente consiste en un código de usuario y contraseña,
              junto al DNI de quien lo solicita.
            </p>

            <p className={classes2.texto}>
              Para solicitar el informe de vida laboral deberás logearte con
              estos datos y seguir las indicaciones que se muestran. Es sin duda
              la forma más sencilla de acceder al informe ,pero para ello
              necesitarás pedir la Clave en las oficinas de Empleo.
            </p>

            <h3 className={classes2.titulo}>
              Solicitud de vida laboral por teléfono
            </h3>

            <p className={classes2.texto}>
              También es posible solicitar un informe de la vida laboral
              mediante contacto telefónico. Sin embargo esto no está disponible
              para todas las comunidades autónomas como son: Álava, Alicante,
              Ávila, Barcelona, Córdoba, Guipúzcoa, Granada, Santa cruz de
              Tenerife, Soria, Valencia y Vizcaya.
            </p>

            <p className={classes2.texto}>
              El resto de comunidades podrán solicitar su informe de vida
              laboral llamando al siguiente teléfono: 901502050&nbsp;
            </p>

            <p className={classes2.texto}>
              Una vez llames por teléfono, se te pedirán algunos datos que debes
              tener a mano como son: Primeros dígitos del código postal, DNI,
              numero de la seguridad social, domicilio y tipo de certificado que
              quieres solicitar.
            </p>

            <p className={classes2.texto}>
              En una semana más o menos, recibirás el informe de vida laboral en
              tu hogar.
            </p>

            <h3 className={classes2.titulo}>Pedir vida laboral por SMS</h3>

            <p className={classes2.texto}>
              También existe la posibilidad de pedir el informe de vida laboral
              a través de un SMS desde la Sede Electrónica de la Seguridad
              Social.
            </p>

            <p className={classes2.texto}>
              El certificado de vida laboral se obtiene a través de un código
              que se recibe en tu teléfono. Es importante que tu número de móvil
              figure en la Tesorería de la Seguridad Social si quieres acceder
              al informe de vida laboral de esta manera, ya que sino, no será
              posible solicitarlo.
            </p>

            <p className={classes2.texto}>
              Te hemos enseñado como puedes acceder al informe de vida laboral a
              través de los diferentes medios que puede realizarse. Ahora solo
              tienes que seguir los pasos que te hemos enseñado para descargar
              tu informe de vida laboral o solicitarlo para que te llegue al
              domicilio señalado.
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
