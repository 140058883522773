/*eslint-disable */
//"https://github.com/zenoamaro/react-quill"
import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Helmet } from "react-helmet";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Picker from "./components/Picker";

import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import HeaderLinks from "./components/HeaderLinks";

// components for this page
import Editor from "./components/Editor";

import blogPostsPageStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

const useStyles = makeStyles(blogPostsPageStyle);

export default function PublicarArticulo() {
  const classes = useStyles();

  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [category, setCategory] = useState("subastas");

  return (
    <div>
      <Header
        color="transparent"
        brand="Inicio"
        fixed
        links={
          <HeaderLinks
            value={value}
            setValue={setValue}
            category={category}
            title={title}
            setTitle={setTitle}
            image={image}
            setImage={setImage}
          />
        }
        changeColorOnScroll={{
          height: 70,
          color: "header",
        }}
      />
      <Parallax
        image={require("assets/img/publicar-articulo1.jpg")}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
              <h2 className={classes.title}>Cocinando nuevo artículo...</h2>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <Picker category={category} setCategory={setCategory} />

          <Editor
            value={value}
            setValue={setValue}
            setTitle={setTitle}
            title={title}
            setImage={setImage}
          />
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a href="/politicas-de-privacidad" className={classes.block}>
                    Políticas de Privacidad
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/aviso-legal" className={classes.block}>
                    Aviso Legal
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/" className={classes.block}>
                    Inicio
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/derechos" className={classes.block}>
                    Derechos
                  </a>
                </ListItem>
              </List>
              <Helmet>
                <title>{"HM Servicios - Publicar Artículo"}</title>
              </Helmet>
            </div>
          </div>
        }
      />
    </div>
  );
}
