import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { dangerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px",
    backgroundColor: dangerColor[1],
  },
}));

export default function Validador() {
  const classes = useStyles();

  return (
    <SnackbarContent
      className={classes.root}
      message="¡Campos vacíos o contraseña incorrecta!"
    />
  );
}
