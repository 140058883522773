import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`



body{
    
    -webkit-font-smoothing: antialiased;
    h1,h2,h3{
    text-align:center;
    font-size:19px;
    font-weight:normal;
    
}
}

p  {
    font-size: 17px;
    a{
        color: #8AC3E1;
    font-weight: "bold";
   
    
    }
    a:hover{
        color: #8AC3E1;
         text-decoration:  underline;

    }
    a:focus{
        color: #8AC3E1;
         text-decoration:  underline;

    }
}









`;
