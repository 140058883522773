import React from "react";
import Button from "@material-ui/core/Button";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Helmet } from "react-helmet";

import Search from "@material-ui/icons/Search";
import CardsWithJobs from "./CardsWithJobs";

import {
  headerColor,
  whiteColor,
  grayColor,
  hexToRgb,
  dangerColor
} from "../../assets/jss/material-kit-pro-react";

const useStylesInput = makeStyles(theme => ({
  root: {
    "& $notchedOutline": {
      borderWidth: 1
    },
    "&:hover $notchedOutline": {
      borderWidth: 1
    },
    "&$focused $notchedOutline": {
      borderWidth: 1,
      borderColor: "black"
    }
  },
  focused: {},
  notchedOutline: {}
}));

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: grayColor[0],
    padding: theme.spacing(8, 0, 6)
  },
  textHeroContent: {
    color: whiteColor,
    fontSize: "105%",
    fontWeight: "bold"
  },
  textHeroContentDestaque: {
    color: headerColor
  },
  heroTittle: {
    color: whiteColor
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: "450px",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1,
    backgroundColor: grayColor[3],
    color: whiteColor
  },
  footer: {
    backgroundColor: grayColor,
    padding: theme.spacing(6),
    color: theme.palette.primary.blanco
  },
  fundoContenedorGrids: {
    backgroundColor: grayColor
  },

  ColorMenu: {
    backgroundColor: headerColor,
    color: whiteColor
  },

  ColorAviso: {
    backgroundColor: theme.palette.primary.aviso,
    padding: "1px",
    fontSize: "120%"
  },
  Ads: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textTransform: "none",
    textAlign: "center"
  },
  menuText: {
    marginTop: "10px",
    fontSize: "18px",
    textTransform: "none"
  },
  noEmail: {
    color: grayColor[3],
    borderColor: grayColor[3],
    marginRight: "10px",

    "&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: grayColor[5]
    },
    inscritos: {
      fontSize: "20px"
    }
  },
  inscribirme: {
    marginTop: "10px",

    color: headerColor,
    borderColor: headerColor,
    "&:hover,&:focus": {
      color: whiteColor,
      borderColor: whiteColor
    }
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh"
  },

  containerLoad: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  containerInput: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-70px"
  },
  botonBuscar: {
    width: "115px",
    backgroundColor: dangerColor[0],
    borderColor: dangerColor[0],

    color: whiteColor,
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(dangerColor[3]) +
      ", 0.2)",

    "&:hover,&:focus": {
      color: dangerColor[0],
      backgroundColor: dangerColor[4],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.2)"
    }
  },
  notFound: {
    padding: "20px"
  },
  listAlert: {
    padding: "20px",
    width: "290px"
  }
}));
export default function JobsContainer() {
  const classes = useStyles();
  const classesInput = useStylesInput();
  return (
    <>
      <Helmet>
        <title>HM Servicios - Vacantes Remotas</title>
      </Helmet>

      <main className={classes.fundoContenedorGrids}>
        <div>
          <Container maxWidth="md">
            <div className={classes.containerInput}>
              <OutlinedInput
                classes={classesInput}
                id="outlined-adornment-password"
                placeholder="ocupación o ciudad..."
                notched={true}
                endAdornment={
                  <InputAdornment position="end">
                    <Button
                      variant="outlined"
                      className={classes.botonBuscar}
                      disabled
                    >
                      <Search style={{ marginRight: "5px" }} />
                      Buscar
                    </Button>
                  </InputAdornment>
                }
                style={{
                  width: "100%",
                  marginTop: "50px"
                }}
                disabled
              />
            </div>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          <CardsWithJobs />
        </Container>
      </main>
    </>
  );
}
