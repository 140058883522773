import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";
import AdsGoogle from "../../../../AdsGoogle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px"
  },
  contenedor: {
    fontSize: "17px"
  },
  titulo: {
    textAlign: "center"
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px"
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline"
    }
  }
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();

  return (
    <div>
      <br />
      <h4 className={classes2.top}>
        Cómo participar en subastas de material y vehículos industriales
      </h4>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              Las grandes maquinarias que se implementan en diferentes ámbitos
              productivos no suelen tener un amplio y accesible método de
              compra.
            </p>
            <p>{<AdsGoogle />}</p>
            <p className={classes2.texto}>
              Por ende, muchas de las empresas del sector industrial optan por
              adquirir sus instrumentos de trabajo por medio de subastas de
              material y vehículos industriales.
            </p>
            <p className={classes2.texto}>
              El mecanismo de subastas garantiza seguridad en las inversiones y
              brinda variedad de herramientas y transporte que no son fáciles de
              adquirir en cualquier lugar.
            </p>
            <p className={classes2.texto}>
              Sin embargo, aún existe mucho desconocimiento ante la forma de
              utilizar este tipo de sistemas; al igual que no resulta fácil
              otorgar confianza a cualquier portal web.
            </p>
            <p className={classes2.texto}>
              Por tal razón, en esta oportunidad queremos brindarte una guía
              completa sobre cómo y dónde participar en subastas de material y
              vehículos industriales.
            </p>
            <h3 className={classes2.titulo}>
              Plataformas de subastas de material y vehículos industriales
            </h3>
            <p className={classes2.texto}>
              En la actualidad existen múltiples plataformas virtuales que se
              encargan de realizar subastas de material y vehículos
              industriales. A continuación, mencionaremos algunas de las más
              reconocidas y cómo estas se encargan de realizar las subastas para
              ofrecer un mejor y más eficaz servicio.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <h4 className={classes2.titulo}>Surplex</h4>
            <p className={classes2.texto}>
              Es una de las páginas de subastas dedicadas únicamente a la compra
              y venta de herramientas para el sector industrial. Tiene 20 años
              brindando sus servicios de forma online.
            </p>
            <p className={classes2.texto}>
              Su número de compradores ronda entre los 125.000 y realizan más de
              45.000 ventas anuales.
            </p>
            <p className={classes2.texto}>
              Quizás te interese:{" "}
              <a
                className={classes2.link}
                href="https://portalgogive.com/subastas"
              >
                Lista de Coches Embargados Subastados.
              </a>
            </p>
            <p className={classes2.texto}>
              Para participar en las subastas de material y vehículos
              industriales de esta plataforma, debes registrarte en su página,
              colocando datos personales básicos.
            </p>
            <p className={classes2.texto}>
              Este registro es completamente gratuito. Posterior a ello, debes
              seleccionar el artículo que desees y ofertar el precio de su
              preferencia.
            </p>
            <p className={classes2.texto}>
              Existe la posibilidad de comprar el producto sin que termine la
              subasta, siempre y cuando no hayan hecho ninguna otra oferta.
            </p>
            <p className={classes2.texto}>
              De lo contrario, para poder ganar debes haber ofertado el mayor
              precio al finalizar el proceso. Si eres el ganador, recibirás un
              correo con los pasos a seguir para realizar el pago.
            </p>
            <p className={classes2.texto}>
              Luego de realizar el pago, se enviará la dirección del sitio para
              retirar el producto. En caso de querer contratar un transporte, la
              empresa cuenta con esta posibilidad y debe realizarse un pago
              adicional.
            </p>
            <h4 className={classes2.titulo}>Oportunidades industriales</h4>
            <p className={classes2.texto}>
              Con material y vehículos industriales disponibles para todo tipo
              de empresas, esta web cuenta con una garantía y confianza
              distinguida.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Su gran trayectoria en el ámbito de las subastas los hace tener
              una gran experiencia en ventas. Mantiene un mecanismo claro y
              fácil en el proceso, lo cual es una ventaja para los menos
              experimentados en este método.
            </p>
            <p className={classes2.texto}>
              Puedes acceder a la subasta creando un usuario. Existen 3 opciones
              para ello: empresa, autónomo o particular. De acuerdo con ello, el
              formulario va a contener preguntas específicas.
            </p>
            <p className={classes2.texto}>
              Posteriormente, tendrás acceso para realizar pujas al momento de
              subastas y crear tu área exclusiva donde podrás seguir más de
              cerca artículos de tu interés. El mecanismo de puja se lleva a
              cabo en la opción de “subastas activas”.
            </p>
            <p className={classes2.texto}>
              Allí puedes buscar el artículo de interés, podrás leer el precio y
              los detalles del producto. En ese momento aparece la opción para
              introducir una oferta y acceder al botón “pujar ahora”.
            </p>
            <p className={classes2.texto}>
              De igual forma, el sistema cuenta con una opción para pujar en
              caso de que otros compradores sobrepasen la oferta programada.
            </p>
            <p className={classes2.texto}>
              Al ganar la subasta, el pago debe realizarse en 7 días. Luego de
              ello puedes retirar tu producto dentro de 8 días hábiles.
            </p>
            <p className={classes2.texto}>
              Quizás te interese:{" "}
              <a
                className={classes2.link}
                href="https://portalgogive.com/subastas"
              >
                Lista de Coches Embargados Subastados.
              </a>
            </p>
            <p className={classes2.texto}>
              Esta empresa cuenta con la opción de envío a domicilio, que tiene
              un costo adicional.
            </p>
            <h4 className={classes2.titulo}>Netbid</h4>
            <p className={classes2.texto}>
              Netbid es otra plataforma para participar en subastas de material
              y vehículos industriales.
            </p>
            <p className={classes2.texto}>
              Esta empresa es una casa de subasta que mantiene su sede principal
              en Alemania, sin embargo, cuenta con una sucursal en España.
            </p>
            <p className={classes2.texto}>
              Gracias a su excelente trabajo, ha logrado expandirse por gran
              parte de Europa proporcionando maquinaria industrial de gran
              alcance y de poco comercio en el mercado. El mecanismo de subasta
              no está muy alejado de las empresas antes descritas.
            </p>
            <p className={classes2.texto}>
              De igual forma se debe pasar por un proceso de registro de datos
              básicos. Al momento de establecer tu oferta, o sea pujar, también
              puedes seleccionar un agente de pujas que pueda realizar un repuje
              mientras la subasta siga abierta.
            </p>
            <p className={classes2.texto}>
              En caso de ser ganador de la subasta recibirás un correo con la
              factura para luego llevar a cabo el pago. Inmediatamente después
              de que te envíen la confirmación de pago puedes retirar tu compra.
            </p>
            <h4 className={classes2.titulo}>
              Las subastas como la mejor opción
            </h4>
            <p className={classes2.texto}>
              Todas las páginas antes mencionadas se caracterizan por ser
              confiables y contar subastas de material y vehículos industriales.
            </p>
            <p className={classes2.texto}>
              Desde maquinarias para construcción, electricidad, vidrios,
              plásticos; tornos, rectificadoras, cierras, taladros; vehículos de
              carga pesada, transporte, entre muchos otros, se pueden encontrar
              a través estas plataformas.
            </p>
            <p className={classes2.texto}>
              Aprovecha las subastas como método de compra para adquirir
              aquellos equipos, materiales y vehículos industriales que
              difícilmente conseguirás en el mercado.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            {/*----------------------- GOOGLE TAG ------------*/}
            <Helmet>
              <title>HM Servicios - Subastas Industriales</title>
            </Helmet>
            {/*FIN GOOGLE TAG*/}
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
