import React from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import JobsContainer from "./JobsContainer";
import listaCursosPageStyle from "assets/jss/material-kit-pro-react/views/listaCursosPageStyle.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

const useStyles = makeStyles(listaCursosPageStyle);

export default function Jobs() {
  const classes = useStyles();

  return (
    <div>
      <Header
        color="header"
        brand={"Vacantes"}
        links={<HeaderLinks dropdownHoverColor="header" />}
      />

      <div className={classes.main}>
        <div className={classes.container}>
          <JobsContainer />
        </div>
      </div>

      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a href="/politicas-de-privacidad" className={classes.block}>
                    Políticas de Privacidad
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/aviso-legal" className={classes.block}>
                    Aviso Legal
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/" className={classes.block}>
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/derechos" className={classes.block}>
                    Derechos
                  </a>
                </ListItem>
              </List>
            </div>
          </div>
        }
      />
    </div>
  );
}
