/*eslint-disable*/
import { makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LongMenu from "views/CursosPage/componentesCursos/LongMenu";
import {
  headerColor,
  whiteColor
} from "views/CursosPage/componentesEmpleo/material-kit-pro-react";
import AdsGoogle from "../../AdsGoogle";
import Grid from "@material-ui/core/Grid";
import DonutSmall from "@material-ui/icons/DonutSmall";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Galera from "../CursosPage/componentesCursos/Galera";
import Footer from "../CursosPage/componentesCursos/Footer";
import ModalCourse from "./ModalCourse";

function randomInt(minParam, maxParam) {
  const min = Math.ceil(minParam);
  const max = Math.floor(maxParam);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const useStyles = makeStyles(theme => ({
  ColorMenu: {
    backgroundColor: headerColor,
    color: whiteColor
  },
  menuText: {
    marginTop: "10px",
    fontSize: "18px",
    textTransform: "none"
  },
  fundoContenedorGrids: {
    backgroundColor: "#121212"
  },
  Ads: {
    color: "#FFF",
    backgroundColor: "#121212",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textTransform: "none"
  },
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    cursor: "pointer"
  },
  cardContent: {
    flexGrow: 1,
    backgroundColor: "#424242",
    color: "#FFF",
    display: "flex",
    flexDirection: "column"
  },
  buttonWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "auto"
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh"
  },
  inscribirme: {
    marginTop: "10px",
    color: headerColor,
    borderColor: headerColor,
    "&:hover,&:focus": {
      color: whiteColor,
      borderColor: whiteColor
    }
  }
}));

const roulleteCourseId = '62d9d334c7f03d0016d6db57'

export default function Courses() {
  const classes = useStyles();
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState();
  useEffect(() => {
    const fetchCourses = async () => {
      const res = await fetch(
        "https://claseflix-api.herokuapp.com/api/courses?excludeNonPublic=true"
      );
      let fetchedCourses = await res.json();
      const roulleteCourseIndex = fetchedCourses.findIndex(course => course.id === roulleteCourseId)
      const firstCourses = []
      if (roulleteCourseIndex !== -1) {
        firstCourses.push(fetchedCourses.splice(roulleteCourseIndex, 1)[0])
      }
      fetchedCourses = shuffle(fetchedCourses).slice(0, 15 - firstCourses.length);
      setCourses([...firstCourses, ...fetchedCourses]);
    };
    fetchCourses();
  }, []);
  const shuffle = array => {
    const shuffledArray = [...array];
    let currentIndex = shuffledArray.length;
    let randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [shuffledArray[currentIndex], shuffledArray[randomIndex]] = [
        shuffledArray[randomIndex],
        shuffledArray[currentIndex]
      ];
    }
    return shuffledArray;
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-714952960";
    script.async = true;
    document.body.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "AW-714952960");
    gtag("event", "conversion", {
      send_to: "AW-714952960/jH8GCL6e69EDEICi9dQC"
    });
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  if (courses.length === 0) {
    return (
      <div className={classes.loading}>
        <DonutSmall style={{ marginRight: "5px" }} />
        Cargando...
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>HM Servicios - Cursos</title>
      </Helmet>
      <AppBar position="relative" className={classes.ColorMenu}>
        <Toolbar>
          <LongMenu />
          <p className={classes.menuText}>Cursos</p>
        </Toolbar>
      </AppBar>
      <main className={classes.fundoContenedorGrids}>
        <div>
          <Container maxWidth="md">
            <br></br>
            <Typography align="left" variant="h6" className={classes.Ads}>
              Cursos online y sin coste con entrada en la Bolsa Nacional
              Interna, últimas plazas…
            </Typography>
            <Typography align="center" className={classes.Ads}>
              <br></br>
              <AdsGoogle />
            </Typography>
          </Container>
          <Container className={classes.cardGrid} maxWidth="md">
            <Grid container spacing={4} className={classes.cardGrid}>
              {courses.map((course, courseIndex) => {
                const mod = courseIndex % 12;
                let shortDescription = course.description.replace(
                  /^(.{50}[^\s]*).*/,
                  "$1"
                );
                const lastChar = shortDescription[shortDescription.length - 1];
                if ([",", "."].includes(lastChar)) {
                  shortDescription = shortDescription.substring(
                    0,
                    shortDescription.length - 1
                  );
                }
                let imageIndex = 0;
                const length = course.featuredImage.length;
                if (length > 1) {
                  imageIndex = randomInt(0, length - 1);
                }
                return (
                  <React.Fragment key={course.id}>
                    {[3, 6].includes(courseIndex) && (
                      <Grid item xs={12} sm={6} md={5}>
                        <AdsGoogle block={2} />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={
                        [3, 6].includes(mod)
                          ? 7
                          : [4, 5, 10, 11].includes(mod)
                          ? 6
                          : 4
                      }
                    >
                      <Card className={classes.card}>
                        {course.id === roulleteCourseId ? (
                          <CardActionArea
                            href={`https://claseflix.com/cursos/${course.slug}/clases`}
                            target='_blank'
                          >
                            <CardMedia
                              className={classes.cardMedia}
                              image={course.featuredImage[imageIndex]}
                              title={course.name}
                            />
                          </CardActionArea>
                        ) : (
                          <CardActionArea
                            onClick={() => setSelectedCourse(course)} 
                          >
                            <CardMedia
                              className={classes.cardMedia}
                              image={course.featuredImage[imageIndex]}
                              title={course.name}
                            />
                          </CardActionArea>
                        )}
                        <CardContent className={classes.cardContent}>
                          <Typography gutterBottom variant="h5" component="h2">
                            {`${course.name}`}
                          </Typography>
                          <Typography>
                            {shortDescription}
                            {shortDescription.length <
                              course.description.length && "..."}
                          </Typography>
                          <div className={classes.buttonWrap}>
                            {course.id === roulleteCourseId ? (
                              <Button
                                className={classes.inscribirme}
                                variant="outlined"
                                href={`https://claseflix.com/cursos/${course.slug}/clases`}
                                target='_blank'
                              >
                                VER CURSO
                              </Button>
                            ) : (
                              <Button
                                className={classes.inscribirme}
                                variant="outlined"
                                onClick={() => setSelectedCourse(course)}
                              >
                                VER CURSO
                              </Button>
                            )}
                            <Galera />
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
            <Typography align="center" className={classes.Ads}>
              <AdsGoogle block={2} />
            </Typography>
          </Container>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
      <ModalCourse
        open={!!selectedCourse}
        onClose={() => setSelectedCourse(undefined)}
        course={selectedCourse}
      />
    </>
  );
}
