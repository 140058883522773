import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h2 className={classes2.top}>
        Cómo conseguir empleo sin estudios ni experiencia
      </h2>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              Aunque cada vez más se piden estudios y experiencia para trabajar,
              <strong>
                &nbsp;todavía existen una serie de empleos sin muchas
                exigencias.
              </strong>
            </p>

            <p className={classes2.texto}>Por eso…</p>

            <p className={classes2.texto}>
              En las siguientes líneas&nbsp;
              <strong>
                voy a revelarte 3 páginas que publican ofertas de empleo a
                diario para personas sin estudios o sin experiencia.
              </strong>
            </p>

            <p className={classes2.texto}>
              Dichas ofertas de empleo&nbsp;
              <strong>no solicitan calificación o “saber hacer previo”</strong>
              &nbsp;debido a que son trabajos que cualquier persona puede
              ejecutar o que disponen de una persona encargada de enseñar a los
              nuevos empleados.
            </p>

            <p className={classes2.texto}>
              ¿Alguna vez has visto a una persona en el McDonald´s que se
              encarga de enseñar a los nuevos trabajadores?
            </p>

            <p className={classes2.texto}>Pues de eso te estoy hablando.</p>

            <p className={classes2.texto}>
              Las páginas que voy a dejarte&nbsp;
              <strong>
                son páginas serias y que comprueban las ofertas de trabajo
                disponibles en su web.
              </strong>
            </p>

            <p className={classes2.texto}>
              Dado la seriedad y la responsabilidad que poseen esas plataformas
              con las empresas, te pido que solo accedas a ellas si realmente
              deseas trabajar de forma seria y responsable.
            </p>

            <p className={classes2.texto}>
              Los datos de los contratistas están expuestos allí&nbsp;
              <strong>
                por lo que puedes llamar directamente a las empresas que buscan
                empleados
              </strong>
              &nbsp;o escribirles a través del correo electrónico.
            </p>

            <p className={classes2.texto}>
              <strong>
                Dada la experiencia que hemos tenido ya con varias personas de
                nuestros blog que han encontrado trabajo
              </strong>
              , te recomendamos llamar siempre que se pueda ya que así
              demuestras más interés y seriedad que una persona que solo se ha
              parado a escribir un e-mail.
            </p>

            <p className={classes2.texto}>
              Los sectores con ofertas de empleo varían enormemente y harán que
              tu búsqueda por trabajo termine muy pronto.
            </p>

            <p className={classes2.texto}>
              <strong>
                Camareros, operadores de telemarketing, reponedores, cajeros,
                peones y cuidadores de personas son los puestos más destacados.
              </strong>
            </p>

            <p className={classes2.texto}>
              Ahora mismo abundan las ofertas para el sector de la agricultura.
            </p>

            <h3 className={classes2.titulo}>Ofertas de Empleo</h3>

            <p className={classes2.texto}>
              Como te prometi al principio, aquí están las 3 páginas con ofertas
              de empleo para personas sin estudios o sin experiencia:
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://www.tablondeanuncios.com/trabajo-agricultura-ganaderia-pesca/"
                target="_blank"
                rel="noreferrer noopener"
                aria-label=" (se abre en una nueva pestaña)"
              >
                <strong>TABLÓN DE ANUNCIOS</strong>
              </a>
            </p>

            <p className={classes2.texto}>
              Tablón de anuncios que publica más 400 ofertas de empleo todos los
              días distribuidas por toda España.
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://www.indeed.es/jobs?q=agricultura&amp;l="
                target="_blank"
                rel="noreferrer noopener"
                aria-label=" (se abre en una nueva pestaña)"
              >
                <strong>INDEED</strong>
              </a>
            </p>

            <p className={classes2.texto}>
              Gigante del mercado laboral que ya dado trabajo a más de 4
              millones de personas.
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://www.infojobs.net/jobsearch/search-results/list.xhtml"
                target="_blank"
                rel="noreferrer noopener"
                aria-label=" (se abre en una nueva pestaña)"
              >
                <strong>INFOJOBS</strong>
              </a>
            </p>

            <p className={classes2.texto}>
              Plataforma reconocida por más de 700 empresas y que publica
              diariamente ofertas de empleo exclusivas.
            </p>

            <p className={classes2.texto}>
              <strong>No pierdas esta oportunidad.</strong>
            </p>

            <p className={classes2.texto}>
              Espero que pronto encuentres trabajo!!!
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
