import React, { useState, useEffect, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import { blackColor } from "assets/jss/material-kit-pro-react.js";
//import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";

import blog6 from "assets/img/cursillo.jpg";
import blog7 from "assets/img/cursos.jpg";
import blog8 from "assets/img/10paginas.jpg";

import sectionSimilarStoriesStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionSimilarStoriesStyle.js";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(sectionSimilarStoriesStyle);
const useStyles2 = makeStyles({
  ajuste: {
    marginTop: "0px",
  },
  leer: {
    color: blackColor,
    fontWeight: "bold",
    marginLeft: "8px",
    "&:hover,&:focus": {
      color: headerColor,
    },
  },
});

export default function SectionSimilarStories() {
  const classes = useStyles();
  const classes2 = useStyles2();
  const [show, setShow] = useState(false);
  const elementRef = useRef();

  useEffect(() => {
    const onChange = (entries, observer) => {
      const el = entries[0];
      //console.log(el);
      if (el.isIntersecting) {
        setShow(true);
        observer.disconnect();
      }
    };
    const observer = new IntersectionObserver(onChange, {
      rootMargin: "20px",
    });
    observer.observe(elementRef.current);
    return () => observer.disconnect();
  }, []);

  return (
    <div ref={elementRef}>
      {show ? (
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem md={12}>
                <h2 className={classes.title + " " + classes.textCenter}>
                  Quizás te interese
                </h2>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <Card blog>
                      <CardHeader image>
                        <a href="/cursos-google">
                          <img src={blog6} alt="curriculum" />
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: "url(" + blog6 + ")",
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                      <CardBody>
                        <Info>
                          <h6>Cursos</h6>
                        </Info>

                        <h4 className={classes.cardTitle}>
                          <a href="/cursos-google">
                            Cursos de Google gratuitos
                          </a>
                        </h4>
                        <p className={classes.description}>
                          Google pone a tu disposición cursos gratuitos de gran
                          calibre para que te puedas formar con cursos de alta
                          calidad y sin salir de casa.
                          <a href="/cursos-google" className={classes2.leer}>
                            Leer Ahora
                          </a>
                        </p>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <Card blog>
                      <CardHeader image>
                        <a href="/trabajar-como-freelancer">
                          <img src={blog8} alt="empleo" />
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: "url(" + blog6 + ")",
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                      <CardBody>
                        <Success>
                          <h6>Empleo</h6>
                        </Success>
                        <h4 className={classes.cardTitle}>
                          <a href="/trabajar-como-freelancer">
                            Empieza a trabajar desde casa de una forma sencilla
                          </a>
                        </h4>
                        <p className={classes.description}>
                          Te he preparado una lista con 10 empresas que buscan
                          empleados para trabajar desde casa urgentemente.
                          <a
                            href="/trabajar-como-freelancer"
                            className={classes2.leer}
                          >
                            Leer Ahora
                          </a>
                        </p>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <Card blog>
                      <CardHeader image>
                        <a href="https://serviciosespana.com/cursos/">
                          <img src={blog7} alt="cursos" />
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: "url(" + blog6 + ")",
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                      <CardBody>
                        <Danger>
                          <h6>Trending</h6>
                        </Danger>
                        <h4 className={classes.cardTitle}>
                          <a href="https://serviciosespana.com/cursos/">
                            Cursos gratuitos del INEM subvencionados por el
                            Gobierno
                          </a>
                        </h4>
                        <p className={classes.description}>
                          El Gobierno de España junto con Amazon, Huawei y
                          Google pone a tu disposición cursos gratuitos y
                          online.
                          <a
                            href="https://serviciosespana.com/cursos/"
                            className={classes2.leer}
                          >
                            {" "}
                            Leer Ahora
                          </a>
                        </p>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      ) : null}
    </div>
  );
}
