/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionDescription from "views/Articulos/Plantilla/Sections/SectionDescription";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import { whiteColor, blackColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(aboutUsStyle);
const useStyles2 = makeStyles({
  fundo: {
    color: blackColor,
    "&:hover, &:focus": {
      color: whiteColor,
    },
  },
});

export default function Plantilla() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <Header
        color="header"
        brand="Inicio"
        links={<HeaderLinks dropdownHoverColor="header" />}
        fixed
      />

      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem
            md={8}
            sm={8}
            className={classNames(
              classes.mlAuto,
              classes.mrAuto,
              classes.textCenter
            )}
          ></GridItem>
        </GridContainer>
      </div>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionDescription />
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="/politicas-de-privacidad"
                    className={(classes2.fundo, classes.block)}
                  >
                    Políticas de Privacidad
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/aviso-legal" className={classes.block}>
                    Aviso Legal
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/blog" className={classes.block}>
                    Blog
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()}, Todos los derechos
              reservados Hugo Rocha
            </div>
          </div>
        }
      />
    </div>
  );
}
