import {
  main,
  container,
  hexToRgb,
  blackColor,
  title,
  grayColor,
  whiteColor,
  defaultFont,
} from 'assets/jss/material-kit-pro-react';

const createArticleStyle = {
  container,
  main: {
    ...main,
    '@media (max-width: 830px)': {
      marginLeft: '10px',
      marginRight: '10px',
    },
    margin: '0 30px 50px',
    borderRadius: '0 0 6px 6px',
    boxShadow: `0 16px 24px 2px rgba(${hexToRgb(
      blackColor
    )}, 0.14), 0 6px 30px 5px rgba(${hexToRgb(
      blackColor
    )}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(blackColor)}, 0.2)`,
    paddingTop: 70,
  },
  title: {
    ...title,
    textAlign: 'center',
  },
  noMargin: {
    marginBottom: 0,
  },
  modalHeader: {
    borderBottom: 'none',
    paddingTop: '24px',
    paddingRight: '24px',
    paddingBottom: '0',
    paddingLeft: '24px',
    minHeight: '16.43px',
  },
  modalTitle: {
    margin: '0',
    lineHeight: '1.5',
  },
  modalCloseButton: {
    '&, &:hover': {
      color: grayColor[0],
    },
    '&:hover': {
      opacity: '1',
    },
    cursor: 'pointer',
    padding: '1rem',
    margin: '-1rem -1rem -1rem auto',
    backgroundColor: 'transparent',
    border: '0',
    WebkitAppearance: 'none',
    float: 'right',
    fontSize: '1.5rem',
    fontWeight: '500',
    lineHeight: '1',
    textShadow: `0 1px 0 ${whiteColor}`,
    opacity: '.5',
  },
  modalClose: {
    width: '16px',
    height: '16px',
  },
  modalBody: {
    paddingTop: '24px',
    paddingRight: '24px',
    paddingBottom: '16px',
    paddingLeft: '24px',
    position: 'relative',
    overflow: 'visible',
  },
  modalFooter: {
    padding: '15px',
    textAlign: 'right',
    paddingTop: '0',
    margin: '0',
  },
  labelRoot: {
    ...defaultFont,
    color: `${grayColor[12]} !important`,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    top: '10px',
    letterSpacing: 'unset',
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
  tagsWrap: {
    paddingTop: 13,
  },
  tagInput: {
    width: 250,
    borderBottom: '#D2D2D2 1px solid',
  },
  vistaPrevia: {
    backgroundColor: "8AC3E1"
  }
};

export default createArticleStyle;
