import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h2 className={classes2.top}>6 Formas de ganar dinero con tu coche</h2>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              Hay muchas personas ganando dinero con su propio coche de unas
              formas un poco secretas, aquí te digo cuáles son...
            </p>

            <p className={classes2.texto}>
              Un coche suele ser un medio de transporte, un elemento
              indispensable en nuestra vida diaria,{" "}
              <strong>
                pero también hay que pensar que es una herramienta de trabajo
                con la que podemos ganar dinero.
              </strong>
            </p>

            <p className={classes2.texto}>
              Te explicamos las{" "}
              <strong>6 maneras de ganar dinero con tu coche </strong>o por lo
              menos poder pagar el mantenimiento, gasolina, seguro, ruedas...
            </p>

            <p className={classes2.texto}>
              Para que no solamente sea un pozo sin fondo y se convierta en una
              herramienta para ganar dinero.
            </p>

            <h3 className={classes2.titulo}>Viajes compartidos</h3>

            <p className={classes2.texto}>
              &nbsp;En nuestro país existen multitud de aplicaciones con las que
              podemos ganar dinero con nuestro coche.{" "}
            </p>

            <p className={classes2.texto}>
              Consisten en aprovechar nuestros viajes y llenar el coche con más
              pasajeros por un módico precio por cada viaje.
            </p>

            <p className={classes2.texto}>
              Las principales aplicaciones para ganar dinero con los viajes son{" "}
              <strong>BlaBlaCar y Amovens.</strong>&nbsp;
            </p>

            <p className={classes2.texto}>
              {" "}
              Para empezar a ganar dinero con los viajes, deberemos llevar a un
              mínimo de 2 pasajeros.{" "}
            </p>

            <p className={classes2.texto}>
              Normalmente cuando llevamos a 1 pasajero solamente pagaremos
              costes de gasolina, siempre y cuando nuestro coche gaste
              poco.&nbsp;
            </p>

            <p className={classes2.texto}>
              Para utilizar estas aplicaciones, deberemos establecer una ruta,
              horarios, paradas y puntos de recogida de nuestros pasajeros.{" "}
            </p>

            <p className={classes2.texto}>
              Poner un precio competitivo es la clave para ganar dinero con
              estos viajes.&nbsp;
            </p>

            <p className={classes2.texto}>
              Las propias aplicaciones de viajes compartidos, ofrecen
              posibilidades de seguros de viaje en los quedaran previstos
              cualquier contratiempo.{" "}
            </p>

            <p className={classes2.texto}>
              En ellos se incluyes incidentes como, robo de equipaje, fallos de
              mecánica y accidentes.
            </p>

            <p className={classes2.texto}>
              Debes tener en cuenta que los pasajeros irán con su propio
              equipaje, por lo que deberás ajustar tu espacio disponible en el
              maletero, al número de pasajeros que lleves.&nbsp;{" "}
            </p>

            <p className={classes2.texto}>
              Incluso si es necesario limitar&nbsp; las maletas a una por
              persona.
            </p>

            <h3 className={classes2.titulo}>Haz ‘Carpooling’</h3>

            <p className={classes2.texto}>
              El termino ‘CARPOOLING’ nació de la necesidad de utilizar medios
              de transporte habituales como autobuses y taxis.{" "}
            </p>

            <p className={classes2.texto}>
              Consiste en compartir tu coche con personas en tu ciudad que
              realizan a diario tu ruta hacia lugares habituales.
            </p>

            <p className={classes2.texto}>
              Puedes ganar dinero hablando con tus compañeros de trabajo y pedir
              una pequeña aportación.{" "}
            </p>

            <p className={classes2.texto}>
              <strong>
                También para ganar dinero puedes entrar en la red social
                CARPLING y buscar personas que se mueven por tu ciudad y
                llevarlas con tu propio coche.&nbsp;
              </strong>
            </p>

            <p className={classes2.texto}>
              Con este servicio, además de ganar dinero, contribuirás a un
              desarrollo sostenible y a una menor contaminación en tu ciudad.
            </p>

            <p className={classes2.texto}>
              Haciendo del planeta un lugar más sostenible.
            </p>

            <h3 className={classes2.titulo}>Alquila tu coche</h3>

            <p className={classes2.texto}>
              Una buena opción para ganar dinero es alquilar tu coche para
              pequeños viajes o grandes periodos de tiempo.{" "}
            </p>

            <p className={classes2.texto}>
              <strong>
                Gracias a plataformas como Amovens puedes poner tu coche a
                disposición de los demás usuarios.{" "}
              </strong>
            </p>

            <p className={classes2.texto}>
              <strong>
                La propia plataforma incluye seguros a todo riesgo para que no
                tengas que preocuparte de accidentes o golpes.&nbsp;
              </strong>
            </p>

            <p className={classes2.texto}>
              <strong>
                Dentro de este sector para ganar dinero con tu coche tienes
                otras alternativas como RideLink, Drivy, Mambocar o SocialCar.
              </strong>
            </p>

            <p className={classes2.texto}>
              Esta forma de ganar dinero es una buena alternativa si tienes tu
              coche parado&nbsp; en el garaje.
            </p>

            <p className={classes2.texto}>
              Dentro de estas plataformas podrás alquilar un coche para eventos
              como bodas o anuncios de televisión.&nbsp;
            </p>

            <h3 className={classes2.titulo}>Comparte plaza de aparcamiento</h3>

            <p className={classes2.texto}>
              &nbsp;Si dispones una plaza de aparcamiento que no usas y quieres
              ganar dinero, también puedes ponerla en alquiler por horas o por
              días.{" "}
            </p>

            <p className={classes2.texto}>
              <strong>
                Para ello dispones de la misma plataforma Carpling o de otras
                del mismo estilo como Parquo y Compartir parquin entre otras.
              </strong>
            </p>

            <p className={classes2.texto}>
              &nbsp;Podrás realquilar tu plaza de garaje cuando la dejes libre
              para ir a trabajar o si vas de vacaciones podrás alquilarla
              durante el tiempo que no la utilices.
            </p>

            <h3 className={classes2.titulo}>Reparte con tu coche</h3>

            <p className={classes2.texto}>
              Todos hemos visto repartidores en nuestras ciudades como Glovo o
              Etece.
            </p>

            <p className={classes2.texto}>
              {" "}
              Con estas dos plataformas tú también puedes ganar dinero,
              simplemente deberás registrarte y empezar a prestar estos
              servicios como particular.&nbsp;
            </p>

            <p className={classes2.texto}>
              Los horarios son muy flexibles y podrás trabajar solamente cuando
              lo necesites o dispongas de tiempo libre.{" "}
            </p>

            <p className={classes2.texto}>
              Básicamente el servicio consiste, en recoger los pedidos en los
              lugares elegidos por los clientes y trasladarlos a su casa.
            </p>

            <p className={classes2.texto}>
              {" "}
              Podrás llevar todo tipo de comida, incluso podrás ganar más dinero
              con las propinas prestas buen servicio.
            </p>

            <h3 className={classes2.titulo}>Anuncios en tu coche</h3>

            <p className={classes2.texto}>
              Otra manera de ganar dinero con nuestro coche es utilizarlo como
              cartel publicitario.{" "}
            </p>

            <p className={classes2.texto}>
              Empresas como SerbeCar se encargan de todo, rotular tu coche y
              ajustar tu coche a las campañas publicitarias.&nbsp;
            </p>

            <p className={classes2.texto}>
              Con este servicio podrás ganar grandes sumas de dinero, entre 100
              y 400€ dependiendo de coche y de la campaña.
            </p>

            <p className={classes2.texto}>
              {" "}
              Debes tener en cuenta que uno de los requisitos fundamentales es
              que tu coche tenga menos de 5 años. &nbsp;
            </p>

            <h3 className={classes2.titulo}>
              ¿Es legal ganar dinero con tu coche?
            </h3>

            <p className={classes2.texto}>
              &nbsp;La respuesta es SÍ, actualmente&nbsp; estos servicios están
              sin regular y cualquier particular puede ofrecerlos.{" "}
            </p>

            <p className={classes2.texto}>
              Existe una limitación para ganar dinero con estas plataformas,
              pero actualmente está sin regular el sector.&nbsp;
            </p>

            <p className={classes2.texto}>
              La Comisión Nacional del Mercado y la Competencia (CNMC), reclama
              que todos estos servicios estén regulados y se termine con el
              abuso de los sectores como el taxi<strong>.</strong>
            </p>

            <p className={classes2.texto}>
              <strong>
                Todos estos servicios que se prestan como particular son
                legales.
              </strong>
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
