import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h2 className={classes2.top}>Ofertas de empleo en Cantabria</h2>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              <strong>
                El norte de España presenta un alto índice de ofertas de empleo
                y aquí está todo lo que necesitas saber para encontrar empleo
                allí.
              </strong>
            </p>

            <p className={classes2.texto}>
              Existe una entidad, que es un organismo del gobierno de carácter
              autónomo y administrativo que lleva operando desde el 2003
              ofertando numerosas vacantes de empleo en Cantabria.
            </p>

            <p className={classes2.texto}>
              Garantizan y ofrecen una experiencia orientada a la obtención de
              un empleo estable y de calidad, por lo que sus ofertas de empleo
              son hechas por empleadores certificados que ofrecen cobertura
              apropiada a sus trabajadores.&nbsp;
            </p>

            <p className={classes2.texto}>
              Además, el uso de la página es totalmente gratis y de carácter
              público y sus ofertas de empleo están coordinadas con el Sistema
              Nacional de Empleo, que asegura condiciones laborales
              igualitarias.&nbsp;
            </p>

            <h3 className={classes2.titulo}>
              ¿Cómo ver las ofertas de empleo?
            </h3>

            <p className={classes2.texto}>
              Ellos se basan en ser intermediarios entre los candidatos a la
              oferta de empleo y los reclutadores.&nbsp;
            </p>

            <p className={classes2.texto}>
              De este modo garantizan la transparencia y accesibilidad del
              proceso.&nbsp;
            </p>

            <p className={classes2.texto}>
              Así como también, los contratos laborales y la comunicación entre
              ambas partes son verificados por la página a la hora de publicar
              una oferta de empleo.
            </p>

            <p className={classes2.texto}>
              También son los encargados de crear los convenios entre las
              entidades colaboradoras que prestan los servicios para las ofertas
              de empleo.
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://www.empleacantabria.es/ofertas-cantabria"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="VER OFERTAS EN CANTABRIA (se abre en una nueva pestaña)"
              >
                <strong>VER OFERTAS EN CANTABRIA</strong>
              </a>
            </p>

            <p className={classes2.texto}>
              Ofrecen asesoramiento y formación, además de ofrecer gestión de
              ayuda para los postulantes referente a las ofertas de empleo en
              Cantabria.
            </p>

            <p className={classes2.texto}>
              También se encargan de ejecutar planes y programas del gobierno
              para ayudar a la formación profesional de cualquier postulante que
              lo necesite, esto se podrá hacer continuamente.
            </p>

            <p className={classes2.texto}>
              Ofrecen especial atención a aquellas personas que estén dentro de
              algún colectivo de riesgo.&nbsp;
            </p>

            <p className={classes2.texto}>
              Además, se promueve que las mismas empresas, una vez que contraten
              por medio de la oferta de empleo, continúen incentivando al
              empleado para formarse dentro de esta.&nbsp;
            </p>

            <p className={classes2.texto}>
              Por último, realizan colaboraciones con la Comisión Regional de
              Formación Profesional para vincular ofertas de empleo con la
              formación profesional de cada aspirante.&nbsp;
            </p>

            <h3>Características del servicio de empleo en Cantabria&nbsp;</h3>

            <p className={classes2.texto}>
              Algunas de los factores que debes conocer antes de usar la
              plataforma:&nbsp;
            </p>

            <li className={classes2.texto}>
              Es de carácter gratuito y acceso universal.
            </li>
            <li className={classes2.texto}>
              Racionalización y eficacia en los procedimientos.
            </li>
            <li className={classes2.texto}>
              Planificación y coordinación administrativa.
            </li>
            <li className={classes2.texto}>
              Eficiencia en la gestión de los recursos.
            </li>
            <li className={classes2.texto}>
              Transparencia y publicidad en la actuación administrativa.
            </li>
            <li className={classes2.texto}>
              Respeto de los principios de igualdad de oportunidades y no
              discriminación.
            </li>
            <li className={classes2.texto}>
              Colaboración y coordinación con la Dirección General competente en
              materia de trabajo.
            </li>
            <li className={classes2.texto}>
              Participación paritaria a través del Consejo de Dirección.
            </li>
            <li className={classes2.texto}>
              Colaboración y coordinación con las distintas Administraciones
              Públicas y, en particular, con el Sistema Nacional de Empleo.
            </li>

            <h3>¿Cómo encontrar ofertas de empleo?</h3>

            <p className={classes2.texto}>
              Es una página con un funcionamiento muy sencillo, ya que
              simplemente se debe filtrar la oferta de empleo que se quiere
              buscar por medio de una palabra clave.
            </p>

            <p className={classes2.texto}>
              Podrás entonces buscar la provincia, municipio, la fecha de inicio
              y de finalización.&nbsp;
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://www.empleacantabria.es/ofertas-cantabria"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="VER OFERTAS EN CANTABRIA (se abre en una nueva pestaña)"
              >
                <strong>VER OFERTAS EN CANTABRIA</strong>
              </a>
            </p>

            <p className={classes2.texto}>
              Los resultados de la búsqueda se desplegarán usando un código
              único para cada uno, la descripción del puesto, el municipio y la
              fecha de inicio.&nbsp;
            </p>

            <p className={classes2.texto}>
              Si entras a la oferta de empleo, verás con más detalle de lo que
              se trata, las habilidades que se están buscando para el puesto y
              un breve resumen del tipo de contrato.
            </p>

            <p className={classes2.texto}>
              En la parte inferior, en el espacio de contacto, verás los datos
              del reclutador y los canales por donde este te responderá y lo que
              debes enviar una vez te pongas en contacto con la empresa.&nbsp;
            </p>

            <p className={classes2.texto}>
              Otra modalidad que ofrece la página es el “autoempleo”, donde
              guían a las personas que deseen crear su propio negocio para que
              pueda ser posible.
            </p>

            <p className={classes2.texto}>
              En el apartado de “contratos” verás la información relacionada y
              todo lo que debes tener en cuenta antes de firmar.
            </p>

            <p className={classes2.texto}>
              Por ejemplo, es importante distinguir entre los&nbsp;
              <strong>
                dos tipos de contratos, uno llamado contrato indefinido y el
                otro contrato temporal.
              </strong>
            </p>

            <p className={classes2.texto}>
              Así como también información valiosa sobre la anulación de
              contratos, subrogación o finalización.
            </p>

            <p className={classes2.texto}>
              <strong>
                En caso de que seas una persona discapacitada que quiera buscar
                una oferta de empleo, la plataforma ofrece una sección
                especializada en ello.
              </strong>
            </p>

            <p className={classes2.texto}>
              Esto es importante, ya que buscan que exista más inclusión social
              por lo que suelen fomentar sus oportunidades cuando se postulan a
              una oferta de empleo.
            </p>

            <p className={classes2.texto}>
              No solo apoyando durante las entrevistas, también prestando
              especial atención en la obtención del empleo y del mantenimiento
              de este.&nbsp;
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://www.empleacantabria.es/ofertas-cantabria"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="VER OFERTAS EN CANTABRIA (se abre en una nueva pestaña)"
              >
                <strong>VER OFERTAS EN CANTABRIA</strong>
              </a>
            </p>

            <p className={classes2.texto}>
              Se habla también de las personas que son socios o miembros de
              EURES, es decir, ya sea que pertenezcas a una entidad pública o
              privada que hayan sido autorizadas mediante el Sistema Nacional de
              Admisión de EURES España.
            </p>

            <p className={classes2.texto}>
              Las personas que formen parte de esta institución deberán seguir
              una serie de tareas dentro de la plataforma, cualquier persona
              puede unirse mediante una autorización.
            </p>

            <p className={classes2.texto}>Deberán:&nbsp;</p>

            <li className={classes2.texto}>
              Contribuir al conjunto de ofertas de empleo.
            </li>
            <li className={classes2.texto}>
              Contribuir al conjunto de demandas de empleo y de Curriculum
              Vitae.
            </li>
            <li className={classes2.texto}>
              Prestar servicios de apoyo a trabajadores y empresarios.
            </li>

            <p className={classes2.texto}>
              Este servicio de apoyo no tendrá ningún costo para las personas
              que apliquen a las ofertas de empleo ni para las empresas que las
              generan.
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://www.empleacantabria.es/ofertas-cantabria"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="VER OFERTAS EN CANTABRIA (se abre en una nueva pestaña)"
              >
                <strong>VER OFERTAS EN CANTABRIA</strong>
              </a>
            </p>

            <p className={classes2.texto}>
              Como verás es una página que en conjunto ofrece una gran cantidad
              de ofertas de empleo que continuamente se irán renovando.&nbsp;
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
