import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Media from "components/Media/Media.js";
import Galera from "../../empleoComponentes/Galera";
import mercadona from "assets/img/mercadona.jpg";
import alcampo from "assets/img/superalcampo.jpg";
import corteingles from "assets/img/corteingles.jpg";
import amazon from "assets/img/amazon.jpg";
import elpozo from "assets/img/elpozo.jpg";
import dia from "assets/img/dia.jpg";
import bricomart from "assets/img/bricomart.jpg";
import telefonica from "assets/img/telefonica.jpg";
import ikea from "assets/img/ikea.jpg";
import prosegur from "assets/img/prosegur.jpg";
import carrefour from "assets/img/carrefour.jpg";
import decathlon from "assets/img/decathlon.jpg";

import Modal from "./Modal";

import sectionCommentsStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionCommentsStyle.js";
const useStyles = makeStyles(sectionCommentsStyle);
const Oferta = ({ oferta, fotoEmpresa }) => {
  const classes = useStyles();
  var image;
  switch (fotoEmpresa) {
    case "mercadona":
      image = mercadona;

      break;
    case "alcampo":
      image = alcampo;

      break;
    case "corteingles":
      image = corteingles;

      break;
    case "amazon":
      image = amazon;

      break;
    case "elpozo":
      image = elpozo;

      break;
    case "decathlon":
      image = decathlon;

      break;
    case "dia":
      image = dia;

      break;
    case "ikea":
      image = ikea;

      break;
    case "bricomart":
      image = bricomart;

      break;
    case "prosegur":
      image = prosegur;

      break;

    case "telefonica":
      image = telefonica;

      break;

    case "carrefour":
      image = carrefour;

      break;

    default:
      image = "Empresa";
      break;
  }

  return (
    <Media
      avatar={image}
      avatarLink={oferta.telefono}
      title={
        <span>
          {oferta.titulo}
          <br /> <small>{oferta.localidad}</small> <br />
        </span>
      }
      body={
        <p className={classes.color555}>
          {oferta.descripcion.slice(0, 300) + "..."}
        </p>
      }
      footer={
        <div className={classes.veroferta}>
          <Galera />
          <Modal oferta={oferta} />
        </div>
      }
    />
  );
};

export default Oferta;
