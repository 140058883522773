import React, { useEffect, useState, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import StyleTemplate from "./styles";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor, headerColor } from "assets/jss/material-kit-pro-react";
import ReactHtmlParser from "react-html-parser";

import AdsGoogle from "../../../AdsGoogle";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";

import axios from "axios";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px"
  },
  contenedor: {
    fontSize: "17px"
  },
  titulo: {
    textAlign: "center"
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "20px"
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline"
    }
  }
});
export default function SectionDescription() {
  const [article, setArticle] = useState({});

  const classes = useStyles();
  const classes2 = useStyles2();
  const { title } = useParams();

  const renderArticle = () => {
    const content = ReactHtmlParser(article.content);
    content.splice(1, 0, <AdsGoogle key={uuidv4()} />);
    let adsPosition = 11;
    while (adsPosition < content.length) {
      content.splice(adsPosition, 0, <AdsGoogle block={2} key={uuidv4()} />);
      adsPosition += 10;
    }
    return content;
  };

  const loadData = useCallback(async () => {
    const url = `https://api.hmservicios.com/api/articulo/${title}`;
    const response = await axios.get(url);

    setArticle(response.data[0]);
  }, [title]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <div>
        <Helmet>
          <title>{"Blog - " + article.title ? article.title : ""}</title>
        </Helmet>
        <br />
        <h4 className={classes2.top}>{article.title}</h4>
        <GridContainer>
          <GridItem
            md={8}
            sm={8}
            className={classNames(classes.mrAuto, classes.mlAuto)}
          >
            <div className={(classes.description, classes2.texto)}>
              {renderArticle()}
            </div>
            <br />
            <br />
          </GridItem>
        </GridContainer>
      </div>
      <StyleTemplate />
    </>
  );
}
