import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import AdsGoogle from "../../AdsGoogle";
import parse from "html-react-parser";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import { hexToRgb, makeStyles } from "@material-ui/core";
import {
  blackColor,
  whiteColor,
  headerColor
} from "assets/jss/material-kit-pro-react";

function parseHtml(htmlString) {
  const elements = parse(htmlString);
  if (typeof elements === "string") {
    return [React.createElement("p", undefined, elements)];
  }
  if (!Array.isArray(elements)) {
    return [<React.Fragment key={0}>{elements}</React.Fragment>];
  }
  return elements;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  container: {
    paddingTop: 24,
    paddingBottom: 24
  },
  buttonWrap: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1rem",
    marginBottom: "3rem",
    "& button": {
      marginLeft: "5px"
    }
  },
  buttonClose: {
    backgroundColor: blackColor,
    color: whiteColor,
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(blackColor) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    "&:hover,&:focus": {
      color: blackColor,
      backgroundColor: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
    }
  },
  buttonView: {
    backgroundColor: headerColor,
    color: whiteColor,
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(headerColor[0]) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(headerColor[0]) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(headerColor[0]) +
      ", 0.2)",
    "&:hover,&:focus": {
      color: headerColor,
      backgroundColor: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(headerColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(headerColor[0]) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(headerColor[0]) +
        ", 0.2)"
    }
  }
});

export default function ModalCourse({ open, onClose, course }) {
  const classes = useStyles();
  const content = course?.longDescription
    ? parseHtml(course.longDescription)
    : [];
  const roulleteId = "62d9d334c7f03d0016d6db57";
  if (content.length > 0 && course?.id !== roulleteId) {
    content.splice(1, 0, <AdsGoogle key={1} />);
  }
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      scroll="body"
    >
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h5" component="h2" gutterBottom>
          {course?.name}
        </Typography>
        {content}
        <div className={classes.buttonWrap}>
          <Button
            variant="contained"
            href={
              course?.isExternal
                ? course?.externalUrl
                : `https://claseflix.com/cursos/${course?.slug}/clases`
            }
            className={classes.buttonView}
          >
            Ver ahora
          </Button>
          <Button
            onClick={onClose}
            variant="contained"
            className={classes.buttonClose}
          >
            Cerrar
          </Button>
        </div>
      </Container>
    </Dialog>
  );
}
