import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor, headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  texto1: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <h2 className={classes2.top}>Derechos</h2>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div>
            <p className={classes2.texto}>
              Este sitio web está protegido por derechos del autor. Está
              prohibido su reproducción sin su consentimiento.
            </p>
            <p className={classes2.texto}>
              Las imágenes usadas en este blog fueron extraídas de freepik.com.
              Tenemos permiso de Freepik para usarlas.
              <a className={classes2.link} href="https://www.freepik.es/">
                {" "}
                Recursos Gratis para todos - www.freepik.es
              </a>
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
