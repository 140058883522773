import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import EmpleoImg from "../../assets/img/bienvenido-empleo.png";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { Link } from "react-router-dom";
import MiddlePageFooterCoches from "components/MiddlePageFooterCoches";

const useStyles = makeStyles({
  fundoContenedorGrids: {
    backgroundColor: "#141414",
    fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    color: "#FFF",
    textAlign: "center",
    minHeight: "100vh",
    paddingTop: 30,
    "& h1": {
      fontFamily: "inherit",
      fontWeight: 700,
      marginBottom: 20,
      fontSize: "1.5rem",
      "@media (min-width: 768px)": {
        fontSize: "1.8rem"
      }
    }
  },
  container: {
    maxWidth: 562,
    "@media (min-width: 768px)": {
      paddingLeft: "4%",
      paddingRight: "4%"
    }
  },
  imgWrap: {
    padding: "0 40px",
    "& img": {
      width: "100%",
      height: "auto"
    }
  },
  buttonCall: {
    margin: "20px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1rem",
    fontWeight: 400,
    "& svg": {
      fontSize: 45,
      marginLeft: 10,
      animation: "$buttonCallArrow 2s infinite"
    }
  },
  "@keyframes buttonCallArrow": {
    "0%": {
      transform: "translateY(-25%)"
    },
    "50%": {
      transform: "translateY(25%)"
    },
    "100%": {
      transform: "translateY(-25%)"
    }
  },
  mainButton: {
    background: "#28a745",
    color: "#FFF",
    lineHeight: "31px",
    textTransform: "none",
    fontSize: 18,
    fontFamily: "inherit",
    "& .MuiButton-iconSizeLarge > *:first-child": {
      fontSize: 16
    },
    "&:hover": {
      background: "rgba(40,167,69,.75)",
      color: "#FFF"
    }
  },
  listWrap: {
    textAlign: "left",
    marginTop: 30,
    "& p": {
      marginBottom: 16,
      fontSize: 16,
      fontWeight: 400
    },
    "& ul": {
      listStyle: "none",
      marginBottom: 16,
      padding: 0,
      margin: 0,
      "& li": {
        marginBottom: 10,
        verticalAlign: "middle",
        display: "flex",
        fontSize: 16,
        fontWeight: 400,
        alignItems: "center"
      },
      "& i": {
        color: "#28a745",
        marginRight: 10,
        fontSize: 30
      }
    }
  }
});

export default function CoursesWelcome() {
  const classes = useStyles();
  const destinationLink = "/cursos";
  return (
    <>
      <Helmet>
        <title>HM Servicios - Cursos Felicitaciones</title>
        <script
          charset="UTF-8"
          src="//web.webpushs.com/js/push/8ceba57321a376d20f7dfb33683e6048_1.js"
          async
        ></script>
      </Helmet>
      <main className={classes.fundoContenedorGrids}>
        <Container className={classes.container}>
          <Typography variant="h5" component="h1">
            Felicitaciones,
            <br />
            Ahora has asegurado su acceso
          </Typography>
          <div className={classes.imgWrap}>
            <Link to={destinationLink}>
              <img
                src={EmpleoImg}
                alt="Ofertas de Empleo"
                width={500}
                height={398}
              />
            </Link>
          </div>
          <p className={classes.buttonCall}>
            Pincha en el botón de abajo
            <ArrowDownwardIcon />
          </p>
          <Button
            to={destinationLink}
            variant="contained"
            fullWidth
            startIcon={<i className="fas fa-lock-open" />}
            size="large"
            className={classes.mainButton}
            component={Link}
          >
            Ver ahora
          </Button>
          <div className={classes.listWrap}>
            <p>Tendrás acceso a ofertas de:</p>
            <ul>
              <li>
                <i className="fas fa-check" />
                Empleo en almacenes y fábricas
              </li>
              <li>
                <i className="fas fa-check" />
                Trabajos en supermercados
              </li>
              <li>
                <i className="fas fa-check" />Y mucho más
              </li>
            </ul>
            <p>Todas las subastas son en tiempo real y en España.</p>
            <p>
              En cada lista de vehículos tienes el precio de la última puja, el
              estado del coche subastado y toda la información necesaria para
              que puedas participar de las subastas.
            </p>
            <p>
              Hay de varios tipos, desde coches de segunda mano hasta vehículos
              para despiece o desguace.
            </p>
            <p>Echa un vistazo.</p>
            <Button
              to={destinationLink}
              variant="contained"
              fullWidth
              startIcon={<i className="fas fa-lock-open" />}
              size="large"
              className={classes.mainButton}
              component={Link}
            >
              Ver ahora
            </Button>
          </div>
        </Container>
        <MiddlePageFooterCoches />
      </main>
    </>
  );
}
