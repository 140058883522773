/*eslint-disable*/ import React, { useContext, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import Footer from "components/Footer/Footer.js";

// sections for this page

import SectionComments from "./Sections/SectionComments.js";
import SectionSimilarStories from "./Sections/SectionSimilarStories.js";

import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";
import Context from "../../Context/Context";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(blogPostPageStyle);

export default function ListaOfertaEmpresa() {
  const classes = useStyles();
  const MeuContext = useContext(Context);
  const { empresa, header, setHeader } = MeuContext;

  switch (empresa) {
    case "mercadona":
      setHeader("Mercadona");

      break;
    case "alcampo":
      setHeader("Alcampo");

      break;
    case "corteingles":
      setHeader("El Corte Inglés");

      break;
    case "amazon":
      setHeader("Amazon");

      break;
    case "decathlon":
      setHeader("Decathlon");

      break;
    case "elpozo":
      setHeader("El Pozo");

      break;

    default:
      setHeader("Empresas");

      break;
  }
  let { company } = useParams();
  useEffect(() => {
    if (company === 'decathlon') {
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-714952960';
      script.async = true;
      document.body.appendChild(script);
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-714952960');
      gtag('event', 'conversion', {'send_to': 'AW-714952960/OHL4CMH-xdADEICi9dQC'});
      return () => {
        document.body.removeChild(script);
      }
    }
  }, [])

  return (
    <div>
      <Helmet>
        <title>{"HM Servicios - " + header}</title>
      </Helmet>
      <Header
        color="header"
        brand={header}
        links={<HeaderLinks dropdownHoverColor="header" />}
      />

      <div className={classes.main}>
        <div className={classes.container}>
          <SectionComments />
        </div>
      </div>
      <SectionSimilarStories />
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a href="/politicas-de-privacidad" className={classes.block}>
                    Políticas de Privacidad
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/aviso-legal" className={classes.block}>
                    Aviso Legal
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/" className={classes.block}>
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/derechos" className={classes.block}>
                    Derechos
                  </a>
                </ListItem>
              </List>
            </div>
          </div>
        }
      />
    </div>
  );
}
