const fakeAdStyle = {
  adWrap: {
    border: '10px solid #ddd',
    background: '#fafafa',
    textAlign: 'center',
    lineHeight: '200px',
    fontSize: '2rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: 15,
  },
  withMargin: {
    marginTop: -15,
  },
};

export default fakeAdStyle;
