import { makeStyles } from '@material-ui/core';
import FakeAd from '../FakeAd';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import articleContentStyle from './ArticleContentStyle';

const useStyles = makeStyles(articleContentStyle);

export default function ArticleContent({ contentString }) {
  const classes = useStyles();
  const content = ReactHtmlParser(contentString);
  content.splice(1, 0, <FakeAd key={1} />);
  let adsPosition = 11;
  while (adsPosition < content.length) {
    content.splice(adsPosition, 0, <FakeAd key={adsPosition} />);
    adsPosition += 10;
  }
  return <div className={classes.wrap}>{content}</div>;
}
