import React from "react";
import PropTypes from "prop-types";

export default class Ads extends React.Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }
  render() {
    const { block = 1 } = this.props;
    return (
      <div>
        {block === 1 && (
          <>
            {/*-- HM SERVICIOS   --*/}
            <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-5097195498846694"
              data-ad-slot="4446549925"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          </>
        )}
        {block === 2 && (
          <>
            {/* HM SERV BLOCO2 */}
            <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-5097195498846694"
              data-ad-slot="5169136129"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          </>
        )}
      </div>
    );
  }
}

Ads.propTypes = {
  block: PropTypes.number
};
