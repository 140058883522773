import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";
import AdsGoogle from "../../../../AdsGoogle";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px"
  },
  contenedor: {
    fontSize: "17px"
  },
  titulo: {
    textAlign: "center"
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px"
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline"
    }
  }
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h4 className={classes2.top}>Cursos de Google gratuitos</h4>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              Google ofrece cursos gratuitos con certificado para que puedas
              aumentar tus oportunidades de conseguir empleo y enriquecer tu
              currculum vitae. Hoy te voy a contar cuales son estos cursos y en
              qué consisten.
            </p>

            <p>{<AdsGoogle />}</p>

            <p className={classes2.texto}>
              Te voy enseñar los 40 cursos que Google ofrece de forma totalmente
              gratuita.
            </p>

            <h3 className={classes2.titulo}>Cursos de Google Gratuitos</h3>

            <p className={classes2.texto}>
              <strong>
                 Interfaz de usuario – Conceptos básicos de Android
              </strong>
            </p>

            <p className={classes2.texto}>
              En este curso puedes aprender los conceptos básicos del sistema
              Android partiendo desde una base elemental. Muy útil si quieres
              aprender sobre este sistema y aplicarlo a&nbsp; tú trabajo.
            </p>

            <p className={classes2.texto}>
              <strong> Google Classroom</strong>
            </p>

            <p className={classes2.texto}>
              Este curso te va a enseñar a organizar clases y deberes así como
              analizar datos de tus alumnos de forma sencilla. Una idea perfecta
              si eres profesor y quieres ponerte al día con las nuevas
              tecnologías incrementando tu productividad y haciéndote la vida
              más fácil.
            </p>

            <p className={classes2.texto}>
              <strong> Crea un plan de redes sociales</strong>
            </p>

            <p className={classes2.texto}>
              Si quieres adentrarte en el mundo de las redes sociales, este
              curso te enseñará como optimizar tu tiempo y tus recursos para
              atraer un mayor número de usuarios a tus redes sociales y así
              aumentar tu tráfico web.
            </p>

            <p className={classes2.texto}>
              <strong> Productividad personal</strong>
            </p>

            <p>{<AdsGoogle block={2} />}</p>

            <p className={classes2.texto}>
              Con este curso vas a aprender a gestionar tu tiempo y tus
              herramientas para poder obtener una mayor producción. Muy útil si
              estas iniciando un nuevo negocio y no sabes cómo organizarte.
            </p>

            <p className={classes2.texto}>
              <strong> Promociona una empresa</strong>
            </p>

            <p className={classes2.texto}>
              Este curso te va a enseñar las claves para promocionar
              correctamente tu empresa en el entorno digital y así obtener una
              mejor fuente de ingresos.
            </p>

            <p className={classes2.texto}>
              <strong> Anuncios de Shopping</strong>
            </p>

            <p className={classes2.texto}>
              Si quieres saber cómo los usuarios pueden ver tus anuncios cuando
              hacen una búsqueda en Google, este es tu curso. Aprenderás las
              claves y los pasos para crear un anuncio en Google partiendo de
              cero y hacerlo llegar el mayor número de usuarios posibles.
            </p>

            <p className={classes2.texto}>
              <strong>
                 Pantallas de aplicaciones múltiples – Conceptos básicos de
                Android
              </strong>
            </p>

            <p className={classes2.texto}>
              Con este curso vas a aprender a usar pantallas dobles en tus
              dispositivos de forma que puedas trabajar más cómodamente. Muy
              útil si eres diseñador gráfico o ingeniero pues podrás trabajar
              desde dos marcos diferentes, ahorrándote tiempo y esfuerzo.
            </p>

            <p className={classes2.texto}>
              <strong> Redes – Conceptos básicos de Android</strong>
            </p>

            <p className={classes2.texto}>
              Esta vez, Google te ofrece un curso sobre el aprendizaje básico de
              redes. Con este curso vas a aprender que tipos de redes existen
              así como todas sus características y la manera de sacarles el
              mejor rendimiento.
            </p>

            <p className={classes2.texto}>
              <strong>
                 Almacenamiento de datos – Conceptos básicos de Android
              </strong>
            </p>

            <p className={classes2.texto}>
              Con este curso vas a aprender en qué consiste el almacenamiento de
              datos en la red. Muy útil si no cuentas con un ordenador de gran
              capacidad o quieres compartir archivos de gran extensión.
            </p>

            <p className={classes2.texto}>
              <strong>
                 Desarrollo de aplicaciones Android, nivel Intermedio
              </strong>
            </p>

            <p className={classes2.texto}>
              Este curso se basa en el aprendizaje de la creación de
              aplicaciones para el sistema Android, para ello deberás tener un
              nivel sobre el que partir ya que ofrece conocimientos avanzado son
              el programa Android.
            </p>

            <p className={classes2.texto}>
              <strong>
                 Optimización de aplicaciones para Android, nivel Avanzado
              </strong>
            </p>

            <p>{<AdsGoogle block={2} />}</p>

            <p className={classes2.texto}>
              En este curso aprenderás a optimizar tus aplicaciones de Android
              para que su carga y visualización no supongan ningún obstáculo
              para los usuarios.
            </p>

            <p className={classes2.texto}>
              <strong> Emprender</strong>
            </p>

            <p className={classes2.texto}>
              Si quieres aventurarte en un nuevo negocio, este curso te ofrece
              las herramientas clave para saber sacar el mayor partido a las
              nuevas tecnologías y aprender estrategias para llegar a más
              usuarios.&nbsp;
            </p>

            <p className={classes2.texto}>
              <strong> Seguridad online</strong>
            </p>

            <p className={classes2.texto}>
              Si quieres mantener a salvo todos tus datos en internet este es tu
              curso. Te enseñará como puedes salvar datos de tus servidores en
              internet y como puedes tratar de recuperarlos de forma sencilla.
            </p>

            <p className={classes2.texto}>
              <strong> Bienestar digital</strong>
            </p>

            <p className={classes2.texto}>
              Un curso muy útil si tienes niños en casa, pues enseña las claves
              para tener una buena salud en la red de manera que evitemos ser
              engañados o tus hijos entren en lugares poco propios para su edad.
            </p>

            <p className={classes2.texto}>
              <strong> Servicios de ubicación de Google en Android</strong>
            </p>

            <p className={classes2.texto}>
              Esta vez, Google nos ofrece un curso para aprender a sacarle el
              mayor partido a la herramienta de Google Maps. Aprenderás las
              calves para buscar lugares e indicaciones para poder personalizar
              las búsquedas.
            </p>

            <p className={classes2.texto}>
               Google <strong>Analythics</strong>
            </p>

            <p className={classes2.texto}>
              Con este curso aprenderás las claves para analizar el tráfico de
              una página web. Te enseñará que les interesa más sobre tu web a
              los usuarios y cómo puedes aumentar el número de visitas.
            </p>

            <p className={classes2.texto}>
              <strong> Agrega Google Mapas a tu aplicación de Android</strong>
            </p>

            <p className={classes2.texto}>
              En esta ocasión Google te ofrece un curso para insertar el mapa de
              Google en tu aplicación móvil. Muy útil si quieres dar a conocer
              la ubicación exacta de tu negocio.
            </p>

            <p className={classes2.texto}>
              <strong> Diseño web responsive, nivel Intermedio</strong>
            </p>

            <p className={classes2.texto}>
              Este curso te enseñará como puedes hacer que tu página web se vea
              correctamente en los diferentes dispositivos a través de los
              cuales pueden acceder a ella. Móviles, ordenadores,
              tabletas….Deberás contar con unos conocimientos básicos en este
              tipo de diseño para poder sacarle el mejor partido.
            </p>

            <p className={classes2.texto}>
              <strong> Optimización de webs, nivel Intermedio</strong>
            </p>

            <p className={classes2.texto}>
              Con este curso podrás aprender como optimizar tu página web para
              sacarle el mejor rendimiento. Te enseñará trucos y consejos que
              puedes aplicar para aumentar tus visitas y genera un mayor tráfico
              web.
            </p>

            <p className={classes2.texto}>
              <strong> Uso de imágenes responsivas, nivel Intermedio</strong>
            </p>

            <p className={classes2.texto}>
              En esta ocasión Google te va a enseñar cómo puedes agregar
              imágenes en tu sitio web y que puedan verse correctamente en todos
              los dispositivos a través de los cuales los usuarios accedan a tu
              web. Deberás contar con unos conocimientos básicos, pues se trata
              de un nivel intermedio que exige un saber mínimo.
            </p>

            <p className={classes2.texto}>
              <strong> Optimización de aplicaciones we</strong>b
            </p>

            <p className={classes2.texto}>
              En este curso vas a aprender a optimizar las aplicaciones para
              páginas web para así aligerar la velocidad con la que los usuarios
              pueden explorar tu página web. Muy interesante si lo que quieres
              es captar usuarios y generar contenidos de calidad.&nbsp;
            </p>

            <p className={classes2.texto}>
              <strong> Android TV y Google Cast, nivel Avanzado</strong>
            </p>

            <p className={classes2.texto}>
              Este curso te enseñara como puedes sacar mayor partido a tu móvil
              para así poder interactuar con sus contenidos conectándolo a tu
              televisor. Una forma sencilla de poder ver todos tus videos o
              fotografías directamente en tu televisión, sin tener que descargar
              la información para después poder usarla.
            </p>

            <p className={classes2.texto}>
              <strong>
                 Monetización de la aplicación con publicidad gráfica
              </strong>
            </p>

            <p className={classes2.texto}>
              Con este curso aprenderás a implantar correctamente anuncios en tu
              página web para que puedas ganar dinero con ello. No es cuestión
              de que llenes tu App de publicidad, esto puede resultar molesto a
              los usuarios. Por ello en este curso te enseñaran las claves para
              poder poner anuncios adaptados y de fácil visualización.
            </p>

            <p className={classes2.texto}>
              <strong> Android Wear, nivel Avanzado</strong>
            </p>

            <p className={classes2.texto}>
              Esta vez Google te ofrece un curso para saber manejar tu reloj
              inteligente y sacarle el máximo partido a tu pulsera. Ofrece
              indicaciones para poder programar diferentes funciones y ver datos
              que puedan resultarte de interés sobre tu salud y forma de vida.
            </p>

            <p className={classes2.texto}>
              <strong> Agrega Google Maps a tu aplicación de Android</strong>
            </p>

            <p className={classes2.texto}>
              En este curso se va a enseñar paso a paso como puedes agregar un
              mapa de Google a tu aplicación móvil para así, poder indicar a los
              usuarios que accedan a ella las ubicaciones exactas de lugares que
              te interesen mostrar.
            </p>

            <p className={classes2.texto}>
              <strong> Android Auto, nivel Avanzado</strong>
            </p>

            <p className={classes2.texto}>
              Este curso está diseñado para que aprendas a manejar el programa
              Android Wear en tu vehículo, ofrece las claves para poder manejar
              las distintas aplicaciones móviles desde la pantalla de navegación
              del vehículo de forma sencilla. Muy útil si quieres instalar
              programas de música para poder escuchar mientras estas
              conduciendo.
            </p>

            <p className={classes2.texto}>
              <strong>
                 Diseño UX para desarrolladores móviles para Principiantes
              </strong>
            </p>

            <p className={classes2.texto}>
              Especialmente indicado para futuros empresarios o emprendedores
              que quieren ofrecer nuevos servicios a los usuarios para
              ofrecerles las mejores condiciones&nbsp; en su experiencia como
              usuario.
            </p>

            <p className={classes2.texto}>
              <strong> Desarrollo de App en Python, nivel Avanzado </strong>
            </p>

            <p className={classes2.texto}>
              Para hacer este curso, deberás contar con unas bases sólidas en el
              manejo del programa Python.Con este curso aprenderás a programar
              Apps de manera profesional.
            </p>

            <p className={classes2.texto}>
              <strong>
                 Desarrollo de juegos para HTML5, nivel Intermedio
              </strong>
            </p>

            <p className={classes2.texto}>
              Este curso está diseñado para poder desarrollar juegos con el
              lenguaje de programación HTML5. Deberás contar con unos
              conocimientos básicos para poder seguir aprendiendo y metiéndote
              más en el mundo del desarrollo de juegos.
            </p>

            <p className={classes2.texto}>
              <strong> Gradle para Android y Java</strong>
            </p>

            <p className={classes2.texto}>
              En este curso aprenderás a usar la herramienta Grandle encargada
              de automatizar códigos abiertos de las páginas webs para
              aplicarlos a Android y Java. .
            </p>

            <p className={classes2.texto}>
              <strong> Marketing Digital curso básico</strong>
            </p>

            <p className={classes2.texto}>
              Con este curso aprenderás en que consiste el marketing digital y
              como puedes hacer crecer tu negocio por internet. Un curso muy
              útil si estás pensando en crear una tienda online o lanzar un
              nuevo producto al mercado.
            </p>

            <p className={classes2.texto}>
              <strong>E-Commerce</strong>
            </p>

            <p>{<AdsGoogle block={2} />}</p>

            <p className={classes2.texto}>
              Con este curso va a aprender a vender tus productos por internet
              partiendo desde cero. De esta manera puedes rentabilizar tu
              negocio y hacer que el número de ventas se incremente.
            </p>

            <p className={classes2.texto}>
              <strong>Productividad Personal en la Era Digital</strong>
            </p>

            <p className={classes2.texto}>
              Esta vez, Google te ofrece los conocimientos necesarios para poder
              aprender a llevar tu negocio hasta lo ms alto enseñándote las
              bases de la tecnología digital. De este modo podrás conocer que
              pide el usuario a la hora de navegar en la red y que puedes hacer
              para ofrecerles los mejores servicios.
            </p>

            <p className={classes2.texto}>
              <strong>Desarrollo de Apps Móviles</strong>
            </p>

            <p className={classes2.texto}>
              En este curso podrás aprender en que consiste el desarrollo básico
              de App para móviles. Una forma sencilla de aprender a configurar
              App sin tener que gastar un dineral en cursos especializados. Si
              quieres empezar a aprender a configurar tu propia aplicación, este
              es tu curso ideal.
            </p>

            <p className={classes2.texto}>
              <strong>Cloud Computing</strong>
            </p>

            <p className={classes2.texto}>
              Este curso te va a enseñar a usar la nube de forma correcta para
              poder almacenar tu información en la red sin necesidad de contar
              con una gran memoria en tu ordenador.
            </p>

            <p className={classes2.texto}>
              <strong> Introducción al Desarrollo Web: HTML y CSS (2/2)</strong>
            </p>

            <p className={classes2.texto}>
              En este curso podrás aprender desde cero a programar páginas webs
              partiendo de los lenguajes HTML y CSS.De esta manera obtendrás los
              conocimientos clave para poder hacer una web de forma profesional.
            </p>

            <p className={classes2.texto}>
              <strong> Transformación digital para el empleo</strong>
            </p>

            <p className={classes2.texto}>
              Esta vez, Google te ofrece un curso para poder transformar tu
              carrera y adaptarla a la era digigtal.Si tienes una empresa y
              crees que esta algo alejada de la tecnología moderna. Este es tu
              curso perfecto.
            </p>

            <p className={classes2.texto}>
              <strong>Analítica Web curso básico</strong>
            </p>

            <p className={classes2.texto}>
              En este curso te van a enseñar cómo puedes analizar una página web
              para sacarle el máximo rendimiento. A través del uso de
              herramientas que analizan el comportamiento de los usuarios que
              acceden a tu página web te dará datos para saber qué es lo que la
              gente busca en tu página web y que aspectos puedes mejorar.
            </p>

            <p className={classes2.texto}>
              <strong> Certificación en Adwords</strong>
            </p>

            <p className={classes2.texto}>
              Con este curso obtendrás el Certificado de Adwors.El curso te
              ofrece las herramientas ideales para que aprendas a redactar
              artículos para tu página web y que te posiciones en los buscadores
              a través de las llamadas kewwords, de esta manera los usuarios
              podrán encontrar más fácilmente tu página web obtenido un mayor
              número de usuarios.
            </p>

            <p>{<AdsGoogle block={2} />}</p>

            <p className={classes2.texto}>
              Te he enseñado cuales son los 40 cursos gratuitos que Google está
              ofreciendo con certificado y en que consiste cada uno. Elige el
              curso que te interese para poder mejorar tu curriculum vitae y
              encontrar un trabajo adaptado a tus necesidades.
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
