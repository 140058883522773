export const jobs = [
  {
    id: "5f32c9ca48109400221123e",
    title: "Digitalización de Archivos",
    description:
      "Vacante para digitalización de archivos muertos. Pasar de papel físico a ordenador. No se pide experiencia.",
    salary: "29€/h",
    url: "https://hmcursos.com/en/guia-completa-funil/"
  },
  {
    id: "5f32c9ca48232109400175bde3e",
    title: "Gestionar Redes Sociales",
    description:
      "Gestionar redes sociales de restaurante desde casa. Hacer posts y responder comentários.",
    salary: "22€/h",
    url: "https://hmcursos.com/en/guia-completa-funil/"
  },
  {
    id: "5f32c9323223ca48109400175bde3e",
    title: "Gestor de Anuncios en Facebook",
    description:
      "Hacer anuncios en redes sociales para promocionar restaurante en Madrid. Vacante para trabajar desde casa.",
    salary: "35€/h",
    url: "https://hmcursos.com/en/guia-completa-funil/"
  },
  {
    id: "5f32c9ca4813fasd09400175bde3e",
    title: "Transcribir vídeos",
    description:
      "Transcripción de vídeos para empresa de cursos online. Sueldo de 23€/h y trabajo remoto. No se pide experiencia.",
    salary: "23€/h",
    url: "https://hmcursos.com/en/guia-completa-funil/"
  },
  {
    id: "5f32c9ca4832edcc109400175bde3e",
    title: "Soporte Chat Online",
    description:
      "Responder a clientes a través de chat online para empresa de venta de piezas de coches. Se da preferencia a mayores de 35 años. ",
    salary: "16€/h",
    url: "https://hmcursos.com/en/guia-completa-funil/"
  },
  {
    id: "5f32c9ca4813332fasdf09400175bde3e",
    title: "Redactor/a de contenido web",
    description:
      "En buscauncoche.online necesitamos manos extra y ofrecemos un excelente puesto de trabajo como redactor de contenido web.",
    salary: "14€/h",
    url: "https://hmcursos.com/en/guia-completa-funil/"
  },
  {
    id: "5f32c9ca481234wda09400175bde3e",
    title: "Creación de Powerpoint",
    description:
      "Vacante para creación de presentaciones en Powerpoint para reuniones con introducción animada.",
    salary: "11€/h",
    url: "https://hmcursos.com/en/guia-completa-funil/"
  },
  {
    id: "5f32c9ca423eaczgh8109400175bde3e",
    title: "Logo y manual de empresa de limpieza",
    description:
      "Quiero un logo y un manual de uso, ver que colores habría que emplear y crear una entidad de marca que sea realista, joven y con fuerza...",
    salary: "70€ trabajo puntual",
    url: "https://hmcursos.com/en/guia-completa-funil/"
  },
  {
    id: "5f32c9ca48109400dspolsk175bde3e",
    title: "Editor de vídeos",
    description:
      "Hola, soy creador de contenido en YouTube y estoy buscando una persona que me ayude con la edición de los videos. ",
    salary: "29€/h",
    url: "https://hmcursos.com/en/guia-completa-funil/"
  },
  {
    id: "5f32c9ca48109400asd332175bde3e",
    title: "Crear contenido",
    description:
      "Crear contenidos conforme a mi necesidad para mi página, redes, canal de YouTube y podcasts. Quiero crear blogs con 3 publicaciones semanales,",
    salary: "23€/h",
    url: "https://hmcursos.com/en/guia-completa-funil/"
  },
  {
    id: "5f32c9ca481q32eszc09400175bde3e",
    title: "Busco Experto En Seo",
    description:
      "Busco experto en seo para posicionar pagina web en mexico. Necesito posicionar 25-30 palabras clave.",
    salary: "230€ trabajo puntual",
    url: "https://hmcursos.com/en/guia-completa-funil/"
  },
  {
    id: "5f32c9ca483311vxzdva109400175bde3e",
    title: "Gestor de redes sociales",
    description:
      "Necesitamos una persona a cargo del manejo de redes sociales y creación de contenido para una empresa dedicada a la minería de criptomonedas.",
    salary: "44€/h",
    url: "https://hmcursos.com/en/guia-completa-funil/"
  },
  {
    id: "5f32c9ca481534vvv09400175bde3e",
    title: "Diseño de guion para canal de youtube",
    description:
      "Necesito una persona para la creación de video para canal automatizado en youtube. El canal va ir sobre el turismo, curiosidades de diferentes ciudades, 10 sitios que tienes que visitar en las diferentes ciudades que se muestren en el canal, etc.",
    salary: "38€/h",
    url: "https://hmcursos.com/en/guia-completa-funil/"
  },
  {
    id: "5f32c9ca48675sfsf109400175bde3e",
    title: "Traductor de subtítulos",
    description: "Traductor de subtítulos para series de Netflix y HBO.",
    salary: "77€/h",
    url: "https://hmcursos.com/en/guia-completa-funil/"
  },
  {
    id: "5f32c9ca48232fassf109400175bde3e",
    title: "Creador de e-mails de marketing",
    description:
      "Diseñar emails de email marketing apra estrategia de empresa de seguridad alemana.",
    salary: "113€/h",
    url: "https://hmcursos.com/en/guia-completa-funil/"
  }
];
