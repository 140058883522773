import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import Grid from "@material-ui/core/Grid";
//import AdsSolcredito from "../../../AdsSolcredito";
import AdsGoogle from "../../../AdsGoogle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Container from "@material-ui/core/Container";

import {
  whiteColor,
  blackColor,
  hexToRgb,
  successColor,
  grayColor,
} from "assets/jss/material-kit-pro-react.js";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  BotonVerCurso: {
    backgroundColor: blackColor,
    color: whiteColor,
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(blackColor) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",

    "&:hover,&:focus": {
      color: blackColor,
      backgroundColor: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)",
    },
  },
  Ads: {
    backgroundColor: theme.palette.primary.blanco,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  icon: {
    color: blackColor,
    marginRight: "10px",
    marginBottom: "-7px",
  },
  cerrar: {
    backgroundColor: blackColor,
    color: whiteColor,
    width: "100px",

    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(blackColor) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    "&:hover,&:focus": {
      color: blackColor,
      backgroundColor: whiteColor,

      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)",
    },
  },
  link: {
    color: whiteColor,
    backgroundColor: "transparent",
    "&:hover,&:focus": {
      color: whiteColor,
    },
  },
  contactar: {
    color: whiteColor,
    marginRight: "10px",
    backgroundColor: successColor[0],
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(blackColor) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    "&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: grayColor[5],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)",
    },
  },
  botones: {
    display: "flex",
    alignItems: "center",
    marginBottom: "100px",
  },
}));

export default function Modal({ oferta }) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        className={classes.BotonVerCurso}
      >
        Ver Oferta
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen="true"
        scroll="body"
      >
        <main>
          <Container>
            {/**ADSENSE ADS*/}
            <AdsGoogle />

            <br></br>
            <h4>{oferta.anunciante}</h4>
            <Typography gutterBottom variant="h5" component="h2">
              {oferta.titulo}
            </Typography>
            <h5>{oferta.localidad}</h5>

            <DialogContentText id="alert-dialog-description">
              {oferta.descripcion}
            </DialogContentText>

            <Grid container spacing={2}>
              <Grid item className={classes.botones}>
                <Button className={classes.contactar}>
                  <a className={classes.link} href={oferta.url}>
                    Enviar Solicitud
                  </a>
                </Button>
                <Button onClick={handleClose} className={classes.cerrar}>
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </Container>
        </main>
      </Dialog>
    </div>
  );
}
