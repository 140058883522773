import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";
import AdsGoogle from "../../../../AdsGoogle";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px"
  },
  contenedor: {
    fontSize: "17px"
  },
  titulo: {
    textAlign: "center"
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px"
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline"
    }
  }
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />

      <h4 className={classes2.top}>El empleo más demandado del mundo</h4>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              A continuación te voy a explicar cómo conseguir empleo de agente
              de ventas y cómo puedes llegar a ser un gran profesional en el
              sector.
            </p>
            <p>{<AdsGoogle />}</p>
            <p className={classes2.texto}>
              Actualmente se están ofreciendo numerosas ofertas de agente de
              ventas y te voy a contar como puedes acceder a una de estas
              ofertas de empleo.
            </p>

            <h3 className={classes2.titulo}>¿Qué es un agente de ventas?</h3>

            <p className={classes2.texto}>
              Un agente de ventas es alguien encargado en hacer llegar los
              productos y servicios a los diferentes usuarios aumentando así las
              ventas de las empresas.
            </p>

            <p className={classes2.texto}>
              De esta manera la empresa se aseguran de que sus servicios se
              difundan correctamente ofreciendo a los usuarios asesoramiento y
              seguimiento en las ofertas contratadas.
            </p>

            <h3 className={classes2.titulo}>
              ¿Dónde conseguir trabajo como agente de ventas?
            </h3>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              <strong>Infojobs:</strong>
            </p>

            <p className={classes2.texto}>
              Para poder buscar y apuntarte a ofertas de empleo como agente de
              ventas en esta web, deberás inscribirte si no lo estás hecho antes
              y rellenar todos tus datos personales así como tu currículo vitae.
            </p>

            <p className={classes2.texto}>
              Tras acceder con tu cuenta de usuario y contraseña, deberás pone
              en la barra principal de búsqueda el nombre de agente de ventas o
              comercial.
            </p>

            <p className={classes2.texto}>
              A continuación selecciona la provincia donde quieres trabajar como
              agente de ventas.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Te aparecerá un listado con todas las ofertas de empleo
              disponibles actualmente vigentes.
            </p>

            <p className={classes2.texto}>
              Puedes personalizar tu búsqueda ajustando los filtros que te
              aparecen en la columna de la izquierda. Estos filtros pueden ser:
              tipo de jornada, experiencia requerida, nivel formativo, rango
              salarial etc...
            </p>

            <p className={classes2.texto}>
              La búsqueda se reducirá según los criterios que hayas marcado.
            </p>

            <p className={classes2.texto}>
              Después, deberás elegir la oferta que te resulte interesante para
              poder ver más información acerca de ella.
            </p>

            <p className={classes2.texto}>
              Si finalmente te sigue interesando, deberás clicar en el botón de:
              Inscribirme a la oferta.
            </p>

            <p className={classes2.texto}>
              Te recomiendo que contestes a las preguntas que puedan indicare y
              adjuntes una carta de presentación donde muestres tus intereses
              para trabajar en la empresa.
            </p>
            <p>{<AdsGoogle block={2} />}</p>

            <p className={classes2.texto}>
              Una vez inscrito podrás ver cómo va tu oferta de trabajo en tu
              perfil personal, clicando en el apartado de: Ver candidaturas.
            </p>

            <p className={classes2.texto}>
              <strong>Indeed:</strong>
            </p>

            <p className={classes2.texto}>
              Esta página web es un buscador de ofertas de empleo en internet
              que agrupa todas las ofertas que existen, por lo que es útil
              si&nbsp; quieres hacer una gran búsqueda en toda la red.
            </p>

            <p className={classes2.texto}>
              Deberás entrar en la página web principal de Indeed a través de tu
              navegador.
            </p>

            <p className={classes2.texto}>
              Después deberás escribir la palabra: agente de ventas. en la
              casilla que aparece en blanco, donde nos dice ``Qué´´
            </p>

            <p className={classes2.texto}>
              A continuación escribe el lugar donde te gustaría encontrar
              trabajo como agente de ventas en la casilla donde aparece
              ``Donde´´.
            </p>

            <p className={classes2.texto}>
              Tras darle clic al botón: Buscar. Te aparecerá un listado con
              todas las ofertas de trabajo disponibles.
            </p>

            <p className={classes2.texto}>
              Si lo deseas puedes personalizar la búsqueda clicando en el botón
              de la columna lateral izquierda donde pone: más opciones:
              Integración laboral.
            </p>

            <p className={classes2.texto}>
              En estas opciones podrás personalizar las ofertas por jornada
              laboral, distancia o idioma de la oferta.
            </p>

            <p className={classes2.texto}>
              Tras ajustar los parámetros de la búsqueda verás el listado con
              las ofertas de empleo.
            </p>

            <p className={classes2.texto}>
              Deberás seleccionar la oferta que te resulte de interés para poder
              acceder y ver más detalles.
            </p>

            <p className={classes2.texto}>
              Si tras leer las características de la oferta, crees que encaja
              con lo que estás buscando deberás pinchar sobre el botón:
              Solicitar.
            </p>

            <p className={classes2.texto}>
              A continuación, escribe tu nombre y apellidos así como un
              correo,un teléfono de contacto y adjunta tu currículo.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Es muy recomendable adjuntar una carta de presentación
              personalizada a la oferta de empleo de la cual vas a postularte.
              Esto, hará que la empresa note que tu interés es importante y
              ganarás puntos a la hora de llamarte para concertar una
              entrevista.
            </p>

            <h3 className={classes2.titulo}>
              ¿Cómo hacer un currículo de agente de ventas?
            </h3>

            <p className={classes2.texto}>
              Te voy a enseñar cuales son las claves para hacer un buen
              currículo como agente de ventas:
            </p>

            <p className={classes2.texto}>Usa la fotografía adecuada:</p>

            <p className={classes2.texto}>
              Debes adjuntar una fotografía con fondo blando donde se muestre tu
              rostro de forma clara sin ningún tipo de objeto que capte la
              atención.
            </p>

            <p className={classes2.texto}>Formación:</p>

            <p className={classes2.texto}>
              Si tienes cursos relacionados con técnicas de ventas o de carácter
              empresarial es importante que los indiques de forma clara. Explica
              en que consistieron estos cursos y donde accediste a su formación.
            </p>

            <p className={classes2.texto}>Experiencia:</p>

            <p className={classes2.texto}>
              Muchas empresas piden cierta experiencia para poder acceder a
              estos puestos de trabajo. Si cuentas con experiencia en el sector,
              explica para qué empresas trabajaste, qué tipo de producto o
              servicios ofrecías y cuáles eran tus funciones en relación al
              puesto de trabajo.
            </p>

            <p className={classes2.texto}>Aptitudes:</p>

            <p className={classes2.texto}>
              Debes indicar que cuentas con las aptitudes necesarias para saber
              desenvolverte en una llamada telefónica y hacer que el producto o
              servicio se venda. Unos adjetivos claves pueden ser: Empatía por
              el cliente, poder de persuasión o pro actividad.
            </p>

            <p className={classes2.texto}>Otros datos:</p>

            <p className={classes2.texto}>
              Algunos de estos puestos de trabajo pueden pedir manejar ciertos
              programas informáticos o dominar un determinado idioma. Por eso,
              es importante que si cuentas con alguna titulación que pueda
              aportarte ventajas a la hora de conseguir un trabajo como agente
              de ventas, la plasmes en tu currículo.
            </p>

            <p className={classes2.texto}>Diseño:</p>

            <p className={classes2.texto}>
              Un agente de ventas debe ser una persona dinámica y versátil. Por
              ello, elije una buena plantilla de curriulum que hable de ti con
              tan solo mirarlo.
            </p>

            <p className={classes2.texto}>
              Posibles preguntas en una entrevista de trabajo
            </p>

            <p className={classes2.texto}>
              Te voy a enseñar cuáles son las principales preguntas que suelen
              realizar a los agentes de ventas en una entrevista. Es importante
              saber contestar adecuadamente para poder hacernos con el puesto
              que queremos.
            </p>

            <p className={classes2.texto}>
              Ante una queja de un cliente ¿Cómo actuarías?:
            </p>

            <p className={classes2.texto}>
              En este tipo de preguntas se espera que muestres una empatía total
              hacia el cliente, por lo que una buena respuesta seria: ‘Ante todo
              intentaría comprender al cliente y buscar una solución a su
              problema que le hiciera está conforme´´
            </p>

            <p className={classes2.texto}>
              ¿Qué te gusta más a la hora de trabajar como agente de ventas:
            </p>

            <p className={classes2.texto}>
              Una buena respuesta podría ser ‘Me gusta el trato con la gente,
              disfruto atendiéndoles y aconsejando servicios que pueden
              facilitar sus vidas´´
            </p>

            <p className={classes2.texto}>Intenta venderme un servicio.</p>

            <p className={classes2.texto}>
              Algunas empresas quieren una demostración de cómo le venderías a
              un cliente el producto o servicio que presten, para ello, es útil
              informarse de que tipos de servicios ofrece la empresa en cuestión
              y como puedes hacer un buen discurso para vender el producto
              persuadiendo al cliente de la forma adecuada.
            </p>

            <h3 className={classes2.titulo}>
              ¿Cómo ser un buen vendedor por teléfono?
            </h3>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>
              Te voy a enseñar cuales son las claves para convertirte en un buen
              vendedor por teléfono, de forma que puedas aumentar tus ventas y
              con ello los posibles incentivos:
            </p>

            <p className={classes2.texto}>Captar la atención: </p>

            <p className={classes2.texto}>
              Si quieres captar la atención de un cliente debes comenzar
              haciendo preguntas que le generen asombro desde el principio de la
              conversación telefónica. Esto hará que muestre interés por la
              oferta que le ofreces y no te de `un colgazo´ demasiado pronto.
            </p>

            <p className={classes2.texto}>Evita monólogos: </p>

            <p className={classes2.texto}>
              Debes intentar interactuar con el cliente y que este colabore en
              la conversación, para eso es útil hacer constantemente preguntas
              que hagan pensar al cliente y de esta manera se implique de forma
              activa.
            </p>

            <p className={classes2.texto}>Conoce el producto: </p>

            <p className={classes2.texto}>
              Tienes que conocer profundamente el producto o servicio que estas
              vendiendo. De esta manera tienes que estar preparado para
              contestar a cualquier tipo de pregunta que te haga un posible
              cliente.
            </p>

            <p className={classes2.texto}>Tono de voz:</p>

            <p className={classes2.texto}>
              Usa un tono de voz que genere confianza en el consumidor y le
              aporte tranquilidad a la hora de comprar o contratar los servicios
              prestados. Si nota una voz insegura o titubeante ,esto puede
              hacerle pensar que se trata de una estafa o que va a salir
              perdiendo.
            </p>
            <p>{<AdsGoogle block={2} />}</p>
            <p className={classes2.texto}>Debes prepárate para las críticas:</p>

            <p className={classes2.texto}>
               Si quieres ser un buen agente debes estar preparado para asumir
              críticas por teléfono de forma que no pierdas la calma en ningún
              momento y sepas empatizar con el cliente y buscar una buena
              solución.
            </p>

            <p className={classes2.texto}>Planifícate:</p>

            <p className={classes2.texto}>
              Es importante que tengas una agenda para apuntar las posibles
              llamadas que debas realizar y organizarte de forma ordenada. De
              esta manera sabrás a quien tienes que llamar cada día o hacer un
              seguimiento de posibles clientes interesados.
            </p>

            <p className={classes2.texto}>Fórmate:</p>

            <p className={classes2.texto}>
              Es importante que te sigas formando aunque ya tengas el puesto de
              trabajo. En internet existen numerosos tutoriales que ayudaran su
              saber cómo aplicar las técnicas de ventas a tus clientes para
              conseguir mayores beneficios.
            </p>

            <p className={classes2.texto}>Sé honesto</p>

            <p className={classes2.texto}>
              La profesión de agente de ventas no cuenta con muy buena
              reputación entre los usuarios ,por lo que la honestidad es un
              punto que van a valorar más que cualquier otra cosa. No intentes
              vender a toda costa.
            </p>

            <p className={classes2.texto}>
              Debes ayudar a tu cliente a que los servicios que contrate sean
              los mejores para sus necesidades.
            </p>

            <p className={classes2.texto}>Escucha:</p>

            <p className={classes2.texto}>
              El cliente necesita ser escuchado y que le des una solución a sus
              problemas. Escucha sus dudas y objetivos y proponle las mejores
              alternativas.
            </p>

            <p className={classes2.texto}>Haz un seguimiento correcto:</p>

            <p className={classes2.texto}>
              No quiero decir con esto que hagas continuas llamadas al posible
              cliente para ver si finalmente va a contratar el producto o
              servicios que ofreces. Un correcto seguimiento exige dedicación y
              paciencia, por lo que deberás tener esto en cuenta si no quieres
              que el usuario acabe presionado y termine por decidirse por la
              competencia.
            </p>

            <p className={classes2.texto}>Habla con propiedad</p>

            <p className={classes2.texto}>
              Para ser un buen agente de ventas, debes saber hablar de forma
              técnica y profesional, mostrando en todo momento que conoces el
              tema en profundidad y que realmente vas a poder ayudarle a
              decidirse por lo mejor. Si el cliente advierte que hablas de forma
              demasiado coloquial, puede pensar que no saber mucho del tema y no
              arriesgarse a contratar el servicio o producto que vendes.
            </p>

            <p className={classes2.texto}>Infórmate</p>

            <p className={classes2.texto}>
              Si quieres conseguir que tus ventas aumenten debes infórmate de
              los productos o servicios que ofrecen ofertas similares a las
              tuyas e intentar convencer al usuario de porque tus servicios son
              la mejor opción.
            </p>

            <p className={classes2.texto}>Post-Venta</p>

            <p className={classes2.texto}>
              Si realmente quieres sr un buen agente de ventas debes preocuparte
              de que los clientes que hayan contratado o comprado tus productos
              están satisfechos con su compra. El boca a boca va a ayudar a que
              tus servicios sean bien valorados, por lo que un cliente
              satisfecho puede hacerte ganar más dinero a través de sus
              contactos.
            </p>
            <p>{<AdsGoogle block={2} />}</p>

            <p className={classes2.texto}>
              Te he enseñado como puedes conseguir empleo como agente de ventas
              y convertirte en el mejor empleado. Si te apasiona el mundo de las
              ventas toma nota de estos consejos para asegurarte un futuro
              óptimo.
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
