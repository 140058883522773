/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";

import ViewDay from "@material-ui/icons/ViewDay";

import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import BlurLinearIcon from "@material-ui/icons/BlurLinear";
import InfoIcon from "@material-ui/icons/Info";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import BallotIcon from "@material-ui/icons/Ballot";

import Layers from "@material-ui/icons/Layers";
import WorkIcon from "@material-ui/icons/Work";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const { dropdownHoverColor } = props;
  const classes = useStyles();
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Menú"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={ViewDay}
          dropdownList={[
            <Link to="/" className={classes.dropdownLink}>
              <BlurLinearIcon className={classes.dropdownIcons} /> Inicio
            </Link>,
            <Link to="/buscar-ofertas" className={classes.dropdownLink}>
              <ViewCarouselIcon className={classes.dropdownIcons} /> Buscar
              Empleo
            </Link>,

            <Link
              to="/empresas-ofertas-empleo"
              className={classes.dropdownLink}
            >
              <WorkIcon className={classes.dropdownIcons} />
              Empresas con Ofertas
            </Link>,
            <Link to="/contactar" className={classes.dropdownLink}>
              <ContactMailIcon className={classes.dropdownIcons} /> Contactar
            </Link>,
            <Link
              to="/politicas-de-privacidad"
              className={classes.dropdownLink}
            >
              <InfoIcon className={classes.dropdownIcons} /> Políticas de
              Privacidad
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Cursos"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={Apps}
          dropdownList={[
            <a
              href="https://hmservicios.com/educacion/inem"
              className={classes.dropdownLink}
            >
              <BallotIcon className={classes.dropdownIcons} />
              Cursos del INEM
            </a>,
            <a
              href="https://hmnoticias.com/cursos"
              className={classes.dropdownLink}
            >
              <Layers className={classes.dropdownIcons} />
              Todos los cursos
            </a>,
          ]}
        />
      </ListItem>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary",
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};
