import React from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Room from "@material-ui/icons/Room";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { whiteColor, blackColor, hexToRgb, headerColor } from "./colores";

import Galera from "./Galera";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: "auto",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1,

    border: 1
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.blanco
  },
  footer: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(6),
    color: theme.palette.primary.blanco
  },
  fundoContenedorGrids: {
    backgroundColor: theme.palette.primary.main
  },
  topi: {
    backgroundColor: theme.palette.text.primary
  },
  ColorMenu: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.main
  },
  letras: {
    color: theme.palette.primary.blanco,
    fontSize: "120%"
  },

  ColorAviso: {
    backgroundColor: theme.palette.primary.aviso,
    padding: "1px",
    fontSize: "120%"
  },
  Ads: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  BotonVerAhora: {
    marginRight: "10px",
    marginTop: "3px",
    backgroundColor: headerColor[0],
    borderColor: headerColor[0],
    color: whiteColor,
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb("#8AC3E1") +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb("#8AC3E1") +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb("#8AC3E1") +
      ", 0.2)",

    "&:hover,&:focus": {
      color: blackColor,
      backgroundColor: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb("#8AC3E1") +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb("#8AC3E1") +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb("#8AC3E1") +
        ", 0.2)"
    }
  },
  companyLogo: {
    height: 80,
    width: "auto",
    margin: "0 auto",
    display: "block"
  }
}));

function CardFull({ job }) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <a href={job.url} title={job.company}>
          <img
            src={job.logo}
            alt={job.company}
            title={job.company}
            className={classes.companyLogo}
          />
        </a>
        <Typography
          variant="h5"
          component="div"
          style={{ marginBottom: "10px" }}
        >
          {job.jobtitle}
        </Typography>
        <Typography>{`${job.description.substring(0, 100) +
          "..."}`}</Typography>

        {job.city !== "null" && (
          <div style={{ display: "flex", marginTop: "10px" }}>
            <Room />
            <p>{job.city}</p>
          </div>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
            marginTop: "10px"
          }}
        >
          <Button
            variant="contained"
            href={job.url}
            className={classes.BotonVerAhora}
          >
            Ver Más
          </Button>

          <Galera />
        </div>
      </CardContent>
      <div
        style={{
          padding: "2px",
          width: "auto",
          height: "25px",
          backgroundColor: "#FCE057",
          textAlign: "center",
          alignContent: "center"
        }}
      >
        <span style={{ color: "black", fontWeight: "bold" }}>
          {moment(job.date).format("DD/MM/YYYY")}
        </span>
      </div>
    </Card>
  );
}

export default CardFull;
