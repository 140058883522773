import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { makeStyles } from "@material-ui/core/styles";
import { whiteColor, blackColor, grayColor } from "./colores";

const ITEM_HEIGHT = 48;

const useStyles = makeStyles((theme) => ({
  links: {
    color: blackColor,
    textDecoration: "none",
    "&:hover": {
      color: grayColor[0],
    },
  },
  icon: {
    color: whiteColor,
    "&:hover": {
      color: whiteColor,
    },
  },
}));

export default function LongMenu() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon className={classes.icon} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "40ch",
          },
        }}
      >
        <MenuItem key="inicio" onClick={handleClose}>
          <a
            href="https://hmservicios.com/"
            className={classes.links}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Inicio
          </a>
        </MenuItem>

        <MenuItem key="vacantes" onClick={handleClose}>
          <a
            href="https://hmservicios.com/vacantes/"
            className={classes.links}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Ofertas de empleo
          </a>
        </MenuItem>

        <MenuItem key="sobrenosotros" onClick={handleClose}>
          <a
            href="https://hmservicios.com/aviso-legal"
            className={classes.links}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Sobre nosotros
          </a>
        </MenuItem>

        <MenuItem key="politicas" onClick={handleClose}>
          <a
            href="https://hmservicios.com/politicas-de-privacidad"
            className={classes.links}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Políticas de Privacidad
          </a>
        </MenuItem>
      </Menu>
    </div>
  );
}
