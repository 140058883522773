import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginLeft: "33px",
    marginBottom: "-20px",
  },
  formControl: {
    margin: "5px",
  },
  checkedIcon: {
    backgroundColor: "transparent",

    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
  },
}));

export default function Picker({ setCategory }) {
  const classes = useStyles();

  const [selected, setSelected] = React.useState({
    subastas: false,
    empleo: false,
    cursos: false,
    otros: true,
  });
  const { subastas, empleo, cursos, otros } = selected;
  const handleChange = (event) => {
    setCategory(event.target.name);
    setSelected({ [event.target.name]: true });
  };

  return (
    <>
      <p style={{ marginLeft: "15px", padding: "15px", fontSize: "20px" }}>
        Categoría
      </p>
      <div className={classes.root}>
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              className={classes.checkedIcon}
              checked={subastas}
              onChange={handleChange}
              name="subastas"
            />
          }
          label="Subastas"
        />
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={empleo}
              onChange={handleChange}
              name="empleo"
              className={classes.checkedIcon}
            />
          }
          label="Empleo"
        />
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={cursos}
              onChange={handleChange}
              name="cursos"
              className={classes.checkedIcon}
            />
          }
          label="Cursos"
        />
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={otros}
              onChange={handleChange}
              name="otros"
              className={classes.checkedIcon}
            />
          }
          label="Otros"
        />
      </div>
    </>
  );
}
