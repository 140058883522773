/*eslint-disable*/ import React, { useContext } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import TouchApp from "@material-ui/icons/TouchApp";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import ProvinciaSelect from "../empleoComponentes/ProviciaSelect";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";

// Sections for this page
import SectionProduct from "./Sections/SectionProduct.js";

import Context from "../../Context/Context";
import { useHistory } from "react-router-dom";
import {
  headerColor,
  whiteColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(landingPageStyle);
const useStyles2 = makeStyles({
  verOfertas: {
    backgroundColor: "success",
    color: whiteColor,
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(whiteColor) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(whiteColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(whiteColor) +
      ", 0.2)",

    "&:hover,&:focus": {
      color: headerColor[0],
      backgroundColor: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(headerColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(headerColor[0]) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(headerColor[0]) +
        ", 0.2)",
    },
  },
});

export default function LandingPage({ ...rest }) {
  const classes = useStyles();
  const classes2 = useStyles2();
  const MeuContext = useContext(Context);
  const { provincia } = MeuContext;
  const history = useHistory();

  const listar = () => {
    history.push("/vacantes");
  };

  return (
    <div>
      <Header
        color="transparent"
        brand="HM Servicios"
        links={<HeaderLinks dropdownHoverColor="header" />}
        fixed
        changeColorOnScroll={{
          height: 70,
          color: "header",
        }}
        {...rest}
      />
      <Parallax image={require("assets/img/portada.jpg")} filter="dark">
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <h1 className={classes.title}>Empleo en España</h1>
              <h4>
                Cada día se publican en España miles de ofertas de empleo, aquí
                encontrarás cada una de ellas de forma gratuita y sin tener que
                registrarte. Todo directo al punto.
              </h4>
              <br />

              <Button
                className={classes2.verOfertas}
                color="success"
                size="lg"
                onClick={() => listar()}
              >
                <TouchApp />
                Ver Ofertas
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionProduct />
          <br />
          <br />
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a href="/politicas-de-privacidad" className={classes.block}>
                    Políticas de Privacidad
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/aviso-legal" className={classes.block}>
                    Aviso Legal
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/" className={classes.block}>
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/derechos" className={classes.block}>
                    Derechos
                  </a>
                </ListItem>
              </List>
            </div>
          </div>
        }
      />
    </div>
  );
}
