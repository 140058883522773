import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Room from "@material-ui/icons/Room";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import Grid from "@material-ui/core/Grid";

import Ads from "../../AdsGoogle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Container from "@material-ui/core/Container";

import {
  whiteColor,
  blackColor,
  hexToRgb,
  successColor,
  grayColor,
  headerColor
} from "./colores";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  BotonVerjob: {
    backgroundColor: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.light,
    color: "white"
  },
  botones: {
    display: "flex",
    alignItems: "center",
    marginBottom: "100px"
  },
  Ads: {
    backgroundColor: theme.palette.primary.blanco,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  icon: {
    color: blackColor,
    marginRight: "10px",
    marginBottom: "-7px"
  },
  cerrar: {
    backgroundColor: blackColor,
    color: whiteColor,
    width: "100px",
    marginBottom: "5%",
    marginTop: "15px",
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(blackColor) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    "&:hover,&:focus": {
      color: blackColor,
      backgroundColor: whiteColor,

      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
    }
  },
  contactar: {
    color: whiteColor,
    marginRight: "10px",
    backgroundColor: successColor[0],
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(blackColor) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    "&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: grayColor[5],
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
    }
  },
  link: {
    color: whiteColor,
    backgroundColor: "transparent",
    "&:hover,&:focus": {
      color: whiteColor
    }
  },
  BotonVerAhora: {
    marginRight: "10px",
    marginTop: "3px",
    backgroundColor: headerColor[0],
    borderColor: headerColor[0],
    color: whiteColor,
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb("#8AC3E1") +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb("#8AC3E1") +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb("#8AC3E1") +
      ", 0.2)",

    "&:hover,&:focus": {
      color: blackColor,
      backgroundColor: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb("#8AC3E1") +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb("#8AC3E1") +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb("#8AC3E1") +
        ", 0.2)"
    }
  }
}));

export default function Modal({ job }) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        className={classes.BotonVerAhora}
      >
        Ver Más
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={true}
        scroll="body"
      >
        <main>
          <Container>
            <br></br>
            <Typography align="center" className={classes.Ads}>
              <Ads />
            </Typography>
            <br></br>
            <Typography gutterBottom variant="h5" component="h2">
              {`${job.titulo}`}
            </Typography>

            <DialogContentText id="alert-dialog-description">
              {job.descripcion}
            </DialogContentText>

            <div style={{ display: "flex", marginTop: "10px" }}>
              <Room style={{ marginRight: "10px" }} />
              <p> {job.localidad}</p>
            </div>
            {job.salary && (
              <div style={{ display: "flex", marginTop: "10px" }}>
                <AccountBalanceWalletIcon style={{ marginRight: "10px" }} />
                <p> {job.salario}</p>
              </div>
            )}

            <Grid container spacing={2}>
              <Grid item className={classes.botones}>
                <a href={`${job.url}`} style={{ textDecoration: "none" }}>
                  <Button className={classes.BotonVerAhora}>
                    Enviar Solicitud
                  </Button>
                </a>

                <Button onClick={handleClose} className={classes.cerrar}>
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </Container>
        </main>
      </Dialog>
    </div>
  );
}
