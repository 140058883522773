import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { successColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px",
    backgroundColor: successColor[1],
  },
}));

export default function Validador() {
  const classes = useStyles();

  return (
    <SnackbarContent
      className={classes.root}
      message="¡Oferta guardada correctamente!"
    />
  );
}
