/*eslint-disable*/
import React, { useContext, useState } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select3 from "../empleoComponentes/Select3";

// @material-ui/icons

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PhoneIcon from "@material-ui/icons/Phone";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import TodayIcon from "@material-ui/icons/Today";
import DescriptionIcon from "@material-ui/icons/Description";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import LockIcon from "@material-ui/icons/Lock";
import RoomIcon from "@material-ui/icons/Room";
import Validar from "../empleoComponentes/Validador";
import Enviado from "../empleoComponentes/Enviado";
// core components

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";

import image from "assets/img/portada.jpg";
import {
  headerColor,
  whiteColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { dangerColor } from "assets/jss/material-kit-pro-react";
import Context from "../../Context/Context";
const useStyles2 = makeStyles({
  header: {
    backgroundColor: headerColor,
  },
  dato: {
    color: dangerColor,
    "&:focus": {
      color: blackColor,
    },
  },
  enviar: {
    backgroundColor: blackColor,
    color: whiteColor,
    width: "150px",
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(blackColor) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",

    "&:hover,&:focus": {
      color: blackColor,
      backgroundColor: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)",
    },
  },
});

const useStyles = makeStyles(loginPageStyle);

export default function SubirOferta() {
  const classes = useStyles();
  const classes2 = useStyles2();
  const MeuContext = useContext(Context);
  const { subir, setSubir, pass, setPass, provinciaSelect } = MeuContext;
  const [error, setError] = useState(false);
  const [acierto, setAcierto] = useState(false);

  const crearOferta = async (oferta) => {
    const url = `https://apiempleoslista.ew.r.appspot.com/api/${provinciaSelect}`;

    const solve = await axios.post(url, oferta);
    console.log(solve.data);
  };

  const enviar = (e) => {
    e.preventDefault();
    //validamos vacio y contraseña
    if (
      subir.anunciante !== "" &&
      subir.localidad !== "" &&
      subir.telefono !== "" &&
      subir.titulo !== "" &&
      subir.fecha !== "" &&
      subir.descripcion !== "" &&
      subir.precio !== ""
    ) {
      if (pass === "1964") {
        setError(false);

        //CREAR OFERTA

        crearOferta(subir);
        setAcierto(true);
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }

    //enviamos a la base
  };
  return (
    <div>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <form className={classes.form} onSubmit={enviar}>
                  <CardHeader
                    color="secondary"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>Oferta de Empleo</h4>
                  </CardHeader>
                  <p className={classes.description + " " + classes.textCenter}>
                    Usa el formulario abajo para subir una oferta de empleo
                  </p>
                  <CardBody signup>
                    <CustomInput
                      name="anunciante"
                      white
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) =>
                          setSubir({ ...subir, anunciante: e.target.value }),
                        placeholder: "Nombre del anunciante...",

                        type: "text",

                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon
                              className={classes.inputIconsColor}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Select3 />
                    <CustomInput
                      name="localidad"
                      white
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) =>
                          setSubir({ ...subir, localidad: e.target.value }),
                        placeholder: "Localidad...",

                        type: "text",

                        startAdornment: (
                          <InputAdornment position="start">
                            <RoomIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <CustomInput
                      name="telefono"
                      white
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Teléfono de contacto...",
                        onChange: (e) =>
                          setSubir({ ...subir, telefono: e.target.value }),
                        type: "text",
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      name="titulo"
                      white
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Título de la oferta",
                        onChange: (e) =>
                          setSubir({ ...subir, titulo: e.target.value }),
                        type: "text",
                        startAdornment: (
                          <InputAdornment position="start">
                            <LabelImportantIcon
                              className={classes.inputIconsColor}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      name="fecha"
                      white
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Fecha de publicación...",
                        onChange: (e) =>
                          setSubir({ ...subir, fecha: e.target.value }),
                        type: "text",
                        startAdornment: (
                          <InputAdornment position="start">
                            <TodayIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      name="descripcion"
                      white
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Descripción...",
                        onChange: (e) =>
                          setSubir({ ...subir, descripcion: e.target.value }),
                        type: "text",
                        startAdornment: (
                          <InputAdornment position="start">
                            <DescriptionIcon
                              className={classes.inputIconsColor}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      name="precio"
                      white
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Salario o sueldo...",
                        onChange: (e) =>
                          setSubir({ ...subir, precio: e.target.value }),
                        type: "text",
                        startAdornment: (
                          <InputAdornment position="start">
                            <MonetizationOnIcon
                              className={classes.inputIconsColor}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      name="contrasena"
                      white
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Contraseña",
                        onChange: (e) => setPass(e.target.value),
                        type: "password",
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </CardBody>

                  <div className={classes.textCenter}>
                    {error ? <Validar /> : null}
                    {acierto ? <Enviado /> : null}
                    <Button type="submit" className={classes2.enviar}>
                      Enviar
                    </Button>
                  </div>
                </form>
                <br />
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
