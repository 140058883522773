import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h4 className={classes2.top}>¿Diésel o Gasolina?</h4>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              Hoy vas a saber cuál es la opción más barata de las dos, con datos
              y testimonios de expertos.
            </p>

            <p className={classes2.texto}>
              La guerra comercial entre los combustibles está en su pleno
              esplendor, a continuación te explicamos que coche de segunda mano
              es más barato de mantener, un diesel o un gasolina.
            </p>

            <p className={classes2.texto}>
              La primera duda que te surge al comprar un coche de segunda mano
              es el tipo de combustible.{" "}
            </p>

            <p className={classes2.texto}>
              Los diesel son más robustos y duraderos, mientras que los coches
              gasolina suelen tener más potencia y aceleración.&nbsp;
            </p>

            <p className={classes2.texto}>
              Un factor muy importante a tener en cuenta es el número de
              kilómetros que vamos a realizar anualmente, principalmente se
              establece la cifra de 20 mil km anuales.
            </p>

            <p className={classes2.texto}>
              &nbsp;Al sobrepasar esa cifra anualmente con nuestro coche de
              segunda mano durante al menos de 8 años, merecerá la pena comprar
              un coche diesel en lugar de uno gasolina&nbsp;
            </p>

            <h3 className={classes2.titulo}>Precio del combustible</h3>

            <p className={classes2.texto}>
              {" "}
              La tendencia a igualar los precios entre la gasolina 95 y el
              diesel + es un hecho, debido a las fluctuaciones entre los
              mercados y a las necesidades de consumo de los países.&nbsp; Los
              consumos de los coches diesel y gasolina tienden a igualarse a su
              vez, mejorando la eficiencia de los motores.
            </p>

            <p className={classes2.texto}>
              &nbsp;El precio de combustible para nuestro coche de segunda mano
              es un factor que nos preocupa, actualmente el precio de la
              gasolina está por encima del precio del diesel.{" "}
            </p>

            <p className={classes2.texto}>
              Según la tendencia de los mercados y las medidas cada vez más
              estrictas de los países sobre la contaminación,&nbsp; la tendencia
              sugiere, que el precio de la gasolina se sitúe en valores más
              bajos que los del diesel.&nbsp;
            </p>

            <h3 className={classes2.titulo}>Mantenimiento</h3>

            <p className={classes2.texto}>
              El&nbsp; motor es algo muy importante en nuestro coche de segunda
              mano, un motor de gran cilindrada hará que consumamos más tanto si
              es diesel o gasolina. El tipo de motor y su cilindrada es un
              factor muy a tener en cuenta a la hora de comprar un coche de
              segunda mano.
            </p>

            <p className={classes2.texto}>
              La diferencia de mantenimiento entre los motores gasolina y diesel
              es muy notable.&nbsp; Por lo general el coche gasolina es mucho
              más barato de mantener que uno diesel.
            </p>

            <p className={classes2.texto}>
              Entre los mantenimiento destacados que se le han de hacer a un
              coche de segunda mano diesel son la limpieza de los filtros
              antipartículas y válvula EGR, en el caso de coches de segunda mano
              gasolina carece de ellos, por lo que no es necesario.
            </p>

            <p className={classes2.texto}>
              En el caso los calentadores en un coche de segunda mano diesel,
              son elementos más sensibles al frío y al desgaste.
            </p>

            <p className={classes2.texto}>
              El coche gasolina carece de ellos, sustituidos por bujías, las
              cuales son más fiables y necesitan menos mantenimiento que en los
              coches de segunda mano con motor diesel.
            </p>

            <p className={classes2.texto}>
              El embrague en los coches gasolina no dura tanto como en los
              diesel, debido a que las transmisiones de los motores de segunda
              mano diesel incorporan un embrague bimasa el cual es mucho más
              robusto.
            </p>

            <p className={classes2.texto}>
              Como conclusión en cuanto al mantenimiento de un coche diesel
              respecto de uno gasolina podemos llegar a las siguientes
              conclusiones:
            </p>

            <p className={classes2.texto}>
              Los motores diesel de segunda mano tienen un mantenimiento más
              caro que los de gasolina pero su fiabilidad y kilometraje puede
              más alto que los de motor gasolina.
            </p>

            <p className={classes2.texto}>
              &nbsp;A la hora de elegir un coche de segunda mano para comprar,
              deberemos analizar si el coche a comprar tiene un número muy
              elevado de kilómetros, descartando este si tiene más de 200 mil km
              y su motor es gasolina. &nbsp;
            </p>

            <h3 className={classes2.titulo}>Gastos en ITV</h3>

            <p className={classes2.texto}>
              En cuando a los gastos obligatorios en nuestro coche de segunda
              mano, uno de ellos a tener en cuenta es el precio de la ITV, que
              anualmente deberemos de pasar.
            </p>

            <p className={classes2.texto}>
              {" "}
              El precio de la ITV anual de un coche de segunda mano diesel ronda
              los 50€ mientras que de uno gasolina los 35€, por lo que será más
              barato el mantenimiento en este aspecto el de gasolina.
            </p>

            <h3 className={classes2.titulo}>Precio de Compra</h3>

            <p className={classes2.texto}>
              Los precios de los coches de segunda mano varían mucho en función
              si el combustible utilizado es diesel o gasolina.{" "}
            </p>

            <p className={classes2.texto}>
              Los coches de segunda mano gasolina, son mucho más baratos que los
              diesel, esto es debido principalmente a que el motor diesel es
              robusto y su durabilidad más alta. &nbsp;
            </p>

            <p className={classes2.texto}>
              En cuanto al precio de compra de un coche de segunda mano
              deberemos analizar los siguientes aspectos dependiendo si el
              combustible es diesel o gasolina.
            </p>

            <p className={classes2.texto}>
              Los coches de segunda mano con diesel pueden tener hasta una vida
              de 400 mil Km, mientras que el de gasolina de unos 200 mil,
              siempre y cuando su mantenimiento se haya llevado
              adecuadamente.&nbsp;
            </p>

            <h3 className={classes2.titulo}>Consumo</h3>

            <p className={classes2.texto}>
              Actualmente el desarrollo de los motores de coches de segunda mano
              diesel se ha implementado a un 98% de su rendimiento, mientras que
              el motor de gasolina solamente al 40%.{" "}
            </p>

            <p className={classes2.texto}>
              Debido al desarrollo durante tantos años del motor diesel.
            </p>

            <p className={classes2.texto}>
              En el presente, se está trabajando activamente en la eficiencia
              energética de los motores gasolina y por lo tanto en un futuro no
              muy lejano, los motores gasolina gastaran mucho menos que los
              diesel.
            </p>

            <p className={classes2.texto}>
              A la hora de elegir un coche de segunda mano gasolina que tenga un
              consumo elevado, siempre se puede barajar su conversión a GLP (Gas
              licuado del Petróleo), con el que conseguiremos en un coche de
              segunda mano, consumos inferiores a los del diesel.
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
