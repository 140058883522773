import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-pro-react.scss?v=1.8.0";
// pages for this product

import LandingPage from "views/LandingPage/LandingPage.js";
import Jobs from "views/Jobs/Jobs.js";
import JobsTalent from "views/JobsTalent/JobsTalent.js";
import JobsTalentCanada from "views/JobsTalentCanada/JobsTalentCanada.js";
import JobsTalentAlemanha from "views/JobsTalentAlemanha/JobsTalentAlemanha.js";
import RemoteJobs from "views/RemoteJobs/RemoteJobs.js";
import RemoteJobsWelcome from "views/RemoteJobsWelcome/RemoteJobsWelcome";

import Politicas from "views/Politicas/Politicas.js";
import AvisoLegal from "views/AvisoLegal/AvisoLegal.js";
import diezPaginas from "views/Articulos/diezPaginas/diezPaginas.js";
import PreguntasTrampa from "views/Articulos/PreguntasTrampa/PreguntasTrampa.js";
import ArticlesFooter from "views/ArticlesFooter/ArticlesFooter.js";
import EmpresasOfertas from "views/EmpresasOfertas/EmpresasOfertas.js";
import State from "./Context/State";
import Paro from "views/Articulos/Paro/Paro";
import EmpleoEuropa from "./views/Articulos/EmpleoEuropa/EmpleoEuropa";
import Agente from "views/Articulos/Agente/Agente";
import Empleo30anos from "./views/Articulos/Empleos30anos/Empleos30anos";
import CursosGoogle from "views/Articulos/CursosGoogle/CursosGoogle";
import MasOfertas from "views/Articulos/MasOfertas/MasOfertas";
import DieselVSGasolina from "views/Articulos/DieselVSGasolina/DieselVSGasolina";
import ConsejosCurriculum from "views/Articulos/ConsejosCurriculum/ConsejosCurriculum";
import Sae from "views/Articulos/Sae/Sae";

import Colocacion from "views/Articulos/Colocacion/Colocacion";
import Virtual from "views/Articulos/Virtual/Virtual";
import Ayuda from "views/Articulos/Ayuda/Ayuda";
import Coche from "views/Articulos/Coche/Coche";
import Plantilla from "views/Articulos/Plantilla/Plantilla";
import Gasolina from "views/Articulos/Gasolina/Gasolina";
import Dejaste from "views/Articulos/Dejaste/Dejaste";
import Alcampo from "views/Articulos/Alcampo/Alcampo";
import NoExperiencia from "views/Articulos/NoExperiencia/NoExperiencia";
import Cantabria from "views/Articulos/Cantabria/Cantabria";
import MediaJornada from "views/Articulos/MediaJornada/MediaJornada";
import Ere from "views/Articulos/Ere/Ere";
import VidaLaboral from "views/Articulos/VidaLaboral/VidaLaboral";
import Derechos from "views/Derechos/Derechos";
import SubirOferta from "views/SubirOferta/SubirOferta";
import Contactar from "views/Contactar/Contactar";
import AhorrarDinero from "views/Articulos/AhorrarDinero/AhorrarDinero";
import Harvard from "./views/Articulos/Harvard/Harvard";
//import SubastaEscrapalia from "views/Articulos/SubastaEscrapalia/SubastaEscrapalia";
import SubastaIndustrial from "views/Articulos/SubastaIndustrial /SubastaIndustrial";

import CursosPage from "./views/CursosPage/componentesCursos/Cursos";
import ListaOfertaEmpresa from "views/ListaOfertaEmpresa/ListaOfertaEmpresa";

import PublicarArticulo from "views/PublicarArticulo/PublicarArticulo";
import BlogList from "./views/BlogList";
import ArticuloTemplate from "./views/ArticuloTemplate";
import Courses from "views/Courses";
import CoursesWelcome from "views/CoursesWelcome";

export default function App() {
  return (
    <>
      <State>
        <Router>
          <Switch>
            <Route exact path="/" component={BlogList} />
            <Route exact path="/blog-list/" component={ArticlesFooter} />
            <Route exact path="/buscar-ofertas" component={LandingPage} />
            <Route exact path="/subir-oferta" component={SubirOferta} />

            <Route exact path="/contactar" component={Contactar} />
            <Route exact path="/vacantes" component={Jobs} />
            <Route exact path="/listado-de-trabajos" component={JobsTalent} />
            <Route exact path="/jobs-ca" component={JobsTalentCanada} />
            <Route exact path="/jobs-de" component={JobsTalentAlemanha} />
            <Route exact path="/push/trabajos" component={JobsTalent} />
            <Route exact path="/vacantes-remotas" component={RemoteJobs} />
            <Route
              exact
              path="/vacantes-remotas-bienvenido"
              component={RemoteJobsWelcome}
            />

            <Route
              exact
              path="/politicas-de-privacidad"
              component={Politicas}
            />
            <Route exact path="/aviso-legal" component={AvisoLegal} />
            <Route exact path="/derechos" component={Derechos} />
            <Route exact path="/educacion/:category" component={CursosPage} />

            <Route
              exact
              path="/publicar-articulo/"
              component={PublicarArticulo}
            />

            <Route exact path="/blog/:title" component={ArticuloTemplate} />

            <Route
              exact
              path="/empresas-ofertas-empleo"
              component={EmpresasOfertas}
            />
            <Route
              exact
              path="/lista-ofertas-empleo-empresas/:company"
              component={ListaOfertaEmpresa}
            />
            <Route
              exact
              path="/trabajar-como-freelancer"
              component={diezPaginas}
            />
            <Route
              exact
              path="/contratado-en-la-primera-entrevista"
              component={PreguntasTrampa}
            />

            <Route exact path="/calcular-subsidio-desempleo" component={Paro} />
            <Route
              exact
              path="/mas-ofertas-empleo-en-el-mundo"
              component={MasOfertas}
            />
            <Route exact path="/empleo-mas-demandado" component={Agente} />
            <Route
              exact
              path="/subasta-material-coches-industrial"
              component={SubastaIndustrial}
            />

            <Route
              exact
              path="/trucos-para-ahorrar-dinero"
              component={AhorrarDinero}
            />
            <Route exact path="/cursos-google" component={CursosGoogle} />

            <Route
              exact
              path="/cual-es-mas-barato"
              component={DieselVSGasolina}
            />
            <Route exact path="/cursos-harvard" component={Harvard} />
            <Route
              exact
              path="/curriculum-impecable"
              component={ConsejosCurriculum}
            />
            <Route
              exact
              path="/profesiones-con-mas-ofertas-empleo"
              component={MasOfertas}
            />
            <Route exact path="/ofertas-empleo-sae" component={Sae} />
            <Route
              exact
              path="/desapareceran-en-30-anos"
              component={Empleo30anos}
            />
            <Route
              exact
              path="/empleo-disponible-europa"
              component={EmpleoEuropa}
            />

            <Route
              exact
              path="/agencias-colocacion-empleo"
              component={Colocacion}
            />
            <Route
              exact
              path="/consejos-entrevistas-online"
              component={Virtual}
            />
            <Route exact path="/ayudas-poblacion" component={Ayuda} />
            <Route exact path="/ganar-dinero-coche" component={Coche} />
            <Route exact path="/plantilla-curriculum" component={Plantilla} />
            <Route exact path="/diesel-gasolina" component={Gasolina} />
            <Route exact path="/dejaste-ultimo-empleo" component={Dejaste} />
            <Route exact path="/ofertas-empleo-alcampo" component={Alcampo} />
            <Route
              exact
              path="/ofertas-empleo-cantabria"
              component={Cantabria}
            />
            <Route
              exact
              path="/empleo-sin-experiencia"
              component={NoExperiencia}
            />

            <Route
              exact
              path="/empleo-media-jornada"
              component={MediaJornada}
            />
            <Route exact path="/ere-erte" component={Ere} />
            <Route
              exact
              path="/descargar-vida-laboral"
              component={VidaLaboral}
            />
            <Route exact path="/cursos" component={Courses} />
            <Route exact path="/cursos-bienvenido" component={CoursesWelcome} />
          </Switch>
        </Router>
      </State>
    </>
  );
}
