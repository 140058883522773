import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components/
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import {
  blackColor,
  headerColor,
} from "../../../../assets/jss/material-kit-pro-react";
const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h4 className={classes2.top}>
        Cómo ver las ofertas de empleo disponibles en toda Europa
      </h4>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              En esta ocasión te voy a enseñar cómo puedes encontrar empleo en
              el mayor portal de empleo de Europa llamado EURES.
            </p>

            <p className={classes2.texto}>
              También voy a contarte cómo puedes hacer una buena entrevista a
              través de internet y cuál es la media de sueldos que existe en
              Europa para de esta manera, valorar mejor si te conviene o no
              salir de España para buscar trabajo.
            </p>

            <h3 className={classes2.titulo}>
              ¿Cómo encontrar trabajo en EURES?
            </h3>

            <p className={classes2.texto}>
              En 1994 se puso en marcha la organizativa de EURES, se trata de
              una red de cooperación que actúa a nivel Europeo para ayudar a los
              ciudadanos de la Unión Europea a encontrar trabajo en todo el
              continente. En este portal puedes encontrar todo lo necesario para
              trabajar por Europa, pues muestra los enlaces y la información
              oficial de cada país de manera detallada.
            </p>

            <p className={classes2.texto}>
              A continuación te voy a enseñar cómo puedes acceder a las ofertas
              de empleo de este portal:
            </p>

            <p className={classes2.texto}>
              Lo primero que deberás hacer es acceder al siguiente enlace a
              través de tu navegador:{" "}
              <a
                className={classes2.link}
                href="https://ec.europa.eu/eures/eures-searchengine/page/main?lang=es#/search"
              >
                https://ec.europa.eu/eures/euressearchengine/page/main?lang=es#/search
              </a>
            </p>

            <p className={classes2.texto}>
              Nos encontraremos con un aparado lateral en el que se muestran los
              filtros que podemos aplicar para hacer una búsqueda de empleo y un
              apartado central donde van a aparecernos los resultados de nuestra
              búsqueda.
            </p>

            <p className={classes2.texto}>
              También podemos buscar directamente el nombre del trabajo que
              queremos obtener y darle directamente al botón de buscar. De esta
              manera aparecerá un listado con todas las ofertas que coincidan
              con tu búsqueda.
            </p>

            <p className={classes2.texto}>
              La otra manera, es personalizar tu búsqueda ajustando los
              diferentes parámetros. Entre estos parámetros tenemos:
            </p>

            <p className={classes2.texto}>
              Localización, tipo de contrato, nivel educativo, tipo de puesto,
              jornada laboral y experiencia. Existen otras dos casillas en las
              que podemos marcar si queremos que tenga el certificado Euros que
              garantiza su fiabilidad o queremos que sea un tipo de trabajo de
              carácter social o voluntariado.
            </p>

            <p className={classes2.texto}>
              Aplicando los filtros deseados, nos aparecerá un listado con todas
              las ofertas que se hayan encontrado según tu búsqueda. Deberemos
              seleccionar la oferta que nos interese para poder ver más detalles
              sobre esta.
            </p>

            <p className={classes2.texto}>
              Te mostrarán los detalles de la oferta, los requerimientos, así
              como la forma de acceder a la oferta.
            </p>

            <p className={classes2.texto}>
              Normalmente cada oferta de trabajo te llevará a un enlace de la
              web de Empléate si es una oferta Española o la destinada a la
              búsqueda de empleo de cada país donde quieras acceder a ofertas
              extranjeras .Debes saber que la mayoría de estas ofertas están en
              el idioma del país de destino, por lo que deberás comprenderlo
              bien para poder acceder a la oferta de trabajo.
            </p>

            <h3 className={classes2.titulo}>
              ¿Cómo tener una entrevista de empleo por internet?
            </h3>

            <p className={classes2.texto}>
              Te voy a enseñar cómo puedes tener una entrevista de trabajo
              exitosa a través de internet. Atento a las siguientes claves:
            </p>

            <p className={classes2.texto}>Conexión estable:</p>

            <p className={classes2.texto}>
              Asegúrate de que tu conexión a internet sea estable y todo el
              software y hardware de tu equipo funciona perfectamente, esto te
              evitará tener errores a la hora de hacer tu e entrevista de
              trabajo.
            </p>

            <p className={classes2.texto}>Imagen corporal:</p>

            <p className={classes2.texto}>
              Vigila que tu aspecto y el de la habitación donde te encuentres
              sea la correcta. Usa un vestuario igual al que usarías cuando vas
              a hacer una entrevista de forma física e intenta que el aspecto de
              tu habitación se encuentre ordenado y muestre buena imagen. No
              podemos hacer una buena entrevista de trabajo si el encargado de
              recursos humanos puede ver una habitación desordenada o lleva de
              detalles que le hagan perder la atención sobre lo realmente
              importante.
            </p>

            <p className={classes2.texto}>Evita molestias:</p>

            <p className={classes2.texto}>
              Asegúrate de que no vayas a ser molestado y que no haya ruidos que
              puedan molestarte a la hora de hacer tu entrevista de trabajo.
              Para ello, es útil que silencies tu móvil, apagues todos los
              aparatos que tengas encendidos y les digas a las personas con las
              que convivas que no hagan ruido mientras dure la entrevista.
            </p>

            <p className={classes2.texto}>Usa notas:</p>

            <p className={classes2.texto}>
              Anota tus dudas y las calves de tu curriculum.Es útil que tengas
              un papel con las dudas más destacadas que quieras preguntarle al
              encargado de recursos humanos y que tengas tu curriculum a mano
              por si surge alguna cuestión a cerca de tu formación académica o
              profesional.
            </p>

            <p className={classes2.texto}>Lenguaje corporal</p>

            <p className={classes2.texto}>
              Los signos de nerviosismo e inseguridad también pueden notarse a
              través de una cámara web, aunque estéis a cientos de kilómetros de
              distancia. Confía en ti mismo y tu potencial. Si sientes que los
              nervios te pueden, puedes hacer una meditación minutos anteriores
              a la entrevista, que ayude a calmar tus nervios y clarificar tu
              mente.
            </p>

            <p className={classes2.texto}>La despedida, crucial</p>

            <p className={classes2.texto}>
              El momento en el que la entrevista ha finalizado es uno de los
              momentos clave que el entrevistador va a recordar.
            </p>

            <p className={classes2.texto}>
              Asegúrate de que todas las dudas han quedado resueltas y no has
              olvidado ningún detalle relevante que quieras preguntar más tarde.
            </p>

            <p className={classes2.texto}>
              Despídete de manera cordial y muéstrate agradecido por el tiempo
              que ha empleado para atenderte. Esto le hará sentirse valorado en
              su trabajo.
            </p>

            <p className={classes2.texto}>
              Tu sonrisa es la última imagen que el entrevistador va a recordar
              de la entrevista, por lo que debes intentar que está no se vea
              forzada y genere sentimientos de serenidad y confianza.
            </p>

            <p className={classes2.texto}>Nombre de usuario:</p>

            <p className={classes2.texto}>
              El nombre de email y usuario es uno de los primeros datos que el
              entrevistador va a poder conocer sobre ti, por eso debes vigilar
              que sea correcto.
            </p>

            <p className={classes2.texto}>
              Utiliza un nombre sencillo que te defina de forma clara. En ningún
              caso uses nombres de usuario con contenido irrespetuoso o que
              formen impresiones negativas con antelación.
            </p>

            <p className={classes2.texto}>
              Si vas a realizar una entrevista por Skype o Zoom, las dos
              herramientas más utilizadas para hacer videoconferencias, utiliza
              una imagen profesional y sostificada que te defina con una persona
              seria y confiada. Si vas a usar esta cuenta para tu ámbito
              personal, recuerda cambiar la foto que utilices normalmente o aún
              mejor, crear una nueva cuenta exclusivamente para hacer
              entrevistas por videoconferencia.
            </p>

            <h3 className={classes2.titulo}>
              ¿Cuáles son los sueldos de Europa?
            </h3>

            <p className={classes2.texto}>
              El salario medio de Europa oscila en los 2.000€ mensuales. En
              España el salario medio alcanza los 1.639 euros aunque es cierto
              que poca gente puede llegar a alcanzar este sueldo, ya que la
              mayoría de las personas que conocemos son los
              llamados``mileuristas´´.
            </p>

            <p className={classes2.texto}>
              En Dinamarca se concede el mejor de los salarios de la Unión
              Europea con un total de 3.807€ mensuales, muy lejos de los sueldos
              que se dan en nuestro país. Le siguen Luxemburgo con 3.228 € e
              Irlanda con 2.790€ de sueldo medio. El informe de Adecco clasifica
              a los 28 países de la Unión Europea en tres grupos.
            </p>

            <p className={classes2.texto}>
              España se encuentra entre los nueve países mejor pagados en el
              segundo tramo de sueldos donde Francia y Reino Unido encabezan la
              lista.
            </p>

            <p className={classes2.texto}>
              Los sueldos con cifras más bajas se encuentran en Bulgaria,
              Rumania y Lituania con un sueldo medio mensual de tan solo 566€.
            </p>

            <h3 className={classes2.titulo}>
              ¿Qué requisitos necesitas para trabajar en Europa?
            </h3>

            <p className={classes2.texto}>
              Como todos sabemos, debido a la pasada crisis económica y las
              condiciones laborarles de nuestro país, muchas personas han
              decidió irse a buscar trabajo por Europa buscando mejores sueldos
              y condiciones para conseguir un mejor trabajo.
            </p>

            <p className={classes2.texto}>
              Te voy a explicar de manera general, cuales son los requisitos que
              se están pidiendo para trabajar en la Unión Europea. Si resides
              dentro de España, será más fácil pues se aplica el famoso
              ``derecho de circulación y residencia para trabajadores´´, por lo
              que no vas a necesitar un visado de trabajo.
            </p>

            <p className={classes2.texto}>
              A continuación te explicare la documentación que es necesaria para
              trabajar dentro de la Unión Europea:
            </p>

            <p className={classes2.texto}>
              Permiso de Trabajo: Los ciudadanos de la Europa, Islandia y
              Noruega no necesitan permiso de trabajo para trabajar en otros
              países de la Unión Europea. Sin embargo si eres ciudadano de otro
              país necesitarás iniciar los trámites para adquirir una tarjeta de
              residencia de larga duración y conseguir finalmente el permiso de
              trabajo.
            </p>

            <p className={classes2.texto}>Al menos dos idiomas:</p>

            <p className={classes2.texto}>
              Además del idioma español es importante que tengas un segundo
              idioma con un buen nivel como el ingles, el francés o el alemán.
              Si quieres presentarte a unas oposiciones dentro de la Unión
              Europea  este, resulta un requisito indispensable.
            </p>

            <p className={classes2.texto}>Convalidación de Títulos:</p>

            <p className={classes2.texto}>
              Si quieres trabajar en Europa, deberás convalidad tus títulos
              académicos para que estos tengan valor oficial. Si eres ciudadano
              Europeo esto no te supondrá ningún problema ya que la mayoría de
              las comunidades de la Unión Europea tienen el mismo modo de
              reconocimiento.Dependientdo del tipo de país donde quieras
              trabajar es posible que se te pida un curso de adaptación al país
              o una prueba de conocimientos.
            </p>

            <p className={classes2.texto}>
              Un trámite que puede demorarse hasta seis meses. Debes saber que
              no pueden echarte del país si puedes demostrar que estás buscando
              empleo de forma activa. De esta manera puedas pedir un certificado
              de asistencia en tus entrevistas para presentarlo y alegar este
              tipo de búsqueda.
            </p>

            <p className={classes2.texto}>Tarjeta sanitaria europea</p>

            <p className={classes2.texto}>
              Si quieres trabajar en algún país de la Unión Europea deberás
              pedir tu tarjeta sanitaria por si tuvieras que acudir a los
              servicios sanitarios durante tu estancia en las país.
            </p>

            <p className={classes2.texto}>
              Te he enseñado como puedes acceder a las diferentes ofertas que
              actualmente está vigentes en toda la Unión Europea.
            </p>

            <p className={classes2.texto}>
              También te he mostrado como puedes hacer una entrevista de trabajo
              por internet y qué requisitos necesitas tener para trabajar fuera
              del territorio nacional. Toma nota de estos datos y prepara tu
              mejor candidatura para embarcarte en un viaje que posiblemente
              cambie tu vida, para bien.
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
