import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import {
  headerColor,
  whiteColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Alert from "../Alert";
import Context from "../../../../Context/Context";
import Button from "components/CustomButtons/Button.js";
import createArticleStyle from './CreateArticleStyle';
import ArticleContent from './ArticleContent';
import getslug from "../../../../utils/GetSlug";



export default function Editor({
  value,
  setValue,
  title,
  setTitle,
  setImage,
}) {
  const MeuContext = useContext(Context);
  const [preview, setPreview] = useState(false)
  const { error, publicado } = MeuContext;

  const useStyles = makeStyles(createArticleStyle);
  const classes = useStyles();

  const styleButton = {
    backgroundColor: headerColor[0],
    color: whiteColor,
    boxShadow:
      "0 14px 26px -12px rgba(" +
      hexToRgb(whiteColor) +
      ", 0.42), 0 4px 23px 0px rgba(" +
      hexToRgb(whiteColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(whiteColor) +
      ", 0.2)",

    "&:hover,&:focus": {
      color: headerColor[0],
      backgroundColor: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(headerColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(headerColor[0]) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(headerColor[0]) +
        ", 0.2)",
    },
  };


  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const slug = () => {
    const slugConstructor = getslug(title)
    let mainSlug = "https://hmservicios.com/blog/" + slugConstructor

    return mainSlug
  }

  return (
    <div style={{ padding: "30px" }}>
      {error ? <Alert type={"error"} message={"Campos vacíos."} /> : null}
      {publicado ? (
        <Alert type={"success"} message={"Artículo publicado correctamente."} />
      ) : null}
      <br />
      <p
        style={{
          fontSize: "20px",
        }}
      >
        Título
      </p>
      <input
        style={{
          width: "100%",
          padding: "5px",
          fontSize: "20px",
          marginBottom: "10px",
        }}
        onChange={(e) => setTitle(e.target.value)}
      />

      {title ? <p
        style={{
          fontSize: "15px",
        }}
      >
        <b>Slug: </b>{slug()}

      </p> : null}


      <p
        style={{
          fontSize: "20px",
        }}
      >
        Image URL
      </p>
      <input
        style={{
          width: "100%",
          padding: "5px",
          fontSize: "20px",
          marginBottom: "30px",
        }}
        onChange={(e) => setImage(e.target.value)}
      />

      <ReactQuill
        theme="snow"
        value={value}
        modules={modules}
        formats={formats}
        onChange={setValue}
      />

      <Button
        style={styleButton}
        onClick={() => setPreview(true)}
      >
        Vista Previa
        </Button>

      <Dialog
        open={preview}
        keepMounted
        onClose={() => setPreview(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        maxWidth={false}
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => setPreview(false)}
          >
            {' '}

          </Button>
          <h4 className={classes.modalTitle}>{title}</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <ArticleContent contentString={value} />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => setPreview(false)}
            className={classes.closeButton}
            color="danger"

          >
            Cerrar
                  </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
