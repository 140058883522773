import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import LogoGoogle from "../../assets/img/google-safe-browsing.png";

const useStyles = makeStyles({
  wrap: {
    backgroundColor: "rgb(40, 40, 40)",
    marginTop: 80,
    textAlign: "center",
    fontSize: 14,
    padding: "30px 10px",
    lineHeight: 1.5,
    fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    "& p": {
      marginBottom: 10,
      fontWeight: 400
    },
    "& h2": {
      marginBottom: 10,
      fontSize: 21,
      fontWeight: 700
    }
  },
  footer: {
    fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    backgroundColor: "#000",
    textAlign: "center",
    padding: "30px 10px 14px",
    lineHeight: 1.5,
    fontSize: 14,
    "& p": {
      marginBottom: 16,
      fontWeight: 400
    }
  },
  logoGoogle: {
    marginBottom: 16,
    display: "block"
  },
  container: {
    maxWidth: 470,
    margin: "0 auto"
  }
});

export default function MiddlePageFooterCoches() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.wrap}>
        <div className={classes.container}>
          <h2>¿A qué tendrás acceso?</h2>
          <p>
            Somos un portal de contenidos cuya misión es ayudarte a encontrar
            vehículos subastados en internet.
          </p>
          <p>
            Producimos contenido informativo y educativo con consejos
            seleccionados sobre coches subastados, vehículos de segunda mano y
            todo lo relacionado al mundo del motor.
          </p>
          <p>
            No somos ningún vendedor de coches y no vendemos ningún tipo de
            servicio en nuestra web.
          </p>
          <p>
            ¿Cómo nos financiamos? Recibimos una parte de la publicidad que se
            muestra en nuestro sitio web, a través de anunciantes que hacen
            publicidad de sus marcas, productos o servicios en nuestro portal.
          </p>
          <h2>¿Cómo podemos ayudarte?</h2>
          <p>
            Nuestro equipo de editores/periodistas están preparados para
            brindarle la mejor información posible.
          </p>
          <p>
            Hay mucha información confusa e incompleta en Internet, pero nuestro
            esfuerzo es garantizar que en nuestra web tengas total clareza en
            todo lo que se le informa.
          </p>
          <p>
            El contenido publicado en nuestro portal ha sido cuidadosamente
            elegido para nuestros lectores.
          </p>
          <p>
            Le recomendamos que investigue bien y visite nuestro sitio web antes
            de participar de cualquier tipo de subasta.
          </p>
          <h2>¿Cómo accedes?</h2>
          <p>
            Es muy sencillo. Todo nuestro contenido está disponible a través de
            artículos en nuestro sitio web, puedes navegar por cualquier
            categoría que te interese.
          </p>
          <p>
            También ponemos a su disposición en algunas áreas de nuestro portal
            la posibilidad de que se suscriba a nuestra lista de correo
            electrónico (boletín informativo) y notificaciones del navegador
            (notificaciones web push).
          </p>
          <p>
            Una vez que se suscriba a una de nuestras listas, le enviaremos
            periódicamente nuestro contenido recomendado.
          </p>
          <p>
            Puede darse de baja de cualquiera de nuestras listas en cualquier
            momento si ya no desea recibir nuestro contenido.
          </p>
          <p>
            Creemos que no hay que pagar para obtener contenido de calidad, por
            lo que la información contenida en este sitio web es 100% gratuita.
          </p>
        </div>
      </div>
      <footer className={classes.footer}>
        <div className={classes.container}>
          <a
            href="https://
transparencyreport.google.com/safe-browsing/search?
url=hmservicios.com"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.logoGoogle}
          >
            <img
              src={LogoGoogle}
              alt="Google Safe Browsing"
              width={240}
              height={106}
            />
          </a>
          <p>
            Producimos y entregamos publicaciones periódicas que se producen de
            forma independiente.
          </p>
          <p>
            Puedes darte de baja en cualquier momento. Al registrarse, acepta
            los términos de nuestra{" "}
            <Link to="/politicas-de-privacidad" target="_blank">
              Política de Privacidad y Términos de Uso
            </Link>
            .
          </p>
        </div>
      </footer>
    </>
  );
}
