import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h2 className={classes2.top}>Ofertas de empleo en Alcampo</h2>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              Uno de los mayores hipermercados de España, lanza ofertas de
              empleo.
            </p>

            <p className={classes2.texto}>
              Auchan Retail España es una filial del grupo francés Auchan y
              posee cerca de 60 hipermercados y 300 supermercados, la mayoría de
              ellos franquiciados, todos con la marca <em>Alcampo</em>.
            </p>

            <p className={classes2.texto}>
              Para tener una idea de la dimensión del Grupo puede señalarse que
              posee en España una plantilla de 20.000 personas y factura una
              cifra bruta anual de 4.500 millones de euros.
            </p>

            <p className={classes2.texto}>
              <strong>
                Y necesitan más empleados para seguir con la expansión.
              </strong>
            </p>

            <p className={classes2.texto}>
              Auchan Retail España es una corporación que se jacta de su alta
              valoración del capital humano, su compromiso con la ecología, su
              responsabilidad en la elección de lo bueno, lo sano y lo local, su
              carácter innovador y sus servicios personalizados.
            </p>

            <h3 className={classes2.titulo}>Ofertas De Empleo</h3>

            <p className={classes2.texto}>
              El sitio web de Auchan Retail posee una página denominada
              <strong>
                {" "}
                <em>Trabaja con nosotros</em>
              </strong>
              , donde figuran los requisitos y condiciones para acceder a un
              empleo en Alcampo.
            </p>

            <p className={classes2.texto}>
              En el portal se realiza una presentación de la compañía destacando
              los que considera sus principales valores esenciales, como por
              ejemplo su permanente evolución.
            </p>

            <p className={classes2.texto}>
              Hay una valoración muy positiva de los colaboradores para la
              perfomance de la firma y finalmente, luego de la introducción,{" "}
              <strong>
                se invita a los interesados en conseguir un empleo en Alcampo a
                formar parte del grupo con un botón que dice{" "}
                <em>Envianos tu curriculum</em>.
              </strong>
            </p>

            <p className={classes2.texto}>
              Al ingresar se convoca a registrarse creando una cuenta con
              contraseña y al finalizar este trámite se abre un formulario para
              volcar los datos personales, incluyendo un recuadro para pegar una
              foto.
            </p>

            <p className={classes2.texto}>
              También se solicitan los datos de experiencia previa, formación,
              idiomas y conocimientos, permitiendo adjuntar hasta dos documentos
              no mayores de 10 MB, tales como Título, Carta de Recomendación,
              Certificados u otros.
            </p>

            <p className={classes2.texto}>
              Como un dato singular, el formulario pregunta en uno de sus
              cuadros si el aspirante al empleo en Alcampo posee alguna
              discapacidad, dando dos opciones: discapacidad menor del 33% ó
              mayor de este porcentaje.
            </p>

            <p className={classes2.texto}>
              En el mismo formulario, un botón verde en el ángulo superior
              derecho reza <em>Ver Ofertas</em> e ingresando en él se visualizan
              los pedidos de empleo en Alcampo vigentes al momento del contacto,
              indicando su respectiva ubicación geográfica.
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://www.auchan-retail.es/trabaja-con-nosotros/"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="VISITAR OFERTAS DE EMPLEO (se abre en una nueva pestaña)"
              >
                VISITAR OFERTAS DE EMPLEO
              </a>
            </p>

            <p className={classes2.texto}>
              Esto permite postularse a las ofertas concretas de empleo en
              Alcampo, seleccionadas por preferencia o conveniencia del
              interesado.
            </p>

            <h2 class="has-text-align-center">Cómo se trabaja en Alcampo</h2>

            <p className={classes2.texto}>
              {" "}
              La compañía reitera en sus presentaciones y declaraciones la
              existencia de sus mecanismos de promoción interna y la consecuente
              política de formación específica.
            </p>

            <p className={classes2.texto}>
              Esta política de capacitación es consistente con la posibilidad de
              desarrollar una carrera profesional dentro de la compañía para
              quienes obtienen su empleo en Alcampo.
            </p>

            <p className={classes2.texto}>
              La empresa posee un Programa de Becas (Programa Gaia) que
              posibilita desarrollar prácticas en Alcampo para luego conseguir
              un contrato por tiempo indefinido como responsable de mercado.
            </p>

            <p className={classes2.texto}>
              También se ofrecen Ciclos Formativos y de Bachillerato para
              oficiales de carnicería, pescadería, panadería, mantenimiento,
              ayudantes de caja y repositores con empleo en Alcampo.
            </p>

            <p className={classes2.texto}>
              Hay facilidades para los estudiantes, permitiéndoles trabajar
              durante fines de semana o periodos de vacaciones de manera de no
              interrumpir sus estudios.
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://www.auchan-retail.es/trabaja-con-nosotros/"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="VISITAR OFERTAS DE EMPLEO (se abre en una nueva pestaña)"
              >
                VISITAR OFERTAS DE EMPLEO
              </a>
            </p>

            <p className={classes2.texto}>
              Finalmente, hay una invitación especial para obtener empleo en
              Alcampo dirigida a Titulados recién graduados, en las áreas de
              Logística, Aprovisionamiento, Control de Gestión, Recursos
              Humanos, Informática, Investigación de Mercado, Calidad, Marketing
              y Comunicación.
            </p>

            <h3 className={classes2.titulo}>
              Conseguir una entrevista de empleo
            </h3>

            <p className={classes2.texto}>
              La entrevista de trabajo para obtener el empleo en Alcampo no
              difiere demasiado de otras similares utilizadas por las grandes
              compañías.
            </p>

            <p className={classes2.texto}>
              Aunque existen puestos de trabajo muy diferentes dentro de la
              cadena Alcampo, la entrevista de trabajo es un requisito
              indispensable para acceder a cualquiera de ellos.
            </p>

            <p className={classes2.texto}>
              Incluso, en más de una oportunidad es la entrevista de trabajo la
              que termina de definir el mejor puesto en el que puede insertarse
              el aspirante al empleo en Alcampo, de acuerdo con su perfil,
              actitudes y aptitudes.
            </p>

            <p className={classes2.texto}>
              El equipo de reclutamiento indagará seguramente acerca de la vida
              personal y formación académica del interesado en el empleo en
              Alcampo, así como su posible experiencia en el sector comercial de
              supermercados.
            </p>

            <p className={classes2.texto}>
              Las preguntas típicas que pueden esperarse en la entrevista son
              las siguientes, entre otras posibles:
            </p>

            <p className={classes2.texto}>
              <em>¿Por qué quiere el puesto en Alcampo?</em>
            </p>

            <p className={classes2.texto}>
              <em>¿Conocía la empresa?</em>
            </p>

            <p className={classes2.texto}>
              <em>¿Qué espera de su empleo en Alcampo?</em>
            </p>

            <p className={classes2.texto}>
              <em>¿Ha comprado alguna vez en nuestros supermercados?</em>
            </p>

            <p className={classes2.texto}>
              <a
                className={classes2.link}
                href="https://www.auchan-retail.es/trabaja-con-nosotros/"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="VISITAR OFERTAS DE EMPLEO (se abre en una nueva pestaña)"
              >
                <strong>VISITAR OFERTAS DE EMPLEO</strong>
              </a>
            </p>

            <p className={classes2.texto}>
              También pueden agregarse algunas preguntas sobre situaciones
              hipotéticas para evaluar psicológicamente al candidato, como por
              ejemplo:{" "}
              <em>
                ¿Cuál es su actitud si su superior le llama la atención
                injustamente por algo que no ha hecho?
              </em>
            </p>

            <h2 class="has-text-align-center">Cómo enviar curriculum</h2>

            <p className={classes2.texto}>
              Resumiendo en unos pocos pasos concretos, la forma de proceder
              para intentar conseguir un empleo en Alcampo es la siguiente:
            </p>
            <li className={classes2.texto}>
              Ingresar en el sitio{" "}
              <strong>
                <a
                  className={classes2.link}
                  rel="noreferrer noopener"
                  aria-label="www.auchan-retail.es/trabaja-con-nosotros/ (se abre en una nueva pestaña)"
                  href="http://www.auchan-retail.es/trabaja-con-nosotros/"
                  target="_blank"
                >
                  www.auchan-retail.es/trabaja-con-nosotros/
                </a>
              </strong>
            </li>
            <li className={classes2.texto}>
              Dirigirse al botón <em>Envíanos tu Currículum</em> y hacer clic
              sobre él
            </li>
            <li className={classes2.texto}>
              Loguearse en Infojobs creando una cuenta
            </li>
            <li className={classes2.texto}>
              Completar el formulario que comienza con los{" "}
              <em>Datos Personales</em>
            </li>
            <li className={classes2.texto}>
              Guardar y enviar el formulario, con los correspondientes adjuntos
            </li>
            <li className={classes2.texto}>
              Aguardar la respuesta y la convocatoria a la <em>Entrevista</em>
            </li>
            <li className={classes2.texto}>
              Concurrir a la entrevista y responder correctamente
            </li>
            <li className={classes2.texto}>
              Completar la primera jornada de capacitación, posterior a la
              Entrevista.
            </li>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
