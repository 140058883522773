import React, { Fragment, useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import { Helmet } from "react-helmet";

import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

import superalcampo from "assets/img/superalcampo.jpg";
import corteingles from "assets/img/corteingles.jpg";
import mercadona from "assets/img/mercadona.jpg";
import amazon from "assets/img/amazon.jpg";
import elpozo from "assets/img/elpozo.jpg";
import decathlon from "assets/img/decathlon.jpg";
import carrefour from "assets/img/carrefour.jpg";
import ikea from "assets/img/ikea.jpg";
import dia from "assets/img/dia.jpg";
import telefonica from "assets/img/telefonica.jpg";
import bricomart from "assets/img/bricomart.jpg";
import prosegur from "assets/img/prosegur.jpg";

import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";
import { Link } from "react-router-dom";
import Context from "../../../Context/Context";

const useStyles = makeStyles(blogsStyle);
const useStyles2 = makeStyles({
  ajuste: {
    marginTop: "-50px",
  },
  leer: {
    color: blackColor,
    marginLeft: "5px",
    fontWeight: "bold",
    textDecoration: "underline",
    "&:hover": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});

export default function SectionBlogs({ ...rest }) {
  const classes = useStyles();
  const classes2 = useStyles2();
  const MeuContext = useContext(Context);
  const { setEmpresa } = MeuContext;

  return (
    <Fragment>
      <Helmet>
        <title>HM Servicios - Empresas con Ofertas de Empleo</title>
      </Helmet>
      <div className="cd-section" {...rest}>
        <div className={classes.blog}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={10}
                md={10}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <CardHeader image plain>
                        <Link
                          to="/lista-ofertas-empleo-empresas/mercadona"
                          onClick={() => setEmpresa("mercadona")}
                        >
                          <img src={mercadona} alt="Mercadona" />
                        </Link>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${mercadona})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${mercadona})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <Success>
                        <h6 className={classes.cardCategory}>EMPLEO</h6>
                      </Success>
                      <h3 className={classes.cardTitle}>
                        <Link
                          to="/lista-ofertas-empleo-empresas/mercadona"
                          onClick={() => setEmpresa("mercadona")}
                        >
                          Mercadona
                        </Link>
                      </h3>
                      <p className={classes.description}>
                        Las últimas ofertas de empleo de Mercadona. Cajero,
                        reponedor, almacén... Las últimas ofertas del
                        supermercado más conocido de España.
                        <Link
                          to="/lista-ofertas-empleo-empresas/mercadona"
                          className={classes2.leer}
                          onClick={() => setEmpresa("mercadona")}
                        >
                          Ver Ahora
                        </Link>
                      </p>
                      <p className={classes.author}>
                        por <b>Hugo Rocha</b>
                      </p>
                    </GridItem>
                  </GridContainer>
                </Card>
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                      <CardHeader image plain>
                        <Link
                          to="/lista-ofertas-empleo-empresas/alcampo"
                          onClick={() => setEmpresa("alcampo")}
                        >
                          <img src={superalcampo} alt="Alcampo" />
                        </Link>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${superalcampo})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${superalcampo})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <Danger>
                        <h6 className={classes.cardCategory}>
                          <TrendingUp />
                          Destacado
                        </h6>
                      </Danger>
                      <h3 className={classes.cardTitle}>
                        <Link
                          to="/lista-ofertas-empleo-empresas/alcampo"
                          onClick={() => setEmpresa("alcampo")}
                        >
                          Alcampo
                        </Link>
                      </h3>
                      <p className={classes.description}>
                        La gran cadena de supermercados Alcampo necesita
                        empleados en varios centros distribuidos por España.
                        Envía tu curriculum y te responderán en menos de 48h.
                        <Link
                          to="/lista-ofertas-empleo-empresas/alcampo"
                          className={classes2.leer}
                          onClick={() => setEmpresa("alcampo")}
                        >
                          Ver Ahora
                        </Link>
                      </p>
                      <p className={classes.author}>
                        por <b>Hugo Rocha</b>
                      </p>
                    </GridItem>
                  </GridContainer>
                </Card>
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <CardHeader image plain>
                        <Link
                          to="/lista-ofertas-empleo-empresas/corteingles"
                          onClick={() => setEmpresa("corteingles")}
                        >
                          <img src={corteingles} alt="El Corte Inglés" />
                        </Link>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${corteingles})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${corteingles})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <Info>
                        <h6 className={classes.cardCategory}>Empleo</h6>
                      </Info>
                      <h3 className={classes.cardTitle}>
                        <Link
                          to="/lista-ofertas-empleo-empresas/corteingles"
                          onClick={() => setEmpresa("corteingles")}
                        >
                          El Corte Inglés Supermercado
                        </Link>
                      </h3>
                      <p className={classes.description}>
                        Los supermercados de El Corte Inglés buscan a diario
                        empleados para acompañar la expansión de la empresa.
                        Trabaja en una de las mejores empresas de España. Envía
                        tu solicitud.
                        <Link
                          to="/lista-ofertas-empleo-empresas/corteingles"
                          className={classes2.leer}
                          onClick={() => setEmpresa("corteingles")}
                        >
                          Ver Ahora
                        </Link>
                      </p>
                      <p className={classes.author}>
                        por <b>Hugo Rocha</b>
                      </p>
                    </GridItem>
                  </GridContainer>
                </Card>
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <CardHeader image plain>
                        <Link
                          to="/lista-ofertas-empleo-empresas/amazon"
                          onClick={() => setEmpresa("amazon")}
                        >
                          <img src={amazon} alt="Amazon" />
                        </Link>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${amazon})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${amazon})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <Info>
                        <h6 className={classes.cardCategory}>Empleo</h6>
                      </Info>
                      <h3 className={classes.cardTitle}>
                        <Link
                          to="/lista-ofertas-empleo-empresas/amazon"
                          onClick={() => setEmpresa("amazon")}
                        >
                          Amazon
                        </Link>
                      </h3>
                      <p className={classes.description}>
                        El gigante del comercio online oferta cada día varias
                        ofertas de empleo en España. Envía tu solicitud y
                        empieza a trabajar en 72h.
                        <Link
                          to="/lista-ofertas-empleo-empresas/amazon"
                          className={classes2.leer}
                          onClick={() => setEmpresa("amazon")}
                        >
                          Ver Ahora
                        </Link>
                      </p>
                      <p className={classes.author}>
                        por <b>Hugo Rocha</b>
                      </p>
                    </GridItem>
                  </GridContainer>
                </Card>
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <CardHeader image plain>
                        <Link
                          to="/lista-ofertas-empleo-empresas/decathlon"
                          onClick={() => setEmpresa("decathlon")}
                        >
                          <img src={decathlon} alt="Decathlon" />
                        </Link>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${decathlon})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${decathlon})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <Info>
                        <h6 className={classes.cardCategory}>Empleo</h6>
                      </Info>
                      <h3 className={classes.cardTitle}>
                        <Link
                          to="/lista-ofertas-empleo-empresas/decathlon"
                          onClick={() => setEmpresa("decathlon")}
                        >
                          Decathlon
                        </Link>
                      </h3>
                      <p className={classes.description}>
                        Decathlon ofrece ofertas de empleo para personas
                        apasionadas por el deporte y con buena atención a
                        clientes. No se necesita experiencia.
                        <Link
                          to="/lista-ofertas-empleo-empresas/decathlon"
                          className={classes2.leer}
                          onClick={() => setEmpresa("decathlon")}
                        >
                          Ver Ahora
                        </Link>
                      </p>
                      <p className={classes.author}>
                        por <b>Hugo Rocha</b>
                      </p>
                    </GridItem>
                  </GridContainer>
                </Card>
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <CardHeader image plain>
                        <Link
                          to="/lista-ofertas-empleo-empresas/elpozo"
                          onClick={() => setEmpresa("elpozo")}
                        >
                          <img src={elpozo} alt="El Pozo" />
                        </Link>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${elpozo})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${elpozo})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <Success>
                        <h6 className={classes.cardCategory}>Empleo</h6>
                      </Success>
                      <h3 className={classes.cardTitle}>
                        <Link
                          to="/lista-ofertas-empleo-empresas/elpozo"
                          onClick={() => setEmpresa("elpozo")}
                        >
                          El Pozo
                        </Link>
                      </h3>
                      <p className={classes.description}>
                        Ofertas de Empleo del Grupo Fuertes. Vacantes en varias
                        empresas, destacando entre ellas El Pozo, Sediasa y
                        Fripozo.
                        <Link
                          to="/lista-ofertas-empleo-empresas/elpozo"
                          className={classes2.leer}
                          onClick={() => setEmpresa("elpozo")}
                        >
                          Ver Ahora
                        </Link>
                      </p>
                      <p className={classes.author}>
                        por <b>Hugo Rocha</b>
                      </p>
                    </GridItem>
                  </GridContainer>
                </Card>
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <CardHeader image plain>
                        <Link
                          to="/lista-ofertas-empleo-empresas/telefonica"
                          onClick={() => setEmpresa("telefonica")}
                        >
                          <img src={telefonica} alt="Telefónica" />
                        </Link>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${telefonica})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${telefonica})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <Info>
                        <h6 className={classes.cardCategory}>Empleo</h6>
                      </Info>
                      <h3 className={classes.cardTitle}>
                        <Link
                          to="/lista-ofertas-empleo-empresas/telefonica"
                          onClick={() => setEmpresa("telefonica")}
                        >
                          Telefónica
                        </Link>
                      </h3>
                      <p className={classes.description}>
                        Buscamos personas que quieran formar parte del mundo de
                        la telefonía, que sepan trabajar bajo presión, dinámicas
                        e inteligentes. Envíanos tu curriculum.
                        <Link
                          to="/lista-ofertas-empleo-empresas/telefonica"
                          className={classes2.leer}
                          onClick={() => setEmpresa("telefonica")}
                        >
                          Ver Ahora
                        </Link>
                      </p>
                      <p className={classes.author}>
                        por <b>Hugo Rocha</b>
                      </p>
                    </GridItem>
                  </GridContainer>
                </Card>
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <CardHeader image plain>
                        <Link
                          to="/lista-ofertas-empleo-empresas/ikea"
                          onClick={() => setEmpresa("ikea")}
                        >
                          <img src={ikea} alt="IKEA" />
                        </Link>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${ikea})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${ikea})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <Success>
                        <h6 className={classes.cardCategory}>Empleo</h6>
                      </Success>
                      <h3 className={classes.cardTitle}>
                        <Link
                          to="/lista-ofertas-empleo-empresas/ikea"
                          onClick={() => setEmpresa("ikea")}
                        >
                          IKEA
                        </Link>
                      </h3>
                      <p className={classes.description}>
                        Ofertas de Empleo en IKEA. Vacantes en varias provincias
                        de España. Envía tu solicitud, te estamos esperando.
                        <Link
                          to="/lista-ofertas-empleo-empresas/ikea"
                          className={classes2.leer}
                          onClick={() => setEmpresa("ikea")}
                        >
                          Ver Ahora
                        </Link>
                      </p>
                      <p className={classes.author}>
                        por <b>Hugo Rocha</b>
                      </p>
                    </GridItem>
                  </GridContainer>
                </Card>
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <CardHeader image plain>
                        <Link
                          to="/lista-ofertas-empleo-empresas/prosegur"
                          onClick={() => setEmpresa("prosegur")}
                        >
                          <img src={prosegur} alt="Prosegur" />
                        </Link>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${prosegur})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${prosegur})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <Success>
                        <h6 className={classes.cardCategory}>Empleo</h6>
                      </Success>
                      <h3 className={classes.cardTitle}>
                        <Link
                          to="/lista-ofertas-empleo-empresas/prosegur"
                          onClick={() => setEmpresa("prosegur")}
                        >
                          Prosegur
                        </Link>
                      </h3>
                      <p className={classes.description}>
                        Ven a formar parte del mundo de la seguridad, te
                        ofrecemos la oportunidad de proteger los bienes más
                        valiosos del mundo y desarrollarte lado a lado con los
                        mejores.
                        <Link
                          to="/lista-ofertas-empleo-empresas/prosegur"
                          className={classes2.leer}
                          onClick={() => setEmpresa("prosegur")}
                        >
                          Ver Ahora
                        </Link>
                      </p>
                      <p className={classes.author}>
                        por <b>Hugo Rocha</b>
                      </p>
                    </GridItem>
                  </GridContainer>
                </Card>
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <CardHeader image plain>
                        <Link
                          to="/lista-ofertas-empleo-empresas/dia"
                          onClick={() => setEmpresa("dia")}
                        >
                          <img src={dia} alt="Dia" />
                        </Link>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${dia})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${dia})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <Danger>
                        <h6 className={classes.cardCategory}>Empleo</h6>
                      </Danger>
                      <h3 className={classes.cardTitle}>
                        <Link
                          to="/lista-ofertas-empleo-empresas/dia"
                          onClick={() => setEmpresa("dia")}
                        >
                          Dia
                        </Link>
                      </h3>
                      <p className={classes.description}>
                        Buscamos personas polivalentes, dispuestas a ejecutar
                        diferentes tareas a lo largo del día, dinámicas y que
                        sepan trabajar en equipo.
                        <Link
                          to="/lista-ofertas-empleo-empresas/dia"
                          className={classes2.leer}
                          onClick={() => setEmpresa("dia")}
                        >
                          Ver Ahora
                        </Link>
                      </p>
                      <p className={classes.author}>
                        por <b>Hugo Rocha</b>
                      </p>
                    </GridItem>
                  </GridContainer>
                </Card>
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <CardHeader image plain>
                        <Link
                          to="/lista-ofertas-empleo-empresas/bricomart"
                          onClick={() => setEmpresa("bricomart")}
                        >
                          <img
                            src={bricomart}
                            alt="Bricomart
                          "
                          />
                        </Link>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${bricomart})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${bricomart})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <Danger>
                        <h6 className={classes.cardCategory}>Empleo</h6>
                      </Danger>

                      <h3 className={classes.cardTitle}>
                        <Link
                          to="/lista-ofertas-empleo-empresas/bricomart"
                          onClick={() => setEmpresa("bricomart")}
                        >
                          Bricomart
                        </Link>
                      </h3>
                      <p className={classes.description}>
                        Incorpórate a un equipo con alto nivel de desarrollo,
                        fórmate dentro de la empresa y recibe retribuciones por
                        tu trabajo. Envía tu solicitud a Bricomart.
                        <Link
                          to="/lista-ofertas-empleo-empresas/bricomart"
                          className={classes2.leer}
                          onClick={() => setEmpresa("bricomart")}
                        >
                          Ver Ahora
                        </Link>
                      </p>
                      <p className={classes.author}>
                        por <b>Hugo Rocha</b>
                      </p>
                    </GridItem>
                  </GridContainer>
                </Card>
                <Card plain blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <CardHeader image plain>
                        <Link
                          to="/lista-ofertas-empleo-empresas/carrefour"
                          onClick={() => setEmpresa("carrefour")}
                        >
                          <img src={carrefour} alt="Carrefour" />
                        </Link>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${carrefour})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${carrefour})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <Success>
                        <h6 className={classes.cardCategory}>Empleo</h6>
                      </Success>
                      <h3 className={classes.cardTitle}>
                        <Link
                          to="/lista-ofertas-empleo-empresas/carrefour"
                          onClick={() => setEmpresa("carrefour")}
                        >
                          Carrefour
                        </Link>
                      </h3>
                      <p className={classes.description}>
                        Te invitamos a hacer parte de unos de los mayores
                        hipermercados del mundo. Tu oportunidad de empezar desde
                        abajo y ascender hasta puestos directivos.
                        <Link
                          to="/lista-ofertas-empleo-empresas/carrefour"
                          className={classes2.leer}
                          onClick={() => setEmpresa("carrefour")}
                        >
                          Ver Ahora
                        </Link>
                      </p>
                      <p className={classes.author}>
                        por <b>Hugo Rocha</b>
                      </p>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
