import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { headerColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(descriptionStyle);
const useStyles2 = makeStyles({
  texto: {
    color: blackColor,
    fontSize: "17px",

    padding: "10px",
  },
  contenedor: {
    fontSize: "17px",
  },
  titulo: {
    textAlign: "center",
  },
  top: {
    marginTop: "100px",
    textAlign: "center",
    padding: "30px",
  },
  link: {
    color: headerColor[0],
    fontWeight: "bold",
    "&:hover, &:focus": {
      color: headerColor[0],
      textDecoration: "underline",
    },
  },
});
export default function SectionDescription() {
  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <br />
      <h2 className={classes2.top}>Por qué dejaste tu último empleo</h2>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <div className={(classes.description, classes2.texto)}>
            <p className={classes2.texto}>
              Esta pregunta ha hecho a miles de personas perder una gran
              oportunidad en una entrevista, cuidado...
            </p>

            <p className={classes2.texto}>
              Esta es probablemente una de las preguntas más difíciles de
              responder para muchos durante las entrevistas laborales.{" "}
            </p>

            <p className={classes2.texto}>
              Algunos se sienten incómodos dando la verdadera razón por la cual
              dejaron su anterior empleo, ya sea por un despido, mal ambiente
              laboral, no te entendiste con tu jefe o incluso porque te
              reemplazaron por el amigo o hijo de tu jefe.&nbsp;
            </p>

            <h3 className={classes2.titulo}>
              Cuál es el mejor motivo que puedo darle al entrevistador
            </h3>

            <p className={classes2.texto}>
              Los despidos son algo común que puede pasarle a cualquiera,
              incluso yo fui despedida en dos ocasiones.{" "}
            </p>

            <p className={classes2.texto}>
              La primera vez fue porque tuve una pésima relación con mi nueva
              jefa, ella recién acababa de llegar a la empresa y como ella había
              tenido problemas con mi jefa anterior se podría decir que parte de
              la responsabilidad recayó en mí, así que solo dure un mes más
              trabajando con ella.&nbsp;
            </p>

            <p className={classes2.texto}>
              En el segundo trabajo fui despedida porque, a pesar de tener
              resultados excelentes en la empresa, mi asistente tenía un pésimo
              desempeño, y no porque genuinamente fuese mala, más bien por su
              edad pues llevaba más de una década en el mismo cargo y mi jefa se
              reusaba a despedirla por pena.
            </p>

            <p className={classes2.texto}>
              En fin, después de reportar su bajo rendimiento recursos humanos
              decidió que debía irme yo, y ahí cambió totalmente mi visión de
              ese departamento,{" "}
              <strong>
                por eso hoy quiero mostrar algunos consejos para que todas las
                personas que estén buscando empleo puedan dar una entrevista
                perfecta, pues se que no es sencillo.&nbsp;
              </strong>
            </p>

            <p className={classes2.texto}>
              Tomando en cuenta mis experiencias anteriores probablemente te
              preguntarás si estas razones de despido podrían ser dichas durante
              una entrevista laboral.&nbsp;
            </p>

            <p className={classes2.texto}>
              Realmente cuando vamos a estas entrevistas lo consideramos de
              manera errónea, por eso tendemos a ponernos nerviosos y a intentar
              a toda costa simpatizar con el reclutador para obtener una
              oportunidad de conseguir el empleo.
            </p>

            <p className={classes2.texto}>
              Pero la realidad es que es una reunión como cualquier otra entre
              dos personas adultas y profesionales que se plantean hacer una
              colaboración, de ese modo se beneficiarán ambas partes.
            </p>

            <p className={classes2.texto}>
              Y si lo planteamos así no suena tan aterrador como antes.&nbsp;
            </p>

            <h3 className={classes2.titulo}>Qué decir...</h3>

            <p className={classes2.texto}>
              Lo que realmente debes considerar es que no hay necesidad de ser
              100% abierto a este tipo de preguntas, con esto me refiero que
              cada uno tiene sus razones para haber dejado su anterior trabajo{" "}
              <strong>
                y no queda muy bien que vayas con el entrevistador a hablar mal
                de otra empresa, pues francamente darás una muy mala
                impresión.&nbsp;
              </strong>
            </p>

            <p className={classes2.texto}>
              Hay muchas razones por las cuales una persona deja su trabajo y
              aunque cada una de ellas son válidas y tienen justificación,
              muchas no suelen ser apropiadas para decirlas durante una
              entrevista.
            </p>

            <p className={classes2.texto}>
              Naturalmente lo ideal no es tener malentendidos ni dar la
              impresión equivocada, así que es una pregunta que debes pensarte
              dos veces antes de responder.&nbsp;
            </p>

            <p className={classes2.texto}>
              Así que lo que te propongo hacer{" "}
              <strong>es maquillar tu respuesta.</strong>
            </p>

            <h3 className={classes2.titulo}>Tus palabras pueden destruirte</h3>

            <p className={classes2.texto}>
              Para que no gastes el tiempo de tu entrevista dando explicaciones
              sin sentido y que al entrevistado no le interesan puedes optar por
              dar una respuesta más concisa encajando lo mejor posible tu
              respuesta a lo que pasó en ese momento.
            </p>

            <p className={classes2.texto}>
              Por ejemplo, en vez de decir que no te gustaba el ambiente laboral
              que creó tu nuevo jefe,
              <strong>
                {" "}
                podrías decir que te sentías cómodo con el trabajo pero que
                recientemente la empresa tomó nuevos objetivos que no se
                adaptaban con lo que querías lograr como profesional.
              </strong>
              &nbsp;
            </p>

            <p className={classes2.texto}>
              De esta manera podrás decir lo mismo, pero con palabras que suenan
              mucho mejor y que dejarán al entrevistador convencido de que diste
              una buena razón.&nbsp;
            </p>

            <p className={classes2.texto}>
              Podrías decir también que tu experiencia en tu trabajo ha sido
              excelente pero{" "}
              <strong>
                ya que llevabas tanto tiempo sintiendo que era momento de buscar
                otras experiencias que puedan ayudarte a crecer como
                profesional.
              </strong>
              &nbsp;
            </p>

            <p className={classes2.texto}>
              <strong>
                También puedes señalar que la empresa a la que te estás
                postulando ofrece oportunidades más atractivas para ti y que van
                más acorde con tu objetivo laboral.{" "}
              </strong>
            </p>

            <p className={classes2.texto}>
              Así además de dar una respuesta elegante explicando las razones
              por las que dejaste tu anterior trabajo, aprovechas para hablar
              bien de la empresa por la que buscas ser contratado.&nbsp;
            </p>

            <p className={classes2.texto}>
              De cualquier manera, lo mejor que puedes hacer es ensayar esta
              respuesta ya que es muy probable que debes responderla en las
              entrevistas laborales, es importante que no te trabes a la hora de
              explicar esto ya que no se vería natural.&nbsp;
            </p>

            <p className={classes2.texto}>
              Lo fundamental es que entiendas que sea cual sea la razón por la
              que dejaste el empleo, es necesario que elijas adecuadamente tus
              respuestas, siempre es posible sacar ventaja de estas.&nbsp;
            </p>
          </div>
          <br />
          <br />
        </GridItem>
      </GridContainer>
    </div>
  );
}
